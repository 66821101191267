import { Link } from "react-router-dom";
import { content_eng } from "ContentManagement/english";

const SkillSelectorInfo = () => {
    const info = content_eng["info"]["addSkiills"];
    return (
        <>
            <p className={"body_small text-secondary-900"}>{info[1]}</p>
            <br />
            <p className={"body_small text-secondary-900"}>
                {info[2]}{" "}
                <b>
                    <Link to={"/app/skills/add-skills"}>{info[3]}</Link>
                </b>{" "}
            </p>
        </>
    );
};

export default SkillSelectorInfo;
