import React, { useState } from "react";
import { meVar } from "GraphQL/ReactiveVariables";
import {
    Card,
    SkipTaskFeedback,
    ButtonDialog,
    TaskFeedbackView,
    CreateFeedback,
    FeedbackFormHeader
} from "ComponentIndex";
import { verifyEditor } from "./Utils";
import { isOwnerOrAdminCurrentVar } from "UtilityFunctionIndex";
import { useLoadForTour } from "HookIndex";

const TaskCard = ({ task, opDisplay }) => {
    useLoadForTour(1, "feedbackTour", 0);

    const me = meVar();
    const editor = verifyEditor(me, task);
    const validRole = isOwnerOrAdminCurrentVar();

    const [open, setOpen] = useState(false);
    const [auxOpen, setAuxOpen] = useState(false);

    return (
        <Card type={"light"} strech={true} rounded={"xl"}>
            <div className={"flex flex-col w-full gap-y-4"}>
                <FeedbackFormHeader task={task} />

                <div className={"flex flex-row-reverse gap-4"}>
                    {validRole ? (
                        <>
                            {opDisplay.displaySkip && (
                                <div id={"skip_button"}>
                                    <SkipTaskFeedback task={task} />
                                </div>
                            )}
                            {opDisplay.dispalyFeedback && (
                                <div id={"feedback_button"}>
                                    <ButtonDialog
                                        open={open}
                                        setOpen={setOpen}
                                        variant={"filled"}
                                        label={"Feedback"}
                                        icon={"rate_review"}>
                                        <CreateFeedback
                                            setOpen={setOpen}
                                            taskData={task}
                                            feedbackStatus={"PE"}
                                            create={true}
                                        />
                                    </ButtonDialog>
                                </div>
                            )}

                            {opDisplay.displayEdit && editor && (
                                <div>
                                    <ButtonDialog
                                        label={"edit"}
                                        open={open}
                                        variant={"tonal"}
                                        icon={"edit"}
                                        setOpen={setOpen}>
                                        <CreateFeedback
                                            setOpen={setOpen}
                                            taskData={task}
                                            feedbackStatus={"GV"}
                                            create={false}
                                        />
                                    </ButtonDialog>
                                </div>
                            )}
                        </>
                    ) : null}
                    {opDisplay.displayView && (
                        <div>
                            <ButtonDialog
                                label={"view"}
                                variant={"outline"}
                                icon={"book"}
                                open={auxOpen}
                                setOpen={setAuxOpen}>
                                <TaskFeedbackView
                                    setOpen={setAuxOpen}
                                    id={task.id}
                                />
                            </ButtonDialog>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default TaskCard;
