import React, { useState } from "react";
import { ButtonDialog, LeaveFeedbackSpaceDialog } from "ComponentIndex";

const LeaveFeedbackSpaceButton = ({ feedbackSpace }) => {
    const [openLeave, setOpenLeave] = useState(false);
    return (
        <ButtonDialog
            label={"Leave"}
            open={openLeave}
            setOpen={setOpenLeave}
            icon={"exit_to_app"}>
            <LeaveFeedbackSpaceDialog
                feedbackSpace={feedbackSpace}
                setOpen={setOpenLeave}
            />
        </ButtonDialog>
    );
};

export default LeaveFeedbackSpaceButton;
