import React from "react";
import classnames from "classnames";
import cls from "./IconButton.module.css";

const IconButton = ({
    icon,
    size,
    variant,
    color = "primary",
    type = "button",
    shadow,
    title = "",
    ...rest
}) => {
    const buttonClass = classnames(cls.button, {
        [cls.buttonSmall]: size === "small",
        [cls.buttonMedium]: size === "medium",
        [cls.buttonLarge]: size === "large",
        [cls.filledButton]: variant === "filled",
        [cls.tonalButton]: variant === "tonal",
        [cls.outlineButton]: variant === "outline",
        [cls.standardButton]: variant === "standard"
    });

    const iconClass = classnames(
        cls.fontControl,
        "material-symbols-rounded",
        {
            [cls.iconSmall]: size === "small",
            [cls.iconMedium]: size === "medium",
            [cls.iconLarge]: size === "large",
            [cls.iconFilled]: variant === "filled",
            [cls.iconTonal]: variant === "tonal",
            [cls.iconOutline]: variant === "outline",
            [cls.iconStandard]: variant === "standard",
            [cls.iconPrimary]: color === "primary",
            [cls.iconPrimaryLight]: color === "primaryLight",
            [cls.iconLight]: color === "light",
            [cls.iconDarck]: color === "dark",
            [cls.shadow]: shadow === true
        }
    );

    return (
        <button
            {...rest}
            type={type}
            title={title}
            className={buttonClass}>
            <span className={iconClass}>{icon}</span>
        </button>
    );
};

export default IconButton;
