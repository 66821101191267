import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { clearCacheItem } from "GraphQL/Cache";
import { deepSyncVar } from "GraphQL/ReactiveVariables";
import {
    PROJECT_SYNC_STATUS_MUTATION,
    ASSIGNEE_SYNC_STATUS_MUTATION
} from "GraphQL/Mutations";

const loadParameters = (parameter) => {
    switch (parameter) {
        case "projects":
            return {
                instruction: PROJECT_SYNC_STATUS_MUTATION,
                objectSyncParam: "projectSyncStatus"
            };
        case "assigneesParameters":
            return {
                instruction: ASSIGNEE_SYNC_STATUS_MUTATION,
                objectSyncParam: "assigneeSyncStatus"
            };
        default:
            return null;
    }
};

export const useSyncSwitch = (
    initialState,
    feedbackSpaceId,
    objectId,
    parameter
) => {
    const params = loadParameters(parameter);
    let [syncState, setSyncState] = useState(initialState);
    let [makeSyncChange] = useMutation(params.instruction, {
        variables: { feedbackSpaceId: feedbackSpaceId },
        onCompleted: () => deepSyncVar(true)
    });
    useEffect(() => {
        setSyncState(initialState);
    }, [initialState]);
    let handleChange = (event) => {
        setSyncState(event.target.checked);
        let variables = {};
        variables.id = objectId;
        variables[params.objectSyncParam] = event.target.checked;
        makeSyncChange({ variables: variables });
        if (!event.target.checked) {
            clearCacheItem(objectId);
        }
    };

    return { syncState, handleChange };
};
