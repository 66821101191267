const BlogTakeAwayHeader = ({ content }) => {
    return (
        <h4
            className={
                "blog_headline_small pb-2 pt-2 text-secondary-900"
            }>
            {content.mainTakeAway.title[0]}
        </h4>
    );
};

export default BlogTakeAwayHeader;
