import {
    BlogKicker,
    HelmetInclude,
    BlogHeader,
    BlogSubHeader,
    BlogTakeAwayHeader,
    BlogOli,
    BlogParagraph,
    BlogCompartmentHeader,
    BlogParagraphHeader,
    BlogBold,
    BlogCategory,
    BlogLink,
    BlogExternalLink
} from "ComponentIndex";
import { blogContentVar } from "GraphQL/ReactiveVariables";
import { currentArticleVar } from "GraphQL/ReactiveVariables";
import HeaderImg from "Images/Blog/CognitiveBiasesInPerformanceArticle/heading-gradient.png";
import OpticalIllusionImg from "Images/Blog/CognitiveBiasesInPerformanceArticle/optical-illusion.png";
import BiasIllusionImg from "Images/Blog/CognitiveBiasesInPerformanceArticle/bias-illusion.png";
import HumanUniversImg from "Images/Blog/CognitiveBiasesInPerformanceArticle/human-univers.png";
import {
    ImpactfullFeedbackArticleLink,
    SystemicProbemsArticleLink,
    TaskBasedFeedbackArticleLink
} from "BlogLinkIndex";
import { useMoveToPageTop } from "HookIndex";

const CONGNITIVE_BIAS_LINK =
    "https://upload.wikimedia.org/wikipedia/commons/6/65/Cognitive_bias_codex_en.svg";

const CognitiveBiasesInPerformanceArticle = ({ articleName }) => {
    const content =
        blogContentVar().CognitiveBiasesInPerformanceArticle;
    useMoveToPageTop();
    currentArticleVar(articleName);

    return (
        <>
            <HelmetInclude content={content} />
            <div>
                <img
                    className={"w-full"}
                    src={HeaderImg}
                    alt={content}
                />
                <div className={"py-8"}>
                    <BlogKicker content={content} />
                    <BlogHeader content={content} />
                    <BlogSubHeader content={content} />
                </div>

                <div>
                    <BlogTakeAwayHeader content={content} />

                    <ol className={"list-inside"}>
                        {content.mainTakeAway.content.map(
                            (item, index) => (
                                <BlogOli key={`takeaways${index}`}>
                                    {item}
                                </BlogOli>
                            )
                        )}
                    </ol>
                </div>
                <BlogParagraph>
                    {content.beforeOpticalIllusion[0]}
                </BlogParagraph>
                <img
                    className={"my-12 w-full"}
                    src={OpticalIllusionImg}
                    alt={content.altText.opticalIllusion[0]}
                />
                <BlogParagraph>
                    {content.afterOpticalIllusion[0]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.cognitiveBiasesIllusions.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.cognitiveBiasesIllusions.content[0]}
                </BlogParagraph>
                <img
                    className={"my-12 w-full"}
                    src={BiasIllusionImg}
                    alt={content.altText.biaslIllusion[0]}
                />
                <BlogParagraph>
                    {content.cognitiveBiasesIllusions.content[1]}
                    <BlogExternalLink to={CONGNITIVE_BIAS_LINK}>
                        {content.cognitiveBiasesIllusions.content[2]}
                    </BlogExternalLink>
                    {content.cognitiveBiasesIllusions.content[3]}
                </BlogParagraph>

                <BlogParagraph>
                    <BlogBold>
                        {
                            content.cognitiveBiasesIllusions.anchoring
                                .title[0]
                        }
                    </BlogBold>
                    {
                        content.cognitiveBiasesIllusions.anchoring
                            .content[0]
                    }
                </BlogParagraph>
                <BlogParagraph>
                    <BlogBold>
                        {
                            content.cognitiveBiasesIllusions
                                .availability.title[0]
                        }
                    </BlogBold>
                    {
                        content.cognitiveBiasesIllusions.availability
                            .content[0]
                    }
                </BlogParagraph>
                <BlogParagraph>
                    <BlogBold>
                        {
                            content.cognitiveBiasesIllusions.affinity
                                .title[0]
                        }
                    </BlogBold>
                    {
                        content.cognitiveBiasesIllusions.affinity
                            .content[0]
                    }
                </BlogParagraph>
                <BlogParagraph>
                    <BlogBold>
                        {
                            content.cognitiveBiasesIllusions
                                .confirmation.title[0]
                        }
                    </BlogBold>
                    {
                        content.cognitiveBiasesIllusions.confirmation
                            .content[0]
                    }
                </BlogParagraph>
                <BlogParagraph>
                    <BlogBold>
                        {
                            content.cognitiveBiasesIllusions.attribution
                                .title[0]
                        }
                    </BlogBold>
                    {
                        content.cognitiveBiasesIllusions.attribution
                            .content[0]
                    }
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.reviewsProneToBias.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.reviewsProneToBias.content[0]}
                    {content.reviewsProneToBias.content[1]}
                    {content.reviewsProneToBias.content[2]}
                    {content.reviewsProneToBias.content[3]}
                </BlogParagraph>
                <img
                    className={"my-12 w-full"}
                    src={HumanUniversImg}
                    alt={content.altText.humanUnivers[0]}
                />
                <BlogParagraph>
                    {content.reviewsProneToBias.content[4]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.reviewsProneToBias.content[5]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.reviewsProneToBias.content[6]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.reduceBias.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.reduceBias.content[0]}
                    <BlogLink to={SystemicProbemsArticleLink}>
                        {content.reduceBias.content[1]}
                    </BlogLink>

                    {content.reduceBias.content[2]}
                    <BlogLink to={TaskBasedFeedbackArticleLink}>
                        {content.reduceBias.content[3]}
                    </BlogLink>
                    {content.reduceBias.content[4]}
                    <BlogLink to={ImpactfullFeedbackArticleLink}>
                        {content.reduceBias.content[5]}
                    </BlogLink>
                    {content.reduceBias.content[6]}
                </BlogParagraph>

                <BlogCategory content={content} />
            </div>
        </>
    );
};

export default CognitiveBiasesInPerformanceArticle;
