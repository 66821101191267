import { useMutation } from "@apollo/client";
import { useState } from "react";
import { LOGIN_MUTATION } from "GraphQL/Mutations";

export const useLoginUser = () => {
    const [logInUser, { loading, error }] = useMutation(LOGIN_MUTATION);

    const [formState, setFormState] = useState({
        email: "",
        password: ""
    });
    const handleFieldChange = (e) => {
        setFormState({
            ...formState,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        logInUser({
            variables: {
                email: formState.email,
                password: formState.password
            }
        })
            .then((data) => {
                window.location.reload();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return {
        loading,
        error,
        formState,
        handleFieldChange,
        handleSubmit
    };
};
