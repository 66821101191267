import React from "react";
import { tourHandlerFactory } from "./TourParameters/TriggerHandlers";

const TourTextLine = ({ text, handlerType }) => {
    const handler = tourHandlerFactory(handlerType);
    return (
        <p
            className={"label_large text-shocking-700 cursor-pointer"}
            onClick={handler}>
            {text}
        </p>
    );
};

export default TourTextLine;
