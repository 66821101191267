import React, { useState } from "react";
import { ButtonDialog, TransferRights } from "ComponentIndex";

const TransferRightsDialog = ({ recordItem }) => {
    const [open, setOpen] = useState(false);
    return (
        <ButtonDialog
            icon={"transfer_within_a_station"}
            label={"Transfer"}
            open={open}
            setOpen={setOpen}>
            <TransferRights recordItem={recordItem} setOpen={setOpen} />
        </ButtonDialog>
    );
};

export default TransferRightsDialog;
