import React from "react";
import logo from "Images/hero.png";
import { SubscriptionForm, ContentWrapperBand } from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const USP = () => {
    const content = landingContentVar().USP;
    return (
        <ContentWrapperBand color={"bg-shocking-50"}>
            <div
                className={
                    "min-h-screen-1/2 flex flex-col px-8 py-16 lg:flex-row items-stretch"
                }
                id={"home"}>
                <div
                    className={
                        "flex-1 flex content-between  justify-center flex-col pr-8"
                    }>
                    <h1
                        className={
                            "display_large text-shocking-700 mb-4 font-bold"
                        }>
                        {content.header[0]}
                    </h1>
                    <h2
                        className={
                            "headline_medium text-secondary-700"
                        }>
                        {content.description[0]}
                    </h2>
                    <div className={"mt-16 w-full"}>
                        <SubscriptionForm />
                    </div>
                </div>
                <div
                    className={
                        "flex-1 flex justify-center items-center mt-16 lg:mt-0"
                    }>
                    <img
                        className='lg:w-full'
                        src={logo}
                        alt={"Enamply logo"}
                    />
                </div>
            </div>
        </ContentWrapperBand>
    );
};

export default USP;
