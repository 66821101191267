import { makeVar } from "@apollo/client";
import { v4 } from "uuid";
export const drawerStateVar = makeVar(false);
export const drawerWidth = makeVar(true);

export const meVar = makeVar({ email: "", userName: "", id: null });

export const currentFeedbackSpaceVar = makeVar({
    id: null,
    feedbackSpaceName: "",
    role: ""
});

// NOTE: eventualyy can be split depending on user prefferancess now used both for landing and app
export const languageVar = makeVar("en");

// TODO: Decide what to do
export const isLoggedInVar = makeVar(false);

export const pageSizeVar = makeVar(20);

export const tasksDueVar = makeVar(null);

// filters
export const pageVar = makeVar(1);
export const pagesVar = makeVar();
export const searchVar = makeVar("");
export const fbStartDateVar = makeVar(null);
export const fbEndDateVar = makeVar(null);
export const taskStartDateVar = makeVar(null);
export const taskEndDateVar = makeVar(null);
export const activeTabVar = makeVar("");
export const skillTypesVar = makeVar([]);
export const skillIdsVar = makeVar([]);
export const projectIdsVar = makeVar([]);
export const assigneeParametersIdsVar = makeVar([]);
export const taskTypeNamesVar = makeVar([]);
export const dataSourceIdsVar = makeVar([]);

export const assigneesParametersForFilterVar = makeVar([]);
export const taskTypesForFilterVar = makeVar([]);
export const dataSourceForFilterVar = makeVar([]);
export const projectsForFilterVar = makeVar([]);
export const skillsForFilterVar = makeVar([]);
export const skillTypesForFilterVar = makeVar([]);
export const inventorySkillTypesForFilterVar = makeVar([]);

export const workspaceNameVar = makeVar("");

export const screenSizeVar = makeVar({});

export const skillsPageVar = makeVar("");

export const feedbackSpacePageVar = makeVar("");

// Update all sync parameters
export const selectAllProjectsVar = makeVar(true);
export const selectAllAssigneesVar = makeVar(true);

// Localization
export const appContentVar = makeVar("");
export const landingContentVar = makeVar("");
export const blogContentVar = makeVar("");

// Product Guide State

export const userTourPreferanceVar = makeVar(null);

export const productGuidesVar = makeVar({
    run: false,
    callBackType: null,
    tourActive: false,
    stepIndex: 0
});

export const currentArticleVar = makeVar("");

export const deepSyncVar = makeVar(false);

export const userLogVar = makeVar([]);
export const sessionVar = makeVar(v4());

