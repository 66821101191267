import React from "react";
import { useReactiveVar } from "@apollo/client";
import { Link } from "react-router-dom";
import {
    currentArticleVar,
    blogContentVar
} from "GraphQL/ReactiveVariables";

const BlogSideCard = ({ articleName, link }) => {
    const content = blogContentVar().BlogIndex[articleName];
    const currentArticle = useReactiveVar(currentArticleVar);

    return (
        <>
            {articleName !== currentArticle ? (
                <Link className={"blog_body_medium "} to={link}>
                    {content.title[0]}
                </Link>
            ) : null}
        </>
    );
};

export default BlogSideCard;
