import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER_PREFERANCES_QUERY } from "GraphQL/Queries";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import {
    currentFeedbackSpaceVar,
    userTourPreferanceVar
} from "GraphQL/ReactiveVariables";
import { useAppContent } from "HookIndex";

export const useGetUserPreference = () => {
    let navigate = useNavigate();
    const { data, loading, error } = useQuery(
        GET_USER_PREFERANCES_QUERY
    );
    useAppContent();

    useEffect(() => {
        if (data && data.userPreferances) {
            currentFeedbackSpaceVar({
                id: masterAcc(data, "userPreference", "id"),
                feedbackSpaceName: masterAcc(
                    data,
                    "userPreference",
                    "name"
                ),
                role: masterAcc(data, "userPreference", "role")
            });
            userTourPreferanceVar(
                masterAcc(data, "userPreference", "tour")
            );
            // TODO: EXPLORE this rof tproper redirects
            navigate("/app");
        } else if (data && data.userPreferances == null) {
            currentFeedbackSpaceVar({
                id: null,
                feedbackSpaceName: "",
                role: ""
            });
            navigate("/app/message-boards/no-feedback-space");
        }
    }, [loading, data]);
    return { data, error, loading };
};
