import React from "react";

const Capsule = ({ children }) => {
    return (
        <div
            className={
                "flex items-center border  border-shocking-50 focus_ring rounded-full"
            }>
            {children}
        </div>
    );
};

export default Capsule;
