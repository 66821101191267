import React from "react";
import { useMassParameterSwitch } from "CustomHooks/CustomMutations";

const SelectAllForSync = ({ feedbackSpace, parameter }) => {
    const [select, handleChangeAll] = useMassParameterSwitch(
        parameter,
        feedbackSpace.id
    );
    return (
        <div className={"flex flex-row gap-x-4 mb-6"}>
            <input
                className={"accent-shocking-700"}
                type={"checkbox"}
                checked={select}
                onChange={handleChangeAll}
            />
            <p
                className={"label_medium text-shocking-700"}
                color={"primary"}>
                {" set all"}
            </p>
        </div>
    );
};

export default SelectAllForSync;
