import React from "react";
import {
    MainLayout,
    FeedbackSpaceNotAvailableActions,
    FeedbackSpaceNotAvailableContent,
    MessagesNavigation
} from "ComponentIndex";

const FeedbackSpaceNotAvailableComponent = () => {
    return (
        <MainLayout
            navigation={<MessagesNavigation />}
            action={<FeedbackSpaceNotAvailableActions />}
            content={<FeedbackSpaceNotAvailableContent />}
            workspace={"Feedback Space not available"}
            title={"Feedback Space not available"}
            activeTab={""}
            logCode={"PNAPL"}
        />
    );
};

export default FeedbackSpaceNotAvailableComponent;
