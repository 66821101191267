import React, { useState } from "react";
import { ButtonDialog, NotificationSettings } from "ComponentIndex";

const ManageNotificationSettings = ({ feedbackSpace }) => {
    const [open, setOpen] = useState(false);
    return (
        <ButtonDialog
            id={"notifications-tettings"}
            label={"Notifications"}
            open={open}
            setOpen={setOpen}
            icon={"notifications"}
            variant={"outline"}>
            <NotificationSettings
                setOpen={setOpen}
                feedbackSpace={feedbackSpace}
            />
        </ButtonDialog>
    );
};

export default ManageNotificationSettings;
