import React from "react";
import { useSyncSwitch } from "./Utils";

const SyncSwitch = ({
    initialState,
    objectId,
    feedbackSpaceId,
    parameter
}) => {
    const { syncState, handleChange } = useSyncSwitch(
        initialState,
        feedbackSpaceId,
        objectId,
        parameter
    );

    return (
        <input
            className={"accent-shocking-700"}
            type={"checkbox"}
            onChange={handleChange}
            checked={syncState}
        />
    );
};

export default SyncSwitch;
