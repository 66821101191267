import React from "react";
import { Label } from "ChartIndex";

const Bar = ({
    data,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onClick,
    textOffestY = 0,
    textOffestX = 0
}) => {
    return (
        <>
            {data && (
                <g>
                    <rect
                        className={"fill-secondary-300"}
                        x={`${data.plot.x}`}
                        y={`${data.plot.y}`}
                        width={`${data.plot.width}`}
                        height={`${data.plot.height}`}
                        rx={`${data.plot.rx}`}
                        ry={`${data.plot.ry}`}
                    />
                    <rect
                        className={
                            "fill-transparent cursor-pointer hover:fill-shocking-700"
                        }
                        onMouseEnter={onMouseEnter}
                        onMouseMove={onMouseMove}
                        onMouseLeave={onMouseLeave}
                        onClick={onClick}
                        x={`${data.plot.x}`}
                        y={`${data.plot.y}`}
                        width={`${data.plot.width + 1}`}
                        height={`${data.plot.height + 1}`}
                        rx={`${data.plot.rx}`}
                        ry={`${data.plot.ry}`}
                    />
                    <Label
                        x={`${data.label.x + textOffestX}`}
                        y={`${data.label.y + textOffestY}`}
                        type={"data_label"}
                        color={"dark"}
                        anchor={"start"}>
                        {data.label.text}
                    </Label>
                </g>
            )}
        </>
    );
};

export default Bar;
