import React from "react";
import { Route, Routes } from "react-router-dom";
import { useLandingContent } from "CustomHooks/CustomHooks";
import {
    HomeContent,
    RegisterContent,
    SendPasswordRecoveryContent,
    PasswordResetContent,
    VerifyAccountContent,
    ResendEmailVerificationContent,
    PublicRoute,
    PublicMainLayout,
    PublicFeaturesContent,
    HomeHeader,
    HomeMenu,
    HomeMenuItems,
    HomeSignButton,
    HeaderMenu,
    PrivacyPolicyContent,
    TermsAndConditionsContent,
    ContactForDemoContent,
    EuStartupSummitContent,
    BlogContent,
    SignInContent
} from "ComponentIndex";

let PublicRoot = () => {
    useLandingContent();
    return (
        <PublicMainLayout>
            <HomeHeader
                leftPart={<HomeMenu>{<HomeMenuItems />}</HomeMenu>}
                actions={<HomeSignButton />}
                rightPart={
                    <HeaderMenu>
                        {
                            <>
                                <HomeMenuItems /> <HomeSignButton />
                            </>
                        }
                    </HeaderMenu>
                }
            />
            <Routes>
                <Route index path={"/"} element={<HomeContent />} />
                <Route
                    path={"/features"}
                    element={<PublicFeaturesContent />}
                />
                <Route path={"/blog/*"} element={<BlogContent />} />
                <Route
                    path={"/register"}
                    element={<RegisterContent />}
                />
                <Route
                    path={"/signing-in"}
                    element={<SignInContent />}
                />
                <Route
                    path={"/reset-password/:token"}
                    element={<PasswordResetContent />}
                />
                <Route
                    path={"/activate/:token/:userEmail"}
                    element={<VerifyAccountContent />}
                />
                <Route
                    path={"/resend-verification"}
                    element={<ResendEmailVerificationContent />}
                />
                <Route
                    path={"/recover-password"}
                    element={<SendPasswordRecoveryContent />}
                />
                <Route path={"/login"} element={<PublicRoute />} />
                <Route
                    path={"/privacy-policy"}
                    element={<PrivacyPolicyContent />}
                />
                <Route
                    path={"/terms"}
                    element={<TermsAndConditionsContent />}
                />
                <Route
                    path={"/contact-us"}
                    element={<ContactForDemoContent />}
                />
                <Route
                    path={"/eu-startup-summit"}
                    element={<EuStartupSummitContent />}
                />
            </Routes>
        </PublicMainLayout>
    );
};

export default PublicRoot;
