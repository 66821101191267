import { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
    SYNC_PROJECTS_MUTATION,
    SYNC_ASSIGNEES_MUTATION,
    SYNC_ATLASSIAN_PROJECTS_MUTATION,
    SYNC_ATLASSIAN_ASSIGNEES_MUTATION
} from "GraphQL/Mutations";
import {
    GET_PROJECT_QUERY,
    GET_ASSIGNEE_PARAMETERS_QUERY,
    CELERY_TASK_STATUS
} from "GraphQL/Queries";

export const syncParameterSwitch = (parameter) => {
    switch (parameter) {
        case "assigneesParameters":
            return {
                asanaInstruction: SYNC_ASSIGNEES_MUTATION,
                atlassianInstrctions: SYNC_ATLASSIAN_ASSIGNEES_MUTATION,
                refetchQuery: GET_ASSIGNEE_PARAMETERS_QUERY,
                dataLabel: "syncAssignees"
            };
        case "projects":
            return {
                asanaInstruction: SYNC_PROJECTS_MUTATION,
                atlassianInstrctions: SYNC_ATLASSIAN_PROJECTS_MUTATION,
                refetchQuery: GET_PROJECT_QUERY,
                dataLabel: "syncProjects"
            };
        default:
            return null;
    }
};

export const useSyncParameters = (parameter, feedbackSpaceId) => {
    let [syncStatus, setSyncStatus] = useState(false);
    const params = syncParameterSwitch(parameter);

    let [paramSync, { data, error, loading }] = useMutation(
        params.asanaInstruction,
        {
            variables: {
                feedbackSpaceId: feedbackSpaceId
            }
        }
    );

    let [
        atlassianParamSync,
        {
            data: attlasianData,
            error: atlassianError,
            loading: atlassianLoading
        }
    ] = useMutation(params.atlassianInstrctions, {
        variables: {
            feedbackSpaceId: feedbackSpaceId
        }
    });

    let [
        getTaskStatus,
        { data: taskSyncData, startPolling, stopPolling }
    ] = useLazyQuery(CELERY_TASK_STATUS);

    let handleSyncParameter = (e) => {
        atlassianParamSync().then((result) => {
            // TODO do something with this
        });
        paramSync().then((result) => {
            setSyncStatus(result.data[params.dataLabel].syncInitiation);
            getTaskStatus({
                variables: {
                    celeryTaskId:
                        result.data[params.dataLabel].celeryTaskId
                }
            });
        });
    };

    let [refetchData] = useLazyQuery(params.refetchQuery, {
        variables: {
            feedbackSpaceId: feedbackSpaceId
        }
    });

    useEffect(() => {
        if (syncStatus) {
            startPolling(5000);
        }
        if (
            taskSyncData &&
            taskSyncData.celeryTaskStatus.celeryTaskStatus
        ) {
            stopPolling();
            setSyncStatus(false);
            refetchData();
        }
    }, [taskSyncData]);

    return {
        syncStatus,
        handleSyncParameter,
        error,
        loading
    };
};
