import React from "react";
import { useAsanaAuthVerification } from "CustomHooks/CustomQueries";
import AsanaLogo from "Images/Asana-vertical.svg";
import {
    AuthorizeAsana,
    DeAuthorizeAsana,
    ConditionalOutput,
    Card
} from "ComponentIndex";

const AsanaAuthorization = () => {
    const [authorized, setAuthorized, data, loading, error] =
        useAsanaAuthVerification();

    return (
        <Card type={"light"} strech={false} elevation={"sm"}>
            <div
                className={
                    "flex flex-row justify-between items-center p-8"
                }>
                <img
                    className={"w-28 "}
                    src={AsanaLogo}
                    alt={
                        "Triangle made out of three orange spheres below it asana name"
                    }
                />
                <ConditionalOutput loading={loading} error={error}>
                    {authorized !== null ? (
                        <>
                            {authorized ? (
                                <DeAuthorizeAsana
                                    setAuthorized={setAuthorized}
                                />
                            ) : (
                                <AuthorizeAsana />
                            )}
                        </>
                    ) : null}
                </ConditionalOutput>
            </div>
        </Card>
    );
};

export default AsanaAuthorization;
