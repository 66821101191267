import {
    adjustXCoordsToBounds,
    adjustYCoordsToBounds
} from "UtilityFunctionIndex";
import {
    varianceRangeParams,
    varianceRangeLabels
} from "frontendSettings";

export const strokeColorChange = (value) => {
    if (value <= 33) {
        return "#32936f";
    } else if (value <= 66) {
        return "#ffbf00";
    } else if (value <= 100) {
        return "#e83f6f";
    } else {
        return null;
    }
};

export const generateScatterPlotData = (
    data,
    xScale,
    yScale,
    xAccessor,
    yAccessor,
    nameItem,
    offset
) => {
    const yScaledMax = yScale(100);
    const yScaledMin = yScale(0);
    const xScaledMax = xScale(100);
    const xScaledMin = xScale(0);
    return data.map((item) => {
        return {
            mean: adjustXCoordsToBounds(
                xScale(xAccessor(item)),
                offset,
                xScaledMin,
                xScaledMax
            ),
            range: adjustYCoordsToBounds(
                yScale(yAccessor(item)),
                offset,
                yScaledMin,
                yScaledMax
            ),
            name: item[nameItem],
            brimColor: {
                stroke: strokeColorChange(yAccessor(item), yScale)
            }
        };
    });
};

export const generateVarianceGrid = (yScale) => {
    return varianceRangeParams.map((item) => {
        return yScale(item.coord);
    });
};

export const generateValianceLabels = (yScale) => {
    return varianceRangeLabels.map((item) => {
        return {
            y: yScale(item.coord),
            label: item.param
        };
    });
};
