import React from "react";
import { AxisTick, Label } from "ChartIndex";

const AxisLeft = ({
    dataSequence,
    length,
    xOffset,
    yOffset,
    textOffsetX,
    textOffsetY,
    textRotation,
    textAnchor = "end",
    labelLength = null
}) => {
    return (
        <>
            {dataSequence && (
                <g
                    className={"bAxis"}
                    transform={`translate(${xOffset},${yOffset})`}>
                    {dataSequence.map((item, index) => {
                        return (
                            <g
                                key={`axisElement${index}`}
                                transform={`translate(0,${item.y})`}>
                                <AxisTick
                                    length={length}
                                    horizontal={true}
                                />
                                <Label
                                    type={"data_label"}
                                    color={"dark"}
                                    anchor={textAnchor}
                                    x={textOffsetX}
                                    y={textOffsetY}
                                    labelLength={labelLength}
                                    rotation={textRotation}>
                                    {item.label}
                                </Label>
                            </g>
                        );
                    })}
                </g>
            )}
        </>
    );
};

export default AxisLeft;
