import { useMutation } from "@apollo/client";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import reduxActions from "State/ActionIndex";
import {
    Button,
    Card,
    Title,
    ConditionalOutput,
    ContentWrapperBand,
    PageToScreenWrapper,
    HelmetExclude
} from "ComponentIndex";
import { useParams } from "react-router";
import { VERIFY_EMAIL_MUTATION } from "GraphQL/Mutations";

const VerifyAccountContent = () => {
    workspaceNameVar("Verify Email");
    const { token, userEmail } = useParams();
    const [verifyEmail, { loading, error }] = useMutation(
        VERIFY_EMAIL_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    "Verification has been successfull please proceed to login page.",
                    "success"
                );
            }
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        verifyEmail({
            variables: {
                email: userEmail,
                verificationToken: token
            }
        })
            .then((data) => {})
            .catch((e) => {});
    };

    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Card
                        type={"light"}
                        strech={false}
                        elevation={"lg"}>
                        <div
                            className={
                                "flex flex-col h-  w-80 gap-y-4"
                            }>
                            <Title>Email verification</Title>
                            <h2 className={"title_medium mb-6"}>
                                Thank you for registering with Enamply
                            </h2>
                            <h3 className={"title_medium"}>
                                Please press the button below to verify
                                your email.
                            </h3>
                            <ConditionalOutput
                                error={error}
                                loading={loading}>
                                <form
                                    className={
                                        "flex flex-col h-  w-80 gap-y-4"
                                    }
                                    onSubmit={handleSubmit}>
                                    <div className={"w-full mb-8"}>
                                        <p
                                            className={
                                                "label_large text-secondary-900"
                                            }>
                                            <span
                                                className={
                                                    "label_large text-shocking-800"
                                                }>
                                                Your email:{" "}
                                            </span>
                                            <span
                                                className={
                                                    "body_large text-secondary-800"
                                                }>
                                                {userEmail}
                                            </span>
                                        </p>
                                    </div>
                                    <div className={"self-end"}>
                                        <Button
                                            variant={"filled"}
                                            type={"submit"}>
                                            verify email
                                        </Button>
                                    </div>
                                </form>
                            </ConditionalOutput>
                        </div>
                    </Card>
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default VerifyAccountContent;
