import React, { useEffect } from "react";
import classnames from "classnames";
import cls from "./Switch.module.css";

const Switch = ({ onChange, checked }) => {
    const switchClass = classnames(cls.switch);
    const sliderClass = classnames(cls.slider, cls.round);
    useEffect(() => {}, [checked]);
    return (
        <label className={switchClass}>
            <input
                type={"checkbox"}
                checked={checked}
                onChange={onChange}
            />
            <span className={sliderClass}></span>
        </label>
    );
};

export default Switch;
