import { AvgRangeChart, ConditionalOutput } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { useLoadForTour } from "HookIndex";
import { useGetRangeFeedbackData } from "./Utils";

const X_ACCESSOR = (d) => {
    return d.xAxis;
};

const Y_ACCESSOR = (d) => {
    return d.yAxis;
};

const RangeAvgFeedback = () => {
    useLoadForTour(1, "insightsTour", 0);
    useLoadForTour(3, "insightsTour", 4);
    const content = appContentVar().RangeAvgFeedback;

    const { data, loading, error } = useGetRangeFeedbackData();

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <AvgRangeChart
                    data={data.varianceToSatisfaction}
                    xAccessor={X_ACCESSOR}
                    yAccessor={Y_ACCESSOR}
                    chartName={content.chartName[0]}
                    yAxisLabel={content.yAxisLabel[0]}
                    nameItem={"assigneeName"}
                />
            ) : null}
        </ConditionalOutput>
    );
};

export default RangeAvgFeedback;
