import { useEffect, useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_USERS_ROLE } from "GraphQL/Mutations";
import reduxActions from "State/ActionIndex";

const selectionToInstruction = (selectedItem) => {
    // Function translates selected to instruction for mutation instruction
    // for credential change
    switch (selectedItem) {
        case "Admin":
            return "ADM";
        case "Member":
            return "MEM";
        default:
            return null;
    }
};

const currentRoleToSelection = (status) => {
    // function converts role abbreviation to item from selection list
    switch (status) {
        case "ADM":
            return "Admin";
        case "MEM":
            return "Member";
        default:
            return null;
    }
};

export const useRoleSelection = (recordItem) => {
    const [selectedRole, setSelectedRole] = useState(
        currentRoleToSelection(recordItem.role)
    );
    const initialRender = useRef(false);

    const [setRole] = useMutation(CHANGE_USERS_ROLE);
    useEffect(() => {
        if (initialRender.current) {
            setRole({
                variables: {
                    instruction: selectionToInstruction(selectedRole),
                    feedbackSpaceId: recordItem.feedbackSpace.id,
                    userId: recordItem.memberAccount.id
                }
            })
                .then((data) => {})
                .catch((e) => {
                    reduxActions.addNotification(e.message, "error");
                });
        } else {
            initialRender.current = true;
        }
    }, [selectedRole]);

    const roleItems = ["Admin", "Member"];

    return { selectedRole, setSelectedRole, roleItems };
};
