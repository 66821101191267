import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import reduxActions from "State/ActionIndex";
import { useClickOutside } from "HookIndex";

import {
    InviteMemberForm,
    ConditionalOutput,
    Title,
    Card
} from "ComponentIndex";
import { SEND_FEEDBACK_SPACE_INVITATION_MUTATION } from "GraphQL/Mutations";

let defaultFormState = {
    inviteEmail: ""
};

const InviteMember = ({ feedbackSpaceId, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const [formState, setFormState] = useState(defaultFormState);
    const [sendFeedbackSpaceInvitation, { error, loading }] =
        useMutation(SEND_FEEDBACK_SPACE_INVITATION_MUTATION, {
            onCompleted: () => {
                setOpen(false);
                reduxActions.addNotification(
                    "Invitation has been sent.",
                    "success"
                );
            }
        });

    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        sendFeedbackSpaceInvitation({
            variables: {
                invitedUser: formState.inviteEmail,
                feedbackSpaceId: feedbackSpaceId
            }
        })
            .then((data) => {})
            .catch((e) => {})
            .then(() => {
                setOpen(false);
            });
    };

    return (
        <div ref={containerRef}>
            <Card>
                <Title>Invite new member to feedback space</Title>
                <ConditionalOutput error={error} loading={loading}>
                    <InviteMemberForm
                        handleFormSubmit={handleFormSubmit}
                        handleFieldChange={handleFieldChange}
                        formState={formState}
                    />
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default InviteMember;
