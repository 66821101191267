import React from "react";
import cn from "classnames";

const InputLabelCount = ({ label, input, count, scale, id }) => {
    const blockWrapperClass = cn(
        ["flex flex-col items-start gap-y-1 justify-start"],
        {
            ["w-full"]: scale === "strech"
        }
    );

    return (
        <div id={id} className={blockWrapperClass}>
            <label className={"label_small text-shocking-700"}>
                {label}
            </label>
            <div className={"w-full"}>{input}</div>
            {!!count && <div className={"self-end"}>{count}</div>}
        </div>
    );
};

export default InputLabelCount;
