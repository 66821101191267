import { Link } from "react-router-dom";
import { ContentWrapperBand } from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const DiscoverOurProduct = () => {
    const content = landingContentVar().DiscoverOurProduct;
    return (
        <ContentWrapperBand padding={16}>
            <div
                className={
                    "w-full flex flex-row justify-center pulsate-fwd"
                }>
                <p className={"text-shocking-700 display_medium "}>
                    <Link to={"/features"}>
                        <span>{content.text[0]}</span>
                        <span className={"font-semibold"}>
                            {content.text[1]}
                        </span>
                    </Link>
                </p>
            </div>
        </ContentWrapperBand>
    );
};

export default DiscoverOurProduct;
