import React from "react";

const TextAreaDisplay = ({ children, label }) => {
    return (
        <div className={"flex flex-col w-8/12"}>
            <p className={"label_small text-shocking-700"}>{label}</p>
            <p
                className={
                    "body_medium text-secondary-800 break-words w-full rounded-md border border-secondary-200 p-4"
                }>
                {children}
            </p>
        </div>
    );
};

export default TextAreaDisplay;
