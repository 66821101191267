import React from "react";
import { NavigationBar, TabBar, ActiveTab } from "ComponentIndex";

const ReportNavigation = () => {
    return ( 
        <NavigationBar>
            <TabBar>
                 <ActiveTab
                    id={"skills_insights_tab"}
                    linkTo={`/app/insights/skills-insights`}
                    fieldValue={"skills"}>
                    {"Skills"}
                </ActiveTab>
                <ActiveTab
                    id={"feedback_insights_tab"}
                    linkTo={`/app/insights/feedback-insights`}
                    fieldValue={"feedback"}>
                    {"Feedback"}
                </ActiveTab>
            </TabBar>
        </NavigationBar>
    );
};

export default ReportNavigation;
