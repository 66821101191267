import React from "react";
import cn from "classnames";
import { useReactiveVar } from "@apollo/client";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import { Link } from "react-router-dom";

let LINK_CLASS = "title_medium text-secondary-800 mx-4 cursor-pointer";
let SELECTED_CLASS = "border-b-2 border-shocking-700";

const HomeMenuItems = () => {
    const content = landingContentVar().HomeMenuItems;
    let location = useReactiveVar(workspaceNameVar);
    let solutionClass = cn(LINK_CLASS, {
        [SELECTED_CLASS]: location === "Solution"
    });
    let featuresClass = cn(LINK_CLASS, {
        [SELECTED_CLASS]: location === "Features"
    });
    let blogClass = cn(LINK_CLASS, {
        [SELECTED_CLASS]: location === "Blog"
    });
    return (
        <>
            <Link className={solutionClass} to={"/"}>
                {content.solution[0]}
            </Link>

            <Link
                className={featuresClass}
                to={"/features/#extended_features"}>
                {content.features[0]}
            </Link>
            <Link className={blogClass} to={"/blog/"}>
                {content.blog[0]}
            </Link>
        </>
    );
};

export default HomeMenuItems;
