import React from "react";
import { trimLongLabel } from "UtilityFunctionIndex";

const ChartAvatar = ({
    text,
    x,
    y,
    theme,
    onMouseEnter,
    onMouseLeave,
    onMouseMove,
    onClick,
    ...rest
}) => {
    const RADIUS = 20;

    return (
        <g>
            <circle
                {...rest}
                {...theme}
                className={"fill-shocking-50 stroke-2 shadow-xl "}
                cy={y}
                cx={x}
                r={RADIUS}></circle>
            <text
                textAnchor={"middle"}
                y={y + 4}
                x={x}
                className={"leading-5 body_medium fill-secondary-700"}>
                {trimLongLabel(text, 2)}
            </text>
            <circle
                onMouseEnter={onMouseEnter}
                onMouseMove={onMouseMove}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
                className={
                    "cursor-pointer fill-transparent stroke-2 stroke-transparent"
                }
                cy={y}
                cx={x}
                r={RADIUS + 1}></circle>
        </g>
    );
};

export default ChartAvatar;
