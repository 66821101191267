import React from "react";
import classnames from "classnames";
import cls from "./Area.module.css";

const Area = ({ d }) => {
    const areaClass = classnames(cls.area);
    return <path className={areaClass} d={d} />;
};

export default Area;
