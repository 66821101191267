import React from "react";

import { useSyncParameters } from "./Utils";
import {
    Loading,
    IconButton,
    ConditionalOutput,
    Button
} from "ComponentIndex";

const SyncParameters = ({ feedbackSpaceId, parameter, title }) => {
    const { syncStatus, handleSyncParameter, data, error, loading } =
        useSyncParameters(parameter, feedbackSpaceId);

    return (
        <ConditionalOutput error={error} loading={loading}>
            {syncStatus ? (
                <Loading size={"small"} />
            ) : (
                <Button
                    title={title}
                    icon={"update"}
                    variant={"text"}
                    onClick={handleSyncParameter}>
                    {"Update list"}
                </Button>
            )}
        </ConditionalOutput>
    );
};

export default SyncParameters;
