import React from "react";
import cn from "classnames";

const Avatar = ({ name, imgSrc = null, onClick }) => {
    const avatarClass = cn([
        "flex items-center content-center",
        "justify-center rounded-full cursor-pointer",
        "border-solid border-2 h-9 w-9 border-shocking-400 bg-shocking-50"
    ]);
    return (
        <button onClick={onClick} className={avatarClass}>
            {imgSrc && <img src={imgSrc} alt={"Hexagonal logo"} />}
            {name && <span>{name}</span>}
        </button>
    );
};

export default Avatar;
