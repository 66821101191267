import React from "react";
import classnames from "classnames";
import cls from "./Content.module.css";

const Content = ({ children }) => {
    const contentComponetClass = classnames(cls.contentComponet);
    return <div className={contentComponetClass}>{children}</div>;
};

export default Content;
