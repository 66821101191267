export const Content = (text) => {
    return text.map((item, index) => {
        return (
            <p
                key={`tourText${index}`}
                className={"body_large text-left mb-2"}>
                {item}
            </p>
        );
    });
};

export const Title = (text) => {
    return (
        <span className={"title_large text-shocking-700"}>{text}</span>
    );
};
