import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_SKILL_LIST_FOR_SETUP_QUERY } from "GraphQL/Queries";
import { masterAcc } from "UtilityFunctions/DataAccessors";

export const usePopulateSkills = (skillType) => {
    const [result, setResult] = useState([]);
    const { data, error, loading } = useQuery(
        GET_SKILL_LIST_FOR_SETUP_QUERY,
        {
            variables: {
                skillTypes: [skillType]
            }
        }
    );
    useEffect(() => {
        if (data) {
            setResult(assembleSkillsFromInventory(data));
        }
    }, [loading]);

    return { result, error, loading };
};

const assembleSkillsFromInventory = (data) => {
    if (data.skills) {
        return data.skills.map((item) => {
            return {
                skillName: masterAcc(item, "skills", "skillName"),
                id: masterAcc(item, "skills", "id")
            };
        });
    }
};
