export const nameTheRole = (abbreviation) => {
    switch (abbreviation) {
        case "ADM":
            return "Admin";
        case "MEM":
            return "Member";
        case "OWN":
            return "Owner";
        default:
            return null;
    }
};
