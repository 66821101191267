// **************************
// ASANA Authorization Link
// **************************   

//Remote;

// //  Remote Apollo
export let GRAPHQL_URI = "https://grifle-bk.herokuapp.com/graphql/";
export const PRODUCTION = true;

// // // // Local Apollo
// export let GRAPHQL_URI = "http://127.0.0.1:8000/graphql/";
// export const PRODUCTION = false;

//
//
//
//
//
//
//
//
// Seetings for range chart curve

export let rangeChartTickLabels = [10, 35, 65, 90];
export let rangeChartLabels = [
    "Concerned",
    "Neutral",
    "Satisfied",
    "Excited"
];

export const satRangeParams = [
    { coord: 10, param: "Concerned" },
    { coord: 35, param: "Neutral" },
    { coord: 65, param: "Satisfied" },
    { coord: 90, param: "Excited" }
];

export const rangeScaleParam = [
    { coord: 15, param: "Low" },
    { coord: 45, param: "Medium" },
    { coord: 75, param: "Big" }
];

export const varianceRangeParams = [
    { coord: 33, param: "Low" },
    { coord: 66, param: "Medium" }
];

export const varianceRangeLabels = [
    { coord: 16.5, param: "Low" },
    { coord: 49.5, param: "Medium" },
    { coord: 82.5, param: "High" }
];

export let rangeGroup = {
    Concerned: [0, 20],
    Neutral: [21, 50],
    Satisfied: [51, 80],
    Excited: [81, 100]
};

//  gradient settings

export let firstGradientColor = "rgb(224,30,90)";
export let secondGradientColor = "rgb(236,178,46)";
export let thirdGradientColor = "rgb(242,212,90)";
export let fourthGradientColor = "rgb(46,182,125)";
export let fivetheGradientColor = "rgb(54,197,240)";

export let gradientFill = (
    <svg style={{ height: 0 }}>
        <defs>
            <linearGradient id='myGradient'>
                <stop offset='0%' stopColor={firstGradientColor} />
                <stop offset='20%' stopColor={secondGradientColor} />
                <stop offset='40%' stopColor={thirdGradientColor} />
                <stop offset='80%' stopColor={fourthGradientColor} />
                <stop offset='100%' stopColor={fivetheGradientColor} />
            </linearGradient>
        </defs>
    </svg>
);

export let gradientFillHorizontal = (
    <svg style={{ height: 0 }}>
        <defs>
            <linearGradient
                id='myGradientHorizontal'
                x1='0%'
                x2='0%'
                y1='100%'
                y2='0%'>
                <stop offset='0%' stopColor={firstGradientColor} />
                <stop offset='20%' stopColor={secondGradientColor} />
                <stop offset='40%' stopColor={thirdGradientColor} />
                <stop offset='80%' stopColor={fourthGradientColor} />
                <stop offset='100%' stopColor={fivetheGradientColor} />
            </linearGradient>
        </defs>
    </svg>
);

export let selectionStyle = {
    data: {
        fill: "white",
        fillOpacity: 0.3,
        strokeWidth: 2
    }
};

export const skillTypes = {
    "Interpersonal skills": "INT",
    "Area specific skills": "ASP",
    "Technical skills": "TEC",
    "Management skills": "MNG",
    unselected: "NA"
};

export const skillTypesInverse = {
    INT: "Interpersonal skills",
    ASP: "Area specific skills",
    TEC: "Technical skills",
    MNG: "Management skills",
    NA: "unselected"
};
