import React from "react";

const BlogOli = ({ children }) => {
    return (
        <li
            className={
                "blog_body_medium text-secondary-900 list-decimal mb-2"
            }>
            {children}
        </li>
    );
};

export default BlogOli;
