import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import reduxActions from "State/ActionIndex";
import {
    Card,
    Input,
    Button,
    Title,
    ConditionalOutput,
    ContentWrapperBand,
    PageToScreenWrapper,
    HelmetExclude,
    ContactForDemoLink
} from "ComponentIndex";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";

import { SEND_PASSWORD_RESET_MUTATION } from "GraphQL/Mutations";

let defaultFormState = {
    email: ""
};

const SendPasswordRecoveryContent = () => {
    const [formState, setFormState] = useState(defaultFormState);
    const [sendReset, { error, loading }] = useMutation(
        SEND_PASSWORD_RESET_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    "Please check your email.",
                    "success"
                );
            }
        }
    );
    workspaceNameVar("Password Recovery");
    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        sendReset({
            variables: {
                email: formState.email
            }
        })
            .then((data) => {})
            .catch((error) => {
                return reduxActions.addNotification(
                    error.message,
                    "error"
                );
            });
    };

    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Card
                        type={"light"}
                        strech={false}
                        elevation={"lg"}>
                        <div
                            className={
                                "flex flex-col h-  w-80 gap-y-4"
                            }>
                            <Title>{"Password reset"}</Title>
                            <h3 className={"title_small"}>
                                Please provide the email address that
                                you used to create your account.
                            </h3>
                            <ConditionalOutput
                                error={error}
                                loading={loading}>
                                <form
                                    className={
                                        "flex flex-col h-  w-80 gap-y-4"
                                    }
                                    onSubmit={handleSubmit}>
                                    <div className={"w-full"}>
                                        <Input
                                            id={"email"}
                                            type={"email"}
                                            onChange={handleFieldChange}
                                            value={formState.email}
                                            placeholder={"Email"}
                                            required={true}
                                        />
                                    </div>

                                    <div className={"self-end"}>
                                        <Button
                                            variant={"filled"}
                                            type={"submit"}>
                                            Send
                                        </Button>
                                    </div>
                                </form>
                            </ConditionalOutput>
                            <div>
                                <span
                                    className={
                                        "label_medium text-secondary-900"
                                    }>
                                    {"Already have an account? "}
                                </span>
                                <Link to='/login'>
                                    <span
                                        className={
                                            "label_medium text-shocking-700"
                                        }>
                                        Login
                                    </span>
                                </Link>
                            </div>
                            <ContactForDemoLink />
                        </div>
                    </Card>
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default SendPasswordRecoveryContent;
