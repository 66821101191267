import { useState, useEffect, useMemo, useRef } from "react";
import { PRODUCTION } from "frontendSettings";
import { useReactiveVar } from "@apollo/client";
import { convertDate } from "UtilityFunctions/DataPreparation";

import {
    languageVar,
    appContentVar,
    landingContentVar,
    currentFeedbackSpaceVar,
    searchVar,
    fbEndDateVar,
    fbStartDateVar,
    pageVar,
    pagesVar,
    taskStartDateVar,
    taskEndDateVar,
    activeTabVar,
    skillTypesVar,
    skillIdsVar,
    projectIdsVar,
    assigneeParametersIdsVar,
    taskTypeNamesVar,
    dataSourceIdsVar,
    pageSizeVar,
    blogContentVar,
    userLogVar,
    sessionVar
} from "GraphQL/ReactiveVariables";
import {
    contentAppEng,
    contentLandingEng
} from "ContentManagement/english";

import { contentBlogEng } from "ContentManagement/blog_english";

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export const useHttpsVerification = () => {
    useEffect(() => {
        if (window.location.protocol !== "https:" && PRODUCTION) {
            window.location.replace(
                `https:${window.location.href.substring(
                    window.location.protocol.length
                )}`
            );
        }
    }, []);
};

// Pagination hook

const DOTS = "...";

const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = (pages, siblingCount = 1, page) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = pages;
        const totalPageNumbers = siblingCount + 5;

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(page - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            page + siblingCount,
            totalPageCount
        );

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots =
            rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);
            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(
                leftSiblingIndex,
                rightSiblingIndex
            );
            return [
                firstPageIndex,
                DOTS,
                ...middleRange,
                DOTS,
                lastPageIndex
            ];
        }
    }, [pages, siblingCount, page]);
    return paginationRange;
};

// HOOK that arerts if there was clik aoutsed of component (ex. close menu)
export const useClickOutside = (ref, setState) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setState(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener(
                "mousedown",
                handleClickOutside
            );
        };
    }, [ref]);
};

// HOOK to identify bounding box and coordinates of an element
// NOTE: If needed went into another aproach on adjusting the menu

export const useBoundingRect = (ref) => {
    let windowSize = useWindowSize();
    let [coords, setCoords] = useState({});
    let setCoord = () => {
        if (ref.current !== undefined && ref.current !== null) {
            let stuf = ref.current.getBoundingClientRect();
            let height = ref.current.clientHeight;
            let width = ref.current.clientWidth;
            setCoords({
                x: stuf.x,
                y: stuf.y,
                width: width,
                height: height,
                top: stuf.top,
                right: stuf.right,
                bottom: stuf.bottom,
                left: stuf.left
            });
        }
    };

    useEffect(() => {
        setCoord(ref);
    }, [windowSize]);

    return { coords, setCoord };
};

export const useChartWrapperDimensions = (ref, margin) => {
    let windowSize = useWindowSize();
    let [dimensions, setDimensions] = useState({ margin: margin });
    let [processed, setProcessed] = useState(false);

    let createBaseDimensions = () => {
        if (ref.current !== undefined && ref.current !== null) {
            setDimensions({
                ...dimensions,
                height: ref.current.clientHeight,

                width: ref.current.clientWidth,
                boundedWidth:
                    ref.current.clientWidth -
                    dimensions.margin.left -
                    dimensions.margin.right,
                boundedHeight:
                    ref.current.clientHeight -
                    dimensions.margin.top -
                    dimensions.margin.bottom
            });
            setProcessed(true);
        }
    };

    useEffect(() => {
        createBaseDimensions();
    }, [windowSize]);

    return [dimensions, processed];
};

export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export const useAppContent = () => {
    const content = useReactiveVar(languageVar);
    switch (content) {
        case "en":
            appContentVar({ ...contentAppEng });
            break;
        default:
            appContentVar({ ...contentAppEng });
            break;
    }
};

export const useLandingContent = () => {
    const content = useReactiveVar(languageVar);
    switch (content) {
        case "en":
            landingContentVar({ ...contentLandingEng });
            break;
        default:
            landingContentVar({ ...contentLandingEng });
            break;
    }
};

export const useBlogContent = () => {
    const content = useReactiveVar(languageVar);
    switch (content) {
        case "en":
            blogContentVar({ ...contentBlogEng });
            break;
        default:
            blogContentVar({ ...contentBlogEng });
            break;
    }
};

export const useUnifiedFilterItems = () => {
    const page = useReactiveVar(pageVar);
    const pages = useReactiveVar(pagesVar);
    const pageSize = useReactiveVar(pageSizeVar);
    const search = useReactiveVar(searchVar);
    let feedbackStartDate = useReactiveVar(fbStartDateVar);
    let feedbackEndDate = useReactiveVar(fbEndDateVar);
    let taskEndDate = useReactiveVar(taskStartDateVar);
    let taskStartDate = useReactiveVar(taskEndDateVar);
    const feedbackStatus = useReactiveVar(activeTabVar);
    const skillTypes = useReactiveVar(skillTypesVar);
    const skillIds = useReactiveVar(skillIdsVar);
    const projectIds = useReactiveVar(projectIdsVar);
    const dataSourceIds = useReactiveVar(dataSourceIdsVar);
    const assigneeParametersIds = useReactiveVar(
        assigneeParametersIdsVar
    );
    const taskTypeNames = useReactiveVar(taskTypeNamesVar);
    const currentFeedbackSpace = useReactiveVar(
        currentFeedbackSpaceVar
    );

    feedbackStartDate = convertDate(feedbackStartDate);
    feedbackEndDate = convertDate(feedbackEndDate);
    taskStartDate = convertDate(taskStartDate);
    taskEndDate = convertDate(taskEndDate);

    return {
        page,
        pages,
        pageSize,
        search,
        feedbackStartDate,
        feedbackEndDate,
        taskStartDate,
        taskEndDate,
        feedbackStatus,
        skillTypes,
        skillIds,
        projectIds,
        assigneeParametersIds,
        currentFeedbackSpace,
        taskTypeNames,
        dataSourceIds
    };
};

export const useMoveToPageTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
};

export const useUserLog = (code, status) => {
    let session = sessionVar();
    useEffect(() => {
        const timestamp = Date.now();
        const internetDatetime = new Date(timestamp).toISOString();
        let logs = userLogVar();
        userLogVar([
            ...logs,
            {
                sessionId: session,
                messageCode: code,
                status: status,
                timeStamp: internetDatetime
            }
        ]);
    }, []);
};
