import { useState } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { getUniqueArray, extractFromArray } from "UtilityFunctionIndex";

const extractIds = (data) => {
    if (data) {
        return data.map((item) => {
            return item.id;
        });
    }
    return data;
};

export const useSkiilsFeedbackAutoComplete = (
    dataState,
    setDataState,
    items,
    itemPrefix,
    noItems
) => {
    const [inputValue, setInputValue] = useState("");

    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        reset,
        removeSelectedItem,
        selectedItems
    } = useMultipleSelection({
        initialSelectedItems: dataState,
        onSelectedItemsChange: (changes) => {
            setDataState(changes.selectedItems.map((item) => item));
        }
    });

    const getFilteredItems = (items) => {
        let compareArray = extractIds(selectedItems);
        return items
            .filter((item) => {
                return compareArray.indexOf(item.id) < 0;
            })
            .filter((item) => {
                return item[itemPrefix]
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
            });
    };

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectItem,
        openMenu
    } = useCombobox({
        inputValue,
        items: getFilteredItems(items),
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(inputValue);
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setInputValue("");
                        addSelectedItem(selectedItem);
                        selectItem(null);
                    }
                    break;
                default:
                    break;
            }
        }
    });
    return {
        items,
        getSelectedItemProps,
        getDropdownProps,
        reset,
        removeSelectedItem,
        selectedItems,
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        getFilteredItems,
        noItems
    };
};

export const prepareSkillState = (
    skillIds,
    newSkills,
    previousSkills
) => {
    let newIds = extractNewIds(newSkills);
    let previousIds = extractNewIds(previousSkills);
    let cleanState = extractFromArray(skillIds, previousIds);
    return getUniqueArray([...cleanState, ...newIds]);
};

const extractNewIds = (newSkills) => {
    if (newSkills) {
        if (newSkills.length) {
            return newSkills.map((skill) => {
                return masterAcc(skill, "skills", "id");
            });
        }
    }
    return [];
};
