import React from "react";
import { DemoForm, ContentWrapperBand } from "ComponentIndex";

const ClickToAction = () => {
    return (
        <ContentWrapperBand color={"bg-white"}>
            <div
                className={
                    "flex flex-col px-8 items-center w-full py-16 gap-y-8"
                }>
                <div
                    className={
                        "max-w-3xl flex flex-col items-center w-full gap-y-8"
                    }>
                    <h1 className={"headline_large text-shocking-700"}>
                        {"Want to explore more?"}
                    </h1>
                    <DemoForm />
                </div>
            </div>
        </ContentWrapperBand>
    );
};

export default ClickToAction;
