import { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import {
    skillsForFilterVar,
    skillIdsVar
} from "GraphQL/ReactiveVariables";

import { masterAcc } from "UtilityFunctionIndex";

export const stringifySkillItems = (item) => {
    return masterAcc(item, "skillInFeedbackSpace", "skillName");
};

export const useSkillsFilterItems = () => {
    const [options, setOptions] = useState([]);
    const data = useReactiveVar(skillsForFilterVar);

    useEffect(() => {
        skillIdsVar(options.map((item) => item.id));
    }, [options]);

    return { data, options, setOptions };
};
