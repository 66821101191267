import { genIsOwner, genIsAdmin } from "UtilityFunctions/Validators";
import { meVar } from "GraphQL/ReactiveVariables";
import { masterAcc } from "UtilityFunctions/DataAccessors";

export const transferRightsValidator = (myRole, role) => {
    if (genIsOwner(myRole) && genIsOwner(role)) {
        return true;
    } else {
        return false;
    }
};

export const changeRoleValidator = (myRole, role) => {
    if (
        (genIsOwner(myRole) || genIsAdmin(myRole)) &&
        !genIsOwner(role)
    ) {
        return true;
    } else {
        return false;
    }
};

export const removeValidator = (recordItem, myRole, role) => {
    if (
        (genIsOwner(myRole) || genIsAdmin(myRole)) &&
        !genIsOwner(role) &&
        !meValidator(recordItem)
    ) {
        return true;
    } else {
        return false;
    }
};

export const leaveValidator = (recordItem, myRole, role) => {
    if (
        meValidator(recordItem) &&
        !transferRightsValidator(myRole, role)
    ) {
        return true;
    } else {
        return false;
    }
};

export const meValidator = (recordItem) => {
    const me = meVar();
    let memberId = masterAcc(
        recordItem,
        "roleInFeedbackSpace",
        "memberId"
    );
    if (me.id == memberId) {
        return true;
    } else {
        return false;
    }
};
