import { blogContentVar } from "GraphQL/ReactiveVariables";
import { currentArticleVar } from "GraphQL/ReactiveVariables";
import {
    HelmetInclude,
    BlogKicker,
    BlogHeader,
    BlogSubHeader,
    BlogTakeAwayHeader,
    BlogOli,
    BlogParagraphHeader,
    BlogParagraph,
    BlogBold,
    BlogLink,
    BlogCategory
} from "ComponentIndex";

import HederImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Header.png";
import TargetImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Target.png";
import VariationImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Variation.png";
import FeedbackAndPerformanceImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Feedback-and-performance.png";
import CommonCauseImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Common-cause.png";
import SpecialCauseImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Special-cause.png";
import MoreDataImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/More-data.png";
import TaskBasedImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Task-based.png";
import {
    ImpactfullFeedbackArticleLink,
    TaskBasedFeedbackArticleLink,
    CognitiveBiasesInPerformanceArticleLink
} from "BlogLinkIndex";
import { useMoveToPageTop } from "HookIndex";

const ReviewEmbraceEmployeesEffortsArticle = ({ articleName }) => {
    const content =
        blogContentVar().ReviewEmbraceEmployeesEffortsArticle;
    currentArticleVar(articleName);
    useMoveToPageTop();

    return (
        <>
            <HelmetInclude content={content} />
            <div>
                <img
                    className={"w-full"}
                    src={HederImg}
                    alt={content.altText.header[0]}
                />

                <div className={"py-8"}>
                    <BlogKicker content={content} />
                    <BlogHeader content={content} />
                    <BlogSubHeader content={content} />
                </div>
                <div>
                    <BlogTakeAwayHeader content={content} />

                    <ol className={"list-inside"}>
                        {content.mainTakeAway.content.map(
                            (item, index) => (
                                <BlogOli key={`takeaways${index}`}>
                                    {item}
                                </BlogOli>
                            )
                        )}
                    </ol>
                </div>
                <BlogParagraphHeader>
                    {content.target.title[0]}
                </BlogParagraphHeader>
                <img
                    className={"w-full my-12"}
                    src={TargetImg}
                    alt={content.altText.target[0]}
                />
                <BlogParagraph>
                    {content.target.content[0]}
                </BlogParagraph>
                <BlogParagraphHeader>
                    {content.variation.title[0]}
                </BlogParagraphHeader>
                <img
                    className={"w-full my-12"}
                    src={VariationImg}
                    alt={content.altText.variation[0]}
                />
                <BlogParagraph>
                    {content.variation.content[0]}
                </BlogParagraph>
                <BlogParagraphHeader>
                    {content.feedbackAndPerformance.title[0]}
                </BlogParagraphHeader>
                <img
                    className={"w-full my-12"}
                    src={FeedbackAndPerformanceImg}
                    alt={content.altText.feedbackAndPerformance[0]}
                />
                <BlogParagraph>
                    {content.feedbackAndPerformance.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.feedbackAndPerformance.listTitle[0]}
                </BlogParagraph>
                <BlogParagraph>
                    <BlogBold>
                        {content.feedbackAndPerformance.commonCause[0]}
                    </BlogBold>
                    {content.feedbackAndPerformance.commonCause[1]}
                </BlogParagraph>
                <img
                    className={"w-full my-12"}
                    src={CommonCauseImg}
                    alt={content.altText.commonCause[0]}
                />
                <BlogParagraph>
                    <BlogBold>
                        {content.feedbackAndPerformance.specialCause[0]}
                    </BlogBold>
                    {content.feedbackAndPerformance.specialCause[1]}
                </BlogParagraph>
                <img
                    className={"w-full my-12"}
                    src={SpecialCauseImg}
                    alt={content.altText.specialCause[0]}
                />
                <BlogParagraph>
                    {content.feedbackAndPerformance.afterParagraph[0]}
                    <BlogLink
                        to={CognitiveBiasesInPerformanceArticleLink}>
                        {
                            content.feedbackAndPerformance
                                .afterParagraph[1]
                        }
                    </BlogLink>
                    {content.feedbackAndPerformance.afterParagraph[2]}
                </BlogParagraph>
                <BlogParagraphHeader>
                    {content.moreData.title[0]}
                </BlogParagraphHeader>
                <img
                    className={"w-full my-12"}
                    src={MoreDataImg}
                    alt={content.altText.moreData[0]}
                />
                <BlogParagraph>
                    {content.moreData.content[0]}
                </BlogParagraph>
                <BlogParagraphHeader>
                    <BlogLink to={TaskBasedFeedbackArticleLink}>
                        {content.taskBased.title[0]}
                    </BlogLink>
                    {content.taskBased.title[1]}
                </BlogParagraphHeader>
                <img
                    className={"w-full my-12"}
                    src={TaskBasedImg}
                    alt={content.altText.taskBased[0]}
                />
                <BlogParagraph>
                    {content.taskBased.content[0]}
                    <BlogLink to={ImpactfullFeedbackArticleLink}>
                        {content.taskBased.content[1]}
                    </BlogLink>
                    {content.taskBased.content[2]}
                </BlogParagraph>
                <BlogCategory content={content} />
            </div>
        </>
    );
};

export default ReviewEmbraceEmployeesEffortsArticle;
