import React from "react";
import { HEADING_TITLE, WELCOME_PART, HELP_DIRECTION } from "./Content";
import { GuideList, Card, TogleTour } from "ComponentIndex";
import { useReactiveVar } from "@apollo/client";
import { userTourPreferanceVar } from "GraphQL/ReactiveVariables";

const ProductTourCard = () => {
    const userGuide = useReactiveVar(userTourPreferanceVar);
    return (
        <>
            {userGuide ? (
                <div className={"xl:w-10/12"}>
                    <Card strech={false} elevation={"lg"}>
                        <div
                            className={
                                "flex flex-col gap-y-6 items-center p-8"
                            }>
                            <h1
                                className={
                                    "headline_medium text-shocking-700 my-4"
                                }>
                                {HEADING_TITLE}
                            </h1>
                            <div
                                className={
                                    "flex sm:flex-col sm:gap-y-6 sm:items-center lg:flex-row lg:gap-x-6 lg:items-stretch lg:justify-between"
                                }>
                                <div
                                    className={
                                        "flex flex-col gap-y-4 lg:w-5/12"
                                    }>
                                    <p
                                        className={
                                            "body-large text-secondary-800"
                                        }>
                                        {WELCOME_PART}
                                    </p>
                                    <p className={"body_small "}>
                                        {HELP_DIRECTION}
                                    </p>
                                </div>
                                <div
                                    className={
                                        "flex flex-col gap-y-6 sm:w-full lg:w-5/12"
                                    }>
                                    <GuideList />
                                    <div className={"self-end"}>
                                        <TogleTour />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            ) : null}
        </>
    );
};

export default ProductTourCard;
