import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    BlogArticle,
    SystemicProbemsArticle,
    ImpactfullFeedbackArticle,
    TaskBasedFeedbackArticle,
    CognitiveBiasesInPerformanceArticle,
    ReviewEmbraceEmployeesEffortsArticle,
    NoFeedbackNoProductivityArticle
} from "ComponentIndex";

const BlogArticles = () => {
    return (
        <BlogArticle>
            <Routes>
                <Route
                    path={"systemic-problems-with-performance-reviews"}
                    element={
                        <SystemicProbemsArticle
                            articleName={"SystemicProbemsArticle"}
                        />
                    }
                />
                <Route
                    path={"how-to-make-micro-feedback-truly-impactful"}
                    element={
                        <ImpactfullFeedbackArticle
                            articleName={"ImpactfullFeedbackArticle"}
                        />
                    }
                />
                <Route
                    path={
                        "task-based-feedback-the-continuous-improvement-technique"
                    }
                    element={
                        <TaskBasedFeedbackArticle
                            articleName={"TaskBasedFeedbackArticle"}
                        />
                    }
                />
                <Route
                    path={"cognitive-biases-in-preformance-review"}
                    element={
                        <CognitiveBiasesInPerformanceArticle
                            articleName={
                                "CognitiveBiasesInPerformanceArticle"
                            }
                        />
                    }
                />
                <Route
                    path={
                        "embrace-variation-of-human-activity-during-performance-reviews"
                    }
                    element={
                        <ReviewEmbraceEmployeesEffortsArticle
                            articleName={
                                "ReviewEmbraceEmployeesEffortsArticle"
                            }
                        />
                    }
                />
                <Route
                    path={"no-feedback-no-productivity-improvements"}
                    element={
                        <NoFeedbackNoProductivityArticle
                            articleName={
                                "NoFeedbackNoProductivityArticle"
                            }
                        />
                    }
                />
            </Routes>
        </BlogArticle>
    );
};

export default BlogArticles;
