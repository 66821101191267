import { combineReducers } from "redux";
import {
    skillContentTypeReducer,
    areaSpecificSkillsReducer,
    technicalSkillsReducer,
    interpersonalSkillsReducer,
    mangementSkillsReducer
} from "./skillReducers";

import { notificationReducer } from "./notificationReducers";

const reducers = combineReducers({
    skillTypeContent: skillContentTypeReducer,
    notificationManagement: notificationReducer,
    areaSpecificSkills: areaSpecificSkillsReducer,
    technicalSkills: technicalSkillsReducer,
    interpersonalSkills: interpersonalSkillsReducer,
    mangementSkills: mangementSkillsReducer
});

export default reducers;
