import React from "react";

const PrivacyContent = () => {
    return (
        <div className={"px-4"}>
            <h1 className={"headline_small mb-6"}>
                Enamply Privacy Policy
            </h1>
            <b className={"mb-4"}>EFFECTIVE: DECEMBER 15, 2021</b>

            <p className={"body_medium mt-2"}>
                Enamply is a feedback and evaluation platform that
                provides teams with the tools to manage their feedback
                flows and increase their productivity.
            </p>
            <p className={"body_medium mt-2"}>
                This Privacy Policy describes Our policies and
                procedures on the collection, use and disclosure of Your
                information when You use the Service and tells You about
                Your privacy rights and how the law protects You.
            </p>
            <p className={"body_medium mt-2"}>
                We use Your Personal data to provide and improve the
                Service. By using the Service, You agree to the
                collection and use of information in accordance with
                this Privacy Policy.
            </p>
            <p className={"body_medium mt-2"}>
                If you have any questions or concerns about how Enamply
                processes your information or about this Privacy
                Statement, you can email us any time at
                privacy@enamply.com.
            </p>

            <h2 className={"title_large mt-6 mb-4"}>Interpretation</h2>
            <p className={"body_medium mt-2"}>
                The words of which the initial letter is capitalized
                have meanings defined under the following conditions.
                The following definitions shall have the same meaning
                regardless of whether they appear in singular or in
                plural.
            </p>
            <h2 className={"title_large mt-6 mb-4"}>Definitions</h2>
            <p className={"body_medium mt-2"}>
                For the purposes of this Privacy Policy:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Account</b> means a unique account created for
                    You to access our Service or parts of our Service.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Affiliate</b> means an entity that controls, is
                    controlled by or is under common control with a
                    party, where "control" means ownership of 50% or
                    more of the shares, equity interest or other
                    securities entitled to vote for election of
                    directors or other managing authority.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Application</b> means the software program
                    provided by the Company downloaded by You on any
                    electronic device, named ENAMPLY.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Company</b> (referred to as either "the Company",
                    "We", "Us" or "Our" in this Agreement) refers to SP
                    Trend SRL, 22 A. Russo, Chisinau, MD2044 Republic of
                    Moldova.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Country</b> refers to: Moldova
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Device</b> means any device that can access the
                    Service such as a computer, a cellphone or a digital
                    tablet.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Personal</b> Data is any information that relates
                    to an identified or identifiable individual.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Service</b> refers to the Application.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Service Provider</b> means any natural or legal
                    person who processes the data on behalf of the
                    Company. It refers to third-party companies or
                    individuals employed by the Company to facilitate
                    the Service, to provide the Service on behalf of the
                    Company, to perform services related to the Service
                    or to assist the Company in analyzing how the
                    Service is used.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>Usage Data</b> refers to data collected
                    automatically, either generated by the use of the
                    Service or from the Service infrastructure itself
                    (for example, the duration of a page visit).
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>You</b> means the individual accessing or using
                    the Service, or the company, or other legal entity
                    on behalf of which such individual is accessing or
                    using the Service, as applicable.
                </li>
            </ul>
            <h2 className={"title_large mt-6 mb-4"}>
                How we interact with You
            </h2>
            <p className={"body_medium mt-2"}>
                In situations where our users are subject to our
                Subscriber Agreement, Enterprise Master Services
                Agreement, or other Master Services Agreement to use
                Enamply’s services, Enamply is the processor/service
                provider (a provider that processes personal data on
                behalf of or at the direction of a controller, or other
                similar designation under the law) and our customer
                (usually a company or organization) is the
                controller/business (the entity that decides how and why
                information is processed) of the information provided to
                Enamply via use of Enamply. In all other cases, Enamply
                is the controller of the information.
            </p>
            <p className={"body_medium mt-2"}>
                Enamply can be used by companies or by individual users.
            </p>
            <p className={"body_medium mt-2"}>
                If you’re using Enamply through your company,
                educational institution, or with your company email
                address, your company or educational institution’s own
                Enamply administrator is responsible for the accounts
                associated with that company or institution and can:
                restrict, suspend, or terminate your access to or
                ability to use the services, access information about
                you, access or retain information stored with us
                (including your workspace content and log data about
                your use of Enamply), and restrict your ability to edit,
                restrict, modify, or delete information associated with
                your use of our products and services.
            </p>
            <h2 className={"title_large mt-6 mb-4"}>
                Information We Process
            </h2>
            <p className={"body_medium mt-2"}>
                Enamply processes information we receive directly from
                you, automatically collected when you use Enamply or
                visit one of our websites and collected by Enamply from
                third parties. However, please note that this Privacy
                Statement does not apply to the processing of your
                information by third parties through your use of any
                third-party integrations if available via our services.
                Please visit those third-party websites directly for
                more information on their privacy and data protection
                practices.
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>
                Information Enamply Receives Directly From You
            </h3>
            <h4 className={"title_small mt-2"}>
                Information needed to create an account
            </h4>
            <p className={"body_medium mt-2"}>
                This includes information that is needed for Enamply to
                create an account for you and manage your ability to log
                in and out of Enamply:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Identifiers, such as first and last name and email
                    address
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Your password for Enamply (hashed) - unique, long,
                    and strong, please
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information related to a third-party authentication
                    identity provider, such as Google Authenticator
                </li>
            </ul>
            <p className={"body_medium mt-2"}>
                If you upgrade your account to a paid account, Enamply
                may collect:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Billing information, such as name, address, and
                    telephone number
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Financial information, such as credit card
                    information collected by our payment processors on
                    our behalf
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information about your chosen Enamply plan
                </li>
            </ul>
            <h4 className={"title_small mt-2"}>
                Information you provide to us through your use of
                Enamply
            </h4>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Information you provide in feedback forms, skill
                    inventory
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information uploaded to Enamply, such as attachments
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information from emails you forward to
                    x@mail.Enamply.com
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Profile photo and other information you choose to
                    include to describe yourself, only collected if you
                    do choose to provide it, such as your gender
                    pronouns
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Professional or employment information, such as your
                    title or role at your company
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Any other information you choose to provide while
                    using Enamply that identifies or can be reasonably
                    associated with you
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information about your contacts, if you choose to
                    provide it
                </li>
            </ul>
            <h4 className={"title_small mt-2"}>
                Other information you may provide to us when you
                interact with Enamply in other ways
            </h4>
            <p className={"body_medium mt-2"}>
                You may voluntarily provide us with information when you
                interact with us in other ways, such as when you
                directly interact with Enamply staff, such as our sales,
                user research, or user operations groups. Enamply may
                process:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Your requests, questions, and responses to us via
                    forms or email
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information you provide in connection with Enamply
                    contests or research studies, if you choose to
                    participate
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information to verify your identity
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Your date of birth
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Your audio and video, if you participate in a sales
                    call or user research study and do not opt out of
                    call recording, which also involves the collection
                    of biometric information associated with the call
                    recordings
                </li>
            </ul>
            <h4 className={"title_small mt-2"}>
                Information transferred from your Task Management
                platform
            </h4>
            <p className={"body_medium mt-2"}>
                This information if collected after you synchronize your
                Enamply account with your Task Management account.
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Project name
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Project unique identifier
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Task name
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Task unique identifier
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Sub Task Name
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Sub Task unique identifier
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Task Assignee Name
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Task Assignee Email
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Task Assignee unique identifier
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Date of Task Change
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Date of Task Completion
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Status showing the completion of the task (In each
                    Task Management Platform, the word used to describe
                    this status can vary)
                </li>
            </ul>
            <h3 className={"title_medium mt-4 mb-2"}>
                Information automatically processed when you visit our
                websites or use our mobile or desktop app
            </h3>
            <h4 className={"title_small mt-2"}>
                Information related to your use of Enamply and our
                websites
            </h4>
            <p className={"body_medium mt-2"}>
                We may also collect the following:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Metadata and inference information related to your
                    use of Enamply, our websites, and third-party
                    integrations to better understand the way you work
                    in Enamply. We may log the actions you take as you
                    use Enamply, including but not limited to the number
                    of Enamply workspaces you work in, the number of
                    tasks you are assigned, when you delete a task or
                    comment, the features and embedded content you
                    interact with, the types of files you share, and
                    what, if any, third-party integrations you use.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Internet network activity, cookies, and similar
                    tracking technologies, including data our servers
                    automatically record, such as your web request, IP
                    address, browser type and settings, referring/exit
                    pages and URLs, number of clicks, date and time
                    stamp information, language preferences, and other
                    such information. Please visit our Cookie Notice for
                    more information about the types of information we
                    collect via cookies, including information about
                    advertising and analytics, and how we use it. To
                    manage your cookie settings, you can adjust them in
                    our cookie preference center here: Manage settings
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information collected because of participation in
                    beta testing
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information about how you interact with our
                    marketing websites, like Enamply.com, such as where
                    you click, how long you visit a page, your
                    scrolling, mouse hovers, and other data to help us
                    better understand your experience and provide you
                    with the best user experience
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Device information and activity when you use Enamply
                    via a mobile device, such as the type of device you
                    are using, device IDs, operating system version, and
                    mobile network information, which may include your
                    mobile phone number.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Derived device geolocation information, such as
                    approximate geographic location inferred from an IP
                    address
                </li>
            </ul>
            <h3 className={"title_medium mt-4 mb-2"}>
                Information Enamply Receives from Other Sources
            </h3>
            <p className={"body_medium mt-2"}>
                Sometimes Enamply receives your information from third
                parties (other individuals, marketing services,
                third-party integrations), which may include:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Information processed from third-party integrations
                    you set up with Enamply. For example, a third-party
                    integration may give us access to information stored
                    in that third party that Enamply will process to
                    facilitate the integration
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Name, email, and business contact information
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Information about you provided to us from other
                    individuals or users of Enamply
                </li>
            </ul>
            <h2 className={"title_large mt-6 mb-4"}>
                How We Use Your Information
            </h2>
            <p className={"body_medium mt-2"}>
                Enamply uses your information to operate our products
                and services, communicate with you, process transactions
                when you change Enamply plans, for security and fraud
                prevention, and to comply with the law. We may process
                your information to:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    <b>To provide and maintain our Service</b>,
                    including to monitor the usage of our Service.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>To manage Your Account</b>: to manage Your
                    registration as a user of the Service. The Personal
                    Data You provide can give You access to different
                    functionalities of the Service that are available to
                    You as a registered user.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>For the performance of a contract</b>: the
                    development, compliance and undertaking of the
                    purchase contract for the products, items or
                    services You have purchased or of any other contract
                    with Us through the Service.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>To contact You</b>: To contact You by email,
                    telephone calls, SMS, or other equivalent forms of
                    electronic communication, such as a mobile
                    application's push notifications regarding updates
                    or informative communications related to the
                    functionalities, products or contracted services,
                    including the security updates, when necessary or
                    reasonable for their implementation.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>To provide You</b> with news, special offers and
                    general information about other goods, services and
                    events which we offer that are similar to those that
                    you have already purchased or enquired about unless
                    You have opted not to receive such information.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>To manage Your requests</b>: To attend and manage
                    Your requests to Us.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>For business transfers</b>: We may use Your
                    information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization,
                    dissolution, or other sale or transfer of some or
                    all of Our assets, whether as a going concern or as
                    part of bankruptcy, liquidation, or similar
                    proceeding, in which Personal Data held by Us about
                    our Service users is among the assets transferred.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>To Combined Information</b>: Unless otherwise
                    prohibited by law, we may combine the information
                    that we collect through your use of our products and
                    services with information that we receive from other
                    sources, both online and offline, and use that
                    combined information as set out above.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    <b>For other purposes</b>: We may use Your
                    information for other purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to
                    evaluate and improve our Service, products,
                    services, marketing and your experience.
                </li>
            </ul>
            <p className={"body_medium mt-2"}>
                We may share Your personal information in the following
                situations:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    With Service Providers: We may share Your personal
                    information with Service Providers to monitor and
                    analyze the use of our Service, to contact You.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    For business transfers: We may share or transfer
                    Your personal information in connection with, or
                    during negotiations of, any merger, sale of Company
                    assets, financing, or acquisition of all or a
                    portion of Our business to another company.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    With Affiliates: We may share Your information with
                    Our affiliates, in which case we will require those
                    affiliates to honor this Privacy Policy. Affiliates
                    include Our parent company and any other
                    subsidiaries, joint venture partners or other
                    companies that We control or that are under common
                    control with Us.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    With business partners: We may share Your
                    information with Our business partners to offer You
                    certain products, services or promotions.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    With other users: when You share personal
                    information or otherwise interact in the public
                    areas with other users, such information may be
                    viewed by all users and may be publicly distributed
                    outside.
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    With Your consent: We may disclose Your personal
                    information for any other purpose with Your consent.
                </li>
            </ul>

            <h2 className={"title_large mt-6 mb-4"}>
                How we manage Your information
            </h2>
            <h3 className={"title_medium mt-4 mb-2"}>
                Retention of Your Personal Data
            </h3>
            <p className={"body_medium mt-2"}>
                The Company will retain Your Personal Data only for as
                long as is necessary for the purposes set out in this
                Privacy Policy. We will retain and use Your Personal
                Data to the extent necessary to comply with our legal
                obligations (for example, if we are required to retain
                your data to comply with applicable laws), resolve
                disputes, and enforce our legal agreements and policies.
            </p>
            <p className={"body_medium mt-2"}>
                The Company will also retain Usage Data for internal
                analysis purposes. Usage Data is generally retained for
                a shorter period of time, except when this data is used
                to strengthen the security or to improve the
                functionality of Our Service, or We are legally
                obligated to retain this data for longer time periods.
            </p>
            <p className={"body_medium mt-2"}>
                Transfer of Your Personal Data
            </p>
            <p className={"body_medium mt-2"}>
                Your information, including Personal Data, is processed
                at the Company's operating offices and in any other
                places where the parties involved in the processing are
                located. It means that this information may be
                transferred to — and maintained on — computers located
                outside of Your state, province, country or other
                governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
            </p>
            <p className={"body_medium mt-2"}>
                Your consent to this Privacy Policy followed by Your
                submission of such information represents Your agreement
                to that transfer.
            </p>
            <p className={"body_medium mt-2"}>
                The Company will take all steps reasonably necessary to
                ensure that Your data is treated securely and in
                accordance with this Privacy Policy and no transfer of
                Your Personal Data will take place to an organization or
                a country unless there are adequate controls in place
                including the security of Your data and other personal
                information.
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>
                Disclosure of Your Personal Data
            </h3>
            <h4 className={"title_small mt-2"}>
                Business Transactions
            </h4>
            <p className={"body_medium mt-2"}>
                If the Company is involved in a merger, acquisition or
                asset sale, Your Personal Data may be transferred. We
                will provide notice before Your Personal Data is
                transferred and becomes subject to a different Privacy
                Policy.
            </p>
            <h4 className={"title_small mt-2"}>Law enforcement</h4>
            <p className={"body_medium mt-2"}>
                Under certain circumstances, the Company may be required
                to disclose Your Personal Data if required to do so by
                law or in response to valid requests by public
                authorities (e.g. a court or a government agency).
            </p>
            <h4 className={"title_small mt-2"}>
                Other legal requirements
            </h4>
            <p className={"body_medium mt-2"}>
                The Company may disclose Your Personal Data in the good
                faith belief that such action is necessary to:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Comply with a legal obligation
                </li>{" "}
                <li className={"body_medium list-inside list-disc"}>
                    Protect and defend the rights or property of the
                    Company
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Prevent or investigate possible wrongdoing in
                    connection with the Service
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Protect the personal safety of Users of the Service
                    or the public
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Protect against legal liability
                </li>
            </ul>

            <h3 className={"title_medium mt-4 mb-2"}>
                Security of Your Personal Data
            </h3>
            <p className={"body_medium mt-2"}>
                The security of Your Personal Data is important to Us,
                but remember that no method of transmission over the
                Internet, or method of electronic storage is 100%
                secure. While We strive to use commercially acceptable
                means to protect Your Personal Data, We cannot guarantee
                its absolute security.
            </p>
            <h2 className={"title_large mt-6 mb-4"}>
                Other Important Information
            </h2>
            <h3 className={"title_medium mt-4 mb-2"}>
                Use by children under 16
            </h3>
            <p className={"body_medium mt-2"}>
                If you are under the age of 16, you may not have an
                Enamply account or use Enamply’s products or services.
                We do not knowingly process any information from, or
                direct any of our products or services to, children
                under the age of 16.
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>
                Marketing Practices and Choices
            </h3>
            <p className={"body_medium mt-2"}>
                If you receive email from us, we may use certain
                analytics tools, such as clear GIFs, to capture data,
                such as when you open our message or click on any links
                or banners our email contains. This data allows us to
                gauge the effectiveness of our communications and
                marketing campaigns.
            </p>
            <p className={"body_medium mt-2"}>
                You may instruct us not to use your contact information
                to contact you by email, postal mail, or phone regarding
                products, services, promotions, and special events that
                might appeal to your interests by contacting us at the
                “Enamply Contact Info” section below. In commercial
                email messages, you can also opt out by following the
                instructions located at the bottom of such emails.
            </p>
            <p className={"body_medium mt-2"}>
                Please note that, regardless of your request, we may
                still use and share certain information as permitted by
                this Privacy Statement or as required by applicable law.
                For example, you may not opt out of certain
                transactional, operational, or service-related emails,
                such as those reflecting our relationship or
                transactions with you.
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>Do-Not-Track</h3>
            <p className={"body_medium mt-2"}>
                Do Not Track is a privacy preference that users can set
                in certain web browsers. We are committed to providing
                you with meaningful choices about the information
                collected on our websites for third party purposes, and
                that is why we provide the ability to opt out, if
                needed, and adjust your cookie preferences. We do not
                currently recognize or respond to browser-initiated DNT
                signals. To learn more about Do Not Track, you can do so
                here. For more information about how Enamply uses
                cookies and similar tracking technologies, visit our
                Cookie Notice.
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>
                Legal Bases for Processing Your Information
            </h3>
            <p className={"body_medium mt-2"}>
                Our legal basis under the General Data Protection
                Regulation (GDPR), a data protection and privacy
                regulation in the European Union, for collecting and
                using your information described above will depend on
                the particular type of information and the specific
                context in which we collect it. However, some examples
                of legal bases for processing that we rely on:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    Where use of your information is necessary to
                    perform our obligations under a contract with you
                    (for example, to comply with the User Terms of
                    Service which you accept by using Enamply’s services
                    or to fulfill the terms of a contract signed with
                    companies)
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Where use of your information is necessary for our
                    legitimate interests or the legitimate interests of
                    others (for example, to provide security, operate
                    our products and services, prevent fraud, analyze
                    use of and improve our products and services, and
                    for similar purposes)
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Where use of your information is necessary to comply
                    with a legal obligation
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    Where we have your consent to process data in a
                    certain way
                </li>
            </ul>
            <h3 className={"title_medium mt-4 mb-2"}>
                Links to Other Websites
            </h3>
            <p className={"body_medium mt-2"}>
                Our Service may contain links to other websites that are
                not operated by Us. If You click on a third party link,
                You will be directed to that third party's site. We
                strongly advise You to review the Privacy Policy of
                every site You visit.
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    We have no control over and assume no responsibility
                    for the content, privacy policies or practices of
                    any third party sites or services.
                </li>
            </ul>
            <h2 className={"title_large mt-6 mb-4"}>
                Your Privacy Rights
            </h2>
            <p className={"body_medium mt-2"}>
                Enamply users from around the world use our products to
                bring clarity to their work. Regardless of what country
                you’re located in, we respect your ability to know,
                access, correct, export, restrict the processing of, and
                delete your information, and have extended those rights
                globally. We will not discriminate against you for
                exercising your privacy rights.
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>
                Information About Your Rights
            </h3>
            <p className={"body_medium mt-2"}>
                Upon your request, and subject to applicable legal
                exceptions, we will:
            </p>
            <ul>
                <li className={"body_medium list-inside list-disc"}>
                    provide access to and/or a copy of certain
                    information we hold about you
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    prevent the processing of your information for
                    promotional purposes (including any direct marketing
                    processing based on profiling)
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    update information which is out of date or incorrect
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    delete certain information which we have about you
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    restrict the way that we process and disclose some
                    of your information
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    transfer your information to a third party provider
                    of services
                </li>
                <li className={"body_medium list-inside list-disc"}>
                    revoke your consent for the processing of your
                    information
                </li>
            </ul>
            <p className={"body_medium mt-2"}>
                Enamply does not sell your data, as defined under
                applicable law, such as the California Consumer Privacy
                Act (CCPA).
            </p>
            <h3 className={"title_medium mt-4 mb-2"}>
                Exercising Your Rights
            </h3>
            <p className={"body_medium mt-2"}>
                We will need to verify your identity and may need to
                verify your relationship with Enamply (for example, if
                you’re an administrator of an Enamply Organization or
                Workspace and you’re making a request on behalf of
                another individual) for security and to prevent fraud.
            </p>
            <p className={"body_medium mt-2"}>
                We may take additional steps to verify that you are
                authorized to make the request. If you are an end user
                of Enamply’s services and not a direct customer of
                Enamply (for example, your company uses Enamply and
                you’re an employee or authorized representative of that
                company), you should direct requests relating to your
                information to the administrator of your company’s
                Enamply account. We will redirect you to your
                administrator or notify the administrator directly. To
                exercise your privacy rights, please make a request by
                filling out this form.
            </p>
            <p className={"body_medium mt-2"}>
                Please note, however, that certain information may be
                exempt from such requests in some circumstances, which
                may include if we need to keep processing your
                information for our legitimate interests or to comply
                with a legal obligation.
            </p>
            <h2 className={"title_large mt-6 mb-4"}>
                Changes To Our Privacy Policy
            </h2>

            <p className={"body_medium mt-2"}>
                We may update Our Privacy Policy from time to time. We
                will notify You of any changes by posting the new
                Privacy Policy on this page.
            </p>
            <p className={"body_medium mt-2"}>
                We will let You know via email and/or a prominent notice
                on Our Service, prior to the change becoming effective
                and update the "Last updated" date at the top of this
                Privacy Policy.
            </p>
            <p className={"body_medium mt-2"}>
                You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.
            </p>

            <h2 className={"title_large mt-6 mb-4"}>
                Contact Us and Privacy Questions
            </h2>
            <p className={"body_medium mt-2"}>
                To contact our Data Protection Officer, please email
                privacy@Enamply.com.
            </p>
        </div>
    );
};

export default PrivacyContent;
