import React from "react";
import {
    ReactTableCell,
    ReactTableHeader,
    CellDataWrapper,
    Tag,
    InfoCell
} from "ComponentIndex";
import { v4 } from "uuid";

import { assignUserName, defineCellColor } from "./Utils";

// Headers

// Cells

export const projectsHeader = (column) => {
    return (
        <ReactTableHeader column={column} columWidth={100}>
            {"Project"}
        </ReactTableHeader>
    );
};

export const assigneeHeader = (column) => {
    return (
        <ReactTableHeader column={column} columWidth={100}>
            {"Assignee"}
        </ReactTableHeader>
    );
};

export const giveByHeader = (column) => {
    return (
        <ReactTableHeader column={column} columWidth={100}>
            {"Give by"}
        </ReactTableHeader>
    );
};

export const taskHeader = (column) => {
    return (
        <ReactTableHeader column={column} columWidth={250}>
            {"Task"}
        </ReactTableHeader>
    );
};

export const rangeHeader = (column) => {
    return (
        <ReactTableHeader column={column} columWidth={100}>
            {"Range"}
        </ReactTableHeader>
    );
};

export const stopHeader = (column) => {
    return (
        <ReactTableHeader column={column}>{"Stop"}</ReactTableHeader>
    );
};

export const continueHeader = (column) => {
    return (
        <ReactTableHeader column={column}>
            {"Continue"}
        </ReactTableHeader>
    );
};

export const improveHeader = (column) => {
    return (
        <ReactTableHeader column={column}>{"Improve"}</ReactTableHeader>
    );
};

export const fweetHeader = (column) => {
    return (
        <ReactTableHeader column={column}>{"Fweet"}</ReactTableHeader>
    );
};

export const infoHeader = (column) => {
    return (
        <ReactTableHeader column={column} columWidth={50}>
            {"info"}
        </ReactTableHeader>
    );
};

// cells

export const projectCell = (cell) => {
    return (
        <ReactTableCell cell={cell} title={cell.value} overflow={true}>
            {cell.value}
        </ReactTableCell>
    );
};

export const taskCell = (cell) => {
    return (
        <ReactTableCell cell={cell} title={cell.value} overflow={true}>
            {cell.value}
        </ReactTableCell>
    );
};

export const userCell = (cell) => {
    return (
        <ReactTableCell cell={cell} title={cell.value} overflow={true}>
            {cell.value}
        </ReactTableCell>
    );
};

export const givenByCell = (cell) => {
    let name = assignUserName(cell.value);
    return (
        <ReactTableCell cell={cell} title={name} overflow={true}>
            {name}
        </ReactTableCell>
    );
};

export const rangeCell = (cell) => {
    let range = (cell.value.upperValue + cell.value.lowerValue) / 2;
    return <ReactTableCell cell={cell}>{range}</ReactTableCell>;
};

export const tagCell = (cell) => {
    return (
        <ReactTableCell
            cell={cell}
            title={
                cell.row.original.stopfeedbackSet[0]
                    .stopFeedbackDescription
            }>
            <CellDataWrapper justify={"center"}>
                {cell.value.map((item) => {
                    return (
                        <Tag key={v4()} variant={"normal"}>
                            {item.tagName}
                        </Tag>
                    );
                })}
            </CellDataWrapper>
        </ReactTableCell>
    );
};

export const actionsCell = (cell) => {
    return (
        <ReactTableCell cell={cell} cellProps={defineCellColor(cell)}>
            <CellDataWrapper justify={"center"}>
                <InfoCell value={cell.value} />
            </CellDataWrapper>
        </ReactTableCell>
    );
};
