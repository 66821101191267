import { Link } from "react-router-dom";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const ContactForDemoLink = () => {
    const content = landingContentVar().ContactForDemoLink;
    return (
        <div>
            <span className={"label_medium text-secondary-900"}>
                {content.noAccount}
            </span>
            <Link to='/contact-us'>
                <span className={"label_medium text-shocking-700"}>
                    {content.contact}
                </span>
            </Link>
        </div>
    );
};

export default ContactForDemoLink;
