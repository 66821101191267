import React from "react";
import classnames from "classnames";
import cls from "./FeedbackSpaceNotifications.module.css";
import {
    FeedbackSpaceDailyDigest,
    FeedbackSpaceWeeklyDigest,
    Title
} from "ComponentIndex";

const FeedbackSpaceNotifications = ({ data, feedbackSpace }) => {
    const feedabckSpaceNotificationsClass = classnames(
        cls.feedabckSpaceNotifications
    );
    return (
        <div className={feedabckSpaceNotificationsClass}>
            <Title type={"title_medium"}>
                Feedback space notifications
            </Title>
            <FeedbackSpaceDailyDigest
                feedbackSpace={feedbackSpace}
                data={data.feedbackSpaceDailyDigest}
            />
            <FeedbackSpaceWeeklyDigest
                feedbackSpace={feedbackSpace}
                data={data.feedbackSpaceWeeklyDigest}
            />
        </div>
    );
};

export default FeedbackSpaceNotifications;
