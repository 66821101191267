import React from "react";
import cn from "classnames";
import {
    taskEndDateVar,
    taskStartDateVar
} from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import DatePicker from "react-datepicker";

import "./DateRangePicker.css";

const inputClass = cn([
    "body_medium outline-none text-white",
    "h-6 rounded-full  bg-transparent pl-9 pr-2",
    "placeholder:label_medium placeholder:text-shocking-50",
    "border border-shocking-50 focus_ring "
]);

const DateRangePicker = () => {
    const startDate = useReactiveVar(taskStartDateVar);
    const endDate = useReactiveVar(taskEndDateVar);

    const handleDateRangeUpdate = (update) => {
        taskStartDateVar(update[0]);
        taskEndDateVar(update[1]);
    };

    return (
        <div className={"relative flex flex-row items-center"}>
            <span
                className={
                    "material-symbols-rounded text-shocking-50 text-sm absolute left-3"
                }>
                {"date_range"}
            </span>
            <DatePicker
                className={inputClass}
                dateFormat={"yyyy-MM-dd"}
                closeOnScroll={true}
                startDate={startDate}
                endDate={endDate}
                isClearable={true}
                selectsRange={true}
                onChange={handleDateRangeUpdate}
                monthsShown={2}
                clearButtonClassName={"material-symbols-rounded"}
                showWeekNumbers
                placeholderText='Select Date Range'
            />
        </div>
    );
};

export default DateRangePicker;
