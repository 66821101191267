import React, { useState } from "react";
import {
    DrawerCompartment,
    IconDialog,
    AddFeedbackSpace,
    ListFeedbackSpaces,
    DrawerFeedbackSpaceSettings
} from "ComponentIndex";

const DrawerFeedbackSpaces = () => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <DrawerCompartment label={"Feedback spaces"}>
                <IconDialog
                    icon={"add"}
                    size={"small"}
                    variant={"standard"}
                    label={"Add Feedback Space"}
                    color={"primaryLight"}
                    shadow={false}
                    open={open}
                    setOpen={setOpen}
                    title={"Create feedback space"}>
                    <AddFeedbackSpace setOpen={setOpen} />
                </IconDialog>
                <DrawerFeedbackSpaceSettings />
            </DrawerCompartment>
            <ListFeedbackSpaces />
        </div>
    );
};

export default DrawerFeedbackSpaces;
