import { useState } from "react";

const xOffset = 30;
const yOffset = 30;

export const useTooltipHandlers = () => {
    const [toolOpen, setToolOpen] = useState({ open: false });

    const handleChartTooltipMouseEnter = (item) => (e) => {
        setToolOpen({ ...toolOpen, ...item, open: true });
    };

    const handleChartTooltipMouseMove = (e) => {
        setToolOpen({
            ...toolOpen,
            x: e.pageX + xOffset,
            y: e.pageY - yOffset
        });
    };

    const handleChartTooltipMouseLeave = (e) => {
        setToolOpen({ open: false });
    };

    return [
        handleChartTooltipMouseEnter,
        handleChartTooltipMouseMove,
        handleChartTooltipMouseLeave,
        toolOpen
    ];
};
