import React from "react";
import { Routes, Route } from "react-router-dom";
import { TaskFeedbackComponent } from "ComponentIndex";

const TaskFeedbackRoot = () => {
    return (
        <Routes>
            <Route path={"/"} element={<TaskFeedbackComponent />} />
        </Routes>
    );
};

export default TaskFeedbackRoot;
