import { gql } from "@apollo/client";

// **************
// Instructions used in custom fetch for authemtication
// **************

export const REFRESH_TOKEN = `mutation{refreshToken{payload}}`;

// ASANA REGISTRATION

// This mutation is creating record about user in database
export let REGISTER_WITH_ASANA_MUTATION = gql`
    mutation {
        registerWithAsana {
            authLink
        }
    }
`;

export let DEAUTHORIZE_ASANA_MUTATION = gql`
    mutation {
        deauthoriseWithAsana {
            result
        }
    }
`;

export let GET_ASANA_AUTH_TOKEN_MUTATION = gql`
    mutation getAsanaAuthToken($code: String!) {
        getAsanaAuthToken(code: $code) {
            result
        }
    }
`;

// ATLASSIAN REGISTRATION

export let GET_ATLASSIAN_CODE_MUTATION = gql`
    mutation {
        registerWithAtlassian {
            authLink
        }
    }
`;

export let GET_ATLASSIAN_AUTH_TOKEN_MUTATION = gql`
    mutation getAtlassianAuthToken($code: String!) {
        getAtlassianAuthToken(code: $code) {
            result
        }
    }
`;

export let CHANGE_ATTLASSIAN_SITE_AUTHORIZATION = gql`
    mutation manageAtlassianSiteAuthorization($siteUrl: String!) {
        manageAtlassianSiteAuthorization(siteUrl: $siteUrl) {
            atlassianSite {
                siteUrl
                authorizedToSync
            }
        }
    }
`;

// ***************************
// Task mutations
// ***************************

export let CREATE_BASIC_FEEDBACK = gql`
    mutation createBasicFeedback(
        $continueFeedback: String!
        $improveFeedback: String!
        $stopFeedback: String!
        $taskId: Int!
        $feedbackSpaceId: Int!
        $textFeedback: String!
        $lowerRange: Int!
        $upperRange: Int!
        $skillIds: [Int]!
    ) {
        createBasicFeedback(
            continueFeedback: $continueFeedback
            improveFeedback: $improveFeedback
            stopFeedback: $stopFeedback
            taskId: $taskId
            feedbackSpaceId: $feedbackSpaceId
            textFeedback: $textFeedback
            lowerRange: $lowerRange
            upperRange: $upperRange
            skillIds: $skillIds
        ) {
            task {
                id
                taskName
                project {
                    projectName
                    id
                }
                parentTask {
                    id
                    taskName
                }

                dueAt
                completedAt
                basicfeedbackstatusSet {
                    id
                    asociatedSkills {
                        id
                        skillName
                        skillType
                    }
                    givenBy {
                        id
                        email
                        username
                    }
                    dateModified
                }

                assigneeParameters {
                    id
                    assigneeName
                }

                textfeedbackSet {
                    id
                    textFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                stopfeedbackSet {
                    id
                    stopFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                continuefeedbackSet {
                    id
                    continueFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                improvefeedbackSet {
                    id
                    improveFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                satisfactionrangeSet {
                    id
                    upperValue
                    lowerValue
                }
            }
        }
    }
`;

export let SKIP_TASK_MUTATION = gql`
    mutation editBasicFeedback(
        $taskId: Int!
        $feedbackSpaceId: Int!
        $feedbackStatus: String!
    ) {
        editBasicFeedback(
            taskId: $taskId
            feedbackSpaceId: $feedbackSpaceId
            feedbackStatus: $feedbackStatus
        ) {
            feedbackStatus {
                id
            }
        }
    }
`;

// ***************************
// Sync Instructions
// ***************************

export let SYNC_PROJECTS_MUTATION = gql`
    mutation syncProjects($feedbackSpaceId: Int!) {
        syncProjects(feedbackSpaceId: $feedbackSpaceId) {
            syncInitiation
            celeryTaskId
        }
    }
`;

export let SYNC_ATLASSIAN_PROJECTS_MUTATION = gql`
    mutation syncAtlassianProjects($feedbackSpaceId: Int!) {
        syncAtlassianProjects(feedbackSpaceId: $feedbackSpaceId) {
            result
        }
    }
`;

export let SYNC_ASSIGNEES_MUTATION = gql`
    mutation syncAssignees($feedbackSpaceId: Int!) {
        syncAssignees(feedbackSpaceId: $feedbackSpaceId) {
            syncInitiation
            celeryTaskId
        }
    }
`;

export let SYNC_ATLASSIAN_ASSIGNEES_MUTATION = gql`
    mutation syncAtlassianAssignees($feedbackSpaceId: Int!) {
        syncAtlassianAssignees(feedbackSpaceId: $feedbackSpaceId) {
            result
        }
    }
`;

export let SYNC_TASKS_MUTATION = gql`
    mutation ($deepSync: Boolean, $feedbackSpaceId: Int!) {
        syncTasks(
            deepSync: $deepSync
            feedbackSpaceId: $feedbackSpaceId
        ) {
            syncInitiation
            celeryTaskId
        }
    }
`;

export let SYNC_ATLASSIAN_TASK_MUTATIONS = gql`
    mutation ($feedbackSpaceId: Int!) {
        syncAtlassianTasks(feedbackSpaceId: $feedbackSpaceId) {
            result
        }
    }
`;

export let PROJECT_SYNC_STATUS_MUTATION = gql`
    mutation (
        $id: String!
        $feedbackSpaceId: Int!
        $projectSyncStatus: Boolean
    ) {
        editProject(
            id: $id
            feedbackSpaceId: $feedbackSpaceId
            projectSyncStatus: $projectSyncStatus
        ) {
            project {
                projectSyncStatus
                id
            }
        }
    }
`;

export let ASSIGNEE_SYNC_STATUS_MUTATION = gql`
    mutation (
        $id: String!
        $feedbackSpaceId: Int!
        $assigneeSyncStatus: Boolean
    ) {
        editAssigneeParameters(
            id: $id
            feedbackSpaceId: $feedbackSpaceId
            assigneeSyncStatus: $assigneeSyncStatus
        ) {
            assigneeParameters {
                id
                assigneeSyncStatus
            }
        }
    }
`;

export let SET_ALL_ASSIGNEES_SYNC_STATUS_MUTATION = gql`
    mutation ($feedbackSpaceId: Int!, $assigneeSyncStatus: Boolean!) {
        editAllAssigneeSyncStatus(
            feedbackSpaceId: $feedbackSpaceId
            assigneeSyncStatus: $assigneeSyncStatus
        ) {
            assigneesParameters {
                id
                assigneeSyncStatus
            }
        }
    }
`;

export let SET_ALL_PROJECT_SYNC_STATUS_MUTATION = gql`
    mutation ($feedbackSpaceId: Int!, $projectSyncStatus: Boolean!) {
        editAllProjectsSyncStatus(
            feedbackSpaceId: $feedbackSpaceId
            projectSyncStatus: $projectSyncStatus
        ) {
            projects {
                id
                projectSyncStatus
            }
        }
    }
`;

// ***************************
// User registration
// ***************************

export const SEND_PASSWORD_RESET_MUTATION = gql`
    mutation ($email: String!) {
        sendPasswordReset(email: $email) {
            sendInitiation
            celeryTaskId
        }
    }
`;

export const VERIFY_EMAIL_MUTATION = gql`
    mutation ($email: String!, $verificationToken: String!) {
        verifyEmail(
            email: $email
            verificationToken: $verificationToken
        ) {
            verificationStatus
        }
    }
`;

export const RESEND_EMAIL_VERIFICATION_MUTATION = gql`
    mutation ($email: String!) {
        sendEmailVerification(email: $email) {
            celeryTaskId
            sendInitiation
        }
    }
`;

export const LOGIN_MUTATION = gql`
    mutation ($email: String!, $password: String!) {
        tokenAuth(email: $email, password: $password) {
            payload
        }
    }
`;

export const PASSWORD_RESET_MUTATION = gql`
    mutation ($email: String!, $password: String!, $token: String!) {
        resetPassword(
            email: $email
            password: $password
            token: $token
        ) {
            resetResult
        }
    }
`;

// NEED SORTING
export const VERIFY_TOKEN_MUTATION = gql`
    mutation {
        verifyToken {
            payload
        }
    }
`;

export const REFRESH_TOKEN_MUTATION = gql`
    mutation {
        refreshToken {
            payload
        }
    }
`;

export const CREATE_ACCOUNT_MUTATION = gql`
    mutation ($email: String!, $password: String!) {
        createAccount(email: $email, password: $password) {
            sendInitiation
        }
    }
`;

export const DELETE_TOKEN_COOKIES_MUTATION = gql`
    mutation {
        deleteTokenCookie {
            deleted
        }
        deleteRefreshTokenCookie {
            deleted
        }
    }
`;

// User preferancess

export const SET_TOUR_PREFERANCE = gql`
    mutation ($userProductGuideParameter: Boolean) {
        setUserProductTour(
            userProductGuideParameter: $userProductGuideParameter
        ) {
            userPreference {
                productTour
            }
        }
    }
`;

export const SET_USER_CURRENT_FEEDBACK_SPACE_MUTATION = gql`
    mutation ($feedbackSpaceId: Int) {
        setCurrentFeedbackSpace(feedbackSpaceId: $feedbackSpaceId) {
            userPreferances {
                currentFeedbackSpace {
                    id
                    feedbackSpaceName
                    roleinfeedbackspaceSet {
                        id
                        role
                    }
                }
            }
        }
    }
`;

export const SEND_FEEDBACK_SPACE_INVITATION_MUTATION = gql`
    mutation ($invitedUser: String!, $feedbackSpaceId: Int!) {
        createFeedbackSpaceInvitation(
            invitedUser: $invitedUser
            feedbackSpaceId: $feedbackSpaceId
        ) {
            feedbackSpaceInvitation
        }
    }
`;

export const RESPOND_TO_FEEDBACK_SPACE_INVITATION_MUTATION = gql`
    mutation (
        $invitationId: Int!
        $response: Boolean!
        $feedbackSpaceId: Int!
    ) {
        respondToFeedbackSpaceInvitation(
            invitationId: $invitationId
            feedbackSpaceId: $feedbackSpaceId
            response: $response
        ) {
            feedbackSpaceInvitation
        }
    }
`;

// Skills

export const CREATE_SKILL_MUTATION = gql`
    mutation (
        $skillName: String!
        $skillType: String!
        $skillDescription: String
    ) {
        createSkill(
            skillType: $skillType
            skillName: $skillName
            skillDescription: $skillDescription
        ) {
            skill {
                id
                skillName
            }
        }
    }
`;

export const EDIT_SKILL_MUTATION = gql`
    mutation (
        $skillId: Int!
        $skillName: String!
        $skillType: String!
        $skillDescription: String
    ) {
        editSkill(
            skillId: $skillId
            skillType: $skillType
            skillName: $skillName
            skillDescription: $skillDescription
        ) {
            skill {
                id
                skillName
                skillType
                skillDescription
            }
        }
    }
`;

// ***************************
// Feedback Space mutations
// ***************************

export const CREATE_FEEDBACK_SPACE_MUTATION = gql`
    mutation ($name: String!) {
        createFeedbackSpace(name: $name) {
            feedbackSpace {
                id
                feedbackSpaceName
            }
        }
    }
`;

export const REMOVE_FROM_FEEDBACK_SPACE_MUTATION = gql`
    mutation ($feedbackSpaceId: Int!, $userId: Int!) {
        removeFeedbackSpaceMember(
            feedbackSpaceId: $feedbackSpaceId
            userId: $userId
        ) {
            result
        }
    }
`;

export const LEAVE_FEEDBACK_SPACE_MUTATION = gql`
    mutation ($feedbackSpaceId: Int!) {
        leaveFeedbackSpace(feedbackSpaceId: $feedbackSpaceId) {
            result
        }
    }
`;

export const CHANGE_USERS_ROLE = gql`
    mutation (
        $feedbackSpaceId: Int!
        $userId: Int!
        $instruction: String!
    ) {
        changeFeedbackSpaceRights(
            feedbackSpaceId: $feedbackSpaceId
            userId: $userId
            instruction: $instruction
        ) {
            roleInFeedbackSpace {
                id
                role
            }
        }
    }
`;

export const EDIT_FEEDBACK_SPACE_NAME_MUTATION = gql`
    mutation ($feedbackSpaceId: Int!, $feedbackSpaceName: String!) {
        editFeedbackSpace(
            feedbackSpaceId: $feedbackSpaceId
            feedbackSpaceName: $feedbackSpaceName
        ) {
            feedbackSpace {
                id
                feedbackSpaceName
            }
        }
    }
`;

// ***************************
// Broarcasts
// ***************************

export const SUBSCRIPTION_MUTATION = gql`
    mutation ($subscriptionEmail: String!) {
        registerLaunchSubscription(
            subscriptionEmail: $subscriptionEmail
        ) {
            subscriptionResult
        }
    }
`;

// ***************************
// Account settings
// ***************************

export const CHANGE_ACCOUNT_NOTIFICATION_PREFERENCE = gql`
    mutation (
        $parameter: Boolean!
        $parameterLabel: String!
        $feedbackSpaceId: Int!
    ) {
        setAccountNotificationPreference(
            parameter: $parameter
            parameterLabel: $parameterLabel
            feedbackSpaceId: $feedbackSpaceId
        ) {
            notificationPreference {
                feedbackSpace {
                    id
                }
            }
        }
    }
`;

export const CHANGE_FEEDBACK_SPACE_NOTIFICATION_PREFERENCE = gql`
    mutation (
        $parameter: Boolean!
        $parameterLabel: String!
        $feedbackSpaceId: Int!
    ) {
        setFeedbackSpaceNotificationPreference(
            parameter: $parameter
            parameterLabel: $parameterLabel
            feedbackSpaceId: $feedbackSpaceId
        ) {
            notificationPreference {
                feedbackSpace {
                    id
                }
            }
        }
    }
`;

export const TRANSFER_FEEDBACK_SPACE_OWNERSHIP = gql`
    mutation ($feedbackSpaceId: Int!, $userId: Int!) {
        transferFeedbackSpaceOwnership(
            feedbackSpaceId: $feedbackSpaceId
            userId: $userId
        ) {
            roleInFeedbackSpace {
                role
                memberAccount {
                    id
                    email
                }
                id
            }
        }
    }
`;

export const SET_FEEDBACK_SPACE_SKILLS = gql`
    mutation ($feedbackSpaceId: Int!, $skillIds: [Int]!) {
        setSkillsInFeedbackSpace(
            feedbackSpaceId: $feedbackSpaceId
            skillIds: $skillIds
        ) {
            skills {
                id
                feedbackSpace {
                    id
                    feedbackSpaceName
                }
                skill {
                    id
                    skillName
                }
            }
        }
    }
`;

export const SET_USER_LOGS = gql`
    mutation ($logRecords: [UserLogRecordInputType!]!) {
        usageLogs(logRecords: $logRecords) {
            result
        }
    }
`;

export const INITIATE_OPEN_ID = gql`
    mutation($openIdIdentifier:String!){
    initiateOpenId(openIdIdentifier:$openIdIdentifier){
    openIdLink}
}`
