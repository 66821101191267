import React from "react";

const BlogReferance = ({ children, to }) => {
    return (
        <p>
            <a
                target='_blank'
                rel='noopener noreferrer'
                href={to}
                className={
                    "blog_label_medium font-secondary-900 underline italic"
                }>
                {children}
            </a>
        </p>
    );
};

export default BlogReferance;
