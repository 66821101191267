const buttonStyle = {
    backgroundColor: "transparent",
    border: 0,
    borderRadius: "0.375rem",
    color: "#ffff",
    outline: "none",
    lineHeight: 1,
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    WebkitAppearance: "none"
};

export const cardStyle = {
    buttonNext: {
        ...buttonStyle,
        backgroundColor: "#9A2472"
    },
    buttonBack: {
        ...buttonStyle,
        color: "#9A2472"
    },
    buttonClose: {
        display: "none"
    },
    buttonSkip: {
        color: "#4C243E",
        fontSize: "0.85rem"
    },
    tooltipTitle: {
        borderBottom: "0.125rem solid #9A2472",
        text: "1.375rem",
        leading: "1.75rem",
        font: "normal"
    },
    beaconInner: {
        backgroundColor: "#E284C2"
    },
    beaconOuter: {
        backgroundColor: "transparent",
        border: `2px solid #E284C2`
    }
};
