import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import reduxActions from "State/ActionIndex";
import { SUBSCRIPTION_MUTATION } from "GraphQL/Mutations";
import {
    ConditionalOutput,
    Loading,
    Button,
    Input
} from "ComponentIndex";

let defautFormState = {
    email: ""
};

const ContactForDemoForm = () => {
    const [formState, setFormState] = useState(defautFormState);

    const [subscribe, { loading, error }] = useMutation(
        SUBSCRIPTION_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    `Thank you for sharing your email with us. We will be in touch soon!`,
                    "success"
                );
                setFormState(defautFormState);
            }
        }
    );

    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        subscribe({
            variables: {
                subscriptionEmail: formState.email
            }
        });
    };

    return (
        <ConditionalOutput error={error} loading={loading}>
            <form
                className={"flex flex-col  w-80 gap-y-4"}
                onSubmit={handleSubmit}>
                <div className={"w-full"}>
                    <Input
                        id='email'
                        type={"email"}
                        onChange={handleFieldChange}
                        value={formState.email}
                        placeholder={"Email Address"}
                        required={true}
                    />
                </div>
                <div className={"self-end"}>
                    {loading && <Loading />}
                    {!loading && (
                        <Button variant={"filled"} type={"submit"}>
                            Send
                        </Button>
                    )}
                </div>
            </form>
        </ConditionalOutput>
    );
};

export default ContactForDemoForm;
