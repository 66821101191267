import { v4 } from "uuid";

export const addNotificationAction = (message, variant) => {
    return {
        type: "add_notification",
        payload: {
            id: v4(),
            variant: variant,
            message: message
        }
    };
};

export const removeNotificationAction = (id) => {
    return {
        type: "remove_notification",
        payload: {
            id: id
        }
    };
};
