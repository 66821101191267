import React from "react";

const BlogSubHeader = ({ content }) => {
    return (
        <h2 className={"blog_headline_small text-secondary-700"}>
            {content.subTitle[0]}
        </h2>
    );
};

export default BlogSubHeader;
