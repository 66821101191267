import React from "react";
import cn from "classnames";

const ContentWrapperBand = ({ children, color, id, padding = 32 }) => {
    const wrapperClass = cn([
        "max-w-7xl mx-auto",
        {
            "py-32": padding === 32,
            "py-16": padding === 16
        }
    ]);
    return (
        <div id={id} className={`${color}`}>
            <div className={wrapperClass}>{children}</div>
        </div>
    );
};

export default ContentWrapperBand;
