import React, { useEffect } from "react";
import reduxActions from "State/ActionIndex";
import { Loading } from "ComponentIndex";

const ConditionalOutput = ({
    error = null,
    loading,
    children,
    loadingLabel = "",
    loadingTitle = "",
    data = null
}) => {
    useEffect(() => {
        if (error) {
            reduxActions.addNotification(error.message, "error");
        }
    }, [error]);

    if (loading) {
        return (
            <div
                className={
                    "w-full h-full flex flex-row justify-center content-center items-center"
                }>
                <Loading
                    label={loadingLabel}
                    loadingTitle={loadingTitle}
                />
            </div>
        );
    }

    return <>{children}</>;
};

export default ConditionalOutput;
