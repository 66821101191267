import React from "react";

const BlogHeader = ({ content }) => {
    return (
        <h1 className={"blog_display_small my-2 text-secondary-900"}>
            {content.title[0]}
        </h1>
    );
};

export default BlogHeader;
