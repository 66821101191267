import React from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
    currentFeedbackSpaceVar,
    activeTabVar,
    tasksDueVar
} from "GraphQL/ReactiveVariables";
import { clearCacheItem } from "GraphQL/Cache";
import reduxActions from "State/ActionIndex";
import { SKIP_TASK_MUTATION } from "GraphQL/Mutations";
import { Button, ConditionalOutput } from "ComponentIndex";

let SkipTaskFeedback = ({ task, disabled = false }) => {
    const currentFeedbackSpace = useReactiveVar(
        currentFeedbackSpaceVar
    );

    let [skipTask, { error, loading }] = useMutation(
        SKIP_TASK_MUTATION,
        {
            onCompleted: () => {
                clearCacheItem(task);
                if (activeTabVar() === "PE") {
                    tasksDueVar(tasksDueVar() - 1);
                }
                reduxActions.addNotification("Task Skipped", "message");
            }
        }
    );

    let handleTaskSkip = (e) => {
        skipTask({
            variables: {
                taskId: task.id,
                feedbackSpaceId: currentFeedbackSpace.id,
                feedbackStatus: "NA"
            }
        })
            .then((data) => {})
            .catch((e) => {});
    };

    return (
        <ConditionalOutput error={error} loading={loading}>
            <Button
                disabled={disabled}
                key={`skipbtn${task.id}`}
                variant={"outline"}
                icon={"skip_next"}
                onClick={handleTaskSkip}>
                skip
            </Button>
        </ConditionalOutput>
    );
};

export default SkipTaskFeedback;
