import React from "react";
import { Card } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { useGetAsanaTokens } from "./Utils";

const AsanaRedirectContent = () => {
    useGetAsanaTokens();
    const content = appContentVar().AsanaRedirectContent;

    return (
        <div
            className={
                "flex flex-col w-full lg:w-8/12 xl:w-6/12 2xl:w-4/12 items-center"
            }>
            <Card type={"light"} basel={"blue-chill"}>
                <p className={"title_large text--700 mb-2"}>
                    {content[0]}
                </p>
                <p className={"body_large text-secondary-800-700"}>
                    {content[1]}
                </p>
            </Card>
        </div>
    );
};

export default AsanaRedirectContent;
