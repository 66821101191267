import { InMemoryCache } from "@apollo/client";

export let appCache = new InMemoryCache({
    typePolicies: {
        TasksType: {
            keyFields: ["id"]
        },
        ProjectsType: {
            keyFields: ["id"]
        },
        AssigneesType: {
            keyFields: ["id"]
        },
        SkillsType: {
            keyFields: ["id"]
        },
        FeedackSpaceType: {
            keyFields: ["id"]
        },
        FeedbackSpaceInvitationsType: {
            keyFields: ["id"]
        },
        RoleInFeedbackSpaceType: {
            keyFields: ["id"]
        },
        AssigneeParametersType: {
            keyFields: ["id"]
        },
        RestrictedAccountType: {
            keyFields: ["id"]
        },
        TagsType: {
            keyFields: ["id"]
        },
        AtlassianSiteInfoType: {
            keyFields: ["siteUrl"]
        },
        BasicFeedbackStatusType: {
            keyFields: ["id"]
        },
        TextFeedbackType: {
            keyFields: ["id"]
        },
        StopFeedbackType: {
            keyFields: ["id"]
        },
        ContinueFeedbackType: {
            keyFields: ["id"]
        },
        ImproveFeedbackType: {
            keyFields: ["id"]
        },
        SatisfactionRangeType: {
            keyFields: ["id"]
        }
    }
});

export let clearCacheItem = (item) => {
    appCache.evict({ id: appCache.identify(item) });
    appCache.gc();
};

export let addCache = (item) => {
    appCache.evict({ id: appCache.identify(item) });
    appCache.gc();
};

export let modifyCacheItemField = (item, modifierObject) => {
    appCache.modify({
        id: appCache.identify(item),
        fields: modifierObject,
        broadcast: true
    });
};

export let massModificationOfItems = (dataArray, modifierObject) => {
    for (let item of dataArray) {
        modifyCacheItemField(item, modifierObject);
    }
};
