import {
    SatisfactionRangeGraph,
    RangeSlider,
    InfoModule,
    EvaluationRangeInfo
} from "ComponentIndex";

const RangeFeedback = ({ rangeState, setRangeState }) => {
    return (
        <div className={"flex flex-col items-center max-w-sm"}>
            <div className={"self-end"}>
                <InfoModule
                    message={<EvaluationRangeInfo />}
                    xOffset={-300}
                />
            </div>
            <div>
                <SatisfactionRangeGraph selectionRange={rangeState} />
            </div>
            <RangeSlider
                min={0}
                max={100}
                rangeState={rangeState}
                setRangeState={setRangeState}
            />
        </div>
    );
};

export default RangeFeedback;
