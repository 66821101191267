import React from "react";
import { useCurrentFeedbackSpace } from "CustomHooks/CustomMutations";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import { ConditionalOutput, IconButton } from "ComponentIndex";

const SelectFeedbackSpaceButton = ({ feedbackSpace }) => {
    const currentFeedabckSpace = currentFeedbackSpaceVar();
    const { setPref, error, loading } =
        useCurrentFeedbackSpace(feedbackSpace);

    return (
        <ConditionalOutput error={error} loading={loading}>
            {currentFeedabckSpace.id === feedbackSpace.id ? (
                <div className={"flex flex-row gap-x-4 items-center"}>
                    <p className={"label_medium text-shocking-700"}>
                        {"Selected Feedback Space"}
                    </p>
                    <IconButton
                        variant={"standard"}
                        size={"large"}
                        disabled
                        color={"primary"}
                        icon={"check_box"}
                    />
                </div>
            ) : (
                <div className={"flex flex-row gap-x-4 items-center"}>
                    <p className={"label_medium text-secondary-700"}>
                        {"Select this Feedback Space"}
                    </p>
                    <IconButton
                        variant={"standard"}
                        onClick={setPref}
                        size={"large"}
                        color={"secondary"}
                        icon={"check_box_outline_blank"}
                        title={"Select this Feedback Space"}
                    />
                </div>
            )}
        </ConditionalOutput>
    );
};

export default SelectFeedbackSpaceButton;
