import React from "react";
import AtlassianLogo from "Images/Atlassian-logo.svg";
import {
    AddAtlassianSite,
    Card,
    Hr,
    AtlassianSiteList
} from "ComponentIndex";

const AtlassianAuthorization = () => {
    return (
        <Card type={"light"} strech={false} elevation={"sm"}>
            <div
                className={
                    "flex flex-row justify-between p-8 items-center"
                }>
                <img
                    className={"w-28"}
                    src={AtlassianLogo}
                    alt={"Slylized letter A with atlassian name below"}
                />
                <div className={"flex flex-col gap-y-4"}>
                    <AtlassianSiteList />

                    <Hr color={"primary"} weight={"small"} />

                    <AddAtlassianSite />
                </div>
            </div>
        </Card>
    );
};

export default AtlassianAuthorization;
