import React, { useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import reduxActions from "State/ActionIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import {
    Title,
    SelectSkillsCompartment,
    Button,
    Card
} from "ComponentIndex";
import { SET_FEEDBACK_SPACE_SKILLS } from "GraphQL/Mutations";
import { useClickOutside } from "HookIndex";

const ManageSkills = ({ feedbackSpace, setOpen }) => {
    const containerRef = useRef(null);
    const content = appContentVar().ManageSkills;
    useClickOutside(containerRef, setOpen);
    const [skillIds, setSkillIds] = useState([]);
    const [setSkills, { data, error, loading }] = useMutation(
        SET_FEEDBACK_SPACE_SKILLS
    );

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setSkills({
            variables: {
                feedbackSpaceId: feedbackSpace.id,
                skillIds: skillIds
            }
        })
            .then((d) => {
                reduxActions.addNotification(
                    "Settings saved",
                    "success"
                );
                setOpen(false);
            })
            .catch((e) => {
                reduxActions.addNotification(e.message, "error");
            });
    };

    return (
        <div
            className={"w-full md:min-w-2xl md:w-fit"}
            ref={containerRef}>
            <Card>
                <Title>{content.title[0]}</Title>
                <form
                    className={"flex flex-col"}
                    onSubmit={handleFormSubmit}>
                    <div
                        className={
                            "flex flex-col lg:flex-row lg:gap-x-6 lg:w-full lg:justify-between"
                        }>
                        <div className={"lg:w-5/12"}>
                            <div className={"pt-4"}>
                                <Title
                                    type={"title_small"}
                                    weight={"none"}>
                                    {content.mngSkills[0]}
                                </Title>
                                <SelectSkillsCompartment
                                    setSkillIds={setSkillIds}
                                    skillIds={skillIds}
                                    feedbackSpace={feedbackSpace}
                                    placeHolder={content.mngSkills[0]}
                                    skillType={"MNG"}
                                    leadingIconName={"schema"}
                                    noItems={content.noItems[0]}
                                />
                            </div>
                            <div className={"pt-4"}>
                                <Title
                                    type={"title_small"}
                                    weight={"none"}>
                                    {content.interpersSkills[0]}
                                </Title>
                                <SelectSkillsCompartment
                                    setSkillIds={setSkillIds}
                                    skillIds={skillIds}
                                    feedbackSpace={feedbackSpace}
                                    placeHolder={
                                        content.interpersSkills[0]
                                    }
                                    skillType={"INT"}
                                    leadingIconName={"groups"}
                                    noItems={content.noItems[0]}
                                />
                            </div>
                        </div>
                        <div className={"lg:w-5/12 w-96"}>
                            <div className={"pt-4"}>
                                <Title
                                    type={"title_small"}
                                    weight={"none"}>
                                    {content.techSkills[0]}
                                </Title>
                                <SelectSkillsCompartment
                                    setSkillIds={setSkillIds}
                                    skillIds={skillIds}
                                    feedbackSpace={feedbackSpace}
                                    placeHolder={content.techSkills[0]}
                                    skillType={"TEC"}
                                    leadingIconName={
                                        "settings_applications"
                                    }
                                    noItems={content.noItems[0]}
                                />
                            </div>
                            <div className={"pt-4"}>
                                <Title
                                    type={"title_small"}
                                    weight={"none"}>
                                    {content.areaSpecSkills[0]}
                                </Title>
                                <SelectSkillsCompartment
                                    setSkillIds={setSkillIds}
                                    skillIds={skillIds}
                                    feedbackSpace={feedbackSpace}
                                    placeHolder={
                                        content.areaSpecSkills[0]
                                    }
                                    skillType={"ASP"}
                                    leadingIconName={"batch_prediction"}
                                    noItems={content.noItems[0]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"pt-4 self-end"}>
                        <Button icon={"save"} variant={"filled"}>
                            {content.button[0]}
                        </Button>
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default ManageSkills;
