import { feedbackSpaceRoleAcc } from "./DataAccessors";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";

export const isOwner = (item) => {
    let role = feedbackSpaceRoleAcc(item);
    return genIsOwner(role);
};

export const isAdmin = (item) => {
    let role = feedbackSpaceRoleAcc(item);
    return genIsAdmin(role);
};

export const isOwnerOrAdmin = (item) => {
    let role = feedbackSpaceRoleAcc(item);
    return genIsOwnerOrAdmin(role);
};

export const isMember = (item) => {
    let role = feedbackSpaceRoleAcc(item);
    return genIsMember(role);
};

export const isOwnerCurrentVar = () => {
    let role = currentFeedbackSpaceVar();
    return genIsOwner(role.role);
};

export const isAdminCurrentVar = () => {
    let role = currentFeedbackSpaceVar();
    return genIsAdmin(role.role);
};

export const isOwnerOrAdminCurrentVar = () => {
    let role = currentFeedbackSpaceVar();
    return genIsOwnerOrAdmin(role.role);
};

export const isMemberCurrentVar = () => {
    let role = currentFeedbackSpaceVar();
    return genIsMember(role.role);
};

export const genIsOwner = (role) => {
    if (role === "OWN") {
        return true;
    } else {
        return false;
    }
};

export const genIsAdmin = (role) => {
    if (role === "ADM") {
        return true;
    } else {
        return false;
    }
};

export const genIsOwnerOrAdmin = (role) => {
    if (role === "OWN" || role === "ADM") {
        return true;
    } else {
        return false;
    }
};

export const genIsMember = (role) => {
    if (role === "MEM") {
        return true;
    } else {
        return false;
    }
};
