import React from "react";
import { HorizontalBarChart } from "ChartIndex";
import { ConditionalOutput } from "ComponentIndex";
import { useLoadForTour } from "HookIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

import { useGetSkillRatingBarChart } from "./Utils";

const X_ACCESSOR = (d) => {
    return d.frequency;
};
const Y_ACCESSOR = (d) => {
    return d.skillName;
};

const SkillRaitingBarChart = () => {
    useLoadForTour(4, "insightsTour", 3);
    const content = appContentVar().SkillRaitingBarChart;

    const { data, loading, error } = useGetSkillRatingBarChart();

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <HorizontalBarChart
                    data={data.skillsFrequency}
                    xAccessor={X_ACCESSOR}
                    yAccessor={Y_ACCESSOR}
                    toolTipAccessor={"assignees"}
                    chartName={content.chartName[0]}
                    toolTipKey={"assignee"}
                    toolTipValue={"frequency"}
                />
            ) : null}
        </ConditionalOutput>
    );
};

export default SkillRaitingBarChart;
