import React, { useRef } from "react";

import { useAsanaAuthVerification } from "CustomHooks/CustomQueries";
import {
    ManageProjects,
    ManageAssignees,
    Title,
    Card,
    DeepSyncButton,
    Hr
} from "ComponentIndex";
import { useClickOutside } from "HookIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

const UnifiedSyncParameters = ({ feedbackSpace, setOpen }) => {
    const content = appContentVar().UnifiedSyncParameters;
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const [authorized] = useAsanaAuthVerification();

    return (
        <div ref={containerRef}>
            <Card strech={true}>
                <div>
                    <Title type={"title_large"} color={"dark"}>
                        {content[0]}
                    </Title>
                    {authorized ? (
                        <div className={"flex flex-col gap-y-4"}>
                            <div
                                className={
                                    "flex flex-row justify-between"
                                }>
                                <ManageProjects
                                    feedbackSpace={feedbackSpace}
                                />
                                <ManageAssignees
                                    feedbackSpace={feedbackSpace}
                                />
                            </div>
                            <Hr />

                            <DeepSyncButton
                                feedbackSpaceId={feedbackSpace.id}
                            />
                        </div>
                    ) : (
                        <p
                            className={
                                "label_medium text-secondary-900"
                            }>
                            {content[1]}
                        </p>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default UnifiedSyncParameters;
