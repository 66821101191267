import React from "react";
import cn from "classnames";

const CellDataWrapper = ({ children, justify = null }) => {
    const tableActionWrapperClass = cn(
        "flex flex-row flex-wrap items-center w-full h-full",
        {
            "justify-start": justify === "left",
            "justify-end": justify === "right",
            "justify-center": justify === "center",
            "justify-around": justify === "spaceArround"
        }
    );
    return <div className={tableActionWrapperClass}>{children}</div>;
};

export default CellDataWrapper;
