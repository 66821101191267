import { blogContentVar } from "GraphQL/ReactiveVariables";
import { currentArticleVar } from "GraphQL/ReactiveVariables";
import {
    BlogCompartmentHeader,
    BlogHeader,
    BlogKicker,
    BlogLink,
    BlogOli,
    BlogParagraph,
    BlogParagraphHeader,
    BlogSubHeader,
    BlogTakeAwayHeader,
    BlogUli,
    BlogReferanceHeader,
    BlogReferance,
    BlogCategory,
    BlogCaption,
    HelmetInclude
} from "ComponentIndex";
import ArticleMainImg from "Images/Blog/ImpactfullFeedbackArticle/top-image-micro-feedback.png";
import EncouragingImg from "Images/Blog/ImpactfullFeedbackArticle/encoouraging-and-constructive.png";
import ActionableImg from "Images/Blog/ImpactfullFeedbackArticle/actionable-micro-feedback-article.png";
import HonestImg from "Images/Blog/ImpactfullFeedbackArticle/current-situation-micro-feedback-article.png";
import {
    CognitiveBiasesInPerformanceArticleLink,
    SystemicProbemsArticleLink,
    TaskBasedFeedbackArticleLink
} from "BlogLinkIndex";
import { useMoveToPageTop } from "HookIndex";

const FIRST_REFERANCE =
    "https://hbr.org/2014/01/your-employees-want-the-negative-feedback-you-hate-to-give";
const SECONT_REFERANCE =
    "https://www.cambridge.org/core/journals/industrial-and-organizational-psychology/article/abs/performance-management-can-be-fixed-an-onthejob-experiential-learning-approach-for-complex-behavior-change/E4F8A435C94B09BA0B2667D05965DA41";

const ImpactfullFeedbackArticle = ({ articleName }) => {
    const content = blogContentVar().ImpactfullFeedbackArticle;
    useMoveToPageTop();
    currentArticleVar(articleName);

    return (
        <>
            <HelmetInclude content={content} />

            <div>
                <BlogCaption caption={content.captions.header[0]}>
                    <img
                        src={ArticleMainImg}
                        alt={content.altText.headerText[0]}
                    />
                </BlogCaption>

                <div className={"py-8"}>
                    <BlogKicker content={content} />
                    <BlogHeader content={content} />
                    <BlogSubHeader content={content} />
                </div>
                <div>
                    <BlogTakeAwayHeader content={content} />

                    <ol className={"list-inside"}>
                        {content.mainTakeAway.content.map(
                            (item, index) => (
                                <BlogOli key={`takeaways${index}`}>
                                    {item}
                                </BlogOli>
                            )
                        )}
                    </ol>
                </div>
                <BlogCompartmentHeader>
                    <img
                        className={"h-48"}
                        src={EncouragingImg}
                        alt={content.altText.encouraging[0]}
                    />
                    <BlogParagraphHeader>
                        {content.encouraging.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.encouraging.content[0]}
                    <BlogLink to={SystemicProbemsArticleLink}>
                        {content.encouraging.content[1]}
                    </BlogLink>
                    {content.encouraging.content[2]}
                    <BlogLink
                        to={CognitiveBiasesInPerformanceArticleLink}>
                        {content.encouraging.content[3]}
                    </BlogLink>
                    {content.encouraging.content[4]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-48"}
                        src={ActionableImg}
                        alt={content.altText.actionable[0]}
                    />
                    <BlogParagraphHeader>
                        {content.actionable.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.actionable.content[0]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-48"}
                        src={HonestImg}
                        alt={content.altText.honest[0]}
                    />
                    <BlogParagraphHeader>
                        {content.honest.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.honest.content[0]}
                </BlogParagraph>
                <ul className={"list-inside my-10"}>
                    {content.honest.listItems.map((item, index) => {
                        return (
                            <BlogUli key={`types${index}`}>
                                {item}
                            </BlogUli>
                        );
                    })}
                </ul>
                <BlogParagraph>
                    {content.honest.closingContent[0]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.taskBasedFeedback.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.taskBasedFeedback.content[0]}{" "}
                    <BlogLink to={TaskBasedFeedbackArticleLink}>
                        {content.taskBasedFeedback.content[1]}
                    </BlogLink>
                    {content.taskBasedFeedback.content[2]}
                </BlogParagraph>

                <div className={"mt-10"}>
                    <BlogReferanceHeader content={content} />

                    <BlogReferance to={FIRST_REFERANCE}>
                        {content.referancess.first[0]}
                    </BlogReferance>
                    <BlogReferance to={SECONT_REFERANCE}>
                        {content.referancess.second[0]}
                    </BlogReferance>
                </div>

                <BlogCategory content={content} />
            </div>
        </>
    );
};

export default ImpactfullFeedbackArticle;
