import React from "react";
import {
    FeedbackReportContent,
    ReportNavigation,
    MainLayout,
    FeedbackReportActions
} from "ComponentIndex";

const FeedbackReportComponent = () => {
    return (
        <MainLayout
            navigation={<ReportNavigation />}
            action={<FeedbackReportActions />}
            content={<FeedbackReportContent />}
            workspace={"Insights"}
            title={"Feedback Report"}
            activeTab={"feedback"}
            feedbackSpaceCheck={true}
            logCode={"PFRPL"}
        />
    );
};

export default FeedbackReportComponent;
