import { landingContentVar } from "GraphQL/ReactiveVariables";

const HRContent = () => {
    const content = landingContentVar().HRContent;
    return (
        <div
            className={
                "flex flex-col gap-y-6 lg:h-full rounded-md shadow-md bg-white p-8 border-solid border-2 border-shocking-800"
            }>
            <h3 className={"title_large text-shocking-800"}>
                {content.header[0]}
            </h3>
            <ul
                className={
                    " flex flex-col gap-y-4 justify-around h-full"
                }>
                <li className={"mb-4 body_large text-secondary-900"}>
                    {content.text[0]}
                </li>
                <li className={"mb-4 body_large text-secondary-900"}>
                    {content.text[1]}
                </li>
                <li className={"mb-4 body_large text-secondary-900"}>
                    {content.text[2]}
                </li>
            </ul>
        </div>
    );
};

export default HRContent;
