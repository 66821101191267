import React, { useState } from "react";
import { ButtonDialog, InviteMember } from "ComponentIndex";

const ManageMemberInvitation = ({ feedbackSpaceId }) => {
    const [open, setOpen] = useState(false);
    return (
        <ButtonDialog
            id={"invite-members"}
            open={open}
            setOpen={setOpen}
            label={"Invite"}
            icon={"person_add"}
            variant={"tonal"}>
            <InviteMember
                feedbackSpaceId={feedbackSpaceId}
                setOpen={setOpen}
            />
        </ButtonDialog>
    );
};

export default ManageMemberInvitation;
