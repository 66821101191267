import React from "react";
import { landingContentVar } from "GraphQL/ReactiveVariables";

import {
    TextPictureLeft,
    TextPictureRight,
    ExtendedFeatureText
} from "ComponentIndex";

import Skills from "Images/ExtendedFeatures/skills_prod.gif";
import SatisfactionRange from "Images/ExtendedFeatures/sat_range.gif";
import Reports from "Images/ExtendedFeatures/reports.gif";
import StopContImpr from "Images/ExtendedFeatures/stop_cont_impr.gif";

const ExtendedFeatures = () => {
    const content = landingContentVar().ExtendedFeatures;
    return (
        <div className={"px-6 flex flex-col gap-y-28  items-center"}>
            <TextPictureRight
                id={"extended_features"}
                header={content.firstFeatureHeader[0]}
                text={
                    <ExtendedFeatureText
                        textContent={content.firstFeatureText}
                    />
                }>
                <img
                    className={"md:w-1/3 rounded shadow-md p-2"}
                    src={StopContImpr}
                    alt={content.stopContrImprAlt[0]}
                />
            </TextPictureRight>
            <TextPictureLeft
                header={content.secondFeatureHeader[0]}
                text={
                    <ExtendedFeatureText
                        textContent={content.secondFeatureText}
                    />
                }>
                <img
                    className={"md:w-1/3 rounded shadow-md p-2"}
                    src={Skills}
                    alt={content.skillsAlt[0]}
                />
            </TextPictureLeft>
            <TextPictureRight
                header={content.thirdFeatureHeader[0]}
                text={
                    <ExtendedFeatureText
                        textContent={content.thirdFeatureText}
                    />
                }>
                <img
                    className={"md:w-1/3 rounded shadow-md p-2"}
                    src={SatisfactionRange}
                    alt={content.satisfactionRangeAlt[0]}
                />
            </TextPictureRight>
            <TextPictureLeft
                header={content.fourthFeatureHeader[0]}
                text={
                    <ExtendedFeatureText
                        textContent={content.fourthFeatureText}
                    />
                }>
                <img
                    className={"md:w-1/3 rounded shadow-md p-2"}
                    src={Reports}
                    alt={content.reportsAlt[0]}
                />
            </TextPictureLeft>
        </div>
    );
};

export default ExtendedFeatures;
