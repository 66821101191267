import React from "react";
import { Td } from "ComponentIndex";

const ReactTableCell = ({
    children,
    cell,
    cellProps = {},
    title = null,
    overflow = false
}) => {
    return (
        <Td
            overflow={overflow}
            title={title}
            cellProps={cell.getCellProps(cellProps)}>
            {children}
        </Td>
    );
};

export default ReactTableCell;
