import ProblemSpan from "./ProblemSpan";
import ProblemTextBody from "./ProblemTextBody";
import { landingContentVar } from "GraphQL/ReactiveVariables";

export const AssesmentTruncated = () => {
    const content = landingContentVar().AssesmentTruncated;
    return (
        <ProblemTextBody>
            {content[0]} <ProblemSpan>{content[1]}</ProblemSpan>
        </ProblemTextBody>
    );
};
export const CognitiveBias = () => {
    const content = landingContentVar().CognitiveBias;
    return (
        <ProblemTextBody>
            {content[0]} <ProblemSpan>{content[1]}</ProblemSpan>
            {content[2]} <ProblemSpan>{content[3]}</ProblemSpan>
        </ProblemTextBody>
    );
};

export const DisconectedFromTasks = () => {
    const content = landingContentVar().DisconectedFromTasks;
    return (
        <ProblemTextBody>
            {content[0]}
            <ProblemSpan>{content[1]}</ProblemSpan>
            {content[2]} <ProblemSpan>{content[3]}</ProblemSpan>
        </ProblemTextBody>
    );
};

export const InconsistantEvaluation = () => {
    const content = landingContentVar().InconsistantEvaluation;
    return (
        <ProblemTextBody>
            {content[0]}
            <ProblemSpan>{content[1]}</ProblemSpan>
            {content[2]} <ProblemSpan>{content[3]}</ProblemSpan>
        </ProblemTextBody>
    );
};

export const NoDataBasedAssesment = () => {
    const content = landingContentVar().NoDataBasedAssesment;
    return (
        <ProblemTextBody>
            {content[0]}
            <ProblemSpan>{content[1]}</ProblemSpan>
        </ProblemTextBody>
    );
};

export const StressfullAndTimeConsuming = () => {
    const content = landingContentVar().StressfullAndTimeConsuming;
    return (
        <ProblemTextBody>
            {content[0]}
            <ProblemSpan>{content[1]}</ProblemSpan>
            {content[2]} <ProblemSpan>{content[3]}</ProblemSpan>
        </ProblemTextBody>
    );
};
