import React from "react";
import { useQuery } from "@apollo/client";
import { ConditionalOutput, FeedbackSpaceSelect } from "ComponentIndex";
import { useReactiveVar } from "@apollo/client";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import { GET_MY_FEEDBACK_SPACES_QUERY } from "GraphQL/Queries";
import { getInitialSelectedItems } from "./Utils";

const ListFeedbackSpaces = () => {
    let currentFeedbackSpace = useReactiveVar(currentFeedbackSpaceVar);

    const { data, error, loading } = useQuery(
        GET_MY_FEEDBACK_SPACES_QUERY
    );
    const initialFeedbackSpace = getInitialSelectedItems(
        data,
        currentFeedbackSpace
    );

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data && currentFeedbackSpace.id ? (
                <FeedbackSpaceSelect
                    id={"feedback_space_select"}
                    data={data.feedbackSpaces}
                    currentFeedbackSpace={initialFeedbackSpace}
                />
            ) : null}
        </ConditionalOutput>
    );
};

export default ListFeedbackSpaces;
