import cn from "classnames";
const DownshiftEmptyItem = ({ children, textColor = "dark" }) => {
    const itemClass = cn(
        "label_medium  text-center h-6 align-middle py-1",
        {
            "text-secondary-600": textColor === "dark",
            "text-white": textColor === "white"
        }
    );
    return <li className={itemClass}>{children}</li>;
};

export default DownshiftEmptyItem;
