import React, { useRef, forwardRef } from "react";
import cn from "classnames";
import { useClickOutside } from "HookIndex";

const MenuDropDown = ({ open, setOpen, children }) => {
    const dropDownRef = useRef(null);
    useClickOutside(dropDownRef, setOpen);

    const dropDownClasses = cn(
        "absolute top-12 right-0 z-10 rounded-md",
        "bg-white w-full shadow-md placeholder:text-secondary-300",
        "placeholder:text-base scale-up-tr min-w-fit",
        {
            ["border border-secondary-100 py-4 px-4 flex flex-col gap-y-2"]:
                open === true
        }
    );

    return (
        <div ref={dropDownRef} className={dropDownClasses}>
            {children}
        </div>
    );
};

export default MenuDropDown;
