import React from "react";
import { Title, Card } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

const DataSourceDescription = () => {
    const content = appContentVar().DataSourceDescription;
    return (
        <Card type={"light"} strech={false}>
            <Title>{content.title}</Title>
            <div className={"flex flex-col items-center"}>
                <p
                    className={
                        "text-secondary-800 body_large mb-4 text-center"
                    }>
                    {content.text[0]}
                </p>
                <p className={"body_large text-secondary-800 "}>
                    {content.text[1]}
                </p>
                <p className={"body_medium text-secondary-800 "}>
                    {content.text[2]}{" "}
                    <a
                        className={
                            "underline text-blue-600 visited:text-violet-500"
                        }
                        href='https://www.enamply.com/terms'
                        target={"_blank"}
                        rel={"noopener noreferrer"}>
                        {content.text[3]}
                    </a>
                    {content.text[4]}{" "}
                    <a
                        className={
                            "underline text-blue-600 visited:text-violet-500"
                        }
                        href='https://www.enamply.com/privacy-policy'
                        target={"_blank"}
                        rel={"noopener noreferrer"}>
                        {content.text[5]}
                    </a>{" "}
                    {content.text[6]}{" "}
                </p>
            </div>
        </Card>
    );
};

export default DataSourceDescription;
