import React from "react";
import { SyncSwitch, ConditionalOutput } from "ComponentIndex";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { useLoadParameterList } from "./Utils";
import { appContentVar } from "GraphQL/ReactiveVariables";

const LoadParameterList = ({ feedbackSpaceId, parameter }) => {
    const content = appContentVar().LoadParameterList;
    const { accessor, data, error, loading } = useLoadParameterList(
        parameter,
        feedbackSpaceId
    );

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <ul
                    className={
                        "max-h-screen-3/4 flex flex-col overflow-y-auto"
                    }>
                    {data[parameter].map((item) => {
                        return (
                            <li
                                className={"flex flex-row mb-2 gap-x-4"}
                                key={`
                                    ${masterAcc(
                                        item,
                                        accessor.accessor,
                                        accessor.idParam
                                    )}`}>
                                <SyncSwitch
                                    initialState={masterAcc(
                                        item,
                                        accessor.accessor,
                                        accessor.syncParm
                                    )}
                                    feedbackSpaceId={feedbackSpaceId}
                                    objectId={masterAcc(
                                        item,
                                        accessor.accessor,
                                        accessor.idParam
                                    )}
                                    parameter={parameter}
                                />
                                <p
                                    className={
                                        "label_medium text-secondary-900"
                                    }>
                                    {`${masterAcc(
                                        item,
                                        accessor.accessor,
                                        accessor.name
                                    )}`}
                                </p>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                content[0]
            )}
        </ConditionalOutput>
    );
};

export default LoadParameterList;
