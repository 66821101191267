const colorScheme = {
    darkBlue: "#003358",
    darkGreen: "#32936f",
    lightBlue: "#0070c0",
    lightGreen: "#93c300",
    yellow: "#ffbf00",
    orange: "#f17105",
    red: "#e83f6f",
    frenchLilac: "#8d5a97",
    lightYellow: "#f4f7be",
    grey: "#373737"
};

export const chartTheme = {
    peripherals: {
        grid: {
            strokeWidth: 1,
            stroke: colorScheme.grey,
            strokeDasharray: "1 1",
            fill: "none"
        },
        heading: {
            strokeWidth: 1,
            fontSize: "16px",
            fill: colorScheme.grey,
            textAnchor: "middle"
        },
        axis: {
            tickStyle: {
                strokeWidth: 1,
                stroke: colorScheme.grey
            },
            textStyle: {
                strokeWidth: 1,
                fontSize: "10",
                fill: colorScheme.grey,
                textAnchor: "middle"
            }
        },
        axisLabel: {
            strokeWidth: 1,
            fontSize: "12px",
            fill: colorScheme.grey,
            textAnchor: "middle"
        }
    },
    plots: {
        boxPlot: {
            minWhiskerStart: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            lowerWhisker: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            q2Start: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            upperQ2BoxLine: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            lowerQ2BoxLine: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            median: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 3,
                fill: "transparent"
            },
            upperQ3BoxLine: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            lowerQ3BoxLine: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            q3Start: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            upperWhisker: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            },
            maxWhiskerStart: {
                stroke: colorScheme.darkBlue,
                strokeWidth: 1,
                fill: "transparent"
            }
        },
        barChart: {
            bar: {
                stroke: colorScheme.darkGreen,
                strokeWidth: 1,
                fill: colorScheme.lightGreen
            },
            label: {
                strokeWidth: 1,
                fontSize: "12",
                fill: colorScheme.grey,
                textAnchor: "middle"
            }
        }
    },
    color: {
        graph: {
            darkBlue: "#003358",
            darkGreen: "#32936f",
            lightBlue: "#0070c0",
            lightGreen: "#93c300",
            yellow: "#ffbf00",
            orange: "#f17105",
            red: "#e83f6f",
            frenchLilac: "#8d5a97",
            lightYellow: "#f4f7be",
            white: "#ffffff"
        },
        gradient: {
            gradient1: "#E01E5A",
            gradient2: "#ECB22E",
            gradient3: "#F2D45A",
            gradient4: "#2EB67D",
            gradient5: "#36C5F0"
        },
        peripherals: {
            grid: "#373737"
        },
        text: {
            dark: "#373737",
            light: "#616161"
        }
    }
};
