import React from "react";
import { useSelector } from "react-redux";
import { Title } from "ComponentIndex";
import { content_eng } from "ContentManagement/english";

const SkillExamples = () => {
    const selectedSkillType = useSelector(
        (state) => state.skillTypeContent
    );
    const content =
        content_eng["skillCategory"][selectedSkillType]["examples"];
    return (
        <>
            <Title type={"title_medium"}>
                {"Examples per type of skills"}
            </Title>
            <ul className={"mt-4"}>
                {content.map((item, index) => {
                    return (
                        <li
                            key={`skillExamplePara${index}`}
                            className={
                                "body_medium text-secondary-900"
                            }>
                            {item}
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default SkillExamples;
