import React from "react";
import cn from "classnames";

const DownshiftListItem = ({
    title,
    item,
    itemProps,
    index,
    highlightedIndex,
    children,
    textColor = "dark"
}) => {
    const itemClass = cn(
        [
            "flex flex-row gap-x-1 cursor-pointer ",
            "label_medium rounded px-2 py-1 truncate",
            "hover:text-shadow"
        ],
        {
            "text-secondary-900 hover:bg-shocking-700 hover:text-white ":
                textColor === "dark",
            "text-white hover:bg-shocking-700 hover:text-white ":
                textColor === "light"
        }
    );
    return (
        <li
            title={title}
            className={itemClass}
            {...itemProps({
                index,
                item,
                style: {
                    backgroundColor:
                        highlightedIndex === index ? "#9A2472" : "",
                    color: highlightedIndex === index ? "#FFFFFF" : ""
                }
            })}>
            {children}
        </li>
    );
};

export default DownshiftListItem;
