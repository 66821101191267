import React from "react";
import {
    ConditionalOutput,
    SkillsInFeedbackSpaceAutocomplete
} from "ComponentIndex";
import { useSkillsFromFeedbackSpace } from "CustomHooks/CustomQueries";
import { usePopulateSkills } from "./Utils";

const SelectSkillsCompartment = ({
    placeHolder,
    skillType,
    leadingIconName,
    feedbackSpace,
    setSkillIds,
    skillIds,
    noItems
}) => {
    let { data, error, loading } = useSkillsFromFeedbackSpace(
        skillType,
        feedbackSpace.id
    );
    let { result } = usePopulateSkills(skillType);

    return (
        <div className={"max-w-xs"}>
            <ConditionalOutput error={error} loading={loading}>
                {data && result ? (
                    <SkillsInFeedbackSpaceAutocomplete
                        setSkillIds={setSkillIds}
                        skillIds={skillIds}
                        data={data}
                        items={result}
                        placeHolder={placeHolder}
                        leadingIconName={leadingIconName}
                        noItems={noItems}
                    />
                ) : null}
            </ConditionalOutput>
        </div>
    );
};

export default SelectSkillsCompartment;
