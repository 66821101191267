import { rewriteURIForGET } from "@apollo/client";
import * as d3 from "d3";
import { rangeChartTickLabels, satRangeParams } from "frontendSettings";

export const generateBoxPlotData = (itemList) => {
    let sortedValue = itemList.sort(d3.ascending);
    return {
        q1: d3.quantile(sortedValue, 0.25),
        median: d3.quantile(sortedValue, 0.5),
        q3: d3.quantile(sortedValue, 0.75),
        min: d3.quantile(sortedValue, 0),
        max: d3.quantile(sortedValue, 1)
    };
};

// TODO Refactor this
export const generateBoxPlotRawData = (
    data,
    dataKey,
    xScale,
    yScale
) => {
    return data.map((item) => {
        let sortedValue = item.value.sort(d3.ascending);
        let q1 = d3.quantile(sortedValue, 0.25);
        let median = d3.quantile(sortedValue, 0.5);
        let q3 = d3.quantile(sortedValue, 0.75);
        let min = d3.quantile(sortedValue, 0);
        let max = d3.quantile(sortedValue, 1);
        return {
            [dataKey]: item[dataKey],
            value: {
                y: yScale(item[dataKey]),
                bandwidth: yScale.bandwidth(),
                q1: xScale(q1),
                median: xScale(median),
                q3: xScale(q3),
                min: xScale(min),
                max: xScale(max)
            }
        };
    });
};

export const trimLongLabel = (item, maxLength) => {
    if (maxLength) {
        if (item.length > maxLength) {
            return item.slice(0, maxLength);
        } else {
            return item;
        }
    } else {
        return item;
    }
};

export const adjustXCoordsToBounds = (coord, offset, min, max) => {
    let adjustedCoord = coord;

    if (coord - offset < min) {
        adjustedCoord = coord - (coord - offset) + 2;
    } else if (coord + offset > max) {
        adjustedCoord = coord + (max - coord - offset) - 2;
    }
    return adjustedCoord;
};

export const adjustYCoordsToBounds = (coord, offset, min, max) => {
    let adjustedCoord = coord;
    if (coord - offset < max) {
        adjustedCoord = coord - (coord - offset) + 2;
    } else if (coord + offset > min) {
        adjustedCoord = coord + (min - coord - offset) - 2;
    }
    return adjustedCoord;
};

export const pointScale = (pointArray, rangeMin, rangeMax) => {
    return d3
        .scalePoint()
        .domain(pointArray)
        .range([rangeMin, rangeMax]);
};

export const curveBasis = (parameter) => {
    switch (parameter) {
        case "curve":
            return d3.curveBasis;
        default:
            return d3.curveBasis;
    }
};

export const lineGenerator = (
    dataSet,
    xFunction,
    yFunction,
    curveParamenter
) => {
    const result = d3
        .line()
        .curve(curveBasis(curveParamenter))
        .x((d) => xFunction(d))
        .y((d) => yFunction(d))(dataSet);
    return result;
};

export const areaGenerator = (
    dataSet,
    xFunction,
    y1Function,
    y2Function,
    curveParamenter
) => {
    return d3
        .area()
        .curve(curveBasis(curveParamenter))
        .x((d) => xFunction(d))
        .y0((d) => y1Function(d))
        .y1((d) => y2Function(d))(dataSet);
};

export const sortParameter = (parameter) => {
    switch (parameter) {
        case "ascending":
            return d3.ascending;
        case "descending":
            return d3.descending;
        default:
            return d3.ascending;
    }
};

export const sortData = (data, parameter) => {
    return data.sort(sortParameter(parameter));
};

export const calculateQuartiles = (
    dataPoints,
    sortParameter,
    quartile
) => {
    return d3.quantile(sortData(dataPoints, sortParameter), quartile);
};
//
export const linearScale = (
    minDomain,
    maxDomain,
    minRange,
    maxRange
) => {
    return d3
        .scaleLinear()
        .domain([minDomain, maxDomain])
        .range([minRange, maxRange]);
};

export const satisfactionRangeGrid = (scale) => {
    return rangeChartTickLabels.map((item) => {
        return scale(item);
    });
};

export const satisfactionRangeAxis = (scale, direction = "x") => {
    return satRangeParams.map((item) => {
        return {
            [direction]: scale(item.coord),
            label: item.param
        };
    });
};

export const generateAxis = (
    dataSet,
    xFunction,
    labelAcccessor,
    direction
) => {
    return dataSet.map((item) => {
        return {
            [direction]: xFunction(item),
            label: labelAcccessor(item)
        };
    });
};

export const generateGrid = (dataSet, axisFunction) => {
    return dataSet.map((item) => {
        return axisFunction(item);
    });
};

export const maxValueFromData = (dataSet, accessor) => {
    return d3.max(dataSet, accessor);
};

export const maxValueFromArray = (dataArray) => {
    return d3.max(dataArray);
};

export const bandScale = (
    domain,
    minRange,
    maxRange,
    paddingInner = 0,
    paddingOuter = 0
) => {
    return d3
        .scaleBand()
        .domain(domain)
        .range([minRange, maxRange])
        .paddingInner(paddingInner)
        .paddingOuter(paddingOuter);
};
