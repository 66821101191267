import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_ASANA_AUTH_TOKEN_MUTATION } from "GraphQL/Mutations";

export const useGetAsanaTokens = () => {
    let navigate = useNavigate();
    let [getAsanaTokens] = useMutation(GET_ASANA_AUTH_TOKEN_MUTATION, {
        onCompleted: () => {
            navigate("/app/account-settings/data-source-settings");
        }
    });
    useEffect(() => {
        let url = new URL(window.location.href);
        let code = url.searchParams.get("code");
        if (code) {
            getAsanaTokens({
                variables: {
                    code: code
                }
            });
        }
    }, []);
};
