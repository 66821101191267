import React from "react";
import { RouterLink, Button } from "ComponentIndex";

const HomeSignButton = () => {
    return (
        <RouterLink to={"/login"}>
            <Button icon={"login"} variant={"text"}>
                Login
            </Button>
        </RouterLink>
    );
};

export default HomeSignButton;
