import React from "react";

const PublicSection = ({
    children,
    verticalHeader,
    horizontalHeader,
    textColor = "text-shocking-700",
    strech = false
}) => {
    return (
        <div
            className={
                "pl-1 pr-8 md:pl-2 xl:pl-4 2xl:pl-0 items-stretch"
            }>
            <div className={"flex flex-col"}>
                <div className={"ml-10 h-8"}>
                    <h1 className={`title_large ${textColor}`}>
                        {horizontalHeader}
                    </h1>
                </div>

                <div className={"flex flex-row items-start"}>
                    <div
                        className={
                            "bg-shocking-700 rotate-180  inline-block py-4 px-1 rounded-r-lg title_large font-medium text-white"
                        }
                        style={{ writingMode: "vertical-rl" }}>
                        {verticalHeader}
                    </div>

                    <div className={"pl-6 pt-8  w-full"}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicSection;
