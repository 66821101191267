import { useLayoutSettings, useFeedbackSpaceCheck } from "./Utils";
import { NoFeedbackSpaceMessage } from "ComponentIndex";
import { useUserLog } from "HookIndex";

const MainLayout = ({
    navigation,
    action,
    content,
    workspace,
    title,
    activeTab,
    feedbackSpaceCheck = false,
    logCode
}) => {
    const feedbackSpaceExists = useFeedbackSpaceCheck();
    useLayoutSettings(workspace, title, activeTab);
    useUserLog(logCode, "start");

    return (
        <div
            className={
                "w-full lg:w-[calc(100%_-_16rem)] h-full flex flex-col"
            }>
            {feedbackSpaceCheck && feedbackSpaceExists === "null" ? (
                <NoFeedbackSpaceMessage />
            ) : (
                <>
                    <div>{navigation}</div>
                    <div>{action}</div>

                    <div
                        className={
                            " p-4 flex flex-col gap-y-4 lg:gap-y-8 items-center scroll-smooth overflow-auto touch-auto  h-full"
                        }>
                        {content}
                    </div>
                </>
            )}
        </div>
    );
};

export default MainLayout;
