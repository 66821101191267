import React from "react";
import { ActionBar, CreateFeedbackSpace } from "ComponentIndex";

const ManageFeedackSpacesActions = () => {
    return (
        <ActionBar>
            <CreateFeedbackSpace />
        </ActionBar>
    );
};

export default ManageFeedackSpacesActions;
