import React, { useState } from "react";
import { IconDialog, Icon, TaskFeedbackView } from "ComponentIndex";

const InfoCell = ({ value }) => {
    const [open, setOpen] = useState(false);

    return (
        <IconDialog
            title={"View detailes"}
            size={"medium"}
            label={"View detailes"}
            variant={"text"}
            open={open}
            setOpen={setOpen}
            icon={
                <Icon
                    name={"subject"}
                    size={"small"}
                    color={"dark"}
                    shadow={false}
                />
            }>
            <TaskFeedbackView setOpen={setOpen} id={value} />
        </IconDialog>
    );
};

export default InfoCell;
