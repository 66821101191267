import {
    CognitiveBiasesInPerformanceArticleLink,
    ImpactfullFeedbackArticleLink,
    ReviewEmbraceEmployeesEffortsArticleLink,
    TaskBasedFeedbackArticleLink
} from "BlogLinkIndex";
import React from "react";

export const TaskBasedQuestion = "What is task-based feedback?";

export const TaskBasedAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                Everything from ad-hoc results to achieving the strategy
                is done through executing tasks. Whether planned or not,
                <b>
                    {" "}
                    tasks are actions by specific people who apply their
                    skills and knowledge to generate value.
                </b>{" "}
                So, value creation is an action. It is not a fuzzy
                concept in the business lexicon.{" "}
                <b>
                    Therefore, a task is a unit of effort that
                    determines a company's performance, so{" "}
                    <a
                        className={"underline"}
                        href={TaskBasedFeedbackArticleLink}
                        target='_blank'
                        rel='noopener noreferrer'>
                        task-based feedback
                    </a>{" "}
                    helps learn quickly and frequently about the real
                    needs of individuals, teams, and organizations to be
                    performant.
                </b>
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                Giving{" "}
                <a
                    className={"underline"}
                    href={ImpactfullFeedbackArticleLink}
                    target='_blank'
                    rel='noopener noreferrer'>
                    impactful feedback
                </a>{" "}
                on selected tasks will reduce{" "}
                <a
                    className={"underline"}
                    href={CognitiveBiasesInPerformanceArticleLink}
                    target='_blank'
                    rel='noopener noreferrer'>
                    cognitive bias
                </a>{" "}
                effects because the knowledge is fresh, and you focus on
                specifics, not general aspects of someone's behavior.
                Task-based feedback loops are short, from minutes to a
                few days after task execution, so improvements happen
                more often and are incremental.
            </p>
        </div>
    );
};

export const CognitiveBiasQuestion = "What is cognitive bias?";

export const CognitiveBiasAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                <b>
                    {" "}
                    <a
                        className={"underline"}
                        href={CognitiveBiasesInPerformanceArticleLink}
                        target='_blank'
                        rel='noopener noreferrer'>
                        Cognitive biases
                    </a>{" "}
                    are subconscious mental shortcuts applied while
                    interacting with others and amplified by context and
                    emotions.
                </b>
            </p>{" "}
            <p className={"body_large text-secondary-800 mb-4"}>
                These automatic thinking processes make us quick and
                efficient but can lead to misinterpretation of reality.
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                Performance reviews are prone to biases because they
                involve judgment and interpretation of the external
                world and others.
            </p>{" "}
            <p className={"body_large text-secondary-800 mb-4"}>
                There are close to two hundred biases mapped by
                scientists, and here are a few examples that we find
                most often in the workplace:
            </p>
            <ul className={"list-disc list-inside mb-6"}>
                <li>
                    <b>Availability bias</b> - we tend to recall easier
                    things that happened recently.
                </li>{" "}
                <li>
                    <b>Anchoring</b> - we anchor our beliefs to things
                    that impress us and memorize emotional events.
                </li>
                <li>
                    <b>Confirmation bias</b> - we like when we are
                    right, so we tend to confirm it to ourselves and
                    ignore contradictory evidence.
                </li>{" "}
                <li>
                    <b>Attribution bias</b> - we perceive our
                    achievements as the result of hard work and
                    attribute failures to external factors, while we
                    think the opposite about others' successes and
                    failures.
                </li>
            </ul>
            <p className={"body_large text-secondary-800 mb-4"}>
                Enamply helps you acknowledge and manage these mental
                shortcuts to diminish their harmful effects during
                performance reviews.
            </p>
        </div>
    );
};

export const KeepTrakOnTasksQuestion =
    "How does Enamply keep track of my tasks?";

export const KeepTrakOnTasksAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                <b>
                    Enamply is integrating with Task Management
                    Platforms using the OATH 2.0 authorization protocol,
                    thus acting on behalf of our users without access to
                    their usernames and passwords.
                </b>
            </p>{" "}
            <p className={"body_large text-secondary-800 mb-4"}>
                Enamply is ready to use with Asana and Jira, but we are
                continually increasing our reach for other platforms, so
                we are happy to prioritize your needs.
            </p>
        </div>
    );
};

export const BiasInPerformanceQuestion =
    "How can I know if my Performance Reviews are affected by biases?";

export const BiasInPerformanceAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                <b>
                    If the evaluations are based only on anecdotal data
                    and periodical surveys without direct connection
                    with specific actions, or if feedback, in general,
                    is infrequent and late after the evaluated events,
                    then the likelihood of bias is high.
                </b>{" "}
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                Another good indication is when performance reviews are
                associated with stress, waste of time, or "needed
                struggle" to motivate, promote or reward employees for
                their efforts. Suppose managers find themselves in a
                damage control period after the performance reviews. In
                that case, it means that feedback flows before the
                appraisal were misleading, so the evaluation was
                affected by biases.
            </p>
        </div>
    );
};

export const HowToStartQuestion = "How do I start with Enamply?";

export const HowToStartAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                <b>
                    We would be glad to offer you a tour and discuss
                    your needs in a short call.
                </b>{" "}
                We are happy to accommodate a trial period to show you
                in your examples how easy it is to use Enamply.{" "}
                <a
                    className={
                        "underline text-blue-700 visited:text-violet-700"
                    }
                    href='https://www.enamply.com/contact-us'
                    target={"_blank"}
                    rel={"noopener noreferrer"}>
                    Please leave us your email
                </a>
                , and we will reach out to you shortly.
            </p>{" "}
            <p className={"body_large text-secondary-800 mb-4"}>
                Once you have access to the platform you will define
                your first Feedback Space, where you will be able to
                invite team members, decide what projects to consider
                for feedback and how you want to be notified about the
                Enamply insights.
            </p>
        </div>
    );
};

export const PriceQuestion = "What is the price for Enamply?";

export const PriceAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                We would be glad to clarify your specific needs during
                an initial tour and then offer you a price. Regardless,
                we are happy to accommodate your requests, so{" "}
                <a
                    className={
                        "underline text-blue-600 visited:text-violet-500"
                    }
                    href='https://www.enamply.com/contact-us'
                    target={"_blank"}
                    rel={"noopener noreferrer"}>
                    please leave us your email
                </a>
                , and we will reach out to you shortly.
            </p>
        </div>
    );
};

export const WithoutSkillsQuestion =
    "Can I use Enamply without a skill map for my team?";

export const WithoutSkillsAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                <b>
                    You can use Enamply without a defined skill map
                    (inventory), but it will be much more helpful if you
                    create one gradually using our tools and library of
                    skills.
                </b>{" "}
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                You can start collecting feedback for tasks and identify
                skills to add to your inventory. Or you can consider
                your existing job description, processes, and standards
                as a source of inspiration to create a base for your
                future skill mapping. Either way, you can adjust it
                based on actual needs with every feedback.
            </p>
        </div>
    );
};

export const RequirementsQuestion =
    "What do I need (requirements) to start using Enamply?";

export const RequirementsAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                <b>
                    The only requirement is to use a Task Management
                    Platform in your work. Enamply can connect through
                    an open API with OATH 2.0, which most platforms
                    support.
                </b>
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                Currently, Enamply is integrated with Asana and Jira
                platforms, but we are continually increasing our reach
                for other platforms, so we are happy to prioritize your
                needs. If you have any doubts, we would be glad to help
                you during an initial tour, so don't hesitate to{" "}
                <a
                    className={
                        "underline text-blue-600 visited:text-violet-500"
                    }
                    href='https://www.enamply.com/contact-us'
                    target={"_blank"}
                    rel={"noopener noreferrer"}>
                    contact
                </a>{" "}
                us.
            </p>
        </div>
    );
};

export const AllreadyHaveQuestion =
    "I already have a Performance review platform; how can I use Enamply?";

export const AllreadyHaveAnswer = () => {
    return (
        <div>
            <p className={"body_large text-secondary-800 mb-4"}>
                Enamply helps you make feedback a habit and improve
                based on data, not impressions. This reduces biases in
                performance reviews, whether done using a platform or
                your proprietary process.{" "}
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                With frequent and on-time feedback, you can{" "}
                <b>
                    improve task execution, quick learning, and know-how
                    sharing in your organization.
                </b>
                This will increase the rate of innovation and{" "}
                <b>
                    help teams with skills mapping and identifying
                    knowledge gaps.
                </b>
            </p>
            <p className={"body_large text-secondary-800 mb-4"}>
                Leadership can{" "}
                <b>
                    have a helicopter view of the actual learning needs
                </b>{" "}
                of teams and individuals, thus{" "}
                <b>
                    improving learning programs and recruitment results
                </b>
                .
            </p>{" "}
            <p className={"body_large text-secondary-800 mb-4"}>
                {" "}
                Applying Enamply insights, you will{" "}
                <a
                    className={"underline"}
                    href={ReviewEmbraceEmployeesEffortsArticleLink}
                    target='_blank'
                    rel='noopener noreferrer'>
                    make better decisions
                </a>{" "}
                during stand-up meetings, Sprint retrospectives, and
                periodical Performance reviews which will increase your
                productivity overall.
            </p>
        </div>
    );
};
