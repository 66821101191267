export const FEATURE_TOUR_TITLES = {
    1: ["Dashboard"],
    2: ["Feedback Page"],
    3: ["Insights and Reports"],
    4: ["Feedback Spaces"],
    5: ["Skills Management"],
    6: ["Sync and Set up"],
    7: ["Refresh Data"],
    8: ["The End"]
};

export const FEATURE_TOUR_TEXT = {
    1: [
        "This is the welcome page on Enamply. ",
        "Here you can learn about the state of a feedback space through our performance indicators.",
        "All the notifications will be posted here."
    ],
    2: [
        "This is the place to choose tasks that matter and collect feedback.",
        "To do so, you first need to be part of a Feedback Space or to setup your own."
    ],
    3: [
        "This is the place to choose tasks that matter and collect feedback.",
        "To do so, you first need to be part of a Feedback Space or to setup your own."
    ],
    4: [
        "In this section, you can create and manage Feedback Spaces. ",
        "A Feedback Space in Enamply is the environment defined by a user who wants to bring together projects and team members to establish feedback loops among them.",
        "Having at least one Feedback Space is mandatory to use Enamply in full."
    ],
    5: [
        "In this section, you can define skills relevant to your organization.",
        "You can allocate skills to four main categories and use them across Feedback Spaces, projects, and teams.",
        "Linking skills to tasks will allow your feedback to be more specific, actionable, and relevant for the task assignee and the organization."
    ],
    6: [
        "Here you can sync Enamply to your Task Management Platform, set-up and manage Feedback Spaces, and manage invitations to Feedback Spaces created by others.",
        "Please go through the setup before you can use Enamply in full."
    ],
    7: [
        "Enamply is refreshing periodically the data transfer from the Task Management Platform.",
        "However, by pressing the Refresh, you can force the application to update the data transfer thus be sure that the information in Enamply is always updated."
    ],
    8: [
        "You are at the end of the tour!",
        'You can repeat the tour any time you need by going to “Help"',
        "Thank you!"
    ]
};

// *****
export const SETUP_TOUR_TITLES = {
    1: ["Sync and Set up"],
    2: ["Authorize Task Management Platform"],
    3: ["Feedback Spaces"],
    4: ["Manage Feedback Space"],
    5: ["Setup Projects and Assignees"],
    6: ["Invite members"],
    7: ["Skills Allocation"],
    8: ["Notification Setup"],
    9: ["Manage roles"],
    10: ["Manage Invitations"],
    11: ["The End"]
};

export const SETUP_TOUR_TEXT = {
    1: [
        "Here you can sync Enamply to your Task Management Platform, set-up and manage Feedback Spaces, and manage invitations to Feedback Spaces created by others.",
        "Please go through the setup before you can use Enamply in full."
    ],
    2: [
        "This is the first step to setting up the Enamply feedback environment and using the platform.",
        "After successful authorization, you can choose the list of projects and assignees visible on Enamply for feedback and evaluation."
    ],
    3: [
        "In this section, you can manage Feedback Spaces that you own or are part of.",
        "Feedback spaces without Task Management Platform Authorization do not allow to see and feedback tasks, but one can still invite members and attribute roles."
    ],
    4: [
        "Here, you can manage the Feedback Spaces if you are an Owner or Admin."
    ],
    5: [
        "Here you can decide what projects and who will be assessed in the Feedback Space."
    ],
    6: [
        "You can invite team members who can offer feedback and learn the insights of this Feedback Space.",
        "You can invite only members with an account on the platform."
    ],
    7: [
        "We encourage you to allocate Skills to each Feedback Space from your Skills Inventory.",
        "This will unlock the possibility of analyzing and improving skills per team member and tasks. You can always edit the skills allocated to the Feedback Space."
    ],
    8: [
        "Staying informed is important so you can decide the type of notifications you want to receive in your email about each Feedback Space. "
    ],
    9: [
        "Here you can modify and manage the roles of the Feedback Space members."
    ],
    10: [
        "Here you will receive invitations to Feedback Spaces created by others and manage invitations you launched."
    ],
    11: [
        "You are at the end of the Setup tour!",
        'You can repeat the tour any time you need by going to "Help". ',
        "Thank you!"
    ]
};

// *****
export const INVITATIONS_TOUR_TITLES = {
    1: ["Notification"],
    2: ["Profile"],
    3: ["Manage Invitations"],
    4: ["The End"]
};

export const INVITATIONS_TOUR_TEXT = {
    1: [
        "Here you will see an icon appear if you have invitations to join a Feedback Space.",
        "No icon, no invitations."
    ],
    2: ["Invitations can be found in profile settings."],
    3: [
        "Here you will receive invitations to Feedback Spaces created by others and manage invitations you launched.",
        "By joining a Feedback Space, you can offer feedback and analyze insights with other members."
    ],
    4: [
        "That was quick! You are at the end of the Tour!",
        'You can repeat the tour any time you need by going to "Help"',
        "Thank you!"
    ]
};

// *****

export const MANAGE_SKILLS_TOUR_TITLES = {
    1: ["Skills Management"],
    2: ["Add Skills to your Skill Inventory"],
    3: ["Choose skill type"],
    4: ["Choose skill type"],
    5: ["Skills Inventory"],
    6: ["The End"]
};

export const MANAGE_SKILLS_TOUR_TEXT = {
    1: ["You can access skill in Skills Managment Compartment."],
    2: [
        "Every task is executed by applying skills, so keeping an updated inventory of skills for your team and organization is crucial.",
        "Here you can add and define skills that will later be available to attach to any task and offer specific feedback.",
        "You can add skills any time; the more you can define, the better the team will know what to develop."
    ],
    3: [
        "Skill types will help you structure the skill sets and identify the focus for future development programs.",
        "Select a Skill type from the drop-down list for each added skill."
    ],
    4: [
        "By selecting the skill type in the drop-down list, you can learn about skill types and check out some examples."
    ],
    5: [
        "Here you can find all the skills that you have in your inventory.",
        "These skills can be attached to any Feedback Space you manage through Feedback Spaces in Account Settings.",
        "You can update the inventory as your team develops, pursues new challenges, and increases its understanding of the relevant skills."
    ],
    6: [
        "You are at the end of the Skills Inventory Tour!",
        'You can repeat the tour any time you need by going to "Help".',
        "Thank you!"
    ]
};

export const FEEDBACK_TOUR_TITLES = {
    1: ["Access Feedback"],
    2: ["Analyze pending tasks"],
    3: ["Collect Feedback"],
    4: ["Skip Feedback"],
    5: ["Manage Given Feedback"],
    6: ["Manage Skipped Tasks"],
    7: ["The End"]
};

export const FEEDABCK_TOUR_TEXT = {
    1: [
        "Here you can access feedback to tasks that are considered to be done."
    ],
    2: [
        "Here you will have acces to all the tasks that are waiting feedback once they are executed in your Task Management platform."
    ],
    3: [
        "By pressing “Feedback”, you will open the Feedback Form for the task.",
        "Once you save the feedback, the task will disappear from this section and appear in “Given”."
    ],
    4: [
        'You can "skip" giving feedback for a task if you don\'t consider it necessary. This can happen for small tasks, unique actions that are not relevant for employee development or other cases.',
        "You can offer feedback later for a skipped task if needed by going to “Skipped” on the upper bar."
    ],
    5: [
        "This is a complete list of all the tasks that have feedback in the specific Feedback Space you are exploring.",
        "Here you can view and edit feedback forms for each task that has feedback."
    ],
    6: [
        "This is a complete list of all the tasks skipped for the specific Feedback Space you are exploring.",
        "Here you can find a task and collect feedback even if it was previously skipped."
    ],
    7: [
        "You are at the end of the tour!",
        'You can repeat the tour any time you need by going to “Help".',
        "Thank you!"
    ]
};

export const INSIGHTS_TOUR_TITLES = {
    1: ["Insights and Reports"],
    2: ["Feedback per Employee"],
    3: ["Filters"],
    4: ["Description"],
    5: ["Feedback per Skill"],
    6: ["Filters"],
    7: ["The End"]
};

export const INSIGHTS_TOUR_TEXT = {
    1: [
        "Here you will find Enamply analysis and insights about the feedback per assignees, skills and projects.",
        "The more feedback is collected the better the insights are."
    ],
    2: [
        "Here you can analyze the insights having an Employee-centric approach."
    ],
    3: [
        "Search for key words, filter by employees, projects or periods to learn more about the feedback."
    ],
    4: [
        "You will find a Description of the insight by clicking the “i” symbol on every card found on the Insights page."
    ],
    5: [
        "Here you can analyze the insights having a Skill-centric approach.",
        "Learn how often a skill is applied, and what is the level of the performance."
    ],
    6: [
        "Filter by employees, skill type, individual skills, projects or periods to learn more about the feedback."
    ],
    7: [
        "You are at the end of the tour!",
        'You can repeat the tour any time you need by going to “Help".',
        "Thank you!"
    ]
};
