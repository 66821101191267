import React from "react";
import cn from "classnames";

const Hr = ({ color, weight }) => {
    const hrClass = cn("rounded-sm", {
        "h-1.5": weight === "large",
        "h-1": weight === "medium",
        "h-0.5": weight === "small",
        "h-0": weight === "none",
        "bg-secondary-50": color === "light",
        "bg-secondary-900": color === "dark",
        "bg-shocking-700": color === "primary"
    });
    return <hr className={hrClass} />;
};

export default Hr;
