import React from "react";

const ChartLoading = () => {
    return (
        <text x={"50%"} y={"50%"} fontSize={30}>
            {"Loading chart..."}
        </text>
    );
};

export default ChartLoading;
