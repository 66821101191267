import React from "react";

const BlogCategory = ({ content }) => {
    return (
        <div className={"flex flex-row gap-2 flex-wrap mt-6"}>
            {content.categories.map((item, index) => {
                return (
                    <span
                        key={`cat${index}`}
                        className={
                            " rounded-full py-1 px-2 border border-secondary-600 blog_label_small text-shocking-700"
                        }>
                        {item}
                    </span>
                );
            })}
        </div>
    );
};

export default BlogCategory;
