import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    ContentWrapperBand,
    PrivacyContent,
    HomeFooter,
    HelmetExclude
} from "ComponentIndex";

const PrivacyPolicyContent = () => {
    workspaceNameVar("Privacy policy");
    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand>
                <PrivacyContent />
                <HomeFooter color={"white"} />
            </ContentWrapperBand>
        </>
    );
};

export default PrivacyPolicyContent;
