import React from "react";
import { Button, ConditionalOutput } from "ComponentIndex";
import { useRemoveFromFeedbackSpace } from "./Utils";

const RemoveFromFeedbackSpace = ({ recordItem }) => {
    const { removeFromFeedbackSpace, error, loading } =
        useRemoveFromFeedbackSpace(
            recordItem.feedbackSpace.id,
            recordItem.memberAccount.id
        );

    return (
        <ConditionalOutput error={error} loading={loading}>
            <Button
                onClick={removeFromFeedbackSpace}
                variant={"tonal"}
                icon={"person_remove"}>
                Remove
            </Button>
        </ConditionalOutput>
    );
};

export default RemoveFromFeedbackSpace;
