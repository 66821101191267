import React from "react";
import classnames from "classnames";
import cls from "./FeedbackSpaceWeeklyDigest.module.css";
import { Switch, HelpModule } from "ComponentIndex";
import { useNotificationPrefChange } from "CustomHooks/CustomMutations";

const FeedbackSpaceWeeklyDigest = ({ data, feedbackSpace }) => {
    const mutationVariable = {
        parameterLabel: "feedback_space_weekly_digest",
        feedbackSpaceId: feedbackSpace.id
    };
    const [switchState, handleSwitchChange] = useNotificationPrefChange(
        data,
        mutationVariable,
        "fb"
    );
    const feedbackSpaceWeeklyDigestClass = classnames(
        cls.feedbackSpaceWeeklyDigest
    );
    const descriptionClass = classnames(cls.description);

    return (
        <div className={feedbackSpaceWeeklyDigestClass}>
            <Switch
                checked={switchState}
                onChange={handleSwitchChange}
            />
            <div className={descriptionClass}>
                <p className={"label_large text-secondary-900"}>
                    {"Send feedback space weekly digest"}
                </p>
            </div>
            <HelpModule
                yOffset={-110}
                xOffset={-120}
                message={
                    "You will receive a short resume of events that heppened in this feedback space during previous week on Enamply platform."
                }
            />
        </div>
    );
};

export default FeedbackSpaceWeeklyDigest;
