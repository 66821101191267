import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
    GET_USER_NOTIFICATIONS_PREFERANCES,
    ASSIGNEE_PARAMETERS_FILTER_QUERY,
    PROJECT_FILTER_QUERY,
    FEEDBACK_SPACE_SKILL_NAME_FILTER_QUERY,
    FEEDBACK_SPACE_SKILL_TYPE_FILTER_QUERY,
    ASANA_TOKEN_VERIF_QUERY,
    GET_MY_FEEDBACK_SPACE_INVITATIONS_QUERY,
    SKILLS_FROM_FEEDBACK_SPACE_QUERY,
    GET_SKILL_TYPES_QUERY,
    TASK_TYPE_FILTER_QUERY,
    DATA_SOURCE_FILTER_QUERY
} from "GraphQL/Queries";

import {
    currentFeedbackSpaceVar,
    assigneesParametersForFilterVar,
    taskTypesForFilterVar,
    dataSourceForFilterVar,
    assigneeParametersIdsVar,
    taskTypeNamesVar,
    projectIdsVar,
    projectsForFilterVar,
    skillIdsVar,
    dataSourceIdsVar,
    skillsForFilterVar,
    skillTypesVar,
    skillTypesForFilterVar,
    inventorySkillTypesForFilterVar
} from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { getGQLData } from "UtilityFunctionIndex";

import {
    makeSkillSet,
    makeSkillTypeSet,
    makeSkillInFeedbackSet,
    makeSkillObjectArray,
    makeInventorySkillObjectArray
} from "./UtilityFunctions";
import { masterAcc } from "UtilityFunctions/DataAccessors";

export const useNotificationPreferences = (feedbackSpace) => {
    const { data, loading, error } = useQuery(
        GET_USER_NOTIFICATIONS_PREFERANCES,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id
            },
            fetchPolicy: "network-only"
        }
    );
    return { data, loading, error };
};

export const useAssigneeFilterData = () => {
    const feedbackSpace = useReactiveVar(currentFeedbackSpaceVar);
    const projectIds = useReactiveVar(projectIdsVar);
    const taskTypeNames = useReactiveVar(taskTypeNamesVar);
    const skillIds = useReactiveVar(skillIdsVar);
    const skillTypes = useReactiveVar(skillTypesVar);
    const dataSourceIds = useReactiveVar(dataSourceIdsVar);

    const { data, loading, error } = useQuery(
        ASSIGNEE_PARAMETERS_FILTER_QUERY,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id,
                assigneeSyncStatus: true,
                projectIds: projectIds,
                skillIds: skillIds,
                skillTypes: skillTypes,
                taskTypeNames: taskTypeNames,
                dataSourceIds: dataSourceIds
            },
            fetchPolicy: "network-only"
        }
    );
    useEffect(() => {
        if (data) {
            assigneesParametersForFilterVar([
                ...data.assigneesParameters
            ]);
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useTaskTypeFilterData = () => {
    const feedbackSpace = useReactiveVar(currentFeedbackSpaceVar);
    const assigneeParametersIds = useReactiveVar(
        assigneeParametersIdsVar
    );
    const projectIds = useReactiveVar(projectIdsVar);
    const skillIds = useReactiveVar(skillIdsVar);
    const skillTypes = useReactiveVar(skillTypesVar);
    const dataSourceIds = useReactiveVar(dataSourceIdsVar);

    const { data, loading, error } = useQuery(TASK_TYPE_FILTER_QUERY, {
        variables: {
            feedbackSpaceId: feedbackSpace.id,
            projectIds: projectIds,
            skillIds: skillIds,
            skillTypes: skillTypes,
            assigneeParametersIds: assigneeParametersIds,
            dataSourceIds: dataSourceIds
        },
        fetchPolicy: "network-only"
    });
    useEffect(() => {
        if (data) {
            taskTypesForFilterVar([...data.taskType]);
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useDataSourceFilterData = () => {
    const feedbackSpace = useReactiveVar(currentFeedbackSpaceVar);
    const assigneeParametersIds = useReactiveVar(
        assigneeParametersIdsVar
    );
    const projectIds = useReactiveVar(projectIdsVar);
    const skillIds = useReactiveVar(skillIdsVar);
    const skillTypes = useReactiveVar(skillTypesVar);
    const taskTypeNames = useReactiveVar(taskTypeNamesVar);

    const { data, loading, error } = useQuery(
        DATA_SOURCE_FILTER_QUERY,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id,
                projectIds: projectIds,
                skillIds: skillIds,
                skillTypes: skillTypes,
                assigneeParametersIds: assigneeParametersIds,
                taskTypeNames: taskTypeNames
            },
            fetchPolicy: "network-only"
        }
    );
    useEffect(() => {
        if (data) {
            dataSourceForFilterVar([...data.dataSource]);
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useProjectFilterData = () => {
    const feedbackSpace = useReactiveVar(currentFeedbackSpaceVar);
    const assigneeParametersIds = useReactiveVar(
        assigneeParametersIdsVar
    );
    const taskTypeNames = useReactiveVar(taskTypeNamesVar);
    const skillIds = useReactiveVar(skillIdsVar);
    const skillTypes = useReactiveVar(skillTypesVar);
    const dataSourceIds = useReactiveVar(dataSourceIdsVar);

    let { data, loading, error } = useQuery(PROJECT_FILTER_QUERY, {
        variables: {
            feedbackSpaceId: feedbackSpace.id,
            projectSyncStatus: true,
            assigneeParametersIds: assigneeParametersIds,
            skillIds: skillIds,
            skillTypes: skillTypes,
            taskTypeNames: taskTypeNames,
            dataSourceIds: dataSourceIds
        },
        fetchPolicy: "network-only"
    });
    useEffect(() => {
        if (data) {
            projectsForFilterVar(data.projects);
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useFeedbackSpaceSkillNameFilterData = () => {
    const feedbackSpace = useReactiveVar(currentFeedbackSpaceVar);
    const assigneeParametersIds = useReactiveVar(
        assigneeParametersIdsVar
    );
    const taskTypeNames = useReactiveVar(taskTypeNamesVar);
    const projectIds = useReactiveVar(projectIdsVar);
    const skillTypes = useReactiveVar(skillTypesVar);
    const dataSourceIds = useReactiveVar(dataSourceIdsVar);

    let { data, loading, error } = useQuery(
        FEEDBACK_SPACE_SKILL_NAME_FILTER_QUERY,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id,
                assigneeParametersIds: assigneeParametersIds,
                projectIds: projectIds,
                skillTypes: skillTypes,
                taskTypeNames: taskTypeNames,
                dataSourceIds: dataSourceIds
            },
            fetchPolicy: "network-only"
        }
    );

    useEffect(() => {
        if (data) {
            skillsForFilterVar([
                ...makeSkillInFeedbackSet(data.skillsInFeedbackSpace)
            ]);
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useFeedbackSpaceSkillTypeFilterData = () => {
    const feedbackSpace = useReactiveVar(currentFeedbackSpaceVar);
    const assigneeParametersIds = useReactiveVar(
        assigneeParametersIdsVar
    );
    const taskTypeNames = useReactiveVar(taskTypeNamesVar);
    const projectIds = useReactiveVar(projectIdsVar);
    const skillIds = useReactiveVar(skillIdsVar);
    const dataSourceIds = useReactiveVar(dataSourceIdsVar);

    let { data, loading, error } = useQuery(
        FEEDBACK_SPACE_SKILL_TYPE_FILTER_QUERY,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id,
                assigneeParametersIds: assigneeParametersIds,
                projectIds: projectIds,
                skillIds: skillIds,
                taskTypeNames: taskTypeNames,
                dataSourceIds: dataSourceIds
            },
            fetchPolicy: "network-only"
        }
    );

    useEffect(() => {
        if (data) {
            skillTypesForFilterVar(
                makeSkillObjectArray(data.skillsInFeedbackSpace)
            );
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useInventorySkillTypesFilterData = () => {
    let { data, loading, error } = useQuery(GET_SKILL_TYPES_QUERY, {
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (data) {
            inventorySkillTypesForFilterVar(
                makeInventorySkillObjectArray(data.skills)
            );
        }
    }, [loading]);
    return { loading: loading, error: error };
};

export const useAsanaAuthVerification = () => {
    const [authorized, setAuthorized] = useState(null);
    const { data, loading, error } = useQuery(ASANA_TOKEN_VERIF_QUERY);
    useEffect(() => {
        setAuthorized(getGQLData(data, "asanaTokenVerif"));
    }, [data]);

    return [authorized, setAuthorized, data, loading, error];
};

export const useGetFeedbackSpaceInvitations = () => {
    const { data, error, loading } = useQuery(
        GET_MY_FEEDBACK_SPACE_INVITATIONS_QUERY,
        {
            variables: {
                statusList: ["INV"]
            }
        }
    );
    return {
        data,
        loading,
        error
    };
};

export const useSkillsFromFeedbackSpace = (
    skillTypes,
    feedbackSpaceId
) => {
    let {
        data: rawData,
        error,
        loading
    } = useQuery(SKILLS_FROM_FEEDBACK_SPACE_QUERY, {
        variables: {
            feedbackSpaceId: feedbackSpaceId,
            skillTypes: skillTypes
        },
        fetchPolicy: "network-only"
    });

    return {
        data: assembleSkillsInFeedbackSpace(rawData),
        loading,
        error
    };
};

const assembleSkillsInFeedbackSpace = (data) => {
    if (data && data.skillsInFeedbackSpace) {
        return data.skillsInFeedbackSpace.map((item) => {
            return {
                skillName: masterAcc(
                    item,
                    "skillInFeedbackSpace",
                    "skillName"
                ),
                id: masterAcc(item, "skillInFeedbackSpace", "skillId")
            };
        });
    } else {
        return [];
    }
};
