import { useEffect, useState } from "react";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { useQuery } from "@apollo/client";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";

import {
    RESUME_TABLE_QUERY,
    SKILLS_FROM_FEEDBACK_SPACE_QUERY
} from "GraphQL/Queries";
import { useFeedbackQuery } from "../TaskFeedbackContent/Utils";

export const makeSkillIDSet = (selectedSkills, options) => {
    let selectedIds = [];

    if (options.length !== 0) {
        selectedIds = options.map((item) => {
            return masterAcc(item, "skills", "id");
        });
    }

    const skillSet = new Set([...selectedSkills, ...selectedIds]);
    return [...skillSet];
};

export const extractSkills = (data) => {
    if (data && data.skillsInFeedbackSpace) {
        return data.skillsInFeedbackSpace.map((item) => {
            return {
                id: masterAcc(item, "skillInFeedbackSpace", "skillId"),
                skillName: masterAcc(
                    item,
                    "skillInFeedbackSpace",
                    "skillName"
                )
            };
        });
    } else {
        return [];
    }
};

export const filterSkillsByType = (skills, skillType) => {
    return skills.filter((skill) => {
        return skill["skillType"] === skillType;
    });
};

export const skillsToString = (item) => {
    return item["skillName"];
};

export const prepareData = (data) => {
    return data.map((item) => {
        return item["skill"];
    });
};

export const useDataPopulateSelectorData = (skillType) => {
    const feedbackSpaceId = currentFeedbackSpaceVar().id;

    const { data, error, loading } = useQuery(
        SKILLS_FROM_FEEDBACK_SPACE_QUERY,
        {
            variables: {
                feedbackSpaceId: feedbackSpaceId,
                skillTypes: [skillType]
            }
        }
    );

    return { data, error, loading };
};
