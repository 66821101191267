import React from "react";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import {
    USP,
    FeedbackProblems,
    HomeFooter,
    OurApproach,
    WhoIsItFor,
    Features,
    ClickToAction,
    BackToTop,
    PositiveEffects,
    FrequentlyAskedQuestions,
    HelmetInclude,
    DiscoverOurProduct
} from "ComponentIndex";

const HomeContent = () => {
    const content = landingContentVar().HomeContent;
    workspaceNameVar("Solution");
    return (
        <>
            <HelmetInclude content={content} />
            <USP />
            <FeedbackProblems />
            <BackToTop />
            <OurApproach />
            <DiscoverOurProduct />
            <ClickToAction />
            <WhoIsItFor />
            <PositiveEffects />
            <Features />
            <FrequentlyAskedQuestions />
            <ClickToAction />
            <HomeFooter />
        </>
    );
};

export default HomeContent;
