import React from "react";

const BlogKicker = ({ content }) => {
    return (
        <h3
            className={
                "blog_title_medium text-secondary-600 uppercase"
            }>
            {content.kicker[0]}
        </h3>
    );
};

export default BlogKicker;
