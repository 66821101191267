import React, { forwardRef } from "react";
import {
    DivWrapper,
    SVGWrapper,
    ChartTooltip,
    ChartLoading,
    Label,
    ChartBounds,
    PlotAreaBackground,
    Bar,
    AxisLeft
} from "ChartIndex";
import { useTooltipHandlers } from "UtilityFunctionIndex";
import TooltipData from "./TooltipData";

const HorizontalBarChartCSV = forwardRef(
    ({ graphs, toolTipKey, toolTipValue }, ref) => {
        const [
            handleChartTooltipMouseEnter,
            handleChartTooltipMouseMove,
            handleChartTooltipMouseLeave,
            toolOpen
        ] = useTooltipHandlers();
        return (
            <DivWrapper>
                <SVGWrapper ref={ref}>
                    {graphs.plotData ? (
                        <>
                            <Label
                                x={graphs.dimensions.width / 2}
                                y={graphs.dimensions.margin.top * 0.5}
                                type={"chart_name"}
                                color={"dark"}>
                                {graphs.chartName}
                            </Label>
                            <ChartBounds
                                xOffset={graphs.dimensions.margin.left}
                                yOffset={graphs.dimensions.margin.top}>
                                <g className='chartBackground'>
                                    <PlotAreaBackground
                                        height={
                                            graphs.dimensions
                                                .boundedHeight
                                        }
                                        width={
                                            graphs.dimensions
                                                .boundedWidth
                                        }
                                        fill={"transparent"}
                                    />
                                </g>
                                <g className='dataPlot'>
                                    {graphs.plotData.map(
                                        (item, index) => {
                                            return (
                                                <Bar
                                                    key={`bar${index}`}
                                                    data={item}
                                                    textOffestX={3}
                                                    textOffsetY={0}
                                                    onMouseEnter={handleChartTooltipMouseEnter(
                                                        item
                                                    )}
                                                    onMouseLeave={
                                                        handleChartTooltipMouseLeave
                                                    }
                                                    onMouseMove={
                                                        handleChartTooltipMouseMove
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </g>
                                <g className='peripherials'>
                                    <AxisLeft
                                        dataSequence={graphs.yAxis}
                                        length={5}
                                        xOffset={-7}
                                        yOffset={0}
                                        textOffsetX={-5}
                                        textOffsetY={3}
                                        textAnchor={"end"}
                                        labelLength={12}
                                    />
                                </g>
                            </ChartBounds>
                        </>
                    ) : (
                        <ChartLoading />
                    )}
                </SVGWrapper>
                <ChartTooltip state={toolOpen}>
                    {toolOpen.open && (
                        <TooltipData
                            data={toolOpen.toolTip}
                            toolTipKey={toolTipKey}
                            toolTipValue={toolTipValue}
                        />
                    )}
                </ChartTooltip>
            </DivWrapper>
        );
    }
);

export default HorizontalBarChartCSV;
