import React from "react";
import { Notification } from "ComponentIndex";
import { useSelector } from "react-redux";

const NotificationManager = () => {
    let notificationState = useSelector(
        (state) => state.notificationManagement
    );
    return (
        <div className={"fixed top-40 right-3 w-80 z-50"}>
            {notificationState.map((note) => {
                return (
                    // <Notification
                    //     key={note.id}
                    //     id={note.id}
                    //     variant={note.variant}
                    //     message={note.message}
                    // />
                    <div></div>
                );
            })}
        </div>
    );
};

export default NotificationManager;
