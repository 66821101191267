import { useMutation } from "@apollo/client";
import { REMOVE_FROM_FEEDBACK_SPACE_MUTATION } from "GraphQL/Mutations";
import { clearCacheItem } from "GraphQL/Cache";

export const useRemoveFromFeedbackSpace = (feedbackSpaceId, userId) => {
    const [remove, { error, loading }] = useMutation(
        REMOVE_FROM_FEEDBACK_SPACE_MUTATION,
        {
            variables: {
                feedbackSpaceId: feedbackSpaceId,
                userId: userId
            },
            onCompleted: () => {
                clearCacheItem(userId);
            }
        }
    );
    const removeFromFeedbackSpace = () => {
        remove()
            .then((data) => {})
            .catch((e) => {});
    };
    return { removeFromFeedbackSpace, error, loading };
};
