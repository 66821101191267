import React from "react";
import cn from "classnames";

const AccordionItem = ({
    children,
    buttonName,
    setExpanded,
    expanded,
    index
}) => {
    const buttonClass = cn([
        "flex flex-row justify-between px-6 shadow-sm py-2 rounded-md",
        {
            "bg-shocking-100 text-shocking-800": expanded !== index,
            "bg-shocking-700 text-shocking-50": expanded === index
        }
    ]);
    const handleExpandedClick = (index) => {
        if (expanded === index) {
            return setExpanded(null);
        }
        setExpanded(index);
    };
    return (
        <li
            className={"mb-2 flex flex-col gap-y-2"}
            key={`acc_item${index}`}>
            <button
                onClick={() => handleExpandedClick(index)}
                className={buttonClass}>
                <span className={"body_large text-left"}>
                    {buttonName}
                </span>
                <span className={"body_large font-bold"}>
                    {expanded === index ? "-" : "+"}
                </span>
            </button>
            {expanded === index ? (
                <div
                    className={
                        "border border-secondary-100 bg-white rounded-md  p-8"
                    }>
                    {children}
                </div>
            ) : null}
        </li>
    );
};

export default AccordionItem;
