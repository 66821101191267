import React from "react";
import { AxisTick, Label } from "ChartIndex";

const AxisBottom = ({
    dataSequence,
    length,
    xOffset,
    yOffset,
    textOffsetX,
    textOffsetY,
    textRotation,
    textAnchor
}) => {
    return (
        <>
            {dataSequence && (
                <g
                    className={"bAxis"}
                    transform={`translate(${xOffset},${yOffset})`}>
                    {dataSequence.map((item, index) => {
                        return (
                            <g
                                key={`axisElement${index}`}
                                transform={`translate(${item.x},0)`}>
                                <AxisTick
                                    length={length}
                                    horizontal={false}
                                />
                                <Label
                                    type={"data_label"}
                                    color={"dark"}
                                    x={textOffsetX}
                                    y={textOffsetY}
                                    rotation={textRotation}
                                    anchor={textAnchor}>
                                    {item.label}
                                </Label>
                            </g>
                        );
                    })}
                </g>
            )}
        </>
    );
};

export default AxisBottom;
