import React, { useState } from "react";
import { ButtonDialog, ManageSkills } from "ComponentIndex";

const ManageFeedbackSpaceSkills = ({ feedbackSpace }) => {
    const [open, setOpen] = useState(false);
    return (
        <ButtonDialog
            id={"skills-allocation"}
            label={"Skills"}
            icon={"psychology"}
            open={open}
            setOpen={setOpen}
            variant={"tonal"}>
            <ManageSkills
                feedbackSpace={feedbackSpace}
                setOpen={setOpen}
            />
        </ButtonDialog>
    );
};

export default ManageFeedbackSpaceSkills;
