import { useState, useEffect } from "react";
import { masterAcc } from "UtilityFunctionIndex";
import {
    projectIdsVar,
    projectsForFilterVar
} from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";

export const stringifyProjectItems = (item) => {
    return masterAcc(item, "project", "name");
};

export const useProjectsFilterItems = () => {
    const [options, setOptions] = useState([]);
    let data = useReactiveVar(projectsForFilterVar);

    useEffect(() => {
        projectIdsVar(options.map((item) => item.id));
    }, [options]);

    return { data, options, setOptions };
};
