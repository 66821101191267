import React from "react";

const BlogExternalLink = ({ children, to }) => {
    return (
        <span className={"underline text-shocking-700"}>
            <a target='_blank' rel='noopener noreferrer' href={to}>
                {children}
            </a>
        </span>
    );
};

export default BlogExternalLink;
