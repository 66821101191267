import React from "react";
import { Select } from "ComponentIndex";
import { useRoleSelection } from "./Utils";

const FeedbackSpaceRole = ({ recordItem }) => {
    const { selectedRole, setSelectedRole, roleItems } =
        useRoleSelection(recordItem);
    return (
        <div className={"w-40"}>
            <Select
                items={roleItems}
                selected={selectedRole}
                setSelected={setSelectedRole}
            />
        </div>
    );
};

export default FeedbackSpaceRole;
