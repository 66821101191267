import React, { useState, useRef } from "react";
import {
    SkillForm,
    Title,
    ConditionalOutput,
    Card
} from "ComponentIndex";
import reduxActions from "State/ActionIndex";
import { treatUndefinedValue } from "UtilityFunctionIndex";
import { useMutation } from "@apollo/client";
import { EDIT_SKILL_MUTATION } from "GraphQL/Mutations";
import { GET_SKILL_LIST_QUERY } from "GraphQL/Queries";
import { useClickOutside } from "HookIndex";
const createInitialState = (data) => {
    return {
        skillId: data.id,
        skillName: data.skillName,
        skillDescription: treatUndefinedValue(data.skillDescription),
        skillType: treatUndefinedValue(data.skillType)
    };
};

const EditSkill = ({ skill, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const [formData, setFormData] = useState(createInitialState(skill));

    const [registerEdit, { loading, error }] = useMutation(
        EDIT_SKILL_MUTATION,
        {
            refetchQueries: [
                {
                    query: GET_SKILL_LIST_QUERY
                }
            ]
        }
    );

    const handleFormSubmit = (e) => {
        e.preventDefault();
        registerEdit({
            variables: {
                ...formData
            }
        })
            .then((data) => {
                reduxActions.addNotification("Skill edited", "success");
            })
            .catch((e) => {
                return reduxActions.addNotification(e.message, "error");
            });
    };
    return (
        <div ref={containerRef} className={"w-96"}>
            <Card type={"light"} strech={true}>
                <Title>Edit skill</Title>
                <ConditionalOutput error={error} loading={loading}>
                    <SkillForm
                        state={formData}
                        setState={setFormData}
                        handleSubmit={handleFormSubmit}
                    />
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default EditSkill;
