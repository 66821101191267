import React from "react";

const PlotAreaBackground = ({ height, width, fill }) => {
    return (
        <rect
            x0={0}
            y={0}
            height={height}
            width={width}
            fill={fill}></rect>
    );
};

export default PlotAreaBackground;
