import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import reportWebVitals from "./reportWebVitals";
import { NotificationManager } from "ComponentIndex";
import { ApolloClient, ApolloProvider, from } from "@apollo/client";
import { appCache } from "GraphQL/Cache";
import { Provider } from "react-redux";
import store from "State/store";
import "./index.css";

import { ErrorTriajLink, TerminalLink } from "GraphQL/Links";
import { HelmetProvider } from "react-helmet-async";

let linkChain = from([ErrorTriajLink, TerminalLink]);

const client = new ApolloClient({
    link: linkChain,
    cache: appCache,
    credentials: "include"
});

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <HelmetProvider>
                    <App />
                    <NotificationManager />
                </HelmetProvider>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
