import React, { useState, useMemo } from "react";
import classnames from "classnames";
import { useCombobox, useMultipleSelection } from "downshift";
import cls from "./SkillsAutocomplete.module.css";
import {
    IconButton,
    Icon,
    DownshiftListItem,
    DownshiftEmptyItem,
    DownshiftItemList
} from "ComponentIndex";
import { masterAcc } from "UtilityFunctionIndex";

const filterListItems = (skillIdsState, inputValue, items) => {
    const lowerCasedInputValue = inputValue.toLowerCase();
    let skillIds = skillIdsState.map((item) => {
        return item["id"];
    });

    let filtered = items.filter((item) => {
        return !skillIds.includes(item["id"]);
    });

    return filtered.filter((item) =>
        item["skillName"].toLowerCase().includes(lowerCasedInputValue)
    );
};

const SkillsAutocomplete = ({
    skillIdsState,
    setSkillIdsState,
    placeHolder,
    leadingIconName = null,
    items,
    itemPrefix,
    emptyMessage = "No items"
}) => {
    const [inputValue, setInputValue] = useState("");
    const itemList = useMemo(
        () => filterListItems(skillIdsState, inputValue, items),
        [inputValue, skillIdsState]
    );

    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        reset,
        removeSelectedItem,
        selectedItems
    } = useMultipleSelection({
        initialSelectedItems: skillIdsState,
        onStateChange: (changes) => {
            setSkillIdsState(changes.selectedItems);
        }
    });

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectItem,
        openMenu
    } = useCombobox({
        inputValue,
        items: itemList,
        itemToString: (item) => masterAcc(item, "skill", "skillName"),
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(inputValue);
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setInputValue("");
                        addSelectedItem(selectedItem);
                        selectItem(null);
                    }

                    break;
                default:
                    break;
            }
        }
    });
    const inputAreaClass = classnames(cls.inputArea);
    const selectedItemClass = classnames(cls.selectedItem);
    const closeButtonClass = classnames(cls.closeButton);
    const selectedItemLabelClass = classnames(cls.selectedItemLabel);
    const inputLineClass = classnames(cls.inputLine);
    const inputClass = classnames(cls.input);
    const clearButtonClass = classnames(cls.clearButton);
    const autoCompleteClass = classnames(cls.autoComplete);
    const selectedItemsClass = classnames(cls.selectedItems);
    const leadingIconClass = classnames(cls.leadingIcon);

    return (
        <div className={autoCompleteClass}>
            <div className={inputAreaClass}>
                <div className={selectedItemsClass}>
                    {selectedItems.map((selectedItem, index) => (
                        <div
                            className={selectedItemClass}
                            key={`selected-item${index}}`}
                            {...getSelectedItemProps({
                                selectedItem,
                                index
                            })}>
                            <span className={selectedItemLabelClass}>
                                <p
                                    className={
                                        "body_small text-secondary-900"
                                    }>
                                    {selectedItem["skillName"]}
                                </p>
                            </span>
                            <IconButton
                                size={"small"}
                                variant={"text"}
                                icon={"close"}
                                className={closeButtonClass}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeSelectedItem(selectedItem);
                                }}
                            />
                        </div>
                    ))}
                </div>
                {!!selectedItems.length && (
                    <div className={clearButtonClass}>
                        <IconButton
                            size={"small"}
                            variant={"text"}
                            icon={"close"}
                            onClick={reset}
                            className={clearButtonClass}
                            aria-label={"toggle menu"}
                        />
                    </div>
                )}
                <div className={inputLineClass}>
                    {leadingIconName && (
                        <div className={leadingIconClass}>
                            <Icon
                                name={leadingIconName}
                                color={"dark"}
                                size={"small"}
                            />
                        </div>
                    )}
                    <input
                        className={inputClass}
                        placeholder={placeHolder}
                        {...getInputProps(
                            getDropdownProps({
                                preventKeyAction: isOpen,
                                onFocus: () => {
                                    if (!isOpen) {
                                        openMenu();
                                    }
                                },
                                onClick: () => {
                                    if (!isOpen) {
                                        openMenu();
                                    }
                                }
                            })
                        )}
                    />
                </div>
            </div>

            <DownshiftItemList
                position={"absolute top-6 w-full"}
                menuProps={getMenuProps}
                isOpen={isOpen}>
                {isOpen ? (
                    itemList !== undefined ? (
                        itemList.map((item, index) => (
                            <DownshiftListItem
                                key={index}
                                title={item[itemPrefix]}
                                item={item}
                                index={index}
                                itemProps={getItemProps}
                                highlightedIndex={highlightedIndex}>
                                {item["skillName"]}
                            </DownshiftListItem>
                        ))
                    ) : (
                        <DownshiftEmptyItem>
                            {emptyMessage}
                        </DownshiftEmptyItem>
                    )
                ) : null}
            </DownshiftItemList>
        </div>
    );
};

export default SkillsAutocomplete;
