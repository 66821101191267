import React from "react";
import cn from "classnames";
import { RouterLink, ContentWrapperBand } from "ComponentIndex";
import logoWhite from "Images/enamply_logo_vertical_white.svg";
import logoDark from "Images/enamply_logo_vertical_black.svg";

const HomeFooter = ({ color = "dark" }) => {
    const linkClass = cn(["body_medium"], {
        "text-secondary-700": color === "white",
        "text-shocking-50": color === "dark"
    });
    const headingClass = cn(["title_small"], {
        "text-shocking-700": color === "white",
        "text-shocking-400": color === "dark"
    });

    return (
        <ContentWrapperBand
            color={color === "white" ? "bg-white" : "bg-secondary-700"}
            padding={16}>
            <div
                className={
                    "flex flex-col items-center gap-y-8 px-6 py-8 w-full"
                }>
                <div
                    className={
                        "flex flex-col flex-wrap gap-y-8 items-center md:flex-row md:justify-around w-full"
                    }>
                    <div className={"w-60"}>
                        <a href={"#home"}>
                            {color === "white" ? (
                                <img
                                    className={"h-14"}
                                    src={logoDark}
                                    alt={
                                        "Enamply Logo, white hexagon with enamply written below"
                                    }
                                />
                            ) : (
                                <img
                                    className={"h-14"}
                                    src={logoWhite}
                                    alt={
                                        "Enamply Logo, white hexagon with enamply written below"
                                    }
                                />
                            )}
                        </a>
                    </div>
                    <div
                        className={
                            "flex flex-col justify-center gap-y-8 xl:flex-row xl:w-2/3 xl:justify-around"
                        }>
                        <div className={"flex flex-col justify-start"}>
                            <h1 className={headingClass}>{"Pages"}</h1>

                            <p>
                                <a
                                    className={linkClass}
                                    href={"#solution"}>
                                    Solution
                                </a>
                            </p>
                            <p>
                                <a
                                    className={linkClass}
                                    href={"/features"}>
                                    Features
                                </a>
                            </p>
                            <p>
                                <a
                                    className={linkClass}
                                    href={"/privacy-policy"}>
                                    Privacy policy
                                </a>
                            </p>
                            <p>
                                <a
                                    className={linkClass}
                                    href={"/terms"}>
                                    Terms of service
                                </a>
                            </p>
                        </div>
                        <div className={"flex flex-col justify-start"}>
                            <h1 className={headingClass}>
                                {"Getting started"}
                            </h1>

                            <p className={linkClass}>
                                <RouterLink to={"/login"}>
                                    {"Login"}
                                </RouterLink>
                            </p>
                        </div>
                        <div className={"flex flex-col justify-start"}>
                            <h1 className={headingClass}>
                                {"Contacts"}
                            </h1>
                            {/* <p>
                            <a>
                                <p
                                    className={linkClass}
                                    color={"light"}
                                    weigth={"medium"}>
                                    Facebook
                                </p>
                            </a>
                        </p> */}
                            <p>
                                <a
                                    className={linkClass}
                                    href={
                                        "https://www.linkedin.com/company/enamply"
                                    }
                                    target={"_blank"}
                                    rel='noopener noreferrer'>
                                    LinkedIn
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className={"label_medium text-shocking-50"}>
                        {"© Copyright 2022"}
                    </p>
                </div>
            </div>
        </ContentWrapperBand>
    );
};

export default HomeFooter;
