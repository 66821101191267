import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useWindowSize, useHttpsVerification } from "HookIndex";
import { screenSizeVar } from "GraphQL/ReactiveVariables";
import "./App.css";
import { PublicRoot, PrivateRoute } from "ComponentIndex";

const App = () => {
    screenSizeVar(useWindowSize());
    useHttpsVerification();

    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/*"} element={<PublicRoot />} />
                <Route path={"/app/*"} element={<PrivateRoute />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
