import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { ConditionalOutput, FeedbackSpaceCard } from "ComponentIndex";
import { feedbackSpacePageVar } from "GraphQL/ReactiveVariables";
import { GET_MY_FEEDBACK_SPACES_QUERY } from "GraphQL/Queries";
import { useLoadForTour } from "HookIndex";

const ManageFeedbackSpacesContent = () => {
    useEffect(() => {
        feedbackSpacePageVar("manage-feedback-space");
    }, []);
    useLoadForTour(2, "setupTour", 1);
    useLoadForTour(8, "setupTour", 9);

    const { data, error, loading } = useQuery(
        GET_MY_FEEDBACK_SPACES_QUERY
    );
    return (
        <div
            id={"feedback-space-list"}
            className={
                " w-full flex flex-col gap-y-8 xl:w-10/12 2xl:w-6/12"
            }>
            <ConditionalOutput error={error} loading={loading}>
                {data
                    ? data.feedbackSpaces.map((feedbackSpace) => {
                          return (
                              <FeedbackSpaceCard
                                  key={`feedbackSpace${feedbackSpace.id}`}
                                  feedbackSpace={feedbackSpace}
                                  color={"dark"}
                              />
                          );
                      })
                    : null}
            </ConditionalOutput>
        </div>
    );
};

export default ManageFeedbackSpacesContent;
