import React, { forwardRef } from "react";
import {
    DivWrapper,
    SVGWrapper,
    ChartTooltip,
    ChartBounds,
    Label,
    PlotAreaBackground,
    Grid,
    ChartAvatar,
    AxisLeft,
    AxisBottom,
    HorizontalGradient,
    ChartLoading
} from "ChartIndex";
import { useTooltipHandlers } from "UtilityFunctionIndex";

const AvgRangeChartSVG = forwardRef(({ graphs }, ref) => {
    const [
        handleChartTooltipMouseEnter,
        handleChartTooltipMouseMove,
        handleChartTooltipMouseLeave,
        toolOpen
    ] = useTooltipHandlers();

    return (
        <DivWrapper>
            <SVGWrapper ref={ref}>
                {graphs.plotData ? (
                    <>
                        <Label
                            x={graphs.dimensions.width / 2}
                            y={graphs.dimensions.margin.top * 0.5}
                            type={"chart_name"}
                            color={"dark"}>
                            {graphs.chartName}
                        </Label>
                        <ChartBounds
                            xOffset={graphs.dimensions.margin.left}
                            yOffset={graphs.dimensions.margin.top}>
                            <g className='chartBackground'>
                                <PlotAreaBackground
                                    height={
                                        graphs.dimensions.boundedHeight
                                    }
                                    width={
                                        graphs.dimensions.boundedWidth
                                    }
                                    fill={"url(#horizontalGradient)"}
                                />

                                <Grid
                                    horizontal={true}
                                    dataArray={graphs.yGrid}
                                    length={
                                        graphs.dimensions.boundedWidth
                                    }
                                />
                                <Grid
                                    horizontal={false}
                                    dataArray={graphs.xGrid}
                                    length={
                                        graphs.dimensions.boundedHeight
                                    }
                                />
                            </g>

                            {graphs.plotData.map((item) => {
                                return (
                                    <ChartAvatar
                                        onMouseEnter={handleChartTooltipMouseEnter(
                                            item
                                        )}
                                        onMouseLeave={
                                            handleChartTooltipMouseLeave
                                        }
                                        onMouseMove={
                                            handleChartTooltipMouseMove
                                        }
                                        key={`group${item.name}`}
                                        text={item.name}
                                        x={item.mean}
                                        y={item.range}
                                        theme={item.brimColor}
                                    />
                                );
                            })}

                            <g className='peripherials'>
                                <AxisLeft
                                    dataSequence={graphs.yAxis}
                                    length={0}
                                    xOffset={-5}
                                    yOffset={0}
                                    textOffsetX={-5}
                                    textOffsetY={3}
                                    textAnchor={"end"}
                                />

                                <AxisBottom
                                    dataSequence={graphs.xAxis}
                                    length={5}
                                    xOffset={0}
                                    yOffset={
                                        graphs.dimensions.boundedHeight
                                    }
                                    textRotation={0}
                                    textAnchor={"middle"}
                                    textOffsetX={0}
                                    textOffsetY={15}
                                />
                            </g>
                        </ChartBounds>
                        <Label
                            x={graphs.dimensions.margin.left * 0.25}
                            y={graphs.dimensions.boundedHeight * 0.5}
                            rotation={-90}
                            type={"axis_name"}>
                            {graphs.yAxisLabel}
                        </Label>

                        <defs>
                            <HorizontalGradient />
                        </defs>
                    </>
                ) : (
                    <ChartLoading />
                )}
            </SVGWrapper>
            <ChartTooltip state={toolOpen}>
                <p className={"body_small text-secondary-900"}>
                    {toolOpen.name}
                </p>
            </ChartTooltip>
        </DivWrapper>
    );
});

export default AvgRangeChartSVG;
