import React from "react";

const PublicCard = ({ icon, title, text }) => {
    return (
        <div className=' rounded-md shadow-md border bg-white p-6 w-full h-full'>
            <div className={"flex flex-col items-center h-full"}>
                <span
                    className={
                        "material-symbols-rounded text-5xl mb-3 text-shocking-700"
                    }>
                    {icon}
                </span>
                <h4 type={"title_medium text-shocking-700"}>{title}</h4>
                <div
                    className={
                        "h-1 w-8 bg-shocking-700 rounded-sm mb-8 mt-4"
                    }
                />
                <p className={"body_medium text-secondary-900"}>
                    {text}
                </p>
            </div>
        </div>
    );
};

export default PublicCard;
