import {
    ResumeTable,
    Card,
    FeedbackEvolution,
    InfoModule,
    FeedbackVarianceChartInfo,
    FeedbackEvolutionInfo,
    InfoTabelFeedbackInfo,
    Title,
    RangeAvgFeedback,
    Pagination
} from "ComponentIndex";

const DisplayFeedbackReport = () => {
    return (
        <div className={"flex flex-col w-full h-full gap-4 2xl:w-3/4"}>
            <div className={"flex flex-col gap-4 xl:flex-row"}>
                <div
                    className={
                        "w-full h-[80vh] md:h-[70vh] lg:h-[70vh] xl:h-[60vh] 2xl:h-[50vh]"
                    }>
                    <Card type={"light"} rounded={"xl"}>
                        <div className={"h-full flex flex-col"}>
                            <div
                                id={"feedback_insights_info"}
                                className={"self-end"}>
                                <InfoModule
                                    xOffset={-280}
                                    message={
                                        <FeedbackVarianceChartInfo />
                                    }
                                />
                            </div>
                            <RangeAvgFeedback />
                        </div>
                    </Card>
                </div>
                <div
                    className={
                        "w-full h-[80vh] md:h-[70vh] lg:h-[70vh] xl:h-[60vh] 2xl:h-[50vh]"
                    }>
                    <Card type={"light"} rounded={"xl"}>
                        <div className={"h-full flex flex-col"}>
                            <div className={"self-end"}>
                                <InfoModule
                                    xOffset={-280}
                                    message={<FeedbackEvolutionInfo />}
                                />
                            </div>
                            <FeedbackEvolution />
                        </div>
                    </Card>
                </div>
            </div>

            <Card type={"light"} rounded={"xl"} strech={false}>
                <div className={"h-full flex flex-col gap-y-4"}>
                    <div className={"self-end"}>
                        <InfoModule
                            xOffset={-280}
                            message={<InfoTabelFeedbackInfo />}
                        />
                    </div>
                    <Title type={"title_medium"}>
                        {"Tags per Feedback"}
                    </Title>
                    <ResumeTable />
                    <div className={"self-center"}>
                        <Pagination />
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default DisplayFeedbackReport;
