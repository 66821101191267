import { useState, useEffect } from "react";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import reduxActions from "State/ActionIndex";
import { PASSWORD_RESET_MUTATION } from "GraphQL/Mutations";
import {
    Button,
    Input,
    Card,
    Title,
    ConditionalOutput,
    ContentWrapperBand,
    PageToScreenWrapper,
    HelmetExclude,
    ContactForDemoLink
} from "ComponentIndex";

let defautFormState = {
    email: "",
    password: "",
    passwordRepeat: ""
};

const PasswordResetContent = () => {
    const { token } = useParams();
    const [formState, setFormState] = useState(defautFormState);
    const [passwordMatch, setPasswordMatch] = useState(false);
    workspaceNameVar("Password Reset");
    useEffect(() => {
        if (
            formState.password === formState.passwordRepeat &&
            formState.password &&
            formState.passwordRepeat &&
            formState.password.length > 6
        ) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }, [formState.password, formState.passwordRepeat]);

    const [createUser, { loading, error }] = useMutation(
        PASSWORD_RESET_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    "Passsword has been reset. Please log in.",
                    "success"
                );
            }
        }
    );

    const handlePasswordChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };

    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createUser({
            variables: {
                email: formState.email,
                password: formState.password,
                token: token
            }
        })
            .then((data) => {})
            .catch((e) => {});
    };

    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Card
                        type={"light"}
                        strech={false}
                        elevation={"lg"}>
                        <div className={"flex flex-col gap-y-4"}>
                            <Title>Password reset</Title>
                            <ConditionalOutput
                                error={error}
                                loading={loading}>
                                <form
                                    className={
                                        "flex flex-col h-  w-80 gap-y-4"
                                    }
                                    onSubmit={handleSubmit}>
                                    <div className={"w-full"}>
                                        <Input
                                            id='email'
                                            type={"email"}
                                            value={formState.email}
                                            onChange={handleFieldChange}
                                            placeholder={"Email"}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"w-full"}>
                                        <Input
                                            type={"password"}
                                            id='password'
                                            value={formState.password}
                                            onChange={
                                                handlePasswordChange
                                            }
                                            placeholder={"Password"}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"w-full"}>
                                        <Input
                                            type={"password"}
                                            id='passwordRepeat'
                                            value={
                                                formState.passwordRepeat
                                            }
                                            onChange={
                                                handlePasswordChange
                                            }
                                            placeholder={
                                                "Repeat Password"
                                            }
                                            required={true}
                                        />

                                        {!passwordMatch && (
                                            <p
                                                className={
                                                    "label_small text-secondary-500"
                                                }>
                                                Passwords should match,
                                                and be longer than 7
                                                characters
                                            </p>
                                        )}
                                    </div>

                                    <div className={"self-end"}>
                                        <Button
                                            variant={"filled"}
                                            type='submit'
                                            disabled={!passwordMatch}>
                                            Reset password
                                        </Button>
                                    </div>
                                </form>
                            </ConditionalOutput>
                            <div>
                                <span
                                    className={
                                        "label_medium text-secondary-900"
                                    }>
                                    {"No need to reset your password? "}
                                </span>
                                <Link to='/login'>
                                    <span
                                        className={
                                            "label_medium text-shocking-700"
                                        }>
                                        Login
                                    </span>
                                </Link>
                            </div>
                            <ContactForDemoLink />
                        </div>
                    </Card>
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default PasswordResetContent;
