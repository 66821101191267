import { BlogCard, HelmetInclude } from "ComponentIndex";
import { blogContentVar } from "GraphQL/ReactiveVariables";
import firstArticleImg from "Images/Blog/SystemicProbemsArticle/different-level-hexagons.png";
import secondArticleImg from "Images/Blog/ImpactfullFeedbackArticle/top-image-micro-feedback.png";
import taskBasedImg from "Images/Blog/TaskBasedFeedbackArticle/incremental-performance.png";
import congnitiveBiasesImg from "Images/Blog/CognitiveBiasesInPerformanceArticle/heading-gradient.png";
import reviewEmbraceImg from "Images/Blog/ReviewEmbraceEmployeesEffortsArticle/Header.png";
import noFeedbackNoProductivityimg from "Images/Blog/NoFeedbackNoProductivityArticle/principles-of-feedback.png";

import {
    TaskBasedFeedbackArticleLink,
    ImpactfullFeedbackArticleLink,
    SystemicProbemsArticleLink,
    CognitiveBiasesInPerformanceArticleLink,
    ReviewEmbraceEmployeesEffortsArticleLink,
    NoFeedbackNoProductivityArticleLink
} from "BlogLinkIndex";

const BlogIndex = () => {
    const items = blogContentVar().BlogIndex;
    return (
        <>
            <HelmetInclude content={items} />
            <div
                className={
                    "flex flex-row flex-wrap gap-6 content-around justify-around "
                }>
                <BlogCard
                    item={items.SystemicProbemsArticle}
                    image={firstArticleImg}
                    link={SystemicProbemsArticleLink}
                />
                <BlogCard
                    item={items.ImpactfullFeedbackArticle}
                    image={secondArticleImg}
                    link={ImpactfullFeedbackArticleLink}
                />
                <BlogCard
                    item={items.TaskBasedFeedbackArticle}
                    image={taskBasedImg}
                    link={TaskBasedFeedbackArticleLink}
                />
                <BlogCard
                    item={items.CognitiveBiasesInPerformanceArticle}
                    image={congnitiveBiasesImg}
                    link={CognitiveBiasesInPerformanceArticleLink}
                />
                <BlogCard
                    item={items.ReviewEmbraceEmployeesEffortsArticle}
                    image={reviewEmbraceImg}
                    link={ReviewEmbraceEmployeesEffortsArticleLink}
                />
                <BlogCard
                    item={items.NoFeedbackNoProductivityArticle}
                    image={noFeedbackNoProductivityimg}
                    link={NoFeedbackNoProductivityArticleLink}
                />
            </div>
        </>
    );
};

export default BlogIndex;
