export const verifyEditor = (me, task) => {
    if (
        task.basicfeedbackstatusSet[0].givenBy &&
        task.basicfeedbackstatusSet[0].givenBy.id === me.id
    ) {
        return true;
    } else {
        return true;
    }
};
