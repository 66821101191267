export const verifyFields = (state) => {
    if (
        verifySkillName(state.skillName) &&
        verifySkillType(state.skillType)
    ) {
        return false;
    } else {
        return true;
    }
};

const verifySkillName = (skillName) => {
    if (skillName) {
        return true;
    } else {
        return false;
    }
};

const verifySkillType = (skillType) => {
    if (
        skillType === "INT" ||
        skillType === "ASP" ||
        skillType === "TEC" ||
        skillType === "MNG"
    ) {
        return true;
    } else {
        return false;
    }
};
