import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { meVar } from "GraphQL/ReactiveVariables";

import {
    LogOut,
    Avatar,
    MenuDropDown,
    RouterLink,
    MenuItem,
    ConditionalOutput,
    Icon,
    Hr
} from "ComponentIndex";
import { ME_QUERY } from "GraphQL/Queries";

const Profile = () => {
    let [open, setOpen] = useState(false);
    let { loading, error, data } = useQuery(ME_QUERY);
    const meData = meVar({
        ...meVar,
        id: data.me.id,
        email: data.me.email,
        userName: data.me.username
    });
    let handleDropDownDisplay = (e) => {
        setOpen(!open);
    };

    return (
        <ConditionalOutput error={error} loading={loading}>
            <div
                id={"profile"}
                className={"relative flex grow justify-end"}>
                <Avatar
                    onClick={handleDropDownDisplay}
                    name={meData.email.substring(0, 2)}
                />
                {open ? (
                    <MenuDropDown open={open} setOpen={setOpen}>
                        <div
                            id={"account_settings"}
                            className={"text-center"}>
                            <p
                                className={
                                    "body_large text-secondary-900"
                                }>
                                {data.me.email}
                            </p>
                        </div>
                        <Hr weight={"small"} color={"primary"} />
                        <RouterLink
                            onClick={handleDropDownDisplay}
                            to={
                                "/app/account-settings/data-source-settings"
                            }>
                            <MenuItem>
                                <Icon
                                    size={"large"}
                                    name={"manage_accounts"}
                                />
                                <p
                                    className={
                                        "body_large text-inherit"
                                    }>
                                    {"Account settings"}
                                </p>
                            </MenuItem>
                        </RouterLink>
                        <MenuItem>
                            <Icon size={"large"} name={"logout"} />
                            <p className={"body_large text-inherit"}>
                                <LogOut
                                    onClick={handleDropDownDisplay}
                                />
                            </p>
                        </MenuItem>
                    </MenuDropDown>
                ) : null}
            </div>
        </ConditionalOutput>
    );
};

export default Profile;
