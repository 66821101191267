export const refreshToken = (uri, instruction) => {
    return fetch(uri, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
            query: instruction
        })
    }).then((response) => response.json());
};
