import React, { useState } from "react";
import { IconDialog, EditSkill } from "ComponentIndex";

const EditSkillDialog = ({ skill }) => {
    const [open, setOpen] = useState(false);
    return (
        <IconDialog
            open={open}
            setOpen={setOpen}
            label={"Edit Skill"}
            icon={"edit"}
            variant={"text"}
            size={"small"}>
            <EditSkill skill={skill} setOpen={setOpen} />
        </IconDialog>
    );
};

export default EditSkillDialog;
