import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import {
    searchVar,
    activeTabVar,
    fbEndDateVar,
    fbStartDateVar,
    workspaceNameVar,
    skillTypesVar,
    currentFeedbackSpaceVar,
    pageVar,
    pagesVar,
    taskStartDateVar,
    taskEndDateVar,
    skillIdsVar,
    projectIdsVar,
    assigneeParametersIdsVar,
    taskTypeNamesVar,
    dataSourceIdsVar
} from "GraphQL/ReactiveVariables";

export const useLayoutSettings = (
    workSpace,
    title,
    activeTab = null
) => {
    useEffect(() => {
        document.title = title;
        // filters
        pageVar(1);
        pagesVar(null);
        searchVar("");
        fbEndDateVar(null);
        fbStartDateVar(null);
        taskStartDateVar(null);
        taskEndDateVar(null);
        activeTabVar(activeTab);
        skillTypesVar([]);
        skillIdsVar([]);
        projectIdsVar([]);
        assigneeParametersIdsVar([]);
        taskTypeNamesVar([]);
        dataSourceIdsVar([]);

        //
        workspaceNameVar(workSpace);
    }, []);
};

export const useFeedbackSpaceCheck = () => {
    const currentFeedbackSpace = useReactiveVar(
        currentFeedbackSpaceVar
    );
    return currentFeedbackSpace.id;
};
