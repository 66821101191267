import { useQuery } from "@apollo/client";
import { useUnifiedFilterItems } from "HookIndex";
import { VARIANCE_TO_SATISFACTION_QUERY } from "GraphQL/Queries";

export const useGetRangeFeedbackData = () => {
    const {
        currentFeedbackSpace,
        taskStartDate,
        taskEndDate,
        projectIds,
        assigneeParametersIds,
        search,
        taskTypeNames,
        dataSourceIds
    } = useUnifiedFilterItems();
    const { data, loading, error } = useQuery(
        VARIANCE_TO_SATISFACTION_QUERY,
        {
            variables: {
                feedbackSpaceId: currentFeedbackSpace.id,
                tskStartDate: taskStartDate,
                tskEndDate: taskEndDate,
                projectIds: projectIds,
                assigneeParametersIds: assigneeParametersIds,
                search: search,
                taskTypeNames: taskTypeNames,
                dataSourceIds: dataSourceIds
            },
            fetchPolicy: "network-only"
        }
    );
    return { data, loading, error };
};
