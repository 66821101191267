import React from "react";
import { Tab } from "ComponentIndex";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { activeTabVar } from "GraphQL/ReactiveVariables";

const ActiveTab = ({ fieldValue, children, id, linkTo = null }) => {
    let navigate = useNavigate();

    const activeTab = useReactiveVar(activeTabVar);
    const handleTabChange = (e) => {
        activeTabVar(e.target.value);
        if (linkTo) {
            navigate(linkTo);
        }
    };
    return (
        <Tab
            id={id}
            handleFunction={handleTabChange}
            fieldValue={fieldValue}
            active={activeTab === fieldValue ? true : false}>
            {children}
        </Tab>
    );
};

export default ActiveTab;
