import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import cls from "./RouterLink.module.css";

const RouterLink = ({ onClick, to, children }) => {
    const routerLinkClass = classnames(cls.routerLink);
    return (
        <Link onClick={onClick} className={routerLinkClass} to={to}>
            {children}
        </Link>
    );
};

export default RouterLink;
