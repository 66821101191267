import { Icon, ComboBoxIcon } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { useSkillTypesFilterItems } from "./Utils";

const SkillTypeFilterIcon = () => {
    const content = appContentVar().SkillTypeFilterIcon;
    const { data, options, setOptions } = useSkillTypesFilterItems();

    return (
        <>
            <ComboBoxIcon
                size={"md_24"}
                options={options}
                setOptions={setOptions}
                items={data}
                itemPrefix={"skillTypeName"}
                emptyMessage={content.emptyMessage[0]}>
                <Icon
                    title={content.title[0]}
                    shadow={true}
                    name={"group_work"}
                    size={"small"}
                    color={"light"}
                />
            </ComboBoxIcon>
        </>
    );
};

export default SkillTypeFilterIcon;
