export const setSkillTypeContentAction = (skillTypeAbbr) => {
    return { type: skillTypeAbbr, payload: skillTypeAbbr };
};

export const loadAreaSpecificSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "loadAreaSpecificSkills",
        payload: skillsFeedbackSpace
    };
};

export const loadTechnicalSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "loadTechnicalSkills",
        payload: skillsFeedbackSpace
    };
};

export const loadInterpersonalSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "loadInterpersonalSkills",
        payload: skillsFeedbackSpace
    };
};

export const loadMangementSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "loadManagementSkills",
        payload: skillsFeedbackSpace
    };
};

export const updateAreaSpecificSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "updateAreaSpecificSkills",
        payload: skillsFeedbackSpace
    };
};

export const updateTechnicalSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "updateTechnicalSkills",
        payload: skillsFeedbackSpace
    };
};

export const updateInterpersonalSkillsAction = (
    skillsFeedbackSpace
) => {
    return {
        type: "updateInterpersonalSkills",
        payload: skillsFeedbackSpace
    };
};

export const updateMangementSkillsAction = (skillsFeedbackSpace) => {
    return {
        type: "updateManagementSkills",
        payload: skillsFeedbackSpace
    };
};
