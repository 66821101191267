import React from "react";
import logo from "Images/enamply_logo_icon_small_black.svg";
const HomeHeader = ({ leftPart, rightPart, actions }) => {
    return (
        <header
            className={
                "w-full sticky top-0 z-10  backdrop-blur border-b border-b-secondary-300/10 bg-white opacity-90 px-8"
            }>
            <div
                className={
                    "max-w-7xl mx-auto flex  justify-between items-center py-2 "
                }>
                <div
                    className={
                        "flex flex-row items-center justify-start"
                    }>
                    <div className={"mr-8"}>
                        <a href={"/"}>
                            <img src={logo} alt='enamply logo' />
                        </a>
                    </div>
                    <div>{leftPart}</div>
                </div>
                <div
                    className={
                        "flex flex-row items-center content-end"
                    }>
                    <div
                        className={
                            "self-end md:flex lg:justify-end lg:items-center md:grow md:self-end hidden"
                        }>
                        {actions}
                    </div>
                    {rightPart}
                </div>
            </div>
        </header>
    );
};

export default HomeHeader;
