import React, { useState, useRef, useEffect } from "react";
import MedianConfidenceChartSVG from "./MedianConfidenceChartSVG";
import { useChartWrapperDimensions } from "HookIndex";

import {
    linearScale,
    pointScale,
    generateGrid,
    satisfactionRangeAxis,
    satisfactionRangeGrid,
    lineGenerator,
    areaGenerator,
    generateAxis
} from "UtilityFunctionIndex";

const MARGINS = {
    top: 30,
    right: 25,
    bottom: 65,
    left: 100
};

const MedianConfidenceChart = ({
    chartName,
    xAxisLabel,
    data,
    xAccessor,
    upperQAccessor,
    lowerQAccessor,
    medianAccessor
}) => {
    const [dataSet, setDataSet] = useState(data);
    const [graphs, setGraphs] = useState({ dimensions: 0 });
    const wrapperRef = useRef();

    const [dimensions, processed] = useChartWrapperDimensions(
        wrapperRef,
        MARGINS
    );

    useEffect(() => {
        if (processed) {
            const xScale = pointScale(
                dataSet.map(xAccessor),
                0,
                dimensions.boundedWidth
            );
            const yScale = linearScale(
                0,
                100,
                dimensions.boundedHeight,
                0
            );

            const xFunction = (dataPoint) => {
                return xScale(xAccessor(dataPoint));
            };
            const meanYFunction = (dataPoint) => {
                return yScale(medianAccessor(dataPoint));
            };

            const upperConfidenceFunction = (dataPoint) => {
                return yScale(upperQAccessor(dataPoint));
            };
            const lowerConfidenceFunction = (dataPoint) => {
                return yScale(lowerQAccessor(dataPoint));
            };
            const meanLine = lineGenerator(
                dataSet,
                xFunction,
                meanYFunction,
                "curve"
            );

            const confidenceInt = areaGenerator(
                dataSet,
                xFunction,
                lowerConfidenceFunction,
                upperConfidenceFunction,
                "curve"
            );

            const xAxis = generateAxis(
                dataSet,
                xFunction,
                xAccessor,
                "x"
            );

            const yAxis = satisfactionRangeAxis(yScale, "y");
            const yGridCoords = satisfactionRangeGrid(yScale);
            const xGridCoords = generateGrid(dataSet, xFunction);

            setGraphs({
                ...graphs,
                dimensions: dimensions,
                medianLine: meanLine,
                confidenceInterval: confidenceInt,
                xGrid: xGridCoords,
                yGrid: yGridCoords,
                xAxis: xAxis,
                yAxis: yAxis,
                xAxisLabel: xAxisLabel,
                chartName: chartName
            });
        }
    }, [data, dimensions]);
    // Add dimetions margin s
    // const graphs = { chartName: chartName };
    return (
        <MedianConfidenceChartSVG graphs={graphs} ref={wrapperRef} />
    );
};

export default MedianConfidenceChart;
