import React from "react";
import { useGetSkillList } from "./Utils";

import {
    Card,
    Title,
    SkillTable,
    ConditionalOutput,
    MessagePage
} from "ComponentIndex";

const SkillList = () => {
    const { data, error, loading } = useGetSkillList();

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data && data.skills.length ? (
                <div className={"w-full lg:w-10/12 xl:w-6/12"}>
                    <Card type={"light"}>
                        <Title type={"title_large"}>
                            {"Skills inventory"}
                        </Title>
                        <SkillTable skills={data.skills} />
                    </Card>
                </div>
            ) : (
                <MessagePage
                    message={"There are no skills to display"}
                />
            )}
        </ConditionalOutput>
    );
};

export default SkillList;
