import React from "react";
import classnames from "classnames";

const BUTTON =
    "flex flex-row justify-center rounded h-9 cursor-pointer pl-4 pr-6 items-center disabled:cursor-not-allowed disabled:opacity-40";
const TEXT = " label_large pl-1 capitalize disabled:opacity-40";
const ICON = "material-symbols-rounded text-[1rem] disabled:opacity-40";

const FILLED = "bg-shocking-700  hover:shadow-md active:shadow-none";
const FILLED_TEXT = "text-white";
const FILLED_ICON = "text-white";

const TONAL_BTN =
    "bg-shocking-300 active:bg-shocking-200 hover:shadow-md active:shadow-none ";
const TONAL_TEXT = "text-shocking-900";
const TONAL_ICON = "text-shocking-900";

const OUTLINE_BTN = "border border-shocking-700";
const OUTLINE_TEXT = "text-shocking-700 ";
const OUTLINE_ICON = "text-shocking-700 ";

const TEXT_BTN = "";
const TEXT_TEXT = "text-shocking-700";
const TEXT_ICON = "text-shocking-700";

const Button = ({ variant, children, icon = null, ...rest }) => {
    const buttonClass = classnames(BUTTON, {
        [FILLED]: variant === "filled",
        [TONAL_BTN]: variant === "tonal",
        [OUTLINE_BTN]: variant === "outline",
        [TEXT_BTN]: variant === "text"
    });
    const iconClass = classnames(ICON, {
        [FILLED_ICON]: variant === "filled",
        [TONAL_ICON]: variant === "tonal",
        [OUTLINE_ICON]: variant === "outline",
        [TEXT_ICON]: variant === "text"
    });
    const textClass = classnames(TEXT, {
        [FILLED_TEXT]: variant === "filled",
        [TONAL_TEXT]: variant === "tonal",
        [OUTLINE_TEXT]: variant === "outline",
        [TEXT_TEXT]: variant === "text"
    });
    return (
        <button className={buttonClass} {...rest}>
            {icon && <span className={iconClass}>{icon}</span>}
            {children && <span className={textClass}>{children}</span>}
        </button>
    );
};

export default Button;
