import React from "react";
import cn from "classnames";

let Tag = ({
    children,
    elevation = false,
    color,
    leadingIcon = null,
    trailingIcon = null,
    title
}) => {
    const tagClass = cn("rounded-lg inline-block h-6 mx-1 my-1", {
        ["shadow-md"]: elevation === true,
        ["bg-secondary-600"]: color === "dark",
        ["bg-secondary-50"]: color === "light",
        ["bg-primary-50"]: color === "primary"
    });

    return (
        <div className={tagClass} title={title}>
            <div className={"flex items-center h-full"}>
                {leadingIcon && <> {leadingIcon} </>}
                <span
                    className={
                        "mx-2 whitespace-nowrap overflow-hidden text-ellipsis "
                    }>
                    <p className={"body_small text-secondary-900"}>
                        {children}
                    </p>
                </span>
                {trailingIcon && <>{trailingIcon}</>}
            </div>
        </div>
    );
};

export default Tag;
