import React from "react";

const Input = ({
    type,
    placeholder,
    onChange,
    value,
    required = false,
    id,
    ...rest
}) => {
    return (
        <input
            id={id}
            className={
                "focus_ring ring-shocking-400 w-full outline-none body_large placeholder:text-secondary-300 placeholder:label_large border border-secondary-500 rounded-md py-2 px-3  text-secondary-900"
            }
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            {...rest}
        />
    );
};

export default Input;
