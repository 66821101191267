import React from "react";
import { useReactiveVar } from "@apollo/client";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    DrawerCompartment,
    RouterLink,
    DrawerMenuItem
} from "ComponentIndex";

const DrawerPersonalSettings = () => {
    const wsName = useReactiveVar(workspaceNameVar);
    return (
        <div id={"skills-setup"}>
            <DrawerCompartment label={"Personal settings"} />
            <RouterLink to={`/app/skills/add-skills`}>
                <DrawerMenuItem
                    id={"skill_management"}
                    selected={wsName === "Skills Management"}
                    iconName={"groups"}
                    itemName={"Skills Management"}
                />
            </RouterLink>
        </div>
    );
};

export default DrawerPersonalSettings;
