import { useState } from "react";
import cn from "classnames";

const generateElementPlacement = (
    coords,
    vertical,
    horizontal,
    yOffset = 0,
    xOffset = 0
) => {
    let topCalc = 0;
    let leftCalc = 0;

    if (vertical === "top") {
        topCalc = coords.height + 20;
    } else if ((vertical = "bottom")) {
        topCalc = -yOffset;
    } else {
        topCalc = 0;
    }

    if (horizontal === "left") {
        leftCalc = -coords.width + xOffset;
    } else if (horizontal === "center") {
        leftCalc = -coords.width * 0.5 + xOffset;
    } else if (horizontal === "right") {
        leftCalc = -0 + xOffset;
    } else {
        leftCalc = 0;
    }
    return { top: `${topCalc}px`, left: `${leftCalc}px` };
};

const ToolTip = ({
    children,
    message,
    yOffset = 10,
    xOffset = 0,
    width = 300
}) => {
    const [open, setOpen] = useState(false);

    const handleOpenState = (e) => {
        setOpen(true);
    };
    const handleCloseState = (e) => {
        setOpen(false);
    };

    const toolTipClass = cn("absolute", {
        "block z-10 pt-3": open,
        ["hidden"]: !open
    });

    return (
        <div className={"relative inline-block cursor-pointer"}>
            <div
                onMouseEnter={handleOpenState}
                onMouseLeave={handleCloseState}
                onClick={handleCloseState}>
                {children}
            </div>

            <div
                onMouseEnter={handleOpenState}
                onMouseLeave={handleCloseState}
                style={{
                    top: `${yOffset}px`,
                    left: `${xOffset}px`,
                    width: `${width}px`
                }}
                className={toolTipClass}>
                <div
                    className={
                        "rounded-md shadow-lg overflow-x-auto break-words p-4 max-w-md min-w-fit bg-white border-matisse-700 border"
                    }>
                    {message}
                </div>
            </div>
        </div>
    );
};

export default ToolTip;
