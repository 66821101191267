import { useState } from "react";
import { IconDialog, EditFeedbackSpaceNameForm } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

const EditFeedbackSpaceNameDialog = ({ feedbackSpace }) => {
    const [open, setOpen] = useState(false);
    const content = appContentVar().EditFeedbackSpaceNameDialog;
    return (
        <IconDialog
            title={content.title}
            icon={"edit"}
            size={"medium"}
            open={open}
            setOpen={setOpen}>
            <EditFeedbackSpaceNameForm
                feedbackSpace={feedbackSpace}
                setOpen={setOpen}
            />
        </IconDialog>
    );
};

export default EditFeedbackSpaceNameDialog;
