import { PublicSection, ContentWrapperBand } from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const PositiveEffects = () => {
    const content = landingContentVar().PositiveEffects;
    return (
        <ContentWrapperBand className={"bg-white"}>
            <PublicSection
                textColor={"text-shocking-700"}
                verticalHeader={content.verticalHeader[0]}
                horizontalHeader={content.horizontalHeader[0]}>
                <div
                    className={`flex flex-col flex-y-8 lg:flex-row gap-x-4 justify-around w-full`}>
                    <div
                        className={
                            "flex flex-col items-center gap-y-8"
                        }>
                        <span
                            className={
                                "display_small md:display_medium text-secondary-700"
                            }>
                            {content.lessHeader[0]}
                        </span>
                        <div>
                            <ul
                                className={
                                    "flex flex-col items-center"
                                }>
                                {content.lessContent.map(
                                    (item, index) => {
                                        return (
                                            <li
                                                className={
                                                    "headline_small text-secondary-700 pb-4"
                                                }
                                                key={`less${index}`}>
                                                {item}
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                    <div
                        className={
                            "flex flex-col items-center gap-y-8 sm:mt-24 lg:mt-0"
                        }>
                        <span
                            className={
                                "display_small md:display_medium text-shocking-700"
                            }>
                            {content.moreHeader[0]}
                        </span>

                        <div>
                            <ul
                                className={
                                    "flex flex-col items-center"
                                }>
                                {content.moreContent.map(
                                    (item, index) => {
                                        return (
                                            <li
                                                className={
                                                    "headline_small text-shocking-700 pb-4"
                                                }
                                                key={`more${index}`}>
                                                {item}
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </PublicSection>
        </ContentWrapperBand>
    );
};

export default PositiveEffects;
