import React from "react";

import {
    DrawerMenu,
    DrawerNavigation,
    DrawerPersonalSettings,
    DrawerFeedbackSpaces
} from "ComponentIndex";
const AppDrawerMenu = () => {
    return (
        <DrawerMenu>
            <div className={"flex flex-col gap-y-12"}>
                <DrawerNavigation />
                <DrawerFeedbackSpaces />
            </div>

            <DrawerPersonalSettings />
        </DrawerMenu>
    );
};

export default AppDrawerMenu;
