import React from "react";
import { Content, MessagePage } from "ComponentIndex";

const FeedbackSpaceNotAvailableContent = () => {
    return (
        <Content>
            <MessagePage
                message={
                    "This feedbak space is no longer available for your account. Please select another feedback space."
                }
            />
        </Content>
    );
};

export default FeedbackSpaceNotAvailableContent;
