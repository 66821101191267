import React from "react";

import logo from "Images/enamply_logo_icon_small_white.svg";
import description from "Images/enamply_description_small.svg";

const Logo = () => {
    return (
        <div className={"flex flex-row"}>
            <img src={logo} alt={"Hexagonal logo"} />
            <img
                className={"hidden lg:inline lg:ml-4"}
                src={description}
                alt={"Enamply logo descriptor"}
            />
        </div>
    );
};

export default Logo;
