import React from "react";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { convertDate } from "UtilityFunctionIndex";
import { Title, LabelWithData } from "ComponentIndex";

const FeedbackFormHeader = ({ task }) => {
    const taskName = masterAcc(task, "task", "name");
    const assigneeName = masterAcc(task, "task", "assigneeName");
    const parentTask = masterAcc(task, "task", "parentName");
    const projectName = masterAcc(task, "task", "projectName");
    const dueDate = convertDate(masterAcc(task, "task", "dueAt"));
    const taskType = masterAcc(task, "task", "taskType");
    const completedAt = convertDate(
        masterAcc(task, "task", "completedAt")
    );
    return (
        <div className={"w-full"}>
            <Title>
                <div className={"flex justify-between gap-10"}>
                    <div>
                        <span
                            className={"label_small text-shocking-700"}>
                            {"Task: "}
                        </span>
                        <span className={"title_large"}>
                            {taskName}
                        </span>
                    </div>
                    <div className={"min-w-fit"}>
                        <span
                            className={"label_small text-shocking-700"}>
                            {"Task type: "}
                        </span>
                        <span className={"title_small"}>
                            {taskType}
                        </span>
                    </div>
                </div>
            </Title>

            <div className={"mt-4 flex flex-col gap-y-4"}>
                <div className={"flex flex-row justify-between"}>
                    <LabelWithData
                        label={"Assignee:"}
                        data={assigneeName}
                    />

                    <LabelWithData
                        label={"Project:"}
                        data={projectName}
                    />

                    <LabelWithData
                        label={"Completed at:"}
                        data={completedAt}
                        align={"left"}
                    />
                </div>
                <div className={"flex flex-row justify-between"}>
                    {parentTask && (
                        <LabelWithData
                            label={"Parent task:"}
                            data={parentTask}
                        />
                    )}
                    {dueDate && (
                        <LabelWithData
                            label={"Due at:"}
                            data={dueDate}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FeedbackFormHeader;
