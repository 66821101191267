import React, { useState, useRef } from "react";
import reduxActions from "State/ActionIndex";
import { useClickOutside } from "HookIndex";

import {
    FeedbackSpaceForm,
    ConditionalOutput,
    Title,
    Card
} from "ComponentIndex";
import { useCreateFeedback } from "CustomHooks/CustomMutations";

const AddFeedbackSpace = ({ setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const [feedbackSpace, setFeedbackSpace] = useState("");
    const [createFeedbackSpace, error, loading] = useCreateFeedback();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        createFeedbackSpace({
            variables: { name: feedbackSpace }
        }).then((data) => {
            reduxActions.addNotification(
                "Feedack Space has been created",
                "success"
            );
            setOpen(false);
        });
    };

    return (
        <div ref={containerRef}>
            <Card type={"light"}>
                <Title type={"title_large"}>
                    Create Feedback Space
                </Title>
                <ConditionalOutput
                    error={error}
                    loading={loading}
                    loadingLabel={"Creating..."}>
                    <FeedbackSpaceForm
                        state={feedbackSpace}
                        setState={setFeedbackSpace}
                        handleSubmit={handleFormSubmit}
                    />
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default AddFeedbackSpace;
