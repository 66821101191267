import React from "react";
import cn from "classnames";

const BTN = [
    "label_medium text-centeritems-center rounded-full border-none h-7 w-7",
    "cursor-pointer  disabled:cursor-default",
    " disabled:text-secondary-400 mx-1",
    "hover:bg-shocking-400 hover:text-secondary-700"
];

const PaginationItem = ({
    children,
    value,
    current,
    pageChange,
    disabled = false
}) => {
    const paginationItemClass = cn(BTN, {
        "bg-shocking-700 text-shocking-50": current,
        "bg-transparent text-secondary-800": !current
    });

    return (
        <button
            onClick={pageChange}
            className={paginationItemClass}
            value={value}
            disabled={disabled}>
            {children}
        </button>
    );
};

export default PaginationItem;
