import { Card, Title } from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import { Link } from "react-router-dom";

const BlogCard = ({ item, image, link }) => {
    const content = landingContentVar().BlogCard;

    return (
        <div className={"sm:w-10/12 lg:w-5/12 h-96"}>
            <Card strech={true} elevation={"md"} padding={null}>
                <div className={"w-full h-full rounded-md"}>
                    <img
                        className={
                            "w-full h-1/2 object-cover  rounded-t-md "
                        }
                        src={image}
                        alt={item.alt[0]}
                    />

                    <div
                        className={
                            "px-6 pt-4 pb-6 flex flex-col h-48 rounded-b-md content-between justify-between gap-y-1"
                        }>
                        <div className={"flex flex-col gap-y-2"}>
                            <Link to={link}>
                                <Title
                                    type={"title_large"}
                                    bottom='mb-2'
                                    truncate={true}>
                                    {item.title[0]}
                                </Title>
                            </Link>
                            <p className={"body_medium truncate"}>
                                {item.subTitle[0]}
                            </p>
                            <div
                                className={
                                    "flex flex-row flex-wrap gap-1"
                                }>
                                {item.categories.map((cat, index) => {
                                    return (
                                        <span
                                            key={`cat${index}`}
                                            className={
                                                "label_small rounded-xl border border-secondary-100 py-1 px-2 text-primary-700"
                                            }>
                                            {cat}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>

                        <Link
                            to={link}
                            className={
                                "text-primary-700 label_large self-end"
                            }>
                            {content[0]}
                        </Link>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default BlogCard;
