import React from "react";
import cn from "classnames";

const ActionBar = ({ children, color = "dark", id }) => {
    const actionBarClass = cn(
        [
            "flex content-end flex-row justify-end px-4 gap-x-2 items-center h-8 w-full"
        ],
        {
            "bg-secondary-200": color === "light",
            "bg-secondary-700": color === "dark",
            "bg-secondary-400": color === "grey"
        }
    );

    return (
        <div id={id} className={actionBarClass}>
            {children}
        </div>
    );
};

export default ActionBar;
