import { useReactiveVar } from "@apollo/client";
import { PaginationItem } from "ComponentIndex";
import { usePagination } from "HookIndex";
import { pageVar, pagesVar } from "GraphQL/ReactiveVariables";

const Pagination = () => {
    const page = useReactiveVar(pageVar);
    const pages = useReactiveVar(pagesVar);
    const pageNumbers = usePagination(pages, 1, page);

    const onNext = () => {
        pageVar(page + 1);
    };

    const onPrev = () => {
        pageVar(page - 1);
    };

    return (
        <>
            {pageNumbers && (
                <div className={"inline-block"}>
                    <PaginationItem
                        value={-1}
                        disabled={page === 1}
                        pageChange={onPrev}>
                        {"<"}
                    </PaginationItem>
                    {pageNumbers.map((pageNr, index) => {
                        return (
                            <PaginationItem
                                key={`pag${pageNr}${index}`}
                                value={pageNr}
                                pageChange={() => pageVar(pageNr)}
                                current={page === pageNr ? true : false}
                                disabled={pageNr === "..."}>
                                {pageNr}
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem
                        value={1}
                        pageChange={onNext}
                        disabled={page === pages}>
                        {">"}
                    </PaginationItem>
                </div>
            )}
        </>
    );
};

export default Pagination;
