import React from "react";
import { useQuery } from "@apollo/client";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";

import { VIEW_TASKS_FEEDBACK } from "GraphQL/Queries";

import { FeedbackForm, ConditionalOutput } from "ComponentIndex";

const CreateFeedback = ({
    taskData,
    setOpen,
    feedbackStatus,
    create
}) => {
    const currentFeedbackSpace = currentFeedbackSpaceVar();
    let { data, loading, error } = useQuery(VIEW_TASKS_FEEDBACK, {
        variables: {
            feedbackStatus: feedbackStatus,
            taskId: taskData.id,
            feedbackSpaceId: currentFeedbackSpace.id
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    });

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <FeedbackForm
                    setOpen={setOpen}
                    taskData={taskData}
                    feedbackData={data.tasks.objects[0]}
                    create={create}
                />
            ) : null}
        </ConditionalOutput>
    );
};

export default CreateFeedback;
