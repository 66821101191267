import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import reduxActions from "State/ActionIndex";
import { SUBSCRIPTION_MUTATION } from "GraphQL/Mutations";
import { ConditionalOutput } from "ComponentIndex";

let defautFormState = {
    email: ""
};

const DemoForm = () => {
    const [formState, setFormState] = useState(defautFormState);

    const [subscribe, { loading, error }] = useMutation(
        SUBSCRIPTION_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    `Thank you for sharing your email with us. We will be in touch soon!`,
                    "success"
                );
                setFormState(defautFormState);
            }
        }
    );

    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        subscribe({
            variables: {
                subscriptionEmail: formState.email
            }
        });
    };

    return (
        <ConditionalOutput error={error} loading={loading}>
            <form
                className={"w-full flex flex-row"}
                onSubmit={handleSubmit}>
                <input
                    className={
                        "focus:ring-2 h-20 focus:ring-inset focus:ring-shocking-700 flex-auto w-8/12 bg-secondary-600 placeholder:text-lg text-lg placeholder:text-secondary-200 text-secondary-50  rounded-tl-md rounded-bl-md border-none px-4 outline-none "
                    }
                    id='email'
                    type={"email"}
                    onChange={handleFieldChange}
                    value={formState.email}
                    placeholder={"Your Email Address"}
                    required={true}
                />
                <button
                    className={
                        "w-4/12 h-20 border-none pl-4 pr-4 cursor-pointer bg-shocking-700 outline-none rounded-tr-md rounded-br-md "
                    }
                    type='submit'>
                    <p className={"body_large text-white"}>
                        {"Contact for a demo"}
                    </p>
                </button>
            </form>
        </ConditionalOutput>
    );
};

export default DemoForm;
