import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import reduxActions from "State/ActionIndex";
import { SUBSCRIPTION_MUTATION } from "GraphQL/Mutations";
import { ConditionalOutput } from "ComponentIndex";

let defautFormState = {
    email: ""
};

const SubscriptionForm = () => {
    const [formState, setFormState] = useState(defautFormState);

    const [subscribe, { loading, error }] = useMutation(
        SUBSCRIPTION_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    `Thank you for sharing your email with us. We will be in touch soon!`,
                    "success"
                );
                setFormState(defautFormState);
            }
        }
    );

    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        subscribe({
            variables: {
                subscriptionEmail: formState.email
            }
        });
    };

    return (
        <ConditionalOutput error={error} loading={loading}>
            <form
                className={"w-full flex flex-row"}
                onSubmit={handleSubmit}>
                <input
                    className={
                        "focus:ring-2 focus:ring-inset focus:ring-shoking-700 flex-auto w-2/3 h-16 bg-secondary-700 text-secondary-100  rounded-tl-md rounded-bl-md border-none px-4 outline-none lg:w-1/2 xl:w-2/3"
                    }
                    id='email'
                    type={"email"}
                    onChange={handleFieldChange}
                    value={formState.email}
                    placeholder={"Your Email Address"}
                    required={true}
                />
                <button
                    className={
                        " w-1/3 h-16 border-none pl-4 pr-4 cursor-pointer text-white bg-shocking-700 outline-none rounded-tr-md rounded-br-md lg:w-1/2 xl:w-1/3"
                    }
                    type='submit'>
                    {"Find out more"}
                </button>
            </form>
        </ConditionalOutput>
    );
};

export default SubscriptionForm;
