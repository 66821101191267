import React from "react";
import {
    MainLayout,
    ManageFeedbackSpacesContent,
    ManageFeedbackSpacesActions,
    AccountSettingsNavigation
} from "ComponentIndex";

const ManageFeedbackSpacesComponent = () => {
    return (
        <MainLayout
            navigation={<AccountSettingsNavigation />}
            action={<ManageFeedbackSpacesActions />}
            content={<ManageFeedbackSpacesContent />}
            workspace={"Account Settings"}
            title={"Manage Feedback Spaces"}
            activeTab={"manage-feedback-space"}
            logCode={"PMFPL"}
        />
    );
};

export default ManageFeedbackSpacesComponent;
