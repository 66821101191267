import {
    Card,
    NoFeedbackSpaceMessageContent,
    GuideList
} from "ComponentIndex";

const NoFeedbackSpaceMessage = () => {
    return (
        <div
            className={
                "flex w-full h-full flex-col  items-center justify-start gap-y-8  mt-16 px-4 md:w-auto"
            }>
            <Card type={"light"} strech={false} elevation={"sm"}>
                <NoFeedbackSpaceMessageContent />
            </Card>
            <Card type={"light"} strech={false}>
                <GuideList />
            </Card>
        </div>
    );
};

export default NoFeedbackSpaceMessage;
