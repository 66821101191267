import React, { useRef } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import { VIEW_TASKS_FEEDBACK } from "GraphQL/Queries";
import { convertDate } from "UtilityFunctionIndex";
import { useClickOutside } from "HookIndex";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import {
    SatisfactionRangeGraph,
    TextAreaDisplay,
    LabelWithData,
    TagAreaDisplay,
    ConditionalOutput,
    Card,
    FeedbackFormHeader
} from "ComponentIndex";

import { appContentVar } from "GraphQL/ReactiveVariables";

let taskObj = null;

const TaskFeedbackView = ({ id, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const currentFeedbackSpace = useReactiveVar(
        currentFeedbackSpaceVar
    );
    const content = appContentVar().TaskFeedbackView;
    let {
        data: tskFeedback,
        loading,
        error
    } = useQuery(VIEW_TASKS_FEEDBACK, {
        variables: {
            feedbackStatus: "GV",
            taskId: id,
            feedbackSpaceId: currentFeedbackSpace.id
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    });

    if (tskFeedback) {
        taskObj = tskFeedback.tasks.objects[0];
    }

    return (
        <div
            className={
                "w-screen scroll-m-1 overflow-auto overscroll-contain lg:w-3/4 xl:w-2/3 max-w-3xl"
            }
            ref={containerRef}>
            <Card type={"light"} strech={false}>
                <ConditionalOutput error={error} loading={loading}>
                    {tskFeedback ? (
                        <div>
                            <FeedbackFormHeader task={taskObj} />
                            <div
                                className={
                                    "flex flex-row justify-between mb-6"
                                }>
                                <LabelWithData
                                    label={content.givenBy}
                                    data={
                                        masterAcc(
                                            taskObj,
                                            "task",
                                            "feedbackGivenName"
                                        )
                                            ? masterAcc(
                                                  taskObj,
                                                  "task",
                                                  "feedbackGivenName"
                                              )
                                            : masterAcc(
                                                  taskObj,
                                                  "task",
                                                  "feedbackGivenEmail"
                                              )
                                    }
                                />
                                <LabelWithData
                                    label={content.givenAt}
                                    data={convertDate(
                                        masterAcc(
                                            taskObj,
                                            "task",
                                            "feedbackModifiedAt"
                                        )
                                    )}
                                    align={"left"}
                                />
                            </div>
                            <div className={"flex flex-col gap-y-6"}>
                                <div
                                    className={
                                        "flex flex-row justify-between"
                                    }>
                                    <TextAreaDisplay
                                        label={content.stop}>
                                        {masterAcc(
                                            taskObj,
                                            "task",
                                            "stopFeedbackDescription"
                                        )}
                                    </TextAreaDisplay>
                                    <TagAreaDisplay
                                        tagList={masterAcc(
                                            taskObj,
                                            "task",
                                            "stopFeedbackTags"
                                        )}
                                        label={content.tags}
                                    />
                                </div>

                                <div
                                    className={
                                        "flex flex-row justify-between"
                                    }>
                                    <TextAreaDisplay
                                        label={content.continue}>
                                        {masterAcc(
                                            taskObj,
                                            "task",
                                            "continueFeedbackDescription"
                                        )}
                                    </TextAreaDisplay>
                                    <TagAreaDisplay
                                        tagList={masterAcc(
                                            taskObj,
                                            "task",
                                            "continueFeedbackTags"
                                        )}
                                        label={content.tags}
                                    />
                                </div>

                                <div
                                    className={
                                        "flex flex-row justify-between"
                                    }>
                                    <TextAreaDisplay
                                        label={content.improve}>
                                        {masterAcc(
                                            taskObj,
                                            "task",
                                            "improveFeedbackDescription"
                                        )}
                                    </TextAreaDisplay>
                                    <TagAreaDisplay
                                        tagList={masterAcc(
                                            taskObj,
                                            "task",
                                            "improveFeedbackTags"
                                        )}
                                        label={content.tags}
                                    />
                                </div>

                                <div
                                    className={
                                        "flex flex-row justify-between"
                                    }>
                                    <TextAreaDisplay
                                        label={content.fweet}>
                                        {masterAcc(
                                            taskObj,
                                            "task",
                                            "textFeedbackDescription"
                                        )}
                                    </TextAreaDisplay>

                                    <TagAreaDisplay
                                        tagList={masterAcc(
                                            taskObj,
                                            "task",
                                            "textFeedbackTags"
                                        )}
                                        label={content.tags}
                                    />
                                </div>

                                <div
                                    className={
                                        "md:w-3/4 self-center xl:w-7/12"
                                    }>
                                    <SatisfactionRangeGraph
                                        selectionRange={[
                                            masterAcc(
                                                taskObj,
                                                "task",
                                                "lowerSatisfactionRange"
                                            ),
                                            masterAcc(
                                                taskObj,
                                                "task",
                                                "upperSatisfactionRange"
                                            )
                                        ]}
                                        staticData={true}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default TaskFeedbackView;
