import React, { useRef } from "react";
import { useFeedbackSpaceUsersQuery, useClickOutside } from "HookIndex";
import { myRoleInFeedbackSpace } from "./Utils";
import {
    Title,
    ConditionalOutput,
    MemberRecord,
    Card
} from "ComponentIndex";

const ManageMembers = ({ feedbackSpaceId, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const { data, error, loading, myRole } =
        useFeedbackSpaceUsersQuery(feedbackSpaceId);

    return (
        <div
            className={"w-full md:min-w-2xl md:w-fit"}
            ref={containerRef}>
            <Card>
                <Title>Manage feedback space roles</Title>

                <ConditionalOutput error={error} loading={loading}>
                    <p className={"label_medium text-shocking-700"}>
                        People with accesss
                    </p>
                    <div className={"flex flex-col gap-y-6"}>
                        {data &&
                            data.feedbackSpaceUsers.map(
                                (recordItem) => {
                                    return (
                                        <MemberRecord
                                            key={`record${recordItem.id}`}
                                            recordItem={recordItem}
                                            myRole={myRoleInFeedbackSpace(
                                                data.feedbackSpaceUsers
                                            )}
                                        />
                                    );
                                }
                            )}
                    </div>
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default ManageMembers;
