import React from "react";
import classnames from "classnames";
import cls from "./FeedbackNotification.module.css";
import { Switch, HelpModule } from "ComponentIndex";
import { useNotificationPrefChange } from "CustomHooks/CustomMutations";

const FeedbackNotification = ({ data, feedbackSpace }) => {
    const mutationVariable = {
        parameterLabel: "feedback_notification",
        feedbackSpaceId: feedbackSpace.id
    };
    const [switchState, handleSwitchChange] = useNotificationPrefChange(
        data,
        mutationVariable,
        "acc"
    );

    const feedbackNotificationClass = classnames(
        cls.feedbackNotification
    );

    const descriptionClass = classnames(cls.description);

    return (
        <div className={feedbackNotificationClass}>
            <Switch
                checked={switchState}
                onChange={handleSwitchChange}
            />
            <div className={descriptionClass}>
                <p className={"label_large text-secondary-900"}>
                    {"Send feedback notification"}
                </p>
            </div>
            <HelpModule
                message={
                    "You will receive email notification every time someone gives feedback to the task assigned to you."
                }
            />
        </div>
    );
};

export default FeedbackNotification;
