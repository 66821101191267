import React from "react";
import { BlogSideCard } from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import {
    TaskBasedFeedbackArticleLink,
    ImpactfullFeedbackArticleLink,
    SystemicProbemsArticleLink,
    CognitiveBiasesInPerformanceArticleLink,
    ReviewEmbraceEmployeesEffortsArticleLink
} from "BlogLinkIndex";

const BlogSideIndex = () => {
    const content = landingContentVar().BlogSideIndex;
    return (
        <div className={"flex flex-col gap-y-6 mt-12"}>
            <div className={"label_large text-shocking-800"}>
                {content[0]}
            </div>
            <BlogSideCard
                articleName={"SystemicProbemsArticle"}
                link={SystemicProbemsArticleLink}
            />
            <BlogSideCard
                articleName={"ImpactfullFeedbackArticle"}
                link={ImpactfullFeedbackArticleLink}
            />
            <BlogSideCard
                articleName={"TaskBasedFeedbackArticle"}
                link={TaskBasedFeedbackArticleLink}
            />
            <BlogSideCard
                articleName={"CognitiveBiasesInPerformanceArticle"}
                link={CognitiveBiasesInPerformanceArticleLink}
            />
            <BlogSideCard
                articleName={"ReviewEmbraceEmployeesEffortsArticle"}
                link={ReviewEmbraceEmployeesEffortsArticleLink}
            />
        </div>
    );
};

export default BlogSideIndex;
