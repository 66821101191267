import React from "react";
import { useQuery } from "@apollo/client";
import { TextWithTags, InputLabelCount, Loading } from "ComponentIndex";
import { GET_ALL_TAGS_QUERY } from "GraphQL/Queries";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";

const TextFeedback = ({
    label,
    rows = 0,
    feedbackState,
    setFeedbackState,
    placeholderText = null,
    maxLength = 280,
    parentRef
}) => {
    const feedbackSpaceId = currentFeedbackSpaceVar().id;

    const { data: tagData, loading } = useQuery(GET_ALL_TAGS_QUERY, {
        variables: {
            feedbackSpaceId: feedbackSpaceId
        },
        fetchPolicy: "network-only"
    });

    if (loading) {
        return <Loading />;
    }

    if (tagData) {
        return (
            <InputLabelCount
                scale={"strech"}
                label={
                    <p
                        className={"label_small text-shocking-700"}
                        htmlFor={`textarea${label}`}>
                        {label}
                    </p>
                }
                input={
                    <TextWithTags
                        id={`textarea${label}`}
                        textValue={feedbackState}
                        setTextValue={setFeedbackState}
                        placeholder={placeholderText}
                        rows={rows}
                        tagData={tagData.tags}
                        dataAccessor={(item) => item.tagName}
                        parentRef={parentRef}
                        maxLength={maxLength}
                        emptyMessage={"No registered tags"}
                    />
                }
                count={
                    <p
                        className={
                            "label_small text-secondary-500"
                        }>{`${feedbackState.length}/${maxLength}`}</p>
                }
            />
        );
    }
};

export default TextFeedback;
