import { rangeGroup } from "frontendSettings";

export const assignUserName = (value) => {
    if (value.username) {
        return value.username;
    } else {
        return value.email;
    }
};

export const defineCellColor = (cell) => {
    let avgValue =
        (cell.row.values.range.upperValue +
            cell.row.values.range.lowerValue) /
        2;
    let color = "";
    switch (true) {
        case avgValue >= rangeGroup.Excited[0]:
            color = `#36c5f0`;

            break;
        case avgValue >= rangeGroup.Satisfied[0]:
            color = `#2eb67d`;
            break;
        case avgValue >= rangeGroup.Neutral[0]:
            color = `#ecb22e`;

            break;
        case avgValue >= rangeGroup.Concerned[0]:
            color = `#e01e5a`;
            break;
        default:
            break;
    }
    return { style: { backgroundColor: color } };
};

export const sortRange = (a, b) => {
    let aAvg =
        (a.values.range.upperValue + a.values.range.lowerValue) / 2;
    let bAvg =
        (b.values.range.upperValue + b.values.range.lowerValue) / 2;
    if (aAvg > bAvg) {
        return 1;
    } else {
        return -1;
    }
};
