import React from "react";
import classnames from "classnames";
import cls from "./AxisTick.module.css";
import { defineDirection } from "./Utils";

const AxisTick = ({ length, horizontal = true }) => {
    const coords = defineDirection(length, horizontal);
    const axisTickClass = classnames(cls.axisTick);
    return <line className={axisTickClass} {...coords} />;
};

export default AxisTick;
