import React from "react";
import msIcon from "Images/ms-symbol.svg";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import { useGetOpenIDLink } from "../Utils";

const AzureLogin = () => {
    const content = landingContentVar().AzureLogin;
    const {makeLinkRequest} = useGetOpenIDLink("microsoft");

    return (
        <div className={"flex flex-row justify-center m-5"}>
            <button
                onClick={()=>{makeLinkRequest()}}
                className={
                    "flex flex-row gap-x-3 items-center h-10 p-3 border border-[#8C8C8C] text-[#5E5E5E]"
                }>
                <img src={msIcon} alt={content.altText} />
                <span className={"text-base"}>{content.signIn}</span>
            </button>
        </div>
    );
};

export default AzureLogin;
