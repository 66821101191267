import React from "react";
import { ProductTourCard, DashboardWidgets } from "ComponentIndex";
import { useLoadForTour } from "HookIndex";

const DashboardContent = () => {
    useLoadForTour(0, "setupTour");
    return (
        <div className={"flex flex-col gap-y-6 items-center"}>
            <ProductTourCard />
            <DashboardWidgets />
        </div>
    );
};

export default DashboardContent;
