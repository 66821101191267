import React from "react";

import { Tag } from "ComponentIndex";

const TagAreaDisplay = ({ tagList, tagStyle, label }) => {
    return (
        <div className={"w-3/12"}>
            <p className={"label_small text-shocking-700"}>{label}</p>
            <div>
                {tagList.map((tag, index) => {
                    return (
                        <Tag
                            key={`${index}${tagStyle}`}
                            variant={tagStyle}>
                            {tag.tagName}
                        </Tag>
                    );
                })}
            </div>
        </div>
    );
};

export default TagAreaDisplay;
