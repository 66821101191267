import { Icon, ComboBoxIcon } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import {
    stringifyTaskTypeItems,
    useTaskTypeFilterItems
} from "./Utils";

const TaskTypeFilterIcon = () => {
    const content = appContentVar().TaskTypeFilterIcon;
    const { data, options, setOptions } = useTaskTypeFilterItems();
    return (
        <ComboBoxIcon
            size={"md_24"}
            options={options}
            setOptions={setOptions}
            itemsToString={stringifyTaskTypeItems}
            items={data}
            emptyMessage={content.emptyMessage[0]}
            itemPrefix={"taskType"}>
            <Icon
                title={content.title[0]}
                name={"task_alt"}
                size={"small"}
                color={"light"}
            />
        </ComboBoxIcon>
    );
};

export default TaskTypeFilterIcon;
