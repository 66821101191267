import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { v4 } from "uuid";
import { useColumnHide } from "./Utils.jsx";

import {
    userCell,
    rangeCell,
    projectsHeader,
    assigneeHeader,
    giveByHeader,
    rangeHeader,
    stopHeader,
    taskHeader,
    continueHeader,
    improveHeader,
    fweetHeader,
    infoHeader,
    givenByCell,
    tagCell,
    actionsCell,
    projectCell,
    taskCell
} from "../Cells.jsx";

import { sortRange } from "../Utils.jsx";

import { Table, Thead, Tr, Tbody, CellWrapper } from "ComponentIndex";

let ResumeTableConstructor = ({ data }) => {
    const tableData = useMemo(() => data, [data]);
    let sortInRange = useMemo(() => sortRange, []);
    const columns = useMemo(
        () => [
            {
                Header: ({ column }) => projectsHeader(column),
                Cell: ({ cell }) => projectCell(cell),
                accessor: "project.projectName",
                id: "project"
            },
            {
                Header: ({ column }) => assigneeHeader(column),
                Cell: ({ cell }) => userCell(cell),
                accessor: "assigneeParameters.assigneeName",
                id: "assignee"
            },
            {
                Header: ({ column }) => giveByHeader(column),
                accessor: "basicfeedbackstatusSet[0].givenBy",
                Cell: ({ cell }) => givenByCell(cell),
                id: "given"
            },
            {
                Header: ({ column }) => taskHeader(column),
                accessor: "taskName",
                Cell: ({ cell }) => taskCell(cell),
                id: "task",
                disableSortBy: true
            },
            {
                Header: ({ column }) => rangeHeader(column),
                accessor: "satisfactionrangeSet[0]",
                Cell: ({ cell }) => rangeCell(cell),
                id: "range",
                disableSortBy: true
            },
            {
                Header: ({ column }) => stopHeader(column),
                accessor: "stopfeedbackSet[0].tags",
                Cell: ({ cell }) => tagCell(cell),
                id: "stopTags",
                disableSortBy: true
            },
            {
                Header: ({ column }) => continueHeader(column),
                accessor: "continuefeedbackSet[0].tags",
                Cell: ({ cell }) => tagCell(cell),
                disableSortBy: true
            },
            {
                Header: ({ column }) => improveHeader(column),
                accessor: "improvefeedbackSet[0].tags",
                Cell: ({ cell }) => tagCell(cell),
                id: "improveTags",
                disableSortBy: true
            },
            {
                Header: ({ column }) => fweetHeader(column),
                accessor: "textfeedbackSet[0].tags",
                Cell: ({ cell }) => tagCell(cell),
                id: "tweetTags",
                disableSortBy: true
            },
            {
                Header: ({ column }) => infoHeader(column),
                accessor: "id",
                Cell: ({ cell }) => actionsCell(cell),
                id: "info",
                sortType: sortInRange
            }
        ],

        []
    );
    const tableInstance = useTable(
        {
            columns,
            data: tableData
        },
        useSortBy
    );
    const {
        setHiddenColumns,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    useColumnHide(setHiddenColumns);

    return (
        <>
            <Table tableProps={getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr
                            key={v4()}
                            rowProps={headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <CellWrapper key={v4()}>
                                        {column.render("Header")}
                                    </CellWrapper>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>

                <Tbody tbodyProps={getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr
                                key={v4()}
                                rowProps={row.getRowProps()}
                                hover={true}>
                                {row.cells.map((cell) => {
                                    return (
                                        <CellWrapper key={v4()}>
                                            {cell.render("Cell")}
                                        </CellWrapper>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </>
    );
};

export default ResumeTableConstructor;
