import React from "react";
import classnames from "classnames";
import cls from "./Line.module.css";

const Line = ({
    d,
    strokeLinejoin = "round",
    strokeLinecap = "round"
}) => {
    const lineClass = classnames(cls.line);
    return (
        <path
            className={lineClass}
            d={d}
            strokeLinejoin={strokeLinejoin}
            strokeLinecap={strokeLinecap}
        />
    );
};

export default Line;
