import React from "react";
import JoyRide from "react-joyride";
import { useReactiveVar } from "@apollo/client";
import { productGuidesVar } from "GraphQL/ReactiveVariables";
import { callbackFactory } from "./TourParameters/CallBackFunctions";
import { useNavigate } from "react-router-dom";

const ProductTour = () => {
    const tourParameters = useReactiveVar(productGuidesVar);
    const navigate = useNavigate();

    const activeCallBack = callbackFactory(
        tourParameters.callBackType,
        navigate
    );

    return (
        <JoyRide
            callback={activeCallBack}
            showSkipButton
            continuous
            disableScrolling
            run={tourParameters.run}
            steps={tourParameters.steps}
            stepIndex={tourParameters.stepIndex}
            locale={{ skip: "End Tour" }}
        />
    );
};

export default ProductTour;
