import { useRef } from "react";
import { appContentVar } from "GraphQL/ReactiveVariables";
import {
    Button,
    RangeFeedback,
    TextFeedback,
    SkillSelectors
} from "ComponentIndex";

const FeedbackFormContainer = ({
    states,
    stateActions,
    handleFromSubmit
}) => {
    let parentRef = useRef();
    const content = appContentVar().FeedbackFormContainer;

    return (
        <form
            className={"flex flex-col"}
            ref={parentRef}
            onSubmit={handleFromSubmit}>
            <div className={"flex flex-col md:flex-row gap-x-4"}>
                <div className={" flex flex-col gap-4 md:w-1/2"}>
                    <div>
                        <TextFeedback
                            feedbackState={states.stopFeedback}
                            setFeedbackState={
                                stateActions.setStopFeedback
                            }
                            rows={4}
                            label={content.stopLabel[0]}
                            placeholderText={content.stopPlaceholder[0]}
                            parentRef={parentRef}
                        />
                    </div>
                    <div>
                        <TextFeedback
                            feedbackState={states.continueFeedback}
                            setFeedbackState={
                                stateActions.setContinuetFeedback
                            }
                            rows={4}
                            label={content.continueLabel[0]}
                            placeholderText={
                                content.continuePlaceholder[0]
                            }
                            parentRef={parentRef}
                        />
                    </div>
                    <div>
                        <TextFeedback
                            feedbackState={states.improveFeedback}
                            setFeedbackState={
                                stateActions.setImproveFeedback
                            }
                            rows={4}
                            label={content.improveLabel[0]}
                            placeholderText={
                                content.improvePlaceholder[0]
                            }
                            parentRef={parentRef}
                        />
                    </div>
                    <div>
                        <TextFeedback
                            feedbackState={states.textFeedback}
                            setFeedbackState={
                                stateActions.setTextFeedback
                            }
                            rows={4}
                            label={content.fweetLabel[0]}
                            placeholderText={
                                content.fweetPlaceholder[0]
                            }
                            parentRef={parentRef}
                        />
                    </div>
                </div>
                <div
                    className={
                        "flex flex-col gap-4 md:w-1/2 items-center"
                    }>
                    <SkillSelectors
                        areaSpecificSkills={states.areaSpecificSkills}
                        technicalSkills={states.technicalSkills}
                        interpersonalSkills={states.interpersonalSkills}
                        managementSkills={states.managementSkills}
                        setAreaSpecificSkills={
                            stateActions.setAreaSpecificSkills
                        }
                        setTechnicalSkills={
                            stateActions.setTechnicalSkills
                        }
                        setInterpersonalSkills={
                            stateActions.setInterpersonalSkills
                        }
                        setManagementSkills={
                            stateActions.setManagementSkills
                        }
                    />
                    <RangeFeedback
                        rangeState={states.rangeInput}
                        setRangeState={stateActions.setRangeInput}
                    />
                </div>
            </div>

            <div className={"self-end mt-8"}>
                <Button
                    type={"submit"}
                    disabled={states.save}
                    variant={"filled"}
                    icon={"save"}>
                    {content.button[0]}
                </Button>
            </div>
        </form>
    );
};

export default FeedbackFormContainer;
