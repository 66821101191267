import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    Card,
    Title,
    ContentWrapperBand,
    PageToScreenWrapper,
    HelmetExclude,
    LoginForm,
    ContactForDemoLink,
    OpenIDCompartment
} from "ComponentIndex";

const LoginContent = () => {
    workspaceNameVar("Login");

    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Card
                        type={"light"}
                        strech={false}
                        elevation={"lg"}>
                        <div className={"flex flex-col gap-y-6"}>
                            <Title type={"title_large"}>Login</Title>
                            <LoginForm />
                            <ContactForDemoLink />
                            <OpenIDCompartment />
                        </div>
                    </Card>
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default LoginContent;
