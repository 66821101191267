import React from "react";
import {
    SkillReportContent,
    MainLayout,
    SkillReportActions,
    ReportNavigation
} from "ComponentIndex";

const SkillReportComponent = () => {
    return (
        <MainLayout
            navigation={<ReportNavigation />}
            action={<SkillReportActions />}
            content={<SkillReportContent />}
            workspace={"Insights"}
            title={"Skills Report"}
            activeTab={"skills"}
            feedbackSpaceCheck={true}
            logCode={"PSRPL"}
        />
    );
};

export default SkillReportComponent;
