import { Helmet } from "react-helmet-async";

const HelmetInclude = ({ content }) => {
    return (
        <Helmet>
            <title>{content.metaTitle[0]}</title>
            <meta
                name='description'
                content={content.metaDescription[0]}
            />
            <meta name='keywords' content={content.metaKeywords[0]} />
        </Helmet>
    );
};

export default HelmetInclude;
