import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { Loading, Application } from "ComponentIndex";

import { ME_QUERY } from "GraphQL/Queries";

const redirectSelection = (error, data, location) => {
    if (error && error.message === "Not logged in") {
        return (
            <Navigate to='/login' state={{ from: location }} replace />
        );
    } else if (error && error.message === "Email is not verified") {
        return <Navigate to='/resend-verification' />;
    } else if (data && data.me.email && data.me.isVerified) {
        return <Application />;
    } else if (data && data.me.email && !data.me.isVerified) {
        return <Navigate to={"/resend-verification"} />;
    }
};

const PrivateRoute = () => {
    const { data, loading, error } = useQuery(ME_QUERY);
    let location = useLocation();
    if (error) {
        console.log("Error", error.message);
    }

    if (loading) return <Loading />;

    return <>{redirectSelection(error, data, location)}</>;
};

export default PrivateRoute;
