import { content_eng } from "ContentManagement/english";

const SkillVarianceChart = () => {
    const info = content_eng["info"]["skillVariance"];
    return (
        <>
            <p className={"body_small text-secondary-900"}>{info[1]}</p>
            <br />
            <p className={"body_small text-secondary-900"}>{info[2]}</p>
        </>
    );
};

export default SkillVarianceChart;
