import React from "react";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import { Link } from "react-router-dom";
import { BlogFollow, BlogSideIndex } from "ComponentIndex";

const BlogArticle = ({ children }) => {
    const content = landingContentVar().BlogArticle;
    return (
        <div className={"flex flex-row"}>
            <div className={"pt-2 px-10 sm:w-full md:w-9/12"}>
                <Link
                    className={"label_medium text-shocking-700"}
                    to={"/blog"}>
                    {content[0]}
                </Link>

                <div className={" py-6"}>{children}</div>
            </div>
            <div
                className={
                    "sm:hidden md:block  md:w-3/12 md:border-l md:border-secondary-50"
                }>
                <div className={"sticky top-12 py-6 px-8"}>
                    <BlogFollow />
                    <BlogSideIndex />
                </div>
            </div>
        </div>
    );
};

export default BlogArticle;
