import { makeArray, removeNullFromArray } from "UtilityFunctionIndex";
import {
    masterAcc,
    skillTypeAcc
} from "../UtilityFunctions/DataAccessors";

// Filter hooks

export const makeSkillSet = (data) => {
    return new Set(
        data
            .map((item) => {
                return item;
            })
            .flat(Infinity)
    );
};

export const makeSkillInFeedbackSet = (data) => {
    return new Set(
        data
            .map((item) => {
                return item["skill"];
            })
            .flat(Infinity)
    );
};

export const makeSkillTypeSet = (data) => {
    return new Set(
        removeNullFromArray(
            makeArray(masterAcc(data, "skills", "type"))
        )
    );
};

export const makeSkillObjectArray = (data) => {
    let skillsArray = Array.from(
        new Set(data.map((item) => item["skill"].skillType))
    );
    let skillsListOfObjets = skillsArray.map((item) => {
        return {
            abbr: item,
            skillTypeName: skillTypeAcc(item)
        };
    });
    return skillsListOfObjets;
};

export const makeInventorySkillObjectArray = (data) => {
    let skillsArray = Array.from(
        new Set(data.map((item) => item.skillType))
    );
    let skillsListOfObjets = skillsArray.map((item) => {
        return {
            abbr: item,
            skillTypeName: skillTypeAcc(item)
        };
    });
    return skillsListOfObjets;
};
