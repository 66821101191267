import { useState } from "react";
import { useReactiveVar } from "@apollo/client";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { Link } from "react-router-dom";
import { TextDialog, AddFeedbackSpace } from "ComponentIndex";

const NoFeedbackSpaceMessageContent = () => {
    const content =
        useReactiveVar(appContentVar).NoFeedbackSpaceMessage;
    const [open, setOpen] = useState(false);
    return (
        <>
            <p
                className={
                    "body_large text-secondary-800 mb-6 text-center"
                }>
                {content[0]}
            </p>
            <p
                className={
                    "body_large text-secondary-800 mb-6 text-center"
                }>
                {content[1]}
                <Link
                    className={
                        "underline font-semibold text-shocking-700"
                    }
                    to={"/app/account-settings/invitations"}>
                    {content[2]}
                </Link>
                {content[3]}
                <TextDialog
                    open={open}
                    setOpen={setOpen}
                    label={content[4]}>
                    <AddFeedbackSpace setOpen={setOpen} />
                </TextDialog>
                {content[5]}
                <br />
                {content[6]}
            </p>
            <p className={"body_large text-secondary-800 text-center"}>
                {content[7]}
                {content[8]}
                <br />
                {content[9]}
                <a
                    className={"font-bold underline text-shocking-700"}
                    href='mailto:support@enamply.com?subject=Notifications'>
                    {content[10]}
                </a>
            </p>
        </>
    );
};

export default NoFeedbackSpaceMessageContent;
