import React from "react";
import { VictoryChart, VictoryAxis, VictoryArea } from "victory";

import { generateRange } from "UtilityFunctions/GraphingUtils";
import {
    rangeChartTickLabels,
    rangeChartLabels,
    gradientFill,
    selectionStyle
} from "frontendSettings";

let mainRange = generateRange(0, 100, 50);

const SatisfactionRangeGraph = ({ selectionRange }) => {
    let generatedRange = generateRange(
        selectionRange[0],
        selectionRange[1],
        50
    );

    return (
        <div className={"w-full"}>
            {gradientFill}
            <VictoryChart
                padding={{ left: 0, right: 0, bottom: 25, top: 0 }}>
                <VictoryArea
                    style={{
                        data: { fill: "url(#myGradient)" }
                    }}
                    interpolation='natural'
                    data={mainRange}
                />
                <VictoryArea
                    style={selectionStyle}
                    data={generatedRange}
                />
                <VictoryAxis
                    tickValues={rangeChartTickLabels}
                    tickFormat={rangeChartLabels}
                />
            </VictoryChart>
        </div>
    );
};

export default SatisfactionRangeGraph;
