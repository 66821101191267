import React from "react";
import cn from "classnames";

const DrawerMenu = ({ children }) => {
    const drawerClass = cn([
        "hidden lg:w-64 lg:relative  lg:bg-secondary-800",
        "lg:h-full lg:flex lg:flex-col",
        "lg:justify-between lg:py-4 lg:px-4"
    ]);
    return <aside className={drawerClass}>{children}</aside>;
};

export default DrawerMenu;
