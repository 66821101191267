const getData = (dataObj, pathArray) => {
    let segment = dataObj;
    for (let element of pathArray) {
        try {
            segment = segment[element];
        } catch (error) {
            return null;
        }
    }
    return segment;
};

export const medianAcc = (item) => {
    return item["median"];
};

export const upperQuantileAcc = (item) => {
    return item["upperQ"];
};

export const lowerQuantileAcc = (item) => {
    return item["lowerQ"];
};

export const periodAcc = (item) => {
    return item["period"];
};

export const feedbackSpaceRoleAcc = (item) => {
    return item.roleinfeedbackspaceSet[0].role;
};

export const masterAcc = (item, accessor, parameter) => {
    switch (accessor) {
        case "assignee":
            return assigneeAcc(item, parameter);
        case "assigneesParameters":
            return assigneeParementersAcc(item, parameter);
        case "project":
            return projectAcc(item, parameter);
        case "userPreference":
            return userPreferenceAcc(item, parameter);
        case "roleInFeedbackSpace":
            return roleInFeedbackSpaceAcc(item, parameter);
        case "account":
            return accountAcc(item, parameter);
        case "skillInFeedbackSpace":
            return skillInFeedbackSpaceAcc(item, parameter);
        case "skills":
            return skillAcc(item, parameter);
        case "feedbackSpace":
            return feedbackSpaceAcc(item, parameter);
        case "task":
            return taskAcc(item, parameter);
        case "taskType":
            return taskTypeAcc(item, parameter);

        case "dataSource":
            return dataSourceAcc(item, parameter);
        default:
            return null;
    }
};

export const dataSourceAcc = (dataSource, parameter) => {
    switch (parameter) {
        case "id":
            return getData(dataSource, ["id"]);
        case "dataSource":
            return getData(dataSource, ["dataSource"]);
        default:
            break;
    }
};

export const taskTypeAcc = (taskType, parameter) => {
    switch (parameter) {
        case "id":
            return getData(taskType, ["id"]);
        case "taskType":
            return getData(taskType, ["taskType"]);
        default:
            break;
    }
};

export const assigneeAcc = (assignee, parameter) => {
    switch (parameter) {
        case "id":
            return assignee.id;
        case "identifier":
            return assignee.assigneeIdentifier;
        case "identifierType":
            return assignee.assigneeIdentifierType;
        default:
            return null;
    }
};

export const feedbackSpaceAcc = (feedbackSpace, parameter) => {
    switch (parameter) {
        case "id":
            return feedbackSpace.id;
        case "name":
            return feedbackSpace.feedbackSpaceName;
        case "role":
            return roleInFeedbackSpaceAcc(
                feedbackSpace.roleinfeedbackspaceSet[0],
                parameter
            );
        default:
            return null;
    }
};

export const projectAcc = (project, parameter) => {
    switch (parameter) {
        case "name":
            return getData(project, ["projectName"]);
        case "id":
            return getData(project, ["id"]);
        case "syncStatus":
            return getData(project, ["projectSyncStatus"]);
        default:
            return null;
    }
};

export const userPreferenceAcc = (preference, parameter) => {
    switch (parameter) {
        case "id":
            return preference.userPreferances.currentFeedbackSpace.id;
        case "name":
            return preference.userPreferances.currentFeedbackSpace
                .feedbackSpaceName;
        case "role":
            return preference.userPreferances.currentFeedbackSpace
                .roleinfeedbackspaceSet[0].role;
        case "tour":
            return preference.userPreferances.productTour;
        default:
            return null;
    }
};

export const roleInFeedbackSpaceAcc = (
    roleInFeedbackSpace,
    parameter
) => {
    switch (parameter) {
        case "id":
            return roleInFeedbackSpace.id;
        case "role":
            return roleInFeedbackSpace.role;
        case "memberEmail":
            return roleInFeedbackSpace.memberAccount.email;
        case "memberId":
            return roleInFeedbackSpace.memberAccount.id;
        default:
            return null;
    }
};

export const accountAcc = (account, parameter) => {
    switch (parameter) {
        case "id":
            return account.id;
        case "email":
            return account.email;
        default:
            return null;
    }
};

export const skillAcc = (skill, parameter) => {
    switch (parameter) {
        case "id":
            return getData(skill, ["id"]);
        case "skillName":
            return getData(skill, ["skillName"]);
        case "typeName":
            return skillTypeAcc(skill.skillType);
        case "typeAbr":
            return skillTypeAbrAcc(skill);
        default:
            return null;
    }
};

export const skillTypeAbrAcc = (skillType) => {
    switch (skillType) {
        case "Interpersonal skills":
            return "INT";
        case "Area specific skills":
            return "ASP";
        case "Technical skills":
            return "TEC";
        case "Management skills":
            return "MNG";
        default:
            return null;
    }
};

export const skillTypeAcc = (skillType) => {
    switch (skillType) {
        case "INT":
            return "Interpersonal skills";
        case "ASP":
            return "Area specific skills";
        case "TEC":
            return "Technical skills";
        case "MNG":
            return "Management skills";
        default:
            return "unselected";
    }
};

export const skillInFeedbackSpaceAcc = (
    skillInFeedbackSpace,
    parameter
) => {
    switch (parameter) {
        case "id":
            return getData(skillInFeedbackSpace, ["id"]);
        case "skillId":
            return skillAcc(skillInFeedbackSpace.skill, "id");
        case "skillName":
            return skillAcc(skillInFeedbackSpace.skill, "skillName");
        case "skillType":
            return skillAcc(skillInFeedbackSpace.skill, "type");
        case "skillList":
            if (skillInFeedbackSpace.length !== 0) {
                return extractSkills(skillInFeedbackSpace);
            } else {
                return [];
            }
        default:
            return null;
    }
};

const extractSkills = (skillsInFeedbackSpace) => {
    return skillsInFeedbackSpace.map((item) => {
        return item.skill;
    });
};

export const basicFeedbackStatusAcc = (
    feedbackStatusSet,
    parameter
) => {
    switch (parameter) {
        case "givenByName":
            return getData(feedbackStatusSet, [
                0,
                "givenBy",
                "username"
            ]);
        case "givenByEmail":
            return getData(feedbackStatusSet, [0, "givenBy", "email"]);
        case "modifiedAt":
            return getData(feedbackStatusSet, [0, "dateModified"]);
        default:
            return null;
    }
};

export const basicStopFeedbackAcc = (stopFeedbackSet, parameter) => {
    switch (parameter) {
        case "description":
            return getData(stopFeedbackSet, [
                0,
                "stopFeedbackDescription"
            ]);
        case "tags":
            return getData(stopFeedbackSet, [0, "tags"]);
        default:
            break;
    }
};

export const basicContinueFeedbackAcc = (
    continueFeedbackSet,
    parameter
) => {
    switch (parameter) {
        case "description":
            return getData(continueFeedbackSet, [
                0,
                "continueFeedbackDescription"
            ]);
        case "tags":
            return getData(continueFeedbackSet, [0, "tags"]);
        default:
            break;
    }
};

export const basicImproveFeedbackAcc = (
    improveFeedbackSet,
    parameter
) => {
    switch (parameter) {
        case "description":
            return getData(improveFeedbackSet, [
                0,
                "improveFeedbackDescription"
            ]);
        case "tags":
            return getData(improveFeedbackSet, [0, "tags"]);
        default:
            break;
    }
};

export const basicTextFeedbackAcc = (textFeedbackSet, parameter) => {
    switch (parameter) {
        case "description":
            return getData(textFeedbackSet, [
                0,
                "textFeedbackDescription"
            ]);
        case "tags":
            return getData(textFeedbackSet, [0, "tags"]);
        default:
            break;
    }
};

export const satisfactionRangeAcc = (
    satisfactionrangeSet,
    parameter
) => {
    switch (parameter) {
        case "upper":
            return getData(satisfactionrangeSet, [0, "upperValue"]);
        case "lower":
            return getData(satisfactionrangeSet, [0, "lowerValue"]);

        default:
            break;
    }
};

export const assigneeParementersAcc = (
    assigneeParameters,
    parameter
) => {
    switch (parameter) {
        case "id":
            return getData(assigneeParameters, ["id"]);
        case "name":
            return getData(assigneeParameters, ["assigneeName"]);
        case "syncStatus":
            return getData(assigneeParameters, ["assigneeSyncStatus"]);
        default:
            break;
    }
};

export const taskAcc = (task, parameter) => {
    switch (parameter) {
        case "id":
            return getData(task, ["id"]);
        case "name":
            return getData(task, ["taskName"]);
        case "parentId":
            return getData(task, ["parentTask", "id"]);
        case "parentName":
            return getData(task, ["parentTask", "taskName"]);
        case "projectName":
            return projectAcc(task.project, "name");
        case "completedAt":
            return getData(task, ["completedAt"]);
        case "dueAt":
            return getData(task, ["dueAt"]);
        case "assigneeEmail":
            return assigneeAcc(task.assignee[0], "email");
        case "assigneeName":
            return assigneeParementersAcc(
                task.assigneeParameters,
                "name"
            );
        case "feedbackGivenName":
            return basicFeedbackStatusAcc(
                task.basicfeedbackstatusSet,
                "givenByName"
            );
        case "feedbackGivenEmail":
            return basicFeedbackStatusAcc(
                task.basicfeedbackstatusSet,
                "givenByEmail"
            );
        case "feedbackModifiedAt":
            return basicFeedbackStatusAcc(
                task.basicfeedbackstatusSet,
                "modifiedAt"
            );
        case "stopFeedbackDescription":
            return basicStopFeedbackAcc(
                task.stopfeedbackSet,
                "description"
            );
        case "stopFeedbackTags":
            return basicStopFeedbackAcc(task.stopfeedbackSet, "tags");
        case "continueFeedbackDescription":
            return basicContinueFeedbackAcc(
                task.continuefeedbackSet,
                "description"
            );
        case "continueFeedbackTags":
            return basicContinueFeedbackAcc(
                task.continuefeedbackSet,
                "tags"
            );
        case "improveFeedbackDescription":
            return basicImproveFeedbackAcc(
                task.improvefeedbackSet,
                "description"
            );
        case "improveFeedbackTags":
            return basicImproveFeedbackAcc(
                task.improvefeedbackSet,
                "tags"
            );
        case "textFeedbackDescription":
            return basicTextFeedbackAcc(
                task.textfeedbackSet,
                "description"
            );
        case "textFeedbackTags":
            return basicTextFeedbackAcc(task.textfeedbackSet, "tags");
        case "upperSatisfactionRange":
            return satisfactionRangeAcc(
                task.satisfactionrangeSet,
                "upper"
            );
        case "lowerSatisfactionRange":
            return satisfactionRangeAcc(
                task.satisfactionrangeSet,
                "lower"
            );
        case "taskAssociatedSkills":
            return task.basicfeedbackstatusSet[0].asociatedSkills;
        case "taskType":
            return taskTypeAcc(task.taskType, "taskType");
        default:
            return null;
    }
};
