import React from "react";
import { useReactiveVar } from "@apollo/client";
import { tasksDueVar } from "GraphQL/ReactiveVariables";
import { TabBar, ActiveTab } from "ComponentIndex";

const FeedbackTabBar = () => {
    const tasksDue = useReactiveVar(tasksDueVar);
    const tasks = "(" + tasksDue + ")";
    return (
        <TabBar>
            <ActiveTab id={"pending_tasks"} fieldValue={"PE"}>
                {`Pending ${tasksDue ? tasks : ""}`}
            </ActiveTab>
            <ActiveTab id={"given_tasks"} fieldValue={"GV"}>
                {"Given"}
            </ActiveTab>
            <ActiveTab id={"skiped_tasks"} fieldValue={"NA"}>
                {"Skipped"}
            </ActiveTab>
        </TabBar>
    );
};

export default FeedbackTabBar;
