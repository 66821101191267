import React from "react";
import cn from "classnames";
import { Icon, Capsule } from "ComponentIndex";

const ActionIconButton = ({ name, title, ...rest }) => {
    const actionButtonClass = cn([
        "cursor-pointer rounded-full bg-transparent",
        "text-center border-none h-6 w-6 inline-flex",
        "p-0 items-center justify-center hover:bg-shocking-800",
        "hover:bg-secondary-800"
    ]);
    return (
        <Capsule>
            <button className={actionButtonClass} {...rest}>
                <Icon
                    title={title}
                    name={name}
                    size={"small"}
                    color={"light"}
                />
            </button>
        </Capsule>
    );
};

export default ActionIconButton;
