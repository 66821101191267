import React from "react";
import LinkedInImg from "Images/SocialMedia/linkedin-icon.png";
import MediumImg from "Images/SocialMedia/medium-icon.png";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const BlogFollow = () => {
    const content = landingContentVar().BlogFollow;
    return (
        <div>
            <p className={"label_large text-shocking-700 mb-4"}>
                {content.follow}
            </p>
            <div className={"flex flex-row justify-start gap-4"}>
                <a
                    href={"https://www.linkedin.com/company/enamply"}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                        className={"w-8 h-8"}
                        alt={content.altText.linkedIn[0]}
                        src={LinkedInImg}
                    />
                </a>
                <a
                    href={"https://medium.com/@enamply"}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <img
                        className={"w-8 h-8"}
                        alt={content.altText.medium[0]}
                        src={MediumImg}
                    />
                </a>
            </div>
        </div>
    );
};

export default BlogFollow;
