import React from "react";

const DrawerCompartment = ({ label, children }) => {
    return (
        <div
            className={
                "flex flex-row justify-between mb-2 overflow-hidden"
            }>
            <p className={"label_small text-shocking-400"}>{label}</p>
            <div>{children}</div>
        </div>
    );
};

export default DrawerCompartment;
