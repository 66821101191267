import React from "react";

const BlogCaption = ({ children, caption }) => {
    return (
        <figure
            className={
                "flex flex-col items-center my-4 text-secondary-600"
            }>
            {children}
            <figcaption className={"self-end blog_label_small"}>
                {caption}
            </figcaption>
        </figure>
    );
};

export default BlogCaption;
