import React from "react";
import classnames from "classnames";
import cls from "./AccountNotifications.module.css";
import {
    FeedbackNotification,
    AccountDailyDigest,
    AccountWeeklyDigest,
    Title
} from "ComponentIndex";

const AccountNotifications = ({ data, feedbackSpace }) => {
    const accountNotificatonsClass = classnames(
        cls.accountNotifications
    );
    return (
        <div className={accountNotificatonsClass}>
            <Title type={"title_medium"}>Account notifications</Title>
            <FeedbackNotification
                feedbackSpace={feedbackSpace}
                data={data.feedbackNotification}
            />
            <AccountDailyDigest
                feedbackSpace={feedbackSpace}
                data={data.accountDailyDigest}
            />
            <AccountWeeklyDigest
                feedbackSpace={feedbackSpace}
                data={data.accountWeeklyDigest}
            />
        </div>
    );
};

export default AccountNotifications;
