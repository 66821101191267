import React, { useEffect } from "react";
import { skillsPageVar } from "GraphQL/ReactiveVariables";
import { SkillList } from "ComponentIndex";
import { useLoadForTour } from "HookIndex";

const SkillsInventoryContent = () => {
    useEffect(() => {
        skillsPageVar("addSkills");
    }, []);
    useLoadForTour(4, "manageSkillsTour", 3);
    return <SkillList />;
};

export default SkillsInventoryContent;
