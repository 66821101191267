import React from "react";
import { Card } from "ComponentIndex";

const MessagePage = ({ message }) => {
    return (
        <Card type={"light"} basel={"blue-chill"}>
            <div
                className={
                    "flex w-full h-full justify-center items-center bg"
                }>
                <h1
                    className={
                        "display_small text-secondary-900 text-center"
                    }>
                    {message}
                </h1>
            </div>
        </Card>
    );
};

export default MessagePage;
