import React from "react";
import {
    MainLayout,
    AccountSettingsNavigation,
    FeedbackSpaceInvitationsActions,
    FeedbackSpaceInvitationsContent
} from "ComponentIndex";

const FeedbackSpaceInvitationsComponent = () => {
    return (
        <MainLayout
            navigation={<AccountSettingsNavigation />}
            action={<FeedbackSpaceInvitationsActions />}
            content={<FeedbackSpaceInvitationsContent />}
            workspace={"Feedback Spaces"}
            title={"Feedback Space Invitations"}
            activeTab={"feedback-space-invitations"}
            logCode={"PFIPL"}
        />
    );
};

export default FeedbackSpaceInvitationsComponent;
