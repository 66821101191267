import React, { useMemo } from "react";
import { v4 } from "uuid";
import { Table, Thead, Tr, Tbody, CellWrapper } from "ComponentIndex";
import { useTable, useSortBy } from "react-table";
import {
    skillNameHeader,
    skillDescriptionHeader,
    skillTypeHeader,
    skillActionsHeader,
    skillNameCell,
    skillTypeCell,
    skillDescriptionCell,
    skillActionsCell
} from "./Cells";

const SkillTable = ({ skills }) => {
    const tableData = useMemo(() => skills, [skills]);
    const colums = useMemo(() => {
        return [
            {
                accessor: "skillName",
                Header: ({ column }) => skillNameHeader(column),
                Cell: ({ cell }) => skillNameCell(cell)
            },
            {
                accessor: "skillType",
                Header: ({ column }) => skillTypeHeader(column),
                Cell: ({ cell }) => skillTypeCell(cell)
            },
            {
                accessor: "skillDescription",
                Header: ({ column }) => skillDescriptionHeader(column),
                Cell: ({ cell }) => skillDescriptionCell(cell),
                disableSortBy: true
            },
            {
                accessor: "id",
                Header: ({ column }) => skillActionsHeader(column),
                Cell: ({ cell }) => skillActionsCell(cell),
                disableSortBy: true
            }
        ];
    }, [skills]);

    const tableInstance = useTable(
        {
            columns: colums,
            data: tableData
        },
        useSortBy
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <Table tableProps={getTableProps}>
            <Thead>
                {headerGroups.map((headerGroup) => {
                    return (
                        <Tr
                            key={v4()}
                            rowProps={headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return (
                                    <CellWrapper key={v4()}>
                                        {column.render("Header")}
                                    </CellWrapper>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Thead>
            <Tbody tbodyProps={getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <Tr
                            key={v4()}
                            rowProps={row.getRowProps()}
                            hover={true}>
                            {row.cells.map((cell) => {
                                return (
                                    <CellWrapper key={v4()}>
                                        {cell.render("Cell")}
                                    </CellWrapper>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};

export default SkillTable;
