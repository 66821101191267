import React from "react";
import {
    MainLayout,
    DataSourceSettingsActions,
    DataSourceSettingsContent,
    AccountSettingsNavigation
} from "ComponentIndex";

const DataSourceSettingsComponent = () => {
    return (
        <MainLayout
            navigation={<AccountSettingsNavigation />}
            action={<DataSourceSettingsActions />}
            content={<DataSourceSettingsContent />}
            workspace={"Account Settings"}
            title={"Account Settings"}
            activeTab={"data-source-settings"}
            logCode={"PDSPL"}
        />
    );
};

export default DataSourceSettingsComponent;
