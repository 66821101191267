import React from "react";
import { NavigationBar, TabBar, ActiveTab } from "ComponentIndex";

const SkillsNavigation = () => {
    return (
        <NavigationBar>
            <TabBar>
                <ActiveTab
                    id={"add-skills"}
                    linkTo={`/app/skills/add-skills`}
                    fieldValue={"addSkills"}>
                    {"Add Skills"}
                </ActiveTab>
                <ActiveTab
                    id={"skills-inventory"}
                    linkTo={`/app/skills/skills-inventory`}
                    fieldValue={"skillsInventory"}>
                    {"Skills inventory"}
                </ActiveTab>
            </TabBar>
        </NavigationBar>
    );
};

export default SkillsNavigation;
