import React, { useEffect, useCallback } from "react";

const BackDrop = ({ open, setOpen, children }) => {
    const handleEscClose = useCallback((e) => {
        if (e.key === "Escape") {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleEscClose, false);
        return () => {
            document.removeEventListener(
                "keydown",
                handleEscClose,
                false
            );
        };
    }, []);
    return (
        <>
            {open && (
                <div
                    onKeyPress={handleEscClose}
                    className={
                        "fixed top-0 left-0 w-screen h-screen z-30 backdrop-blur flex flex-col items-center justify-center bg-secondary-200/50"
                    }>
                    {children}
                </div>
            )}
        </>
    );
};

export default BackDrop;
