import React from "react";

const BlogReferanceHeader = ({ content }) => {
    return (
        <p
            className={
                "blog_label_medium font-semibold text-secondary-900 italic"
            }>
            {content.referancess.header[0]}
        </p>
    );
};

export default BlogReferanceHeader;
