import React, { useEffect } from "react";
import {
    MessagePage,
    ConditionalOutput,
    FeedbackSpaceInvitationCard,
    NoFeedbackSpaceInvitations
} from "ComponentIndex";
import { useQuery } from "@apollo/client";
import { GET_MY_FEEDBACK_SPACE_INVITATIONS_QUERY } from "GraphQL/Queries";
import { feedbackSpacePageVar } from "GraphQL/ReactiveVariables";
import { useLoadForTour } from "HookIndex";

const FeedbackSpaceInvitationsContent = () => {
    useEffect(() => {
        feedbackSpacePageVar("feedback-space-invitations");
    }, []);
    useLoadForTour(9, "setupTour", 8);
    useLoadForTour(2, "invitationTour", 1);

    const { data, error, loading } = useQuery(
        GET_MY_FEEDBACK_SPACE_INVITATIONS_QUERY,
        { variables: { statusList: ["INV"] } }
    );

    return (
        <ConditionalOutput error={error} loading={loading}>
            <div
                className={
                    "flex flex-col w-full lg:w-8/12 xl:w-6/12 2xl:w-4/12"
                }>
                {data && data.feedbackSpaceInvitations.length ? (
                    <div>
                        {data.feedbackSpaceInvitations.map(
                            (invitation) => {
                                return (
                                    <FeedbackSpaceInvitationCard
                                        key={`invitation${invitation.id}`}
                                        invitation={invitation}
                                        feedbackSpaceId={
                                            invitation.feedbackSpace.id
                                        }
                                    />
                                );
                            }
                        )}
                    </div>
                ) : (
                    <NoFeedbackSpaceInvitations />
                )}
            </div>
        </ConditionalOutput>
    );
};

export default FeedbackSpaceInvitationsContent;
