import React from "react";
import cn from "classnames";

const Icon = ({ name, size, color, title = null }) => {
    const iconClass = cn("text-base material-symbols-rounded", {
        "text-sm": size === "small",
        "text-base": size === "medium",
        "text-lg": size === "large",
        "text-2xl":size ==="2xlarge",
        "text-shocking-700": color === "primary",
        "text-shocking-400": color === "primaryLight",
        "text-white": color === "light",
        "text-secondary-900": color === "dark",
        "text-secondary-500": color === "grey",
        "text-blue-chill-700": color === "blue-chill",
        "text-matisse-700": color === "matisse",
        "text-la-palma-700": color === "la-palma",
        "text-old-gold-700":color === "old-gold",
        "text-thunderbird-700":color === "thunderbird"

    });
    return (
        <span title={title ? title : null} className={iconClass}>
            {name}
        </span>
    );
};

export default Icon;
