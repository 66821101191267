import React from "react";

import { v4 } from "uuid";

const TooltipData = ({ data, toolTipKey, toolTipValue }) => {
    return (
        <>
            {data.map((item) => {
                return (
                    <div
                        key={v4()}
                        className={
                            "flex justify-between w-full flex-row gap-x-4"
                        }>
                        <div className={"inline-block"}>
                            <p
                                className={
                                    "body_small text-secondary-900"
                                }>
                                {`${item[toolTipKey]}: `}
                            </p>
                        </div>
                        <div className={"inline-block"}>
                            <p
                                className={
                                    "body_small text-secondary-900"
                                }>
                                {item[toolTipValue]}
                            </p>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default TooltipData;
