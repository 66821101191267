import React from "react";
import cn from "classnames";

import { searchVar } from "GraphQL/ReactiveVariables";

const inputClass = cn([
    "body_medium outline-none text-white",
    "h-6 rounded-full  bg-transparent pl-9 pr-2",
    "placeholder:label_medium placeholder:text-shocking-50",
    "border border-shocking-50 focus_ring "
]);

const SearchBar = () => {
    let handleChanage = (e) => {
        searchVar(e.target.value);
    };

    return (
        <div className={"relative flex flex-row items-center"}>
            <span
                className={
                    "material-symbols-rounded text-shocking-50 text-sm absolute left-3"
                }>
                {"search"}
            </span>
            <input
                type={"text"}
                className={inputClass}
                placeholder={"Search"}
                onChange={handleChanage}
            />
        </div>
    );
};

export default SearchBar;
