import React from "react";
import { ActionBar, 
    DateRangePicker,
    AssigneeFilterIcon,
    ProjectsFilterIcon,
    TaskTypeFilterIcon,
    DataSourceFilterIcon
} from "ComponentIndex";


import {
    useAssigneeFilterData,
    useProjectFilterData,
    useTaskTypeFilterData,
    useDataSourceFilterData
} from "CustomHooks/CustomQueries";


const DashboardActions = () => {
    useAssigneeFilterData();
    useProjectFilterData();
    useTaskTypeFilterData();
    useDataSourceFilterData();

    return <ActionBar color={"dark"}>
            <DateRangePicker />
            <AssigneeFilterIcon />
            <ProjectsFilterIcon />
            <TaskTypeFilterIcon />
            <DataSourceFilterIcon />

    </ActionBar>;
};

export default DashboardActions;
