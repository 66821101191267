import React from "react";
import cn from "classnames";

import { Hr } from "ComponentIndex";

const Title = ({
    children,
    type = "title_large",
    textColor = "text-secondary-800",
    truncate = false,
    dividerColor = "primary",
    weight = "small",
    bottom = "mb-6"
}) => {
    const titleClass = cn([type, textColor], {
        "truncate ": truncate
    });
    return (
        <div className={bottom}>
            <h1 className={titleClass}>{children}</h1>
            <Hr color={dividerColor} weight={weight} />
        </div>
    );
};

export default Title;
