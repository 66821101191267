import React from "react";

import { IconButton, BackDrop } from "ComponentIndex";

const IconDialog = ({
    children,
    label,
    size,
    icon,
    variant,
    color,
    shadow,
    open,
    setOpen,
    title = ""
}) => {
    return (
        <>
            <IconButton
                title={title}
                variant={variant}
                size={size}
                icon={icon}
                label={label}
                color={color}
                shadow={shadow}
                onClick={(e) => {
                    setOpen(true);
                }}
            />
            <BackDrop open={open} setOpen={setOpen}>
                {children}
            </BackDrop>
        </>
    );
};

export default IconDialog;
