import { useState, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { useNavigate, useLocation } from "react-router-dom";
import {
    SET_USER_CURRENT_FEEDBACK_SPACE_MUTATION,
    CHANGE_ACCOUNT_NOTIFICATION_PREFERENCE,
    CHANGE_FEEDBACK_SPACE_NOTIFICATION_PREFERENCE,
    CREATE_FEEDBACK_SPACE_MUTATION,
    SET_ALL_ASSIGNEES_SYNC_STATUS_MUTATION,
    SET_ALL_PROJECT_SYNC_STATUS_MUTATION,
    GET_ATLASSIAN_AUTH_TOKEN_MUTATION,
    SET_USER_LOGS
} from "GraphQL/Mutations";
import {
    GET_MY_FEEDBACK_SPACES_QUERY,
    GET_PROJECT_QUERY,
    GET_ASSIGNEE_PARAMETERS_QUERY
} from "GraphQL/Queries";
import {
    currentFeedbackSpaceVar,
    userLogVar
} from "GraphQL/ReactiveVariables";

export const useCurrentFeedbackSpace = (feedbackSpace) => {
    let navigate = useNavigate();
    let location = useLocation();
    const [setPreferance, { loading, data, error }] = useMutation(
        SET_USER_CURRENT_FEEDBACK_SPACE_MUTATION,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id
            }
        }
    );
    useEffect(() => {
        if (data && data.setCurrentFeedbackSpace) {
            currentFeedbackSpaceVar({
                id: masterAcc(
                    data.setCurrentFeedbackSpace,
                    "userPreference",
                    "id"
                ),
                feedbackSpaceName: masterAcc(
                    data.setCurrentFeedbackSpace,
                    "userPreference",
                    "name"
                ),
                role: masterAcc(
                    data.setCurrentFeedbackSpace,
                    "userPreference",
                    "role"
                )
            });
            if (
                location.pathname ===
                "/app/message-boards/no-feedback-space"
            ) {
                navigate("/app");
            }
        } else if (data && data.setCurrentFeedbackSpace == null) {
            currentFeedbackSpaceVar({
                id: null,
                feedbackSpaceName: "",
                role: ""
            });
        } else if (error) {
        }
    }, [data]);

    const setPref = () => {
        setPreferance()
            .then((data) => {
                // window.location.reload();
            })
            .catch((e) => {
                navigate(
                    "/app/message-boards/feedback-space-not-availble"
                );
            });
    };

    return { setPref, error, loading, data };
};

export const useNotificationPrefChange = (
    state,
    variables,
    parameter
) => {
    const [switchState, setSwitchState] = useState(state);
    const mutation = selectMutation(parameter);
    const [changePref] = useMutation(mutation, {
        variables: { parameter: !switchState, ...variables },
        fetchPolicy: "network-only"
    });
    const handleSwitchChange = () => {
        setSwitchState(!switchState);
        changePref();
    };
    return [switchState, handleSwitchChange];
};

const selectMutation = (parameter) => {
    if (parameter === "acc") {
        return CHANGE_ACCOUNT_NOTIFICATION_PREFERENCE;
    } else if (parameter === "fb") {
        return CHANGE_FEEDBACK_SPACE_NOTIFICATION_PREFERENCE;
    }
};

export const useCreateFeedback = () => {
    const [createFeedbackSpace, { data, error, loading }] = useMutation(
        CREATE_FEEDBACK_SPACE_MUTATION,
        { refetchQueries: [{ query: GET_MY_FEEDBACK_SPACES_QUERY }] }
    );
    return [createFeedbackSpace, error, loading];
};

export const parameterSwitch = (parameter) => {
    switch (parameter) {
        case "assigneesParameters":
            return {
                instruction: SET_ALL_ASSIGNEES_SYNC_STATUS_MUTATION,
                variableName: "assigneeSyncStatus",
                refetchQuery: GET_ASSIGNEE_PARAMETERS_QUERY
            };
        case "projectsSync":
            return {
                instruction: SET_ALL_PROJECT_SYNC_STATUS_MUTATION,
                variableName: "projectSyncStatus",
                refetchQuery: GET_PROJECT_QUERY
            };
        default:
            return null;
    }
};

export const useMassParameterSwitch = (parameter, feedbackSpaceId) => {
    const [select, setSelect] = useState(true);
    const params = parameterSwitch(parameter);

    let [changeSync] = useMutation(params.instruction, {
        refetchQueries: [
            {
                query: params.refetchQuery,
                variables: { feedbackSpaceId: feedbackSpaceId }
            }
        ]
    });

    const handleChangeAll = (e) => {
        changeSync({
            variables: {
                feedbackSpaceId: feedbackSpaceId,
                [params.variableName]: !select
            }
        });
        setSelect(!select);
    };

    return [select, handleChangeAll];
};

export const useSendLog = () => {
    let [sendLogs] = useMutation(SET_USER_LOGS);

    useInterval(() => {
        let userLogs = userLogVar();
        // console.log("LOGS", userLogVar());
        if (userLogs.length) {
            sendLogs({
                variables: { logRecords: userLogs },
                onCompleted: () => {
                    userLogVar([]);
                }
            });
        } else {
            // console.log("Logs Cleared", userLogs);
        }
    }, 30000);
};

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};
