import React, { useEffect } from "react";
import { skillsPageVar } from "GraphQL/ReactiveVariables";
import { useLoadForTour } from "HookIndex";
import {
    AddSkill,
    SkillCathegoryText,
    SkillExamples,
    Card
} from "ComponentIndex";

const AddSkillsContent = () => {
    useEffect(() => {
        skillsPageVar("addSkills");
    }, []);

    useLoadForTour(1, "manageSkillsTour", 0);
    useLoadForTour(3, "manageSkillsTour", 4);

    return (
        <div
            className={
                "flex flex-col w-full gap-4 md:w-10/12 xl:w-8/12 2xl:w-4/12"
            }>
            <AddSkill />
            <div
                id={"skill-examples"}
                className={
                    "flex flex-col gap-4 w-full md:flex-row md:justify-between"
                }>
                <div className={"w-full md:w-6/12"}>
                    <Card type={"light"}>
                        <SkillCathegoryText />
                    </Card>
                </div>
                <div className={"w-full md:w-6/12"}>
                    <Card type={"light"}>
                        <SkillExamples />
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default AddSkillsContent;
