import { useEffect } from "react";
import  {useMutation}  from "@apollo/client";
import {INITIATE_OPEN_ID} from "GraphQL/Mutations"

export const useGetOpenIDLink = (openIdIdentifier) => {
    const [makeLinkRequest,{data, error, loading}] = useMutation(INITIATE_OPEN_ID,{variables:{
        openIdIdentifier:openIdIdentifier
    }})
    useEffect(()=>{
        if (data){
            window.location.replace(data.initiateOpenId.openIdLink)

        }
    },[loading])

    return {makeLinkRequest,error,loading};
};
