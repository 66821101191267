import React from "react";
import { NavigationBar, TabBar, ActiveTab } from "ComponentIndex";

const AccountSettingsNavigation = () => {
    return (
        <NavigationBar>
            <TabBar>
                <ActiveTab
                    id={"data-source"}
                    linkTo={
                        "/app/account-settings/data-source-settings"
                    }
                    fieldValue={"data-source-settings"}>
                    {"Data Sources"}
                </ActiveTab>
                <ActiveTab
                    id={"feedback-spaces-tab"}
                    linkTo={
                        "/app/account-settings/manage-feedback-spaces"
                    }
                    fieldValue={"manage-feedback-space"}>
                    {"Feedback Spaces"}
                </ActiveTab>
                <ActiveTab
                    id={"invitations-tab"}
                    linkTo={`/app/account-settings/invitations`}
                    fieldValue={"feedback-space-invitations"}>
                    {"Invitations"}
                </ActiveTab>
            </TabBar>
        </NavigationBar>
    );
};

export default AccountSettingsNavigation;
