import cn from "classnames";

const LabelWithData = ({ label, data, align = "right" }) => {
    const labelDataClass = cn([
        "flex  flex-col justify-between",
        {
            "items-end": align === "left",
            "items-start": align === "right"
        }
    ]);
    return (
        <div className={labelDataClass}>
            <p className={"label_small text-shocking-700"}>{label}</p>
            <p className={"body_small text-secondary-900"}>{data}</p>
        </div>
    );
};

export default LabelWithData;
