import React, { useState, useEffect } from "react";
import reduxActions from "State/ActionIndex";
import { Select } from "ComponentIndex";
import { skillTypes, skillTypesInverse } from "frontendSettings";
const ITEMS = [
    "Interpersonal skills",
    "Area specific skills",
    "Technical skills",
    "Management skills"
];

const SelectSkillType = ({ state, setState }) => {
    const [selected, setSelected] = useState(
        skillTypesInverse[state.skillType]
    );

    useEffect(() => {
        setState({ ...state, skillType: skillTypes[selected] });
        reduxActions.setSkillTypeContent(skillTypes[selected]);
    }, [selected]);

    return (
        <Select
            scale={"strech"}
            items={ITEMS}
            setSelected={setSelected}
            selected={selected}
        />
    );
};

export default SelectSkillType;
