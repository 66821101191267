import { HttpLink, fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GRAPHQL_URI } from "frontendSettings";
import { refreshToken } from "GraphQL/RefreshToken";
import { REFRESH_TOKEN } from "GraphQL/Mutations";
import { isLoggedInVar } from "GraphQL/ReactiveVariables";

export let ErrorTriajLink = new onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
        console.log("e-triaj");
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                console.log("ERRORS LINK",err.message)
                switch (err.message) {
                    case "Not logged in":
                        console.log("tr-1");
                        return fromPromise(
                            refreshToken(GRAPHQL_URI, REFRESH_TOKEN)
                                .then((data) => {
                                    console.log("gt-1");
                                })
                                .catch((e) => {
                                    console.log("on token refresh:", e);
                                    isLoggedInVar(false);
                                })
                        )
                            .filter((value) => {
                                console.log("Response value ",value);
                                return true;
                            })
                            .flatMap(() => {
                                // console.log("Got Token");
                                return forward(operation);
                            });
                    case "You do not have permission to perform this action":
                        console.log("tr-2");
                        return fromPromise(
                            refreshToken(GRAPHQL_URI, REFRESH_TOKEN)
                                .then((data) => {
                                    console.log("gt-1");
                                })
                                .catch((e) => {
                                    console.log("on token refresh:", e);
                                    isLoggedInVar(false);
                                })
                        )
                            .filter((value) => {
                                console.log(value);
                                return true;
                            })
                            .flatMap(() => {
                                console.log("gt-3");
                                return forward(operation);
                            });
                    default:
                        console.log("Default");
                        return fromPromise(
                            refreshToken(GRAPHQL_URI, REFRESH_TOKEN)
                                .then((data) => {
                                    console.log("gt-1");
                                })
                                .catch((e) => {
                                    console.log("on token refresh:", e);
                                    isLoggedInVar(false);
                                })
                        )
                            .filter((value) => {
                                console.log(value);
                                return true;
                            })
                            .flatMap(() => {
                                console.log("gt-3");
                                return forward(operation);
                            });

                }
            }
        }
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }
    }
);

export let TerminalLink = new HttpLink({
    uri: GRAPHQL_URI,
    credentials: "include"
});
