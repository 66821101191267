import React from "react";

const BlogParagraphHeader = ({ children }) => {
    return (
        <h4
            className={
                "pb-4 pt-2 blog_display_medium my-10 text-secondary-800 max-w-xl"
            }>
            {children}
        </h4>
    );
};

export default BlogParagraphHeader;
