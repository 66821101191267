import React from "react";

const PageToScreenWrapper = ({ children }) => {
    return (
        <div
            className={
                "h-[calc(100vh_-_11.3rem)] flex flex-col items-center"
            }>
            {children}
        </div>
    );
};

export default PageToScreenWrapper;
