import React, { useState, useEffect, useRef } from "react";
import { useChartWrapperDimensions } from "HookIndex";

import {
    generateScatterPlotData,
    generateVarianceGrid,
    generateValianceLabels
} from "./UtilityFunctions";
import {
    linearScale,
    satisfactionRangeGrid,
    satisfactionRangeAxis
} from "UtilityFunctionIndex";

import AvgRangeChartSVG from "./AvgRangeChartSVG";

const MARGINS = {
    top: 30,
    right: 10,
    bottom: 30,
    left: 100
};

const AvgRangeChart = ({
    data,
    xAccessor,
    yAccessor,
    chartName,
    yAxisLabel,
    xAxisLabel,
    nameItem
}) => {
    const [graphs, setGraphs] = useState({ plotData: null });
    const wrapperRef = useRef();

    const [dimensions, processed] = useChartWrapperDimensions(
        wrapperRef,
        MARGINS
    );

    useEffect(() => {
        if (processed) {
            const yScale = linearScale(
                0,
                100,
                dimensions.boundedHeight,
                0
            );

            const xScale = linearScale(
                0,
                100,
                0,
                dimensions.boundedWidth
            );

            const yGridCoords = generateVarianceGrid(yScale);

            const yAxis = generateValianceLabels(yScale);

            const xGridCoords = satisfactionRangeGrid(xScale);

            const xAxis = satisfactionRangeAxis(xScale, "x");

            const scatterData = generateScatterPlotData(
                data,
                xScale,
                yScale,
                xAccessor,
                yAccessor,
                nameItem,
                20
            );

            setGraphs({
                ...graphs,
                plotData: scatterData,
                chartName: chartName,
                yAxisLabel: yAxisLabel,
                xAxisLabel: xAxisLabel,
                xGrid: xGridCoords,
                yGrid: yGridCoords,
                xAxis: xAxis,
                yAxis: yAxis,
                dimensions: { ...dimensions }
            });
        }
    }, [data, dimensions]);

    return <AvgRangeChartSVG graphs={graphs} ref={wrapperRef} />;
};

export default AvgRangeChart;
