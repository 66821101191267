import React from "react";
import { isOwner, isAdmin, isMember } from "UtilityFunctionIndex";

const DetermineRole = ({ feedbackSpace }) => {
    return (
        <>
            <span className={"label_medium text-shocking-700"}>
                {"Your role: "}
            </span>
            {isOwner(feedbackSpace) ? (
                <span className={"body_medium text-secondary-900"}>
                    Owner
                </span>
            ) : null}
            {isAdmin(feedbackSpace) ? (
                <span className={"body_medium text-secondary-900"}>
                    Admin
                </span>
            ) : null}
            {isMember(feedbackSpace) ? (
                <span className={"body_medium text-secondary-900"}>
                    Member
                </span>
            ) : null}
        </>
    );
};

export default DetermineRole;
