import React from "react";
import { RouterLink, IconButton } from "ComponentIndex";

const DrawerFeedbackSpaceSettings = () => {
    return (
        <RouterLink to={"/app/account-settings/manage-feedback-spaces"}>
            <IconButton
                title={"Feedback space settings"}
                icon={"settings"}
                color={"primaryLight"}
                variant={"standard"}
                shadow={false}
                size={"small"}
            />
        </RouterLink>
    );
};

export default DrawerFeedbackSpaceSettings;
