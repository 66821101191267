import React from "react";
import cn from "classnames";

const Td = ({
    children,
    cellProps,
    overflow = false,
    title = null
}) => {
    const tdClass = cn("py-1 px-2 body_small text-secondary-900", {
        "whitespace-nowrap overflow-hidden text-ellipsis max-w-0":
            overflow === true
    });
    return (
        <td
            title={title ? title : null}
            className={tdClass}
            {...cellProps}>
            {children}
        </td>
    );
};

export default Td;
