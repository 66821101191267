import {
    PublicSection,
    ContentWrapperBand,
    TextPictureRight,
    TextPictureLeft,
    SolutionText,
    SolutionHeader
} from "ComponentIndex";

import { landingContentVar } from "GraphQL/ReactiveVariables";

import IncrementalImprovement from "Images/OurApproach/incremental_improvement.svg";
import TimeTrinity from "Images/OurApproach/time_trinity.svg";
import LeanImprovement from "Images/OurApproach/lean_improvement.png";
import RelevantTasks from "Images/OurApproach/tasks_sync.svg";
import SkillPerson from "Images/OurApproach/skill_person.svg";
import {
    CognitiveBiasesInPerformanceArticleLink,
    ImpactfullFeedbackArticleLink,
    ReviewEmbraceEmployeesEffortsArticleLink,
    TaskBasedFeedbackArticleLink
} from "BlogLinkIndex";

const OurApproach = () => {
    const content = landingContentVar().OurApproach;
    return (
        <ContentWrapperBand color={"bg-white"}>
            <PublicSection
                verticalHeader={content.verticalHeader}
                horizontalHeader={content.horizontalHeader}
                id={"solution"}>
                <div className={"flex flex-col gap-y-28  items-center"}>
                    <div className={"self-start"}>
                        <h1
                            className={
                                "display_small text-shocking-700"
                            }>
                            {content.eliminateFrictionHeader[0]}
                        </h1>
                    </div>
                    <TextPictureRight
                        text={
                            <>
                                <SolutionHeader>
                                    {content.monitorsTheStatus[0]}
                                    <a
                                        className={"underline"}
                                        href={
                                            ImpactfullFeedbackArticleLink
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        {content.monitorsTheStatus[1]}
                                    </a>
                                </SolutionHeader>
                                <SolutionText>
                                    {content.monitorsTheStatus[2]}
                                </SolutionText>
                            </>
                        }>
                        <img
                            className={"w-60 md:w-48"}
                            src={RelevantTasks}
                            alt={content.relevantTasksAlt[0]}
                        />
                    </TextPictureRight>
                    <TextPictureLeft
                        text={
                            <>
                                <SolutionHeader>
                                    {content.provideJustInTime[0]}
                                    <a
                                        className={"underline"}
                                        href={
                                            TaskBasedFeedbackArticleLink
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        {content.provideJustInTime[1]}
                                    </a>
                                    {content.provideJustInTime[2]}
                                    <a
                                        className={"underline"}
                                        href={
                                            CognitiveBiasesInPerformanceArticleLink
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        {content.provideJustInTime[3]}
                                    </a>
                                    {content.provideJustInTime[4]}
                                </SolutionHeader>
                                <SolutionText>
                                    {content.provideJustInTime[5]}
                                </SolutionText>
                            </>
                        }>
                        <img
                            className={"w-60 lg:w-48"}
                            src={TimeTrinity}
                            alt={content.timeTrinityAlt[0]}
                        />
                    </TextPictureLeft>

                    <div className={"self-start"}>
                        <h1
                            className={
                                "display_small text-shocking-700"
                            }>
                            {content.enableIncrementalHeader[0]}
                        </h1>
                    </div>

                    <TextPictureRight
                        text={
                            <>
                                <SolutionHeader>
                                    {content.collectFrequent[0]}
                                    <a
                                        className={"underline"}
                                        href={
                                            TaskBasedFeedbackArticleLink
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        {content.collectFrequent[1]}
                                    </a>
                                    {content.collectFrequent[2]}
                                </SolutionHeader>
                                <SolutionText>
                                    {content.collectFrequent[3]}
                                </SolutionText>
                            </>
                        }>
                        <img
                            className={"w-60 lg:w-48"}
                            src={IncrementalImprovement}
                            alt={content.incrementalImprovementAlt[0]}
                        />
                    </TextPictureRight>

                    <TextPictureLeft
                        text={
                            <>
                                <SolutionHeader>
                                    {content.quicklyIdentify[0]}
                                </SolutionHeader>
                                <SolutionText>
                                    {content.quicklyIdentify[1]}
                                </SolutionText>
                            </>
                        }>
                        <img
                            className={"w-60 lg:w-48"}
                            src={SkillPerson}
                            alt={content.skillPersonAlt[0]}
                        />
                    </TextPictureLeft>

                    <TextPictureRight
                        text={
                            <>
                                <SolutionHeader>
                                    {
                                        content
                                            .applyComprehensiveInsights[0]
                                    }
                                    <a
                                        className={"underline"}
                                        href={
                                            ReviewEmbraceEmployeesEffortsArticleLink
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        {
                                            content
                                                .applyComprehensiveInsights[1]
                                        }
                                    </a>
                                    {
                                        content
                                            .applyComprehensiveInsights[2]
                                    }
                                </SolutionHeader>
                                <SolutionText>
                                    {
                                        content
                                            .applyComprehensiveInsights[3]
                                    }
                                </SolutionText>
                            </>
                        }>
                        <img
                            className={"w-60 lg:w-48"}
                            src={LeanImprovement}
                            alt={content.leanImprovementAlt[0]}
                        />
                    </TextPictureRight>
                </div>
            </PublicSection>
        </ContentWrapperBand>
    );
};

export default OurApproach;
