import React from "react";

import {
    MainLayout,
    SkillsNavigation,
    SkillsInventoryContent,
    SkillsInventoryActions
} from "ComponentIndex";

const SkillsInventoryComponent = () => {
    return (
        <MainLayout
            navigation={<SkillsNavigation />}
            action={<SkillsInventoryActions />}
            content={<SkillsInventoryContent />}
            workspace={"Skills Management"}
            title={"Skills Management"}
            activeTab={"skillsInventory"}
            logCode={"PSIPL"}
        />
    );
};

export default SkillsInventoryComponent;
