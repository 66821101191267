import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import reduxActions from "State/ActionIndex";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    Loading,
    Button,
    Input,
    Card,
    Title,
    ConditionalOutput,
    ContentWrapperBand,
    PageToScreenWrapper,
    HelmetExclude
} from "ComponentIndex";
import { RESEND_EMAIL_VERIFICATION_MUTATION } from "GraphQL/Mutations";

let defaultFormState = {
    email: ""
};

const ResendEmailVerificationContent = () => {
    const [formState, setFormState] = useState(defaultFormState);
    const [resendConfirmation, { loading, error }] = useMutation(
        RESEND_EMAIL_VERIFICATION_MUTATION,
        {
            onCompleted: () => {
                reduxActions.addNotification(
                    "An email has been sent. Please check your mailbox.",
                    "success"
                );
                return <Link to='/login'>Login Page</Link>;
            }
        }
    );
    workspaceNameVar("Resend Confirmation");

    const handleFieldChange = (e) => {
        setFormState({ ...formState, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        resendConfirmation({
            variables: {
                email: formState.email
            }
        })
            .then((data) => {})
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Card
                        type={"light"}
                        strech={false}
                        elevation={"lg"}>
                        <div className={"flex flex-col w-80 gap-y-4"}>
                            <Title>Repeat account verification</Title>
                            <h3 className={"title_small"}>
                                Your account is not verified yet. Please
                                type in the email address that you used
                                to create your account.
                            </h3>
                            <ConditionalOutput
                                error={error}
                                loading={loading}>
                                <form
                                    className={
                                        "flex flex-col w-80 gap-y-4"
                                    }
                                    onSubmit={handleSubmit}>
                                    <div className={"w-full"}>
                                        <Input
                                            id={"email"}
                                            type={"email"}
                                            onChange={handleFieldChange}
                                            value={formState.email}
                                            placeholder={"Email"}
                                            required={true}
                                        />
                                    </div>
                                    <div className={"self-end"}>
                                        {loading && <Loading />}
                                        {!loading && (
                                            <Button
                                                variant={"filled"}
                                                type={"submit"}>
                                                Resend
                                            </Button>
                                        )}
                                    </div>
                                </form>
                            </ConditionalOutput>
                            <div>
                                <span
                                    className={
                                        "label_medium text-secondary-900"
                                    }>
                                    Don't have an account?{" "}
                                </span>
                                <Link to='/register'>
                                    <span
                                        className={
                                            "label_medium text-shocking-700"
                                        }>
                                        Register
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </Card>
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default ResendEmailVerificationContent;
