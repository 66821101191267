import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import {
    useBoundingRect,
    useWindowSize,
    useClickOutside
} from "HookIndex";

const filterTags = (data, value) => {
    /* used to search inside the list of tags */
    if (value) {
        return data.filter((item) => {
            return item.toLowerCase().includes(value.toLowerCase());
        });
    } else {
        return data;
    }
};

const createData = (data, accessorFunction) => {
    return data.map(accessorFunction);
};

const textAreaClass = cn([
    "w-full placeholder:text-secondary-300",
    "text-sm placeholder:text-xs border focus:border-shocking-400",
    "border-secondary-500 rounded-md p-2 focus:ring-1",
    "focus:ring-inset focus:border-shocking-800",
    "focus:ring-shocking-700 outline-none text-secondary-900"
]);

const dropDownListItemClass = cn([
    "cursor-pointer text-secondary-900 ",
    "text-xs font-normal rounded-md p-2"
]);

const noDataListItemClass = cn([
    "cursor-pointer text-secondary-900",
    "text-xs font-normal rounded-md p-2"
]);

const dropDownMenuClass = cn([
    "absolute top-12 left-0 z-10 rounded-md",
    "bg-white w-full shadow-md placeholder:text-secondary-300",
    "placeholder:text-base border border-secondary-100",
    "py-2 px-3 flex flex-col gap-y-2 max-h-[20vh] overflow-hidden"
]);

const TextWithTags = ({
    textValue,
    setTextValue,
    placeholder,
    rows,
    maxLength,
    tagData,
    dataAccessor,
    emptyMessage = "No data",
    parentRef
}) => {
    const textAreaRef = useRef(); //used to maintain focus in the area field
    const dropDownRef = useRef();
    const windowSize = useWindowSize();
    const [tagValue, setTagValue] = useState(""); // valua of the tag
    const [tagStartIndex, setTagStartIndex] = useState(null); //index closes to cursor # simbol is situated
    const [tagEndIndex, setTagEndIndex] = useState(0); // index where the closest to cursor " " is situated
    const [cursor, setCursor] = useState(null); // index of the cursor in the text area
    const [openMenu, setOpenMenu] = useState(false); //opened closed menu with tags
    const [selectedItemIndex, setSelectedItemIndex] = useState(0); // order number of he item selected
    const [data, setData] = useState(createData(tagData, dataAccessor)); //Data with tags

    const [menuBase, setMenuBase] = useState(0);
    const [menuPosition, setMenuPosition] = useState(0); //used to position the element of dropdown

    const [visibility, setVisibility] = useState("hidden");
    let { coords: dropDownCoords, setCoord: setDropDownCoords } =
        useBoundingRect(dropDownRef);
    let { coords: parentCoords } = useBoundingRect(parentRef);

    let { coords: textAreaCoords } = useBoundingRect(textAreaRef);

    useClickOutside(dropDownRef, setOpenMenu);

    // Changing extracting dropdown coordinates when number
    // of items in the list changes
    useEffect(() => {
        if (openMenu) {
            setVisibility("visible");
        } else {
            setVisibility("hidden");
        }
    }, [openMenu]);

    useEffect(() => {
        if (
            textAreaCoords.bottom + dropDownCoords.height >
            parentCoords.bottom
        ) {
            setMenuBase(0);
        } else {
            setMenuBase(textAreaCoords.height + 20);
        }
    }, [windowSize]);

    useEffect(() => {
        setDropDownCoords();
    }, [data]);

    useEffect(() => {
        if (menuBase) {
            setMenuPosition(menuBase);
        } else {
            setMenuPosition(menuBase - dropDownCoords.height);
        }
    }, [dropDownCoords, openMenu]);

    useEffect(() => {
        // setting up value of a tag
        if (tagStartIndex > tagEndIndex) {
            setOpenMenu(true);
            setTagValue(textValue.slice(tagStartIndex + 1, cursor));
        } else {
            resetTagMenu();
        }
    }, [textValue, tagValue]);

    // filtering the list of data when entering #
    useEffect(() => {
        // used original data, if you filter several time
        // on stated data it will not find excluded data
        setData(
            filterTags(createData(tagData, dataAccessor), tagValue)
        );
    }, [tagValue]);

    // plasing cursor ath the end of line or between lines if tag is in
    // the middle of text
    useEffect(() => {
        textAreaRef.current.setSelectionRange(cursor, cursor);
    }, [cursor]);

    const resetTagMenu = () => {
        /* Removes metrics and coordinates 
            related to tag that was inserted and
            focuses text area*/
        setMenuPosition(menuBase);
        setTagValue("");
        setTagStartIndex(null);
        setTagEndIndex(null);
        setOpenMenu(false);
        textAreaRef.current.focus();
    };

    const createStringWithTag = (string) => {
        let resultString =
            textValue.slice(0, tagStartIndex + 1) +
            string +
            " " +
            textValue.slice(textAreaRef.current.selectionStart);
        setCursor(
            textAreaRef.current.selectionStart + string.length + 1
        );

        return resultString;
    };

    const handleTextInputChange = (e) => {
        if (e.target.value.length <= maxLength) {
            setTextValue(e.target.value);
        } else {
            setTextValue(e.target.value.slice(0, maxLength));
        }
        setCursor(e.target.selectionStart);

        setTagStartIndex(
            e.target.value.lastIndexOf("#", e.target.selectionStart)
        );
        setTagEndIndex(
            e.target.value.lastIndexOf(" ", e.target.selectionStart - 1)
        );
    };

    const handleItemSelectClick = (e) => {
        setTextValue(createStringWithTag(e.target.textContent));

        resetTagMenu();
    };

    const handleKeyboardSelect = (e) => {
        if (
            openMenu &&
            e.key === "Enter" &&
            typeof data[selectedItemIndex] != "undefined"
        ) {
            e.preventDefault();
            setTextValue(createStringWithTag(data[selectedItemIndex]));
            resetTagMenu();
        } else if (openMenu && e.key === "ArrowDown") {
            e.preventDefault();
            if (selectedItemIndex > data.length - 2) {
                setSelectedItemIndex(0);
            } else {
                setSelectedItemIndex(selectedItemIndex + 1);
            }
        } else if (openMenu && e.key === "ArrowUp") {
            e.preventDefault();
            if (selectedItemIndex === 0) {
                setSelectedItemIndex(data.length - 1);
            } else {
                setSelectedItemIndex(selectedItemIndex - 1);
            }
        }
    };

    return (
        <div className={"relative"}>
            <textarea
                ref={textAreaRef}
                onKeyDown={handleKeyboardSelect}
                className={textAreaClass}
                value={textValue}
                onChange={handleTextInputChange}
                placeholder={placeholder}
                rows={rows}
                spellCheck={true}
                maxLength={maxLength}></textarea>

            <ul
                ref={dropDownRef}
                className={dropDownMenuClass}
                style={{
                    top: menuPosition ? menuPosition : 0,
                    visibility: visibility
                }}>
                {data.length ? (
                    data.map((item, index) => {
                        return (
                            <li
                                className={dropDownListItemClass}
                                onClick={handleItemSelectClick}
                                tabIndex={0}
                                onMouseOver={(e) =>
                                    setSelectedItemIndex(index)
                                }
                                key={`tagItem${index}`}
                                style={
                                    selectedItemIndex === index
                                        ? {
                                              backgroundColor:
                                                  "#9A2472",
                                              color: "#ffffff",
                                              textShadow:
                                                  " 0 1px 2px rgba(0, 0, 0, 0.9)"
                                          }
                                        : {}
                                }>
                                {item}
                            </li>
                        );
                    })
                ) : (
                    <li className={noDataListItemClass}>
                        {emptyMessage}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default TextWithTags;
