import React from "react";
import classnames from "classnames";
import cls from "./Loading.module.css";

import { Loader } from "ComponentIndex";
const Loading = ({
    label,
    size = "medium",
    color = "primary",
    loadingTitle = ""
}) => {
    const loadingClass = classnames(cls.loading);
    const loadingLabelClass = classnames(cls.loadingLabel);
    const loaderContainerClass = classnames(cls.loaderContainer);
    return (
        <div className={loaderContainerClass}>
            <div className={loadingClass}>
                <Loader
                    size={size}
                    color={color}
                    loadingTitle={loadingTitle}
                />
                {label && (
                    <p className={loadingLabelClass}>
                        <p className={"label_medium text-shocking-700"}>
                            {label}
                        </p>
                    </p>
                )}
            </div>
        </div>
    );
};

export default Loading;
