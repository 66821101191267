import React, { useState } from "react";
import { ButtonDialog, ManageMembers } from "ComponentIndex";

const ManageMembersButton = ({ feedbackSpace }) => {
    const [openManage, setOpenManage] = useState(false);

    return (
        <ButtonDialog
            id={"manage-roles"}
            label={"Manage"}
            open={openManage}
            setOpen={setOpenManage}
            icon={"manage_accounts"}
            variant={"outline"}>
            <ManageMembers
                feedbackSpaceId={feedbackSpace.id}
                setOpen={setOpenManage}
            />
        </ButtonDialog>
    );
};

export default ManageMembersButton;
