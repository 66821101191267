import React from "react";
import cn from "classnames";
import { useSelect } from "downshift";
import {
    Icon,
    DownshiftItemList,
    DownshiftEmptyItem,
    DownshiftListItem
} from "ComponentIndex";

const buttonClass = cn([
    "w-full placeholder:text-secondary-300 h-9",
    "placeholder:label_large border border-secondary-500",
    "rounded py-2 px-2 focus_ring label_large",
    "outline-none text-secondary-900 flex flex-row justify-between"
]);

const Select = ({
    items,
    selected = null,
    setSelected,
    labelName,
    itemsToString
}) => {
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps
    } = useSelect({
        items,
        initialSelectedItem: selected,
        onSelectedItemChange: (changes) => {
            setSelected(changes.selectedItem);
        },
        itemsToString: itemsToString
    });

    return (
        <div className={"relative w-full"}>
            <label {...getLabelProps()}>
                <p className={"label_small text-secondary-900"}>
                    {labelName}
                </p>
            </label>
            <button
                className={buttonClass}
                variant={"positive"}
                type='button'
                {...getToggleButtonProps()}>
                {selectedItem || "---"}
                <Icon size={"small"} name={"arrow_drop_down"} />
            </button>
            <DownshiftItemList
                position={"absolute top-10 w-full"}
                menuProps={getMenuProps}
                isOpen={isOpen}>
                {isOpen
                    ? items.map((item, index) => (
                          <DownshiftListItem
                              key={`${item}${index}`}
                              title={item}
                              item={item}
                              index={index}
                              itemProps={getItemProps}
                              highlightedIndex={highlightedIndex}>
                              {item}
                          </DownshiftListItem>
                      ))
                    : null}
            </DownshiftItemList>
        </div>
    );
};

export default Select;
