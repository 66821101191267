const CREATED_USING_CANVA = "Created using Canva";

const CAT_PERFORMANCE_REVIEWS = "Performance Reviews";
const CAT_CONTINUOUS_IMPROVEMENT = "Continuous Improvement";
const CAT_SKILLS_DEVELOPMENT = "Skills Development";
const CAT_PRODUCTIVITY = "Productivity";
const CAT_CONTINUOUS_FEEDBACK = "Continuous Feedback";
const CAT_MICRO_FEEDBACK = "Micro Feedback";
const CAT_FEEDBACK = "Feedback";
const CAT_EMPLOYEE_ENGAGEMET = "Employee Engagement";
const CAT_PERFORMANCE_MANAGEMENT = "Performance Management";
const CAT_PROCESS_VARIATION = "Process Variation";
const CAT_COGNITIVE_BIAS = "Cognitive Bias";
const CAT_HR = "HR";

export const contentBlogEng = {
    BlogIndex: {
        metaTitle: [
            "ENAMPLY | Resources about our thought process and approach to feedback and performance reviews"
        ],
        metaDescription: [
            "Articles and thoughts about the productivity management and how feedback and performance reviews can be the tools to help people improve the system in which they work."
        ],
        metaKeywords: [
            "performance reviews, continuous feedback, micro-feedback, skills management, productivity, team management, employee engagement, feedback, bias, career planning, development plan, task execution, task-based feedback, planning, cognitive bias, evaluation, assessment, incremental improvement"
        ],
        SystemicProbemsArticle: {
            alt: [
                "three dimetional objets o different height, base form of whici is hexagon"
            ],
            title: ["Systemic problems with Performance reviews"],
            subTitle: [
                "Six main issues that make employee performance evaluations inefficient and often harmful."
            ],
            categories: [
                CAT_PERFORMANCE_REVIEWS,
                CAT_PERFORMANCE_MANAGEMENT,
                CAT_EMPLOYEE_ENGAGEMET,
                CAT_FEEDBACK,
                CAT_SKILLS_DEVELOPMENT
            ]
        },
        ImpactfullFeedbackArticle: {
            alt: ["drawing of people taking care of plants"],
            title: ["How to make micro-feedback truly impactful?"],
            subTitle: ["It can't be all positive."],
            categories: [
                CAT_PERFORMANCE_REVIEWS,
                CAT_MICRO_FEEDBACK,
                CAT_CONTINUOUS_FEEDBACK,
                CAT_PRODUCTIVITY,
                CAT_SKILLS_DEVELOPMENT
            ]
        },
        TaskBasedFeedbackArticle: {
            alt: [
                "In the bottom left corner is a loop with three droplets that follow each other representing the task-based feedback loop: Perform Task, Analyze Feedback, Upgrade Skill. Parallel to the “skill” droplet, a circle represents an adjacent element that refers to Adjust Plan (development plans). On the upper right corner 4 feedback loops, one from another, increase performance, while conventional periodical performance reviews have a limited and late effect on performance growth."
            ],
            title: [
                "Task-based feedback — The continuous improvement technique."
            ],
            subTitle: [
                "One of the most valuable yet most ignored organizational habit."
            ],
            categories: [
                CAT_PERFORMANCE_REVIEWS,
                CAT_CONTINUOUS_IMPROVEMENT,
                CAT_SKILLS_DEVELOPMENT,
                CAT_PRODUCTIVITY,
                CAT_CONTINUOUS_FEEDBACK
            ]
        },
        CognitiveBiasesInPerformanceArticle: {
            alt: [
                "A gradient with black on the left side and light purple on the right side. It represents the nuanced world of performance reviews and human-to-human evaluation. Cognitive biases make us perceive the world around us differently."
            ],
            title: [
                "How nuanced are the effects of cognitive biases in performance reviews?"
            ],
            subTitle: [
                "An overview of our mental shortcuts and how to manage them through feedback."
            ],
            categories: [
                CAT_PERFORMANCE_REVIEWS,
                CAT_CONTINUOUS_FEEDBACK,
                CAT_PRODUCTIVITY,
                CAT_FEEDBACK,
                CAT_COGNITIVE_BIAS
            ]
        },
        ReviewEmbraceEmployeesEffortsArticle: {
            alt: [
                "A timeline graph with five data points, three of which are blue and represent predictable results in the expected range, and two are orange and represent unexpected results. On the right side of the image, a person shows a pie chart illustrating the data points from the timeline chart. The image suggests that a process, a human action, registered different results from the expected trend line and that variation is inherent."
            ],
            title: [
                "How to ensure that performance reviews embrace all the employee’s efforts?"
            ],
            subTitle: [
                "Acknowledge the variation in human activity and explore it through feedback."
            ],
            categories: [
                CAT_PERFORMANCE_REVIEWS,
                CAT_CONTINUOUS_FEEDBACK,
                CAT_PROCESS_VARIATION,
                CAT_FEEDBACK,
                CAT_CONTINUOUS_IMPROVEMENT
            ]
        },
        NoFeedbackNoProductivityArticle: {
            alt: [
                "Four hexagons represent the principles of an effective feedback system. 1. Enamply Cycle, reading: Perform task, Analyze feedback, Upgrade Skill, and Adjust Plan. 2. A question mark over a process cog with the Enamply cycle suggests that feedback must be about the process. 3. A timeline graph with an uptrend, and an Enamply cycle over it, meaning that variation and lessons learned must be part of the feedback. 4. An uptrend of 4 Enamply cycles indicating incremental improvements."
            ],
            title: ["No feedback, no productivity improvement."],
            subTitle: [
                "Four principles to ensure feedback and performance reviews efficacy."
            ],
            categories: [
                CAT_PERFORMANCE_REVIEWS,
                CAT_PRODUCTIVITY,
                CAT_CONTINUOUS_FEEDBACK,
                CAT_CONTINUOUS_IMPROVEMENT,
                CAT_HR
            ]
        }
    },
    SystemicProbemsArticle: {
        metaTitle: [
            "ENAMPLY | Systemic problems with Performance reviews"
        ],
        metaDescription: [
            "Performance reviews do not address the root causes of underproductivity. Assessment is Non-Data Driven. Evaluation is Disconnected from Specific work. Feedback is infrequent and overdue. The process is stressful and time-consuming. Assessment ignores incremental improvement. Evaluation is affected by cognitive bias"
        ],
        metaKeywords: [
            "performance reviews, performance management , employee engagement, feedback, skills development"
        ],
        kicker: ["Performance review and feedback"],
        title: ["Systemic problems with Performance reviews"],
        subTitle: [
            "Six main issues that make employee performance evaluations inefficient and often harmful."
        ],
        mainTakeAway: {
            title: ["Main takeaways:"],
            problem: [
                "Problem: Performance reviews do not address the root causes of underproductivity."
            ],
            issueList: ["Six main issues:"],
            issues: [
                "Assessment is Non-Data Driven",
                "Evaluation is Disconnected from Specific work",
                "Feedback is infrequent and overdue",
                "The process is stressful and time-consuming",
                "Assessment ignores incremental improvements",
                "Evaluation is affected by ",
                "cognitive bias"
            ]
        },
        quote: {
            text: [
                "“Performance appraisal is that occasion when once a year you find out who claims sovereignty over you.”"
            ],
            author: ["Peter Block"]
        },
        afterQuote: [
            "It is concerning that this phrase is still valid for so many companies and teams. The main thing that this quote refers to is that performance reviews rarely focus on identifying the true “why’s” behind a certain level of performance."
        ],
        mainIssue: {
            title: [
                "Performance reviews do not address the root causes of underproductivity."
            ],
            firstParagraph: [
                "Organizations are complex systems that constantly deal with conflicting priorities and undesirable events. Management must ensure that employees have the skills and resources to implement necessary processes that increase the likelihood of the best possible result with every iteration. In other words - help people improve the system. Performance reviews might have the good intent to improve things but are often about judging and rating. This is confirmed by studies that show that:"
            ],
            statisticsProblems: [
                "95% of managers are dissatisfied with their performance reviews/management systems",
                "9 in 10 HR leaders say that Performance reviews don’t yield accurate information",
                "77% of HR executives say that performance reviews don’t accurately reflect employee contributions",
                "2/3 of performance management systems misidentify high performers"
            ],
            closingParagrah: [
                "Following are six issues that impact the most performance reviews."
            ]
        },
        nonData: {
            title: ["Assessment is Non-Data Driven."],
            content: [
                "Evaluators rely on their memories or surveys, filled with impressions. The only useful data available might be the % achievement of a work-related KPI, which doesn’t show directly how an employee did, but rather how the system performed. Organizations implement some of the most advanced and expensive measurement mechanisms to capture data about their customers, processes, equipment, and transport to improve things. Somewhat neglected is the ",
                "data regarding roadblocks of their employees."
            ]
        },
        evalDisconnected: {
            title: ["Evaluation is Disconnected from Specific work."],
            content: [
                "Usually, feedback captures a long period and many actions at once. Most often, feedback is not easily actionable because it targets general behaviors. This is useful in principle, but it lacks agility toward improvement. For the feedback to be impactful, it should have a specific context that both parties understand well."
            ]
        },
        feedbackInfrequent: {
            title: ["Feedback is infrequent and overdue."],
            content: [
                "Studies show that more than 56% of employees don’t receive necessary feedback on what to improve. Nearly 60% of all respondents and 72% of employees under age 30 stated that they would like feedback daily or weekly. Tasks that matter are executed more often than the feedback is received, so there are lost opportunities for the employees to learn and for the organization to collect insights for systemic improvement.",
                "The worst-case scenario is when feedback comes once a year during the performance evaluations. Retrospectives and periodical one-on-once have the good intent to ensure feedback flows. These events rarely bring structured feedback and are overdue regarding the working process of the employee. It is unlikely to offer specific feedback, plan on conflicting priorities, and decide on balancing resources all in 15–30 minutes. Guess which part of the meeting is the time donor for others. Even that given feedback in periodical meetings needs to be more structured to be helpful and easy to trace during the periodical Performance evaluations."
            ]
        },
        processStressfull: {
            title: ["The process is stressful and time-consuming."],
            content: [
                "Performance evaluations became a “necessary evil”. Reviews have a high cost on the working process, relationships, time, and individual mental health. Employees who go through evaluations are uncertain of the measurement. They have their understanding of reality and go into the periodical evaluation with expectations that often are misleading. The receiver waits in anticipation and uncertainty while the evaluator struggles to construct an objective measure. The evaluator is struggling with memories or some insights manually gathered during the period and might be put in the position to use performance reviews to achieve political objectives, which amplifies the misalignment with employees’ perceptions and expectations. That may be why 59% of employees feel performance reviews are not worth the time invested.",
                "Too often, after performance reviews, the management team has to go through a period of “damage control” and put in extra effort to keep people engaged or even keep them in the company."
            ]
        },
        assesmentIgnores: {
            title: ["Assessment ignores incremental improvements."],
            content: [
                "People learn gradually, while feedback often covers more tasks and a prolonged period at once. It is hard to change many things simultaneously, so feedback is less impactful given periodically and on many topics. Even breakthroughs are gradual build-ups to a tipping point, so a healthy feedback flow and reality check increase the chances of improvements.",
                "The longer it takes to detect the opportunity to improve, the higher the costs for the company. Feedback during performance reviews is just too expensive."
            ]
        },
        cognitiveBias: {
            title: ["Evaluation is affected by cognitive bias."],
            content: [
                "Long periods between evaluations, lack of data, the uncertainty of the evaluation processes, and weak connection with actual work challenges enhance people’s natural biases. As you noticed, the first five issues result from a flawed process, while the 6th one concerns human behavior and nature. By solving the previous five issues, we can massively improve the impact of performance appraisals and diminish the inherent ",
                "cognitive biases",
                " that we all bring to work.",
                "Leadership needs to engage employees in exploring how to create value and achieve the company’s vision, especially when remote and hybrid work are booming. New generations of employees increasingly consider their work part of their identity, so they will only engage with organizations that help them grow. The feedback that comes rarely is vague and highly affected by cognitive biases and will hardly help someone to develop at their real potential."
            ]
        },
        closing: [
            "Companies discover ",
            "task-based feedback ",
            "as one of the most cost-effective and ",
            "impactful micro-feedback techniques ",
            "that genuinely help them fight the abovementioned problems."
        ],
        altText: {
            hexagonImg: [
                "Different heights podiums in form of hexagons that are a ironic representation of Systemic Problems of performance reviews"
            ],
            statisticsImg: [
                "Statistics that indicate how performance reviews are not efficient: 95% of managers are dissatisfied with their performance reviews/management systems • 9 in 10 HR leaders say that Performance reviews don’t yield accurate information • 77% of HR executives say that performance reviews don’t accurately reflect employee contributions • 2/3 of performance management systems misidentify high performers."
            ],
            assesmentTruncatedImg: [
                "A magnifying glass with a question mark on it looking over two ripped pages. It represents that assessment is Non-Data Driven."
            ],
            disconnectedFromTasksImg: [
                "A cycle type of image containing four elements arranged clockwise: a list of executed tasks, cross, a magnifying glass over a page, a questioning mark. It represents that Evaluation is Disconnected from Specific work."
            ],
            inconsistantImg: [
                "A Calendar with dates represented randomly by question marks, crosses, and ticks. It represents that Feedback is infrequent and overdue."
            ],
            stressfullTimeImg: [
                "A watch that is vibrating and the arrows are represented by lightning. It represents that the process is stressful and time-consuming."
            ],
            ignoreImprovementImg: [
                "A trend line with multiple and smaller steps going up with a crossed eye and a trend line that has one bigger step at the end of it but which doesn’t reach the levels of the first trend lin. It represents that assessment ignores incremental improvements."
            ],
            cognitiveBiasImg: [
                "Brain with a labyrinth in it. It represents that evaluation is affected by cognitive bias."
            ]
        },
        referancess: {
            header: ["References"],
            first: [
                "Cambridge, Industrial and Organizational Psychology Journal"
            ],
            second: ["PWC Study"],
            third: ["SHRM Article"]
        },
        captions: {
            statistics: [CREATED_USING_CANVA]
        },
        categories: [
            CAT_PERFORMANCE_REVIEWS,
            CAT_PERFORMANCE_MANAGEMENT,
            CAT_EMPLOYEE_ENGAGEMET,
            CAT_FEEDBACK,
            CAT_SKILLS_DEVELOPMENT
        ]
    },
    ImpactfullFeedbackArticle: {
        metaTitle: [
            "ENAMPLY | How to make micro-feedback truly impactful?"
        ],
        metaDescription: [
            "Micro-feedback is impactful only if it is encouraging and constructive. Impactful feedback must be actionable and lead to incremental improvements. Improvement comes only from an honest understanding of the current state. The best Micro-feedback mechanism is Task-based feedback."
        ],
        metaKeywords: [
            "performance reviews, micro feedback, continuous feedback, productivity, skills development"
        ],
        kicker: ["Continuous feedback"],
        title: ["How to make micro-feedback truly impactful?"],
        subTitle: ["It can't be all positive."],
        mainTakeAway: {
            title: ["Main Takeaways:"],
            content: [
                "Micro-feedback is impactful only if it is encouraging and constructive.",
                "Impactful feedback must be actionable and lead to incremental improvements.",
                "Improvement comes only from an honest understanding of the current state.",
                "The best Micro-feedback mechanism is Task-based feedback."
            ]
        },
        encouraging: {
            title: [
                "Micro-feedback is impactful only if it is encouraging and constructive."
            ],
            content: [
                "If you are looking into the micro-feedback concept, you must agree that giving feedback once a month, quarterly, or yearly during performance reviews is just ",
                "counterproductive",
                '. Periodical feedback can\'t be specific because many things are discussed at a time, hardly based on verifiable data. It can be stressful due to its "special event" aura, usually later than needed, so highly affected by the ',
                "cognitive biases ",
                "of both the giver and the receiver. So, our take on Micro-feedback is that it should be a mechanism that provides a smaller portion, more frequent, and much more specific insights about how to get better. It is a timely and seamless reality check. As we all know, reality can be challenging, so focusing only on positive reinforcement in Micro-Feedback can also be counterproductive."
            ]
        },
        actionable: {
            title: [
                "Impactful feedback must be actionable and lead to incremental improvements."
            ],
            content: [
                "Suppose one can't do anything about the feedback. In that case, it is just blaming and becomes a frustrating experience for both sides. People learn, and change happens incrementally. Even breakthroughs result from many actions that bring everything to a tipping point, after which significant change becomes observable. Considering this, if feedback is given in a huge portion at once, it can be a shocking experience. A significant gap between the current state and future expectations can demotivate people. You run a mile by running every meter."
            ]
        },
        honest: {
            title: [
                "Improvement comes only from an honest understanding of the current state."
            ],
            content: [
                `There can't be any improvement if the only feedback is based on positive reinforcement and praising good work. People like it when they are treated honestly. Employees want to have an answer to "Did this happen thanks to or despite my actions?". Studies show that not only is positive feedback important, but so is critical feedback. Moreover, 92% of respondents agreed that "Negative (redirecting) feedback, if delivered appropriately, is effective at improving performance". There are three types of feedback that we consider equally valuable for individuals, teams, and organizations:`
            ],
            listItems: [
                "Positive reinforcement — recognizing good work (Continue)",
                "Constructive feedback — suggesting what can be done better (Improve)",
                "Negative feedback — highlighting behaviors that are not constructive (Stop)"
            ],
            closingContent: [
                "A Gartner study noted that 56% of employees say they do not receive feedback on what to improve, which shows that people want to know how they can do better. Organizations must have feedback loops that assure timely and actionable feedback."
            ]
        },
        taskBasedFeedback: {
            title: [
                "The best Micro-feedback mechanism is Task-based feedback."
            ],
            content: [
                `Best practices show that feedback must have a context and be frequent and actionable. Tasks are units of effort that determine a company's performance. Whether planned or not, tasks are actions by specific people who apply their skills and knowledge to generate value. Building a habit of`,
                "task-based feedback ",
                "will ensure that employees receive specific and timely insights with a context of action. Moreover, enabling teams to openly share positive, constructive, and negative feedback in micro-portions will make feedback impactful and accelerate continuous improvement."
            ]
        },
        referancess: {
            header: ["References"],
            first: ["Zenger and Folkman Harvard Business Review"],
            second: [
                "Cambridge, Industrial and Organizational Psychology Journal"
            ]
        },
        categories: [
            CAT_PERFORMANCE_REVIEWS,
            CAT_MICRO_FEEDBACK,
            CAT_CONTINUOUS_FEEDBACK,
            CAT_PRODUCTIVITY,
            CAT_SKILLS_DEVELOPMENT
        ],
        altText: {
            headerText: [
                "Six people each executing a gardening task from left to right: Sprinkle with fertilizers, cutting branches, sowing, cleaning the stem, digging and raking. This image represents the fact that Micro-feedback is impactful only if it is encouraging and constructive, actionable and lead to incremental improvements, and is based on an honest understanding of the current state."
            ],
            encouraging: [
                "Two people each executing a gardening task from left to right: Sprinkle with fertilizers, cutting branches. This image represents the fact that Micro-feedback is impactful only if it is encouraging and constructive."
            ],
            actionable: [
                "Two people each executing a gardening task from left to right: sowing and cleaning the stem. This image represents the fact that Micro-feedback is impactful only if it is actionable and leads to incremental improvements."
            ],
            honest: [
                "Two people each executing a gardening task from left to right: digging and raking. This image represents the fact that Improvement comes only from an honest understanding of the current state."
            ]
        },
        captions: {
            header: [CREATED_USING_CANVA]
        }
    },
    TaskBasedFeedbackArticle: {
        metaTitle: [
            "ENAMPLY | Task-based feedback — The continuous improvement technique."
        ],
        metaDescription: [
            "Task-based feedback is the process that gives employees specific, timely, and structured feedback on how to act better. A task is a unit of effort determining a company’s performance. Task-based feedback is about HOW not WHAT. Task-based feedback is cost-efficient and essential for continuous improvement. Not all tasks are created equally — feedback tasks that matter."
        ],
        metaKeywords: [
            "performance reviews, continuous feedback, productivity, skills development, continuous improvement"
        ],
        kicker: ["Continuous feedback and performance growth"],
        title: [
            "Task-based feedback — The continuous improvement technique."
        ],
        subTitle: [
            "One of the most valuable yet most ignored organizational habit."
        ],
        mainTakeAway: {
            title: ["Main Takeaways:"],
            content: [
                "Task-based feedback is the process that gives employees specific, timely, and structured feedback on how to act better.",
                "A task is a unit of effort determining a company’s performance.",
                "Task-based feedback is about HOW not WHAT.",
                "Task-based feedback is cost-efficient and essential for continuous improvement.",
                "Not all tasks are created equally — feedback tasks that matter."
            ]
        },
        taskBasedFeedback: {
            title: [
                "Task-based feedback is the process that gives employees specific, timely, and structured feedback on how to act better."
            ],
            content: [
                "This is one of the best data sources for employee development and work improvement. ",
                "Impactful feedback",
                " must be actionable and lead to incremental improvements. So, employees receiving insights that can be applied to the next task assures a constant flow of micro-feedback that is easy to grasp and use. Progress can come only from an honest understanding of the current state. Tasks are a direct and specific reflection of the current reality, so capturing this becomes a vital source of information that should be used during periodical objective settings, career development plans and performance reviews. Feedback loops become shorter and more frequent, assuring that teams know the actual struggles and pain points in their processes, can improve what truly matters, and learn quickly."
            ]
        },
        taskUnitOfEffort: {
            title: [
                "A task is a unit of effort that determines a company’s performance."
            ],
            content: [
                "Everything from ad-hoc results to achieving the strategy is done through executing tasks. Whether planned or not, tasks are actions by specific people who apply their skills and knowledge to generate value. So, value creation is an action. It is not just a fuzzy concept in the business lexicon. Therefore, a task is a unit of effort that determines a company’s performance. Task-based feedback helps learn quickly and frequently about the real needs of individuals, teams, and organizations to create value.",
                "Some of the most successful teams learn fast from their daily work. They are nimble to adjust their resource allocation based on changing market needs and new technological capabilities. They are embracing the culture of learning by doing, a.k.a buzz name “fail fast”."
            ]
        },
        taskHow: {
            title: ["Task-based feedback is about HOW not WHAT."],
            content: [
                "By focusing on the process that the employee overtook to execute the task, one can offer feedback that will have a long-term and far-reaching impact. An improved approach to task execution through behavior change, skill development, or resource allocation will increase the likelihood of better results in the future. Very often, however, the feedback is mainly about the result itself. We comment on what to change in a report, a letter, or a presentation, so it becomes a micromanagement tool.",
                "Task-based feedback should enable teams to openly share ideas of how to put in place methods that will maximize their productivity. By helping the employee to explore “how” to improve work, feedback will guide them to discover new needed skills and define the priority to develop existing skills. In this way, the skill set at the individual, team, and organizational levels are constantly upgraded."
            ]
        },
        costEfficient: {
            title: [
                "Task-based feedback is cost-efficient and essential for continuous improvement."
            ],
            content: [
                "First, let’s agree that there can’t be any Continuous improvement if we offer feedback once a month, quarterly, or yearly during performance reviews. A company needs engaged and accountable employees for improvements to happen frequently. Studies show that employees who receive daily feedback from their manager are three times more likely to be engaged than those who receive feedback once a year or less.",
                "In this context, it is encouraging to notice that more and more teams are embracing the “fail fast” and agile principles. These approaches acknowledge that there is no way one knows beforehand everything they need to do to accomplish the goal. Therefore, one must act and learn through, ideally, minor mistakes, a.k.a fails, improve and act again. This is a fundamental entrepreneurship principle as well. Successful entrepreneurs constantly learn from their actions and seek frequent and specific feedback to improve effectively. Companies that want to engage employees to be proactive, action-oriented, and innovative need to encourage them to learn by doing, thus, by failing. There is no “fail fast” without learning quickly about how to improve.",
                "Continuous improvement is best achieved through quick iterations and building on previous learnings. The continuous improvement becomes unreasonably expensive without frequent and short feedback loops targeted at specific actions. The longer it takes to detect the opportunity to improve, the higher the costs for the company."
            ]
        },
        taskThatMatter: {
            title: [
                "Not all tasks are created equally — feedback tasks that matter."
            ],
            content: [
                "Every team must understand where it stands on the company’s value chain, which means how it contributes to the successful delivery of the product or service to the external customer. Having this understanding, one can better plan and set goals for development and then measure if a change is indeed an improvement. Tasks that matter are those actions that impact the overall goal achievement. It is accurate at an individual, team, and organizational level. By leveraging feedback for critical activities, one will always have a positive and relevant impact on the organization’s overall success."
            ]
        },
        referancess: {
            header: ["References"],
            first: ["Gallup Study"]
        },
        categories: [
            CAT_PERFORMANCE_REVIEWS,
            CAT_CONTINUOUS_IMPROVEMENT,
            CAT_SKILLS_DEVELOPMENT,
            CAT_PRODUCTIVITY,
            CAT_CONTINUOUS_FEEDBACK
        ],
        altText: {
            headerText: [
                "In the bottom left corner is a loop with three droplets that follow each other representing the task-based feedback loop: Perform Task, Analyze Feedback, Upgrade Skill. Parallel to the “skill” droplet, a circle represents an adjacent element that refers to Adjust Plan (development plans). On the upper right corner 4 feedback loops, one from another, increase performance, while conventional periodical performance reviews have a limited and late effect on performance growth."
            ],
            triad: [
                "A loop with three droplets that follow each other representing the task-based feedback loop: Perform Task, Analyze Feedback, Upgrade Skill. Parallel to the “skill” droplet, a circle represents an adjacent element that refers to Adjust Plan (development plans)."
            ],
            continuousImprovement: [
                "Four feedback loops, connected one from another, have a compounding impact on performance, while conventional periodical performance reviews have a limited and late effect on performance growth."
            ]
        },
        captions: {
            header: [CREATED_USING_CANVA]
        }
    },
    CognitiveBiasesInPerformanceArticle: {
        metaTitle: [
            "ENAMPLY | How nuanced are the effects of cognitive biases in performance reviews?"
        ],
        metaDescription: [
            "Cognitive Biases are Thinking illusions, in other words, subconscious mental shortcuts applied while interacting with others and amplified by context and emotions. Performance reviews are prone to biases because they involve judgment and interpretation of the external world and others. The effect of cognitive biases in performance reviews can be reduced through a habit of frequent, specific, and timely feedback."
        ],
        metaKeywords: [
            `${CAT_PERFORMANCE_REVIEWS},${CAT_CONTINUOUS_FEEDBACK},${CAT_PRODUCTIVITY},${CAT_FEEDBACK},${CAT_COGNITIVE_BIAS}`
        ],
        kicker: ["Performance Reviews and Cognitive Biases"],
        title: [
            "How nuanced are the effects of cognitive biases in performance reviews?"
        ],
        subTitle: [
            "An overview of our mental shortcuts and how to manage them through feedback."
        ],
        mainTakeAway: {
            title: ["Main Takeaways:"],
            content: [
                "Cognitive Biases are Thinking illusions, in other words, subconscious mental shortcuts applied while interacting with others and amplified by context and emotions.",
                "Performance reviews are prone to biases because they involve judgment and interpretation of the external world and others.",
                "The effect of cognitive biases in performance reviews can be reduced through a habit of frequent, specific, and timely feedback."
            ]
        },
        beforeOpticalIllusion: [
            "This is an Optical Illusion. We know the circles are the same color, but we perceive them differently."
        ],
        afterOpticalIllusion: [
            "The human brain is fantastic. It is efficient and productive, but this comes at a price. We develop and enforce shortcuts in our thinking. It is natural, so we all have them and apply them in our work."
        ],
        cognitiveBiasesIllusions: {
            title: ["Cognitive Biases are Thinking illusions."],
            content: [
                "Cognitive biases are subconscious mental shortcuts applied while interacting with others and amplified by context and emotions. These automatic thinking processes make us quick and efficient but can lead to misinterpretation of reality. We tend to memorize emotional events, anchor our beliefs to things that impressed us, and we like when we are right, so we tend to confirm it to ourselves and ignore contradictory evidence. We feel safer in familiar circumstances and with people like us. We think we know more than we do and are comfortable in our ignorance. We bring all this into the evaluation and performance appraisal processes.",
                "There are almost 200 biases categorized and ",
                "mapped,",
                " and science is still developing on this issue. Here are a few examples of biases we observe that often affect performance reviews and feedback."
            ],
            anchoring: {
                title: ["Anchoring "],
                content: [
                    "— placing too much importance on one trait of information when making decisions regardless of how relevant it is. This information can be related to the first impression, emotional events, not challenging your image about someone, or acting by the rule “If all you have is a hammer, everything looks like a nail”."
                ]
            },
            availability: {
                title: ["Availability heuristic "],
                content: [
                    "— making decisions based on the information that is easiest to recall and not necessarily the most relevant facts. So, recent events can negatively influence your perception of someone’s performance. Employees might compensate for this by trying to disagree less with their team and take on less risk following performance reviews while trying to be as remarkable as possible in a positive way."
                ]
            },
            affinity: {
                title: ["Affinity or similarity "],
                content: [
                    "— people tend to favor those like them. Generally, we tend to feel more comfortable in the circumstances and with people like us. Groups with shared hobbies and habits like smoking, coffee connoisseurs, and others are well-known effects of this group of biases."
                ]
            },
            confirmation: {
                title: ["Confirmation bias "],
                content: [
                    "— we tend to search for, interpret and remember information that confirms our beliefs. We ignore contradictory evidence about individuals or circumstances. Once an impression is formed, it subconsciously affects our belief system and influences our decisions. “He is late today. He is always late.”, “I knew I had to check on her. She is always making mistakes.” And many other phrases go through managers’ minds and are rutted into confirmation biases."
                ]
            },
            attribution: {
                title: ["Attribution bias "],
                content: [
                    "— we perceive our achievements as the result of hard work and attribute failures to external factors, while we think the opposite about others’ successes and failures. The phrase “How hard can it be?” is central to this bias. We tend to diminish the magnitude of the effort someone puts into the work while we are over-emphasizing our actions."
                ]
            }
        },
        reviewsProneToBias: {
            title: [
                "Performance reviews are prone to biases because they involve judgment and interpretation of the external world and others."
            ],
            content: [
                "Companies use expensive, numerous, and sophisticated measurement devices to capture data about customers, logistics, transportation, production, and other processes. In contrast, to measure the human performance review, we use one of the most ",
                "“…inaccurate, unreliable, and capricious methods we could possibly devise: one human subjectively reviewing another human” ",
                "(Peter R. Scholtes). ",
                "There is no feedback, evaluation, or measurement until there is an interaction between people at work. All these activities are prone to be affected by cognitive biases because people bring their interpretation of reality into them. People tend to believe that they understand everything as it is, but there is much more.",
                "Companies must accept the limitations and create environments and processes that diminish cognitive biases’ effects in performance review, feedback, and productivity management.",
                "If the evaluations are based only on anecdotal data and periodical surveys without direct connection with specific actions, or if feedback, in general, is infrequent and late after the evaluated events, then the likelihood of bias is high.",
                "Another good indication is when performance reviews are associated with stress, waste of time, or “needed struggle” to motivate, promote or reward employees for their efforts. Suppose managers find themselves in a damage control period after the performance reviews. In that case, feedback before the appraisal was misleading, so the evaluation was affected by biases."
            ]
        },
        reduceBias: {
            title: [
                "The effect of cognitive biases in performance reviews can be reduced through a habit of frequent, specific, and timely feedback."
            ],
            content: [
                "The influence of cognitive biases is one of the ",
                "systemic problems with performance reviews. ",
                "The fact that performance reviews do not address the root causes of underproductivity is directly influenced by the fact that teams do not rely on structured data, which would come from timely, frequent, and specific feedback. We believe ",
                "task-based feedback",
                " is one of the best mechanisms to assure incremental and continuous performance growth. It is an ",
                "impactful micro-feedback",
                " technique that provides necessary and sufficient insights that embrace the natural way of human development, the gradual skill upgrade."
            ]
        },
        categories: [
            CAT_PERFORMANCE_REVIEWS,
            CAT_CONTINUOUS_FEEDBACK,
            CAT_PRODUCTIVITY,
            CAT_FEEDBACK,
            CAT_COGNITIVE_BIAS
        ],
        altText: {
            headerText: [
                "A gradient with black on the left side and light purple on the right side. It represents the nuanced world of performance reviews and human-to-human evaluation. Cognitive biases make us perceive the world around us differently."
            ],
            opticalIllusion: [
                "An image of a gradient color from black on the left to light purple on the right. On top of the gradient color sit two circles of the same color, one on the left and one on the right side of the image. The circles appear to be of different colors while they are of the same color. This is a well-known optical illusion that most of people experience."
            ],
            biaslIllusion: [
                "An image of a gradient color from black on the left to light purple on the right. On top of the gradient color sit two circles of the same color, one on the left and one on the right side of the image. The circles appear to be of different colors while they are of the same color. This is a well-known optical illusion that most people experience. On top of the left circle is written Cognitive Biases and on top of the right circle is written Thinking Illusions."
            ],
            humanUnivers: [
                "The image’s background represents the universe as a black area with stars and galaxies. In the bottom-middle of the image stays a cartoon head of a human with the face dissected as an open door. Inside the head, there is a small universe. The image suggests that each of us is a miniature cosmos, and we perceive the world differently."
            ]
        }
    },
    ReviewEmbraceEmployeesEffortsArticle: {
        metaTitle: [
            "ENAMPLY | How to ensure that performance reviews embrace all the employee’s efforts?"
        ],
        metaDescription: [
            "A target represents a level of excellence defined and expected by the stakeholders. Variation is a difference between a target and an actual result and is inherent to any human action. Feedback and performance reviews must help understand variations in employees’ actions and lead to better prioritization of improvements. More data clarifies variation and decreases performance review biases. Task-based feedback acknowledges the inherent variation of human activity and helps learn by doing."
        ],
        metaKeywords: [
            `${CAT_PERFORMANCE_REVIEWS},${CAT_CONTINUOUS_FEEDBACK},${CAT_PROCESS_VARIATION},${CAT_FEEDBACK},${CAT_CONTINUOUS_IMPROVEMENT}`
        ],
        kicker: ["Feedback and Performance review"],
        title: [
            "How to ensure that performance reviews embrace all the employee’s efforts?"
        ],
        subTitle: [
            "Acknowledge the variation in human activity and explore it through feedback."
        ],
        mainTakeAway: {
            title: ["Main Takeaways:"],
            content: [
                "A target represents a level of excellence defined and expected by the stakeholders.",
                "Variation is a difference between a target and an actual result and is inherent to any human action.",
                "Feedback and performance reviews must help understand variations in employees’ actions and lead to better prioritization of improvements.",
                "More data clarifies variation and decreases performance review biases.",
                "Task-based feedback acknowledges the inherent variation of human activity and helps learn by doing."
            ]
        },
        target: {
            title: [
                "A target represents a level of excellence defined and expected by the stakeholders."
            ],
            content: [
                "External customers, internal customers (colleagues from other teams, departments, and subdivisions), suppliers, society, and shareholders are all stakeholders that define their value expectations. Ideally, we know precisely what our stakeholders expect from us, but most often, we don’t. That is why it is essential to listen closely to the voices of the stakeholders through quick, frequent, and meaningful feedback. Only by having constructive and positively reinforcing feedback can we evaluate and understand variations in our performance."
            ]
        },
        variation: {
            title: [
                "Variation is a difference between a target and an actual result and is inherent to any human action."
            ],
            content: [
                "It is rare when an individual, a team, or an organization can claim that they reached their target’s bull’s eye and did it exactly as the last time. Underachievement and overachievement are both a variation from the expected outcome. These are not inherently good or bad from the perspective of productivity management. Variation exists no matter how good someone is at their job. We all have many years of experience in cleaning our teeth, but we can’t guarantee the same result every time. Even such an everyday activity is prone to generate variate results or be executed differently."
            ]
        },
        feedbackAndPerformance: {
            title: [
                "Feedback and performance reviews must help understand variations in employees’ actions and lead to better prioritization of improvements."
            ],
            content: [
                "As mentioned, variation is part of any human process. To be effective in implementing improvements, one must know what type of variation is measured in the performance of the employees. The remedy is dependent on the type of variation."
            ],
            listTitle: [
                "There are two distinct variations in human activity and any process:"
            ],
            commonCause: [
                "Common cause",
                " variations — an occurrence that is constantly active within a process. It is predictable and consistent. Statistically, a process or a human activity is stable and predictable if common causes generate all the variation. Example: A business analysis team evaluated that developing a data quality report usually takes (on average) from 3 to 5 hours. All factors influencing the task to be executed in this range are common causes. One can list factors like: employee skills are adequate, expectations from the stakeholders are clear, all the resources are predictably available, data is according to the format, the computer is working correctly, etc. To be able to decrease the execution time or to diminish the variation from three to five hours to three to four hours, the team must improve one of the listed common causes. However, if a report is executed in seven hours, this might indicate that the task was affected by an unpredictable factor which is a special cause of variation."
            ],
            specialCause: [
                "Special cause",
                " of variation — an unanticipated, emergent glitch in the process. A special cause is an event that no one can predict, so while it is recommended to learn from these events, these are hardly representative of the process’s overall productivity. An action with many such glitches is unstable and thus risky for the team and the organization."
            ],
            afterParagraph: [
                "Humans are naturally affected by ",
                "cognitive biases",
                ". We tend to memorize things that impressed us in a good or bad way. We anchor our beliefs on readily available information, making emotional events more impactful. So, unfortunately, performance reviews and rare feedback are unconsciously based on those memories and events that are most likely special causes. We use them involuntarily in our evaluations even if they are not a good representation of reality."
            ]
        },
        moreData: {
            title: [
                "More data clarifies variation and decreases performance review biases."
            ],
            content: [
                "As one can observe, to understand if an employee delivers a result influenced by common or special causes, one must have sufficient data. Having just a few memories or impressions about someone and looking for trends will lead to a mismatch between the genuine efforts, skill gaps, and needs of the employee and the evaluation. One measurement is an oversimplified and false representation of reality. Research shows that individual performance ratings have zero correlation with actual business results. Feedback flow must be sufficient to provide many data points for a proper understanding of variation and the influence of common and special causes. The scarcity of feedback will exacerbate the bias problem when “A bad apple spoils the barrel” — horn effect or one outstanding result puts a nimb on the employee — halo effect."
            ]
        },
        taskBased: {
            title: [
                "Task-based feedback",
                " acknowledges the inherent variation of human activity and helps learn by doing."
            ],
            content: [
                "Outstanding results should be defined as data-based, not impression-based. Noted outstandingly bad results can lead to identifying gaps in individual or team skill matrices and can help prioritize what to improve. Outstandingly good actions can be shared and used as best practices, thus improving the way of work. All those processes executed with a predictable level of variation will add to the accurate representation of employee or team efforts and better explain their productivity level during evaluations. Frequent ",
                "impactful micro-feedback",
                " based on specific actions will increase the amount and quality of the data. For quantitative analysis, one must use ranges that help capture the variation in task execution and provide insights into what and when to improve first."
            ]
        },
        categories: [
            CAT_PERFORMANCE_REVIEWS,
            CAT_CONTINUOUS_FEEDBACK,
            CAT_PROCESS_VARIATION,
            CAT_FEEDBACK,
            CAT_CONTINUOUS_IMPROVEMENT
        ],
        altText: {
            header: [
                "A timeline graph with five data points, three of which are blue and represent predictable results in the expected range, and two are orange and represent unexpected results. On the right side of the image, a person shows a pie chart illustrating the data points from the timeline chart. The image suggests that a process, a human action, registered different results from the expected trend line and that variation is inherent."
            ],
            target: [
                "A timeline graph with Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward from left to right. The only data point in the center of the trend line (Target) is captured on a whiteboard as a shooting target, and a woman is pointing toward it. The image suggests that a target represents a set level of excellence defined and expected by the stakeholders."
            ],
            variation: [
                "A timeline graph with Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward from left to right. There is a blue data point lower than a faded-out point on the Target line, and the space between them is called Variation. Near the data point is an image of a woman working hard on her tasks. The image suggests that variation is a difference between a target and an actual result and is inherent to any human action."
            ],
            feedbackAndPerformance: [
                "A timeline graph with Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward from left to right. There is a blue data point lower than a faded-out point on the Target line. A person analyzes executed tasks through a magnifying glass, and another image of a girl prioritizes two findings represented by light bulbs. Feedback and performance reviews must help understand variations in employees’ actions and lead to better prioritization of improvements."
            ],
            commonCause: [
                "A timeline graph with Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward from left to right. There is a blue data point lower than a faded-out point on the Target line. There is a blue area with the Target line at the center. This area represents the spectrum for the variation provoked by a common cause of variation, which is an occurrence that is constantly active within a process. It is predictable and consistent."
            ],
            specialCause: [
                "A timeline graph with Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward from left to right. There is a blue data point lower than a faded-out point on the Target line. There is a blue area with the Target line at the center. All the space on the graph beyond the blue area is the area of Special cause of variation, an unanticipated, emergent glitch in the process."
            ],
            moreData: [
                "There are five data points on a timeline graph: Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward. The image suggests that more data clarifies variation and decreases performance review biases."
            ],
            taskBased: [
                "There are five data points on a timeline graph: Excellence on the Y dimension, Time on the X dimension, and a trend line called Target going upward. On the left, a woman works on a laptop on top of a calendar. On the right, a woman holding a task is pointing up, and a rocket is launching. This image represents that task-based feedback acknowledges the inherent variation of human activity and helps learn by doing."
            ]
        }
    },
    NoFeedbackNoProductivityArticle: {
        metaTitle: [
            "ENAMPLY | No feedback, no productivity improvement."
        ],
        metaDescription: [
            "Performance reviews are meant to empower organizations to go further faster. High productivity comes from reasonable challenge, necessary resources, and acknowledgment of future gain. Facts to consider when implementing feedback mechanisms. Principles of a sound feedback management system."
        ],
        metaKeywords: [
            `${CAT_PERFORMANCE_REVIEWS}, ${CAT_PRODUCTIVITY}, ${CAT_CONTINUOUS_FEEDBACK}, ${CAT_CONTINUOUS_IMPROVEMENT}, ${CAT_HR}`
        ],
        kicker: ["Performance reviews and Feedback"],
        title: ["No feedback, no productivity improvement."],
        subTitle: [
            "Four principles to ensure feedback and performance reviews efficacy."
        ],
        mainTakeAway: {
            title: ["Main Takeaways:"],
            content: [
                "Performance reviews are meant to empower organizations to go further faster.",
                "High productivity comes from reasonable challenge, necessary resources, and acknowledgment of future gain.",
                "Facts to consider when implementing feedback mechanisms",
                "Humans are naturally biased",
                "Timely feedback is actionable and relevant",
                "People learn and change incrementally",
                "Variation is inherent to any human action",
                "More data brings clarity to reality",
                "Principles of a sound feedback management system",
                "Develop the habit of quick, frequent, and specific feedback.",
                "Feedback the HOW, not the WHAT.",
                "Capture lessons learned and embrace unpredictability.",
                "Feedback must be actionable and lead to incremental improvements."
            ]
        },
        performanceEmpower: {
            title: [
                "Performance reviews are meant to empower organizations to go further faster."
            ],
            content: [
                'Performance reviews should not be monthly, quarterly, or annual "events" when people receive general information about an extended period, which is hardly actionable. Also, these are not solely for the organizations to promote or distribute rewards or warnings to their employees. Performance reviews and feedback are intertwined and interactive processes through which organizations should accomplish a complex understanding of the systemic roadblocks toward higher individual and team productivity.',
                'It is essential to approach performance reviews with a holistic approach, to have a system thinking perspective. This implies that teams can analyze their performance as a result of interrelated factors that have different causes with varying levels of influence. As we all know, "If you want to go fast, go alone. If you want to go further, go together". No one in an organization is achieving great success alone. That is why organizations exist, to create synergies between individuals motivated to accomplish a shared far-reaching vision. Performance reviews are meant to empower organizations to go further faster.'
            ]
        },
        highProductivity: {
            title: [
                "High productivity comes from reasonable challenge, necessary resources, and acknowledgment of future gain."
            ],
            content: [
                "In more technical terms, a performance review is a periodic reality check that provides enough insights to adjust the effort and resource distribution at all levels to achieve the needed productivity level. An individual, a team, or an organization can be highly productive only if reasonably challenged, supplied with necessary and sufficient resources, and appreciates current efforts as part of a more significant future accomplishment.",
                "At an individual level, performance reviews must be holistic discussions about employee and company goals alignment and shared appreciation of both parties' needs. Every feedback session, and performance review effort, must provoke meaningful improvements. Feedback is an investment in the future result."
            ]
        },
        factsToConsider: {
            title: [
                "Facts to consider when implementing feedback mechanisms"
            ],
            content: {
                humansBiased: [
                    "Humans are naturally biased",
                    " — ",
                    "Cognitive biases",
                    " are subconscious mental shortcuts applied while interacting with others and amplified by context and emotions. These automatic thinking processes make us quick and efficient but can lead to misinterpretation of reality. Performance reviews and feedback are affected by cognitive biases because people rely on memories and impressions during these processes. It is paramount to set up systems and processes in organizations to help to reduce, not to enhance, biases."
                ],
                timelyFeedback: [
                    "Timely feedback is actionable and relevant",
                    " — The sooner the feedback gets to the person, the sooner they will learn and be able to act on it. With time, our memories of the action are more and more influenced by cognitive biases. Tools like retrospectives or one on ones are trying to solve this but need more agility."
                ],
                peopleLearn: [
                    "People learn and change incrementally",
                    " — If we give feedback more often, we empower the natural way of change — incremental improvement. Professionalism is a journey with many incremental adjustments, not a breakthrough. Very few things happen overnight. Usually, everything is a result of cumulative, constant action."
                ],
                variation: [
                    "Variation is inherent to any human action",
                    " — Variation exists no matter how good someone is at their job. We all have many years of experience in cleaning our teeth, but we can't guarantee the same result every time. Even such an everyday activity is prone to generate different results. As mentioned before, humans are biased, so the risk of overreacting to a case in which someone failed or succeeded is natural. By acknowledging and adjusting our feedback and productivity measurements to consider variations in human actions, teams will learn how to prioritize what to improve."
                ],
                moreData: [
                    "More data brings clarity about reality",
                    " — One measurement is an oversimplified and false representation of reality. Research shows that individual performance ratings have zero correlation with actual business results. Feedback flow must be sufficient to provide many data points for decision-making. The scarcity of feedback will exacerbate the bias problem."
                ],
                article: [
                    "Check out the article ",
                    "Problems with Performance Reviews",
                    " for more on these issues."
                ]
            }
        },
        principlesOfFeedback: {
            title: [
                "Principles of a sound feedback management system."
            ],
            content: {
                developHabit: [
                    "Develop the habit of quick, frequent, and specific feedback.",
                    "Having a routine of giving and asking for repeated feedback regularly and seamlessly will enable teams to communicate openly and share the good and the bad of the work. Having mechanisms of truly ",
                    "impactful micro-feedback ",
                    "will normalize both encouraging and constructive feedback. Enamply helps teams to make feedback a habit."
                ],
                feedbackHow: [
                    "Feedback the HOW, not the WHAT.",
                    "Focus on the process that generated the result, not the result itself. Improving the work process through behavior change, skill development, or resource allocation will increase the likelihood of better results in the future. Feedback is almost always about a specific task result — for example, a report, analysis, feature, or presentation. Results matter but the key to efficiency and productivity is to improve the process that leads to the outcome. Traceable and structured ",
                    "Task-based feedback",
                    " is the best source for a data-driven approach to Performance Evaluations. Enamply offers features and guides teams to help improve their way of work."
                ],
                captureLessons: [
                    "Capture lessons learned and embrace variations of human actions.",
                    "Lessons learned are those pieces of information that, being shared and implemented, have a meaningful impact. By learning by doing and embracing variations in human activity, the team will quickly identify their skill gaps, best practices, and what to prioritize for improvement. Enamply measures feedback with a proprietary range which helps capture the unpredictability in task execution. Applying Enamply Scale, you can capture the good and the bad in the feedback."
                ],
                actionableFeedback: [
                    "Feedback must be actionable and lead to incremental improvements.",
                    "Studies show that more than 56% of employees don't receive necessary feedback on what to improve. Nearly 60% of all respondents and 72% of employees under age 30 stated that they would like feedback daily or weekly. Employees need timely and specific feedback that can be applied to the next task and can be a reliable source of data during later periodical objective settings or performance reviews. Enamply helps you capture quantitative and qualitative evaluation, making the feedback actionable and empowering teams to learn by doing."
                ]
            }
        },
        referancess: {
            header: ["References"],
            first: [
                "Cambridge, Industrial and Organizational Psychology Journal"
            ],
            second: ["PWC Study"]
        },

        categories: [
            CAT_PERFORMANCE_REVIEWS,
            CAT_PRODUCTIVITY,
            CAT_CONTINUOUS_FEEDBACK,
            CAT_CONTINUOUS_IMPROVEMENT,
            CAT_HR
        ],
        altText: {
            principlesOfFeedback: [
                "Four hexagons represent the principles of an effective feedback system. 1. Enamply Cycle, reading: Perform task, Analyze feedback, Upgrade Skill, and Adjust Plan. 2. A question mark over a process cog with the Enamply cycle suggests that feedback must be about the process. 3. A timeline graph with an uptrend, and an Enamply cycle over it, meaning that variation and lessons learned must be part of the feedback. 4. An uptrend of 4 Enamply cycles indicating incremental improvements."
            ],
            feedbackHabit: [
                "A person works at the computer with 4 interconnected elements over her: The Enamply cycle (Perform task, Analyze feedback, Upgrade Skill, and Adjust Plan), a process cog, a clock, and documents. The image suggests that one must develop the habit of quick, frequent, and specific feedback."
            ],
            feedbackHow: [
                "A person looks to a set of process cogs and holds a tool. Inside a cog sits the Enamply cycle (Perform task, Analyze feedback, Upgrade Skill, and Adjust Plan), and over the cog, there is a question mark. The image suggests that a feedback system must ensure that Feedback the HOW, not the WHAT."
            ],
            feedbackLessons: [
                "A person points to a pie chart with an Enamply cycle (Perform task, Analyze feedback, Upgrade Skill, and Adjust Plan) inside. On the left side is an uptrend timeline graph with an Enamply cycle over. The image suggests that a feedback system must Capture lessons learned and embrace variations of human actions."
            ],
            actionableFeedback: [
                "Three Enamply cycles (Perform task, Analyze feedback, Upgrade Skill, and Adjust Plan) arranged on an uptrend. Over the cycles stands a person that reaches a 12 sides star representing a goal. The image suggests that Feedback must be actionable and lead to incremental improvements."
            ]
        }
    }
};
