import { AvgRangeChart } from "ComponentIndex";
import { ConditionalOutput } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { useGetRangeAvgSkillData } from "./Utils";

const X_ACCESSOR = (d) => {
    return d.xAxis;
};

const Y_ACCESSOR = (d) => {
    return d.yAxis;
};

const RangeAvgSkill = () => {
    const content = appContentVar().RangeAvgSkill;

    const { data, loading, error } = useGetRangeAvgSkillData();

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <AvgRangeChart
                    data={data.skillsVariance}
                    chartName={content.chartName[0]}
                    yAxisLabel={content.yAxisLabel[0]}
                    xAccessor={X_ACCESSOR}
                    yAccessor={Y_ACCESSOR}
                    nameItem={"skillName"}
                />
            ) : null}
        </ConditionalOutput>
    );
};

export default RangeAvgSkill;
