import React from "react";
import {
    Hr,
    SelectAllForSync,
    LoadParameterList,
    SyncParameters
} from "ComponentIndex";

const MangeProjects = ({ feedbackSpace }) => {
    return (
        <div className={"flex flex-col"}>
            <div className={" flex flex-row justify-between"}>
                <h1 className={"title_small text-secondary-800"}>
                    Projects sync setup
                </h1>
                <SyncParameters
                    title={"Update list from data source"}
                    feedbackSpaceId={feedbackSpace.id}
                    parameter={"projects"}
                />
            </div>
            <div className={"mb-6"}>
                <Hr weight={"small"} color={"dark"} />
            </div>
            <SelectAllForSync
                parameter={"projectsSync"}
                feedbackSpace={feedbackSpace}
            />
            <LoadParameterList
                feedbackSpaceId={feedbackSpace.id}
                parameter={"projects"}
            />
        </div>
    );
};

export default MangeProjects;
