import { gql } from "@apollo/client";

// Sync Queries
export let CELERY_TASK_STATUS = gql`
    query celeryTaskStatus($celeryTaskId: String!) {
        celeryTaskStatus(celeryTaskId: $celeryTaskId) {
            celeryTaskId
            celeryTaskStatus
        }
    }
`;

// ASANA registering queries

export let ASANA_TOKEN_VERIF_QUERY = gql`
    query asanaTokenVerif {
        asanaTokenVerif
    }
`;

export let ME_QUERY = gql`
    query {
        me {
            id
            email
            username
            isVerified
        }
    }
`;

//  Core feedback queries

export let GET_TASKS_QUERY = gql`
    query tasks(
        $feedbackStatus: String!
        $feedbackSpaceId: Int!
        $page: Int
        $pageSize: Int
        $search: String
        $tskStartDate: Date
        $tskEndDate: Date
        $projectIds: [Int]
        $assigneeParametersIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        tasks(
            feedbackStatus: $feedbackStatus
            feedbackSpaceId: $feedbackSpaceId
            page: $page
            pageSize: $pageSize
            search: $search
            projectIds: $projectIds
            assigneeParametersIds: $assigneeParametersIds
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            page
            pages
            length
            objects {
                basicfeedbackstatusSet {
                    id
                    givenBy {
                        id
                        email
                        username
                    }
                }
                taskName
                id
                taskType {
                    taskType
                }
                completedAt
                dueAt
                parentTask {
                    id
                    taskName
                }
                project {
                    id
                    projectName
                }
                assigneeParameters {
                    id
                    assigneeName
                }
            }
        }
    }
`;

export let PROJECTS_QUERY = gql`
    query projects(
        $feedbackSpaceId: Int!
        $projectSyncStatus: Boolean
        $assigneeParametersIds: [Int]
    ) {
        projects(
            feedbackSpaceId: $feedbackSpaceId
            projectSyncStatus: $projectSyncStatus
            assigneeParametersIds: $assigneeParametersIds
        ) {
            id
            projectName

            projectSyncStatus
        }
    }
`;

// Filters

export let ASSIGNEE_PARAMETERS_FILTER_QUERY = gql`
    query assigneesParameters(
        $feedbackSpaceId: Int!
        $assigneeSyncStatus: Boolean
        $projectIds: [Int]
        $skillIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        assigneesParameters(
            feedbackSpaceId: $feedbackSpaceId
            assigneeSyncStatus: $assigneeSyncStatus
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            id
            assigneeName
        }
    }
`;

export let TASK_TYPE_FILTER_QUERY = gql`
    query taskType(
        $feedbackSpaceId: Int!
        $projectIds: [Int]
        $skillIds: [Int]
        $skillTypes: [String]
        $assigneeParametersIds: [Int]
        $dataSourceIds: [Int]
    ) {
        taskType(
            feedbackSpaceId: $feedbackSpaceId
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            assigneeParametersIds: $assigneeParametersIds
            dataSourceIds: $dataSourceIds
        ) {
            id
            taskType
        }
    }
`;

export let DATA_SOURCE_FILTER_QUERY = gql`
    query dataSource(
        $feedbackSpaceId: Int!
        $projectIds: [Int]
        $skillIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $assigneeParametersIds: [Int]
    ) {
        dataSource(
            feedbackSpaceId: $feedbackSpaceId
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            assigneeParametersIds: $assigneeParametersIds
            taskTypeNames: $taskTypeNames
        ) {
            id
            dataSource
        }
    }
`;

export let PROJECT_FILTER_QUERY = gql`
    query projects(
        $feedbackSpaceId: Int!
        $projectSyncStatus: Boolean
        $assigneeParametersIds: [Int]
        $skillIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        projects(
            feedbackSpaceId: $feedbackSpaceId
            projectSyncStatus: $projectSyncStatus
            assigneeParametersIds: $assigneeParametersIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            id
            projectName
        }
    }
`;
export let FEEDBACK_SPACE_SKILL_NAME_FILTER_QUERY = gql`
    query skillsInFeedbackSpace(
        $feedbackSpaceId: Int!
        $assigneeParametersIds: [Int]
        $projectIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        skillsInFeedbackSpace(
            feedbackSpaceId: $feedbackSpaceId
            assigneeParametersIds: $assigneeParametersIds
            projectIds: $projectIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            id
            skill {
                id
                skillName
            }
        }
    }
`;
export let FEEDBACK_SPACE_SKILL_TYPE_FILTER_QUERY = gql`
    query skillsInFeedbackSpace(
        $feedbackSpaceId: Int!
        $assigneeParametersIds: [Int]
        $projectIds: [Int]
        $skillIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        skillsInFeedbackSpace(
            feedbackSpaceId: $feedbackSpaceId
            assigneeParametersIds: $assigneeParametersIds
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            id
            skill {
                id
                skillType
            }
        }
    }
`;

export let SKILLS_FROM_FEEDBACK_SPACE_QUERY = gql`
    query skillsInFeedbackSpace(
        $skillTypes: [String]
        $feedbackSpaceId: Int!
    ) {
        skillsInFeedbackSpace(
            skillTypes: $skillTypes
            feedbackSpaceId: $feedbackSpaceId
        ) {
            skill {
                id
                skillName
                skillType
            }
        }
    }
`;

// CACHE QUERIES
// TODO: if everythin works withou it please delete (used Reactive Var)
export let ME_QUERY_CACHE = gql`
    query {
        me @client {
            id
            email
        }
    }
`;

// REPORTING QUERIES

export let RESUME_TABLE_QUERY = gql`
    query tasks(
        $page: Int
        $feedbackStatus: String!
        $feedbackSpaceId: Int!
        $search: String
        $pageSize: Int
        $fbStartDate: Date
        $fbEndDate: Date
        $tskStartDate: Date
        $tskEndDate: Date
        $projectIds: [Int]
        $assigneeParametersIds: [Int]
        $taskTypeNames: [String]
        $orderBy: Int
        $dataSourceIds: [Int]
    ) {
        tasks(
            page: $page
            feedbackStatus: $feedbackStatus
            feedbackSpaceId: $feedbackSpaceId
            search: $search
            pageSize: $pageSize
            fbStartDate: $fbStartDate
            fbEndDate: $fbEndDate
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            projectIds: $projectIds
            assigneeParametersIds: $assigneeParametersIds
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
            orderBy: $orderBy
        ) {
            page
            pages
            length
            objects {
                id
                taskName
                project {
                    id
                    projectName
                }

                assigneeParameters {
                    id
                    assigneeName
                }

                basicfeedbackstatusSet {
                    id
                    givenBy {
                        id
                        email
                        username
                    }
                }
                textfeedbackSet {
                    id
                    textFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                continuefeedbackSet {
                    id
                    continueFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                improvefeedbackSet {
                    id
                    improveFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                stopfeedbackSet {
                    id
                    stopFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                satisfactionrangeSet {
                    id
                    dateAdded
                    upperValue
                    lowerValue
                }
            }
        }
    }
`;

export let VARIANCE_TO_SATISFACTION_QUERY = gql`
    query varianceToSatisfaction(
        $feedbackSpaceId: Int!
        $tskStartDate: Date
        $tskEndDate: Date
        $projectIds: [Int]
        $assigneeParametersIds: [Int]
        $search: String
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        varianceToSatisfaction(
            feedbackSpaceId: $feedbackSpaceId
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            projectIds: $projectIds
            assigneeParametersIds: $assigneeParametersIds
            search: $search
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            assigneeName
            yAxis
            xAxis
        }
    }
`;

export let FEEDBACK_EVOLUTION_QUERY = gql`
    query feedbackEvolution(
        $feedbackSpaceId: Int!
        $tskStartDate: Date
        $tskEndDate: Date
        $projectIds: [Int]
        $assigneeParametersIds: [Int]
        $search: String
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        feedbackEvolution(
            feedbackSpaceId: $feedbackSpaceId
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            projectIds: $projectIds
            assigneeParametersIds: $assigneeParametersIds
            search: $search
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            median
            lowerQ
            upperQ
            period
        }
    }
`;

// TODO: verify info related to dates of the report

export const SKILLS_FREQUENCY_QUERY = gql`
    query skillsFrequency(
        $feedbackSpaceId: Int!
        $tskStartDate: Date
        $tskEndDate: Date
        $assigneeParametersIds: [Int]
        $skillIds: [Int]
        $projectIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        skillsFrequency(
            feedbackSpaceId: $feedbackSpaceId
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            assigneeParametersIds: $assigneeParametersIds
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            skillName
            frequency
            assignees {
                assignee
                frequency
            }
        }
    }
`;

export const SKILLS_VARIANCE_QUERY = gql`
    query skillsVariance(
        $feedbackSpaceId: Int!
        $tskStartDate: Date
        $tskEndDate: Date
        $assigneeParametersIds: [Int]
        $skillIds: [Int]
        $projectIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        skillsVariance(
            feedbackSpaceId: $feedbackSpaceId
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            assigneeParametersIds: $assigneeParametersIds
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            skillName
            yAxis
            xAxis
        }
    }
`;

export const SKILLS_MATRIX_QUERY = gql`
    query skillMatrix(
        $feedbackSpaceId: Int!
        $tskStartDate: Date
        $tskEndDate: Date
        $assigneeParametersIds: [Int]
        $skillIds: [Int]
        $projectIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
        skillMatrix(
            feedbackSpaceId: $feedbackSpaceId
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            assigneeParametersIds: $assigneeParametersIds
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            body {
                assignee
                skills {
                    skillName
                    occurancess
                    feedbackHex
                }
            }
            header
        }
    }
`;

// TODO verify  fbStartDate fbEndDate
export let SKILLS_TAG_TABLE_QUERY = gql`
    query tasks(
        $feedbackStatus: String!
        $feedbackSpaceId: Int!
        $fbStartDate: Date
        $fbEndDate: Date
        $assigneeParametersIds: [Int]
        $skillIds: [Int]
        $projectIds: [Int]
        $skillTypes: [String]
        $taskTypeNames: [String]
    ) {
        tasks(
            feedbackStatus: $feedbackStatus
            feedbackSpaceId: $feedbackSpaceId
            fbStartDate: $fbStartDate
            fbEndDate: $fbEndDate
            assigneeParametersIds: $assigneeParametersIds
            projectIds: $projectIds
            skillIds: $skillIds
            skillTypes: $skillTypes
            taskTypeNames: $taskTypeNames
        ) {
            objects {
                id

                assigneeParameters {
                    id
                    assigneeName
                }

                satisfactionrangeSet {
                    id
                    dateAdded
                    upperValue
                    lowerValue
                }
                basicfeedbackstatusSet {
                    id
                    asociatedSkills {
                        id
                        skillName
                    }
                }
                textfeedbackSet {
                    id
                    tags {
                        id
                        tagName
                    }
                }
                continuefeedbackSet {
                    id
                    tags {
                        id
                        tagName
                    }
                }
                improvefeedbackSet {
                    id
                    tags {
                        id
                        tagName
                    }
                }
                stopfeedbackSet {
                    id
                    tags {
                        id
                        tagName
                    }
                }
            }
        }
    }
`;

export let VIEW_TASKS_FEEDBACK = gql`
    query tasks(
        $feedbackStatus: String!
        $feedbackSpaceId: Int!
        $taskId: Int
    ) {
        tasks(
            feedbackStatus: $feedbackStatus
            feedbackSpaceId: $feedbackSpaceId
            taskId: $taskId
        ) {
            objects {
                id
                basicfeedbackstatusSet {
                    id
                    asociatedSkills {
                        id
                        skillName
                        skillType
                    }
                }
                textfeedbackSet {
                    id
                    textFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                stopfeedbackSet {
                    id
                    stopFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                continuefeedbackSet {
                    id
                    continueFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                improvefeedbackSet {
                    id
                    improveFeedbackDescription
                    tags {
                        id
                        tagName
                    }
                }
                satisfactionrangeSet {
                    id
                    upperValue
                    lowerValue
                }
                basicfeedbackstatusSet {
                    asociatedSkills {
                        skillName
                        skillType
                        id
                    }
                }
            }
        }
    }
`;

// used in asana setup
export let GET_PROJECT_QUERY = gql`
    query projects($feedbackSpaceId: Int!) {
        projects(feedbackSpaceId: $feedbackSpaceId) {
            id
            projectName
            projectSyncStatus
        }
    }
`;

export let GET_ASSIGNEE_PARAMETERS_QUERY = gql`
    query assigneesParameters($feedbackSpaceId: Int!) {
        assigneesParameters(feedbackSpaceId: $feedbackSpaceId) {
            id
            assigneeSyncStatus
            assigneeName
            feedbackSpace {
                id
                feedbackSpaceName
            }
        }
    }
`;

export const FEEDBACK_SPACE_USERS_QUERY = gql`
    query feedbackSpaceUsers($feedbackSpaceId: Int!) {
        feedbackSpaceUsers(feedbackSpaceId: $feedbackSpaceId) {
            id
            role
            feedbackSpace {
                id
                feedbackSpaceName
            }
            memberAccount {
                email
                id
            }
        }
    }
`;

export const GET_MY_FEEDBACK_SPACES_QUERY = gql`
    query {
        feedbackSpaces {
            id
            feedbackSpaceName
            roleinfeedbackspaceSet {
                id
                role
            }
        }
    }
`;

export const GET_MY_FEEDBACK_SPACE_INVITATIONS_QUERY = gql`
    query feedbackSpaceInvitations($statusList: [String]) {
        feedbackSpaceInvitations(statusList: $statusList) {
            id
            feedbackSpace {
                id
                feedbackSpaceName
            }
            status
        }
    }
`;

export const GET_USER_PREFERANCES_QUERY = gql`
    query {
        userPreferances {
            productTour
            currentFeedbackSpace {
                id
                feedbackSpaceName
                roleinfeedbackspaceSet {
                    id
                    role
                }
            }
        }
    }
`;

export const GET_SKILL_LIST_QUERY = gql`
    query skills($searchCriteria: String, $skillTypes: [String]) {
        skills(
            searchCriteria: $searchCriteria
            skillTypes: $skillTypes
        ) {
            id
            skillName
            skillType
            skillDescription
        }
    }
`;

export const GET_SKILL_LIST_FOR_SETUP_QUERY = gql`
    query skills($skillTypes: [String]) {
        skills(skillTypes: $skillTypes) {
            id
            skillName
        }
    }
`;

export const GET_SKILL_TYPES_QUERY = gql`
    query {
        skills {
            id
            skillType
        }
    }
`;

export const GET_ALL_TAGS_QUERY = gql`
    query tags($feedbackSpaceId: Int!) {
        tags(feedbackSpaceId: $feedbackSpaceId) {
            id
            tagName
        }
    }
`;

// Account settings queries

export const GET_USER_NOTIFICATIONS_PREFERANCES = gql`
    query userNotificationPreference($feedbackSpaceId: Int!) {
        userNotificationPreference(feedbackSpaceId: $feedbackSpaceId) {
            feedbackNotification
            accountDailyDigest
            accountWeeklyDigest
            feedbackSpaceDailyDigest
            feedbackSpaceWeeklyDigest
        }
    }
`;

// Dashboard

export const GET_DASHBOARD_DATA_QUERY = gql`
    query dashboardData($feedbackSpaceId: Int!
        $tskStartDate: Date
        $tskEndDate: Date
        $projectIds: [Int]
        $assigneeParametersIds: [Int]
        $taskTypeNames: [String]
        $dataSourceIds: [Int]
    ) {
            dashboardData(feedbackSpaceId: $feedbackSpaceId,
            projectIds: $projectIds
            assigneeParametersIds: $assigneeParametersIds
            tskStartDate: $tskStartDate
            tskEndDate: $tskEndDate
            taskTypeNames: $taskTypeNames
            dataSourceIds: $dataSourceIds
        ) {
            pendingTasks
            skippedTasks
            givenTasks
            oldestTask {
                id
                taskName
                completedAt
            }
            avgFeedbackTime
        }
    }
`;

// Atlassian

export const GET_ATLASSIAN_SITES_QUERY = gql`
    query {
        atlassianSites {
            siteName
            siteUrl
            avatarUrl
            authorizedToSync
        }
    }
`;

