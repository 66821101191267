import { useQuery } from "@apollo/client";
import {
    GET_PROJECT_QUERY,
    GET_ASSIGNEE_PARAMETERS_QUERY
} from "GraphQL/Queries";

const loadParameters = (parameter) => {
    switch (parameter) {
        case "projects":
            return [
                {
                    instruction: GET_PROJECT_QUERY,
                    dataHeader: "projects",
                    id: "id"
                },
                {
                    accessor: "project",
                    syncParm: "syncStatus",
                    idParam: "id",
                    name: "name"
                }
            ];
        case "assigneesParameters":
            return [
                {
                    instruction: GET_ASSIGNEE_PARAMETERS_QUERY,
                    dataHeader: "assigneesParameters",
                    id: "id"
                },
                {
                    accessor: "assigneesParameters",
                    syncParm: "syncStatus",
                    idParam: "id",
                    name: "name"
                }
            ];
        default:
            return null;
    }
};

export const useLoadParameterList = (parameter, feedbackSpaceId) => {
    const [param, accessor] = loadParameters(parameter);
    let { data, error, loading } = useQuery(param.instruction, {
        variables: {
            feedbackSpaceId: feedbackSpaceId
        },
        fetchPolicy: "network-only"
    });

    return { accessor, data, loading, error };
};
