import React from "react";

const BlogCompartmentHeader = ({ children }) => {
    return (
        <div className={"flex flex-row gap-x-8 items-center"}>
            {children}
        </div>
    );
};

export default BlogCompartmentHeader;
