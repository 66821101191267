import React from "react";
import {
    LeaveFeedbackSpaceButton,
    RemoveFromFeedbackSpace,
    FeedbackSpaceRole,
    TransferRightsDialog
} from "ComponentIndex";
import {
    transferRightsValidator,
    changeRoleValidator,
    removeValidator,
    leaveValidator
} from "./Utils";

const MemberActionPart = ({ recordItem, myRole }) => {
    return (
        <div className={"flex flex-row gap-x-6"}>
            {changeRoleValidator(myRole, recordItem.role) ? (
                <FeedbackSpaceRole recordItem={recordItem} />
            ) : null}
            {removeValidator(recordItem, myRole, recordItem.role) ? (
                <RemoveFromFeedbackSpace recordItem={recordItem} />
            ) : null}
            {leaveValidator(recordItem, myRole, recordItem.role) ? (
                <LeaveFeedbackSpaceButton
                    feedbackSpace={recordItem.feedbackSpace}
                />
            ) : null}
            {transferRightsValidator(myRole, recordItem.role) ? (
                <TransferRightsDialog recordItem={recordItem} />
            ) : null}
        </div>
    );
};

export default MemberActionPart;
