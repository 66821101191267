import React from "react";
import cn from "classnames";

const Tr = ({ children, rowProps, hover = false }) => {
    const trClass = cn({
        "hover:bg-shocking-100 hover_text": hover === true
    });
    return (
        <tr className={trClass} {...rowProps}>
            {children}
        </tr>
    );
};

export default Tr;
