import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import { GET_DASHBOARD_DATA_QUERY } from "GraphQL/Queries";
import { useUnifiedFilterItems } from "HookIndex";
import { useFeedbackQuery } from "Screens/Feedback/Content/TaskFeedbackContent/Utils";



export const useDashboardData = () => {
    let [pendingPercent, setPendingPercent] = useState(0)
    let [givenPercent, setGivenPercent] = useState(0)
    let [skippedPercent, setSkippedPercent] = useState(0)
    let [skippedRatio, setSkippedRatio] = useState(0)
    const {
        taskStartDate,
        taskEndDate,
        projectIds,
        assigneeParametersIds,
        currentFeedbackSpace,
        taskTypeNames,
        dataSourceIds
    } = useUnifiedFilterItems();

    const { data, error, loading } = useQuery(
        GET_DASHBOARD_DATA_QUERY,
        {
            variables: {
                feedbackSpaceId: currentFeedbackSpace.id,
                taskStartDate:taskStartDate,
                taskEndDate:taskEndDate,
                projectIds:projectIds,
                assigneeParametersIds:assigneeParametersIds,
                taskTypeNames:taskTypeNames,
                dataSourceIds:dataSourceIds,
            },
            fetchPolicy: "network-only"
        }
    );


    useEffect(()=>{
        if (data && data.dashboardData.avgFeedbackTime !== null){
            let totalTasks = 
            (data.dashboardData.pendingTasks+
            data.dashboardData.givenTasks+
            data.dashboardData.skippedTasks)

            setPendingPercent(Math.round( data.dashboardData.pendingTasks / totalTasks *100))
            setGivenPercent(Math.round(data.dashboardData.givenTasks / totalTasks *100))
            setSkippedPercent(Math.round(data.dashboardData.skippedTasks / totalTasks *100))
            setSkippedRatio(Math.round(data.dashboardData.skippedTasks /data.dashboardData.givenTasks *100 ))

        }
    },[loading,data])

    return { data, error, loading, pendingPercent, givenPercent, skippedPercent, skippedRatio };
};
