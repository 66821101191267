import React from "react";

const MenuItem = ({ children }) => {
    return (
        <div
            className={
                "flex flex-row content-start gap-x-4 items-center rounded-md w-60 py-2 px-3 cursor-pointer text-secondary-900 hover:text-white hover:bg-shocking-700 menu-item-hover"
            }>
            {children}
        </div>
    );
};

export default MenuItem;
