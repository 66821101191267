import React, { useState } from "react";
import {
    AccordionItem,
    ContentWrapperBand,
    PublicSection
} from "ComponentIndex";
import {
    TaskBasedQuestion,
    TaskBasedAnswer,
    CognitiveBiasQuestion,
    CognitiveBiasAnswer,
    KeepTrakOnTasksQuestion,
    KeepTrakOnTasksAnswer,
    BiasInPerformanceQuestion,
    BiasInPerformanceAnswer,
    HowToStartQuestion,
    HowToStartAnswer,
    PriceQuestion,
    PriceAnswer,
    WithoutSkillsQuestion,
    WithoutSkillsAnswer,
    RequirementsQuestion,
    RequirementsAnswer,
    AllreadyHaveQuestion,
    AllreadyHaveAnswer
} from "./Content/Questions";

const FrequentlyAskedQuestions = () => {
    const [open, setOpen] = useState(null);
    return (
        <ContentWrapperBand className={"bg-shocking-100"}>
            <PublicSection
                textColor={"text-shocking-700"}
                verticalHeader={"QUESTIONS"}
                horizontalHeader={"ASKED"}>
                <div className={"flex flex-row justify-center w-full "}>
                    <ul className={"sm:w-full max-w-2xl"}>
                        <AccordionItem
                            buttonName={TaskBasedQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={1}>
                            {<TaskBasedAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={CognitiveBiasQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={2}>
                            {<CognitiveBiasAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={KeepTrakOnTasksQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={3}>
                            {<KeepTrakOnTasksAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={BiasInPerformanceQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={4}>
                            {<BiasInPerformanceAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={HowToStartQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={5}>
                            {<HowToStartAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={PriceQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={6}>
                            {<PriceAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={WithoutSkillsQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={7}>
                            {<WithoutSkillsAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={RequirementsQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={8}>
                            {<RequirementsAnswer />}
                        </AccordionItem>
                        <AccordionItem
                            buttonName={AllreadyHaveQuestion}
                            setExpanded={setOpen}
                            expanded={open}
                            index={9}>
                            {<AllreadyHaveAnswer />}
                        </AccordionItem>
                    </ul>
                </div>
            </PublicSection>
        </ContentWrapperBand>
    );
};

export default FrequentlyAskedQuestions;
