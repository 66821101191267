import { ImpactfullFeedbackArticleLink } from "BlogLinkIndex";
import {
    HelmetInclude,
    BlogKicker,
    BlogHeader,
    BlogSubHeader,
    BlogTakeAwayHeader,
    BlogOli,
    BlogCompartmentHeader,
    BlogParagraphHeader,
    BlogParagraph,
    BlogReferanceHeader,
    BlogReferance,
    BlogCategory,
    BlogCaption,
    BlogLink
} from "ComponentIndex";
import {
    blogContentVar,
    currentArticleVar
} from "GraphQL/ReactiveVariables";
import { useMoveToPageTop } from "HookIndex";

import IncremetalPerformanceImg from "Images/Blog/TaskBasedFeedbackArticle/incremental-performance.png";
import StrategicPerspectiveImg from "Images/Blog/TaskBasedFeedbackArticle/strategic-perspective.png";
import TaskFeebackSkillImg from "Images/Blog/TaskBasedFeedbackArticle/task-feedback-skill.png";

const FIRST_REFERANCE =
    "https://www.gallup.com/workplace/285674/improve-employee-engagement-workplace.aspx";

const TaskBasedFeedbackArticle = ({ articleName }) => {
    const content = blogContentVar().TaskBasedFeedbackArticle;
    currentArticleVar(articleName);
    useMoveToPageTop();
    return (
        <>
            <HelmetInclude content={content} />
            <div>
                <BlogCaption caption={content.captions.header[0]}>
                    <img
                        src={IncremetalPerformanceImg}
                        alt={content.altText.headerText[0]}
                    />
                </BlogCaption>
                <div className={"py-8"}>
                    <BlogKicker content={content} />
                    <BlogHeader content={content} />
                    <BlogSubHeader content={content} />
                </div>
                <div>
                    <BlogTakeAwayHeader content={content} />

                    <ol className={"list-inside"}>
                        {content.mainTakeAway.content.map(
                            (item, index) => (
                                <BlogOli key={`takeaways${index}`}>
                                    {item}
                                </BlogOli>
                            )
                        )}
                    </ol>
                </div>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.taskBasedFeedback.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.taskBasedFeedback.content[0]}
                    <BlogLink to={ImpactfullFeedbackArticleLink}>
                        {content.taskBasedFeedback.content[1]}
                    </BlogLink>
                    {content.taskBasedFeedback.content[2]}
                </BlogParagraph>
                <img
                    src={TaskFeebackSkillImg}
                    alt={content.altText.triad[0]}
                />
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.taskUnitOfEffort.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.taskUnitOfEffort.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.taskUnitOfEffort.content[1]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.taskHow.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.taskHow.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.taskHow.content[1]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.costEfficient.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.costEfficient.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.costEfficient.content[1]}
                </BlogParagraph>
                <img
                    src={StrategicPerspectiveImg}
                    alt={content.altText.continuousImprovement[0]}
                />
                <BlogParagraph>
                    {content.costEfficient.content[2]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <BlogParagraphHeader>
                        {content.taskThatMatter.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.taskThatMatter.content[0]}
                </BlogParagraph>
                <div className={"mt-10"}>
                    <BlogReferanceHeader content={content} />
                    <BlogReferance to={FIRST_REFERANCE}>
                        {content.referancess.first[0]}
                    </BlogReferance>
                </div>
                <BlogCategory content={content} />
            </div>
        </>
    );
};

export default TaskBasedFeedbackArticle;
