import React from "react";
import { Content, MessagePage } from "ComponentIndex";

const NoFeedbackSpaceContent = () => {
    return (
        <Content>
            <MessagePage
                message={"Please select feedback space or create one."}
            />
        </Content>
    );
};

export default NoFeedbackSpaceContent;
