import React, { useState, useRef } from "react";
import cn from "classnames";
import { useClickOutside } from "HookIndex";
import { IconButton } from "ComponentIndex";

const dropDownClass = cn([
    "scale-up-tl ",
    "absolute  top-10 left-2 border",
    "border-secondary-300/10 bg-secondary-800",
    "rounded-md shadow-lg w-72",
    "flex flex-col h-96",
    "py-4 px-4 justify-between"
]);

const AppHeaderMenu = ({ children }) => {
    const [open, setOpen] = useState(false);
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    return (
        <div className={"relative lg:hidden "}>
            {open ? (
                <IconButton
                    variant={"standard"}
                    size={"large"}
                    color={"light"}
                    icon={"more_vert"}
                    onClick={() => setOpen(false)}
                />
            ) : (
                <IconButton
                    variant={"standard"}
                    size={"large"}
                    icon={"more_vert"}
                    onClick={() => setOpen(true)}
                />
            )}
            {open ? (
                <div ref={containerRef} className={dropDownClass}>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export default AppHeaderMenu;
