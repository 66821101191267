import React from "react";

import { useReactiveVar } from "@apollo/client";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    Notifications,
    ShallowSyncIcon,
    Profile,
    Logo,
    AppHeaderMenu,
    DrawerNavigation,
    DrawerFeedbackSpaces,
    DrawerPersonalSettings,
    HelpMenu
} from "ComponentIndex";

const AppHeader = () => {
    let workSpace = useReactiveVar(workspaceNameVar);
    return (
        <header
            className={
                "relative top-0 z-10 w-full flex flex-row justify-between h-12  bg-secondary-800 px-4  py-2 items-center"
            }>
            <div className={"flex flex-row gap-x-4 items-center"}>
                <AppHeaderMenu>
                    <div className={"flex flex-col gap-y-12"}>
                        <DrawerNavigation />
                        <DrawerFeedbackSpaces />
                    </div>
                    <DrawerPersonalSettings />
                </AppHeaderMenu>
                <Logo />
            </div>
            <div>
                <h1 className={"title_medium text-secondary-50"}>
                    {workSpace}
                </h1>
            </div>
            <div
                id={"test_place_2"}
                className={
                    "flex flex-row gap-x-6 items-center align-middle"
                }>
                <Notifications />
                <HelpMenu />
                <ShallowSyncIcon />
                <Profile />
            </div>
        </header>
    );
};

export default AppHeader;
