import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import { Route, Routes } from "react-router-dom";
import {
    BlogIndex,
    ContentWrapperBand,
    HomeFooter,
    BlogArticles
} from "ComponentIndex";
import { useBlogContent } from "CustomHooks/CustomHooks";

const BlogContent = () => {
    workspaceNameVar("Blog");
    useBlogContent();
    return (
        <>
            <ContentWrapperBand
                color={"bg-white"}
                id={"blog-index"}
                padding={16}>
                <Routes>
                    <Route path={""} element={<BlogIndex />} />
                    <Route
                        path={"article/*"}
                        element={<BlogArticles />}
                    />
                </Routes>
            </ContentWrapperBand>
            <HomeFooter />
        </>
    );
};

export default BlogContent;
