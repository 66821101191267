import React from "react";
import { appContentVar } from "GraphQL/ReactiveVariables";

const EvaluationRangeInfo = () => {
    const content = appContentVar().EvaluationRangeInfo;
    return (
        <>
            <p
                className={
                    "body_small font-semibold text-secondary-900"
                }>
                {content.intro[0]}
            </p>
            <div className={"flex flex-col gap-y-4 mt-6"}>
                <p className={"body_small text-secondary-900"}>
                    <span className={"font-medium"}>
                        {content.concerned[0]}
                    </span>
                    {content.concerned[1]}
                </p>
                <p className={"body_small text-secondary-900"}>
                    <span className={"font-medium"}>
                        {content.neutral[0]}
                    </span>
                    {content.neutral[1]}
                </p>
                <p className={"body_small text-secondary-900"}>
                    <span className={"font-medium"}>
                        {content.satisfied[0]}
                    </span>
                    {content.satisfied[1]}
                </p>
                <p className={"body_small text-secondary-900"}>
                    <span className={"font-medium"}>
                        {content.exited[0]}
                    </span>
                    {content.exited[1]}
                </p>
            </div>
        </>
    );
};

export default EvaluationRangeInfo;
