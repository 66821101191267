const SAVE_BUTTON = "SAVE";
const STOP_LABEL = "Stop";
const CONTINUE_LABEL = "Continue";
const IMPROVE_LABEL = "Improve";
const FWEET_LABEL = "Fweet";
const READ_MORE = "Read More...";
const SKILL_NAME = "Name";
const SKILL_TYPE = "Type";
const SKILL_DESCRIPTION = "Description";
const GIVEN_BY_LABEL = "Given by:";
const ASSIGNEE_LABEL = "Assignee:";
const PROJECT_LABEL = "Project:";
const COMPLETED_AT_LABEL = "Completed at:";
const GIVEN_AT_LABEL = "Given at:";
const TAGS_LABEL = "Tags";
const EMAIL = "Email";
const PASSWORD = "Password";
const RESETPASSWORD = "Reset password";

export const content_eng = {
    charts: {
        feedbackEvolution: {
            chartName: "How feedback evolved in time",
            xAxisLabel: "(week/year)"
        }
    },
    info: {
        addSkiills: {
            1: "You can search and add skills to the task; thus, feedback will evaluate skills development. ",
            2: "You can add new skills to ",
            3: "Skills Inventory."
        },

        skillVariance: {
            1: 'The graph indicates the level of variation in the feedback per skill. Variation results from the spread (Min and Max) applied on the scale when giving feedback per task. High variation results from using spreads that go over one level of evaluation (Example: "Concerned" to "Satisfied"). In contrast, you register low variation if the spread is around the same level.',
            2: 'A skill achieves the best level of development when it is in the "Satisified" to " Excited" area and registers low variation.'
        },
        feedbakEvolution: {
            1: "The timeline diagram shows the evolution of feedback in time. The black line is the Median (50% line) which indicates half of the evaluations. The white area covers most of the feedback evaluations. The width of the white area shows the level of variation in the task feedback."
        },
        infoTableFeedback: {
            1: "The table shows the hashtags per feedback and overall level of evaluation according to the scale (colors). You can see the detailed feedback per task by mouse over the hashtags or clicking on the info icons. Filters apply to the table as well as to the graphs."
        },
        skillFrequency: {
            1: "The graph shows how often a skill was applied. The frequency is measured based on the number of tasks with feedback and allocated skill. If you can't see a specific skill in the graph, there is no feedback for tasks with the skill of interest."
        },
        boxWhiskerSkill: {
            1: '"Box and whisker diagram" shows the distribution of feedback results per skill. The bigger the box area, the more variation in the task feedback. If the Median (50% line) is closer to the 25% mark, more feedback measurement tends to have a lower evaluation. And vice versa, if the Median is closer to the 75% mark, most of the results have a higher measurement. The whiskers show the Min and Max extremes of the evaluations.',
            2: "A smaller difference between the 25% and 75% mark indicates a more stable result."
        },
        InfoTableSkillInfo: {
            1: "The table shows the hashtags per skill and overall level of evaluation according to the scale (colors). You can see the list of tasks by clicking on the info icons. Filters apply to the table as well as to the graphs."
        },
        varianceEvaluationPlot: {
            1: 'The graph indicates the level of variation in the feedback. Variation results from the spread (Min and Max) applied on the scale when giving feedback per task—high variation results from using spreads that go over one level of evaluation (Example: "Concerned" to "Satisfied"). In contrast, you register low variation if the spread is around the same level.',
            2: "Low variation and high levels of evaluations indicate that the feedback is positive."
        }
    },
    skillCategory: {
        noneSelected: {
            1: "No skill type selected",
            2: "Please selecte skill type for description",
            examples: ["Select skill type for examples"]
        },
        interpersonal: {
            1: "Interpersonal skills",
            2: "We use Interpersonal skills every day when interacting with other people, both individually and in groups. People with strong interpersonal skills tend to work well with other people, formally and informally.",
            examples: [
                "Verbal communication",
                "Non-verbal Communication",
                "Active-Listening",
                "Collaboration",
                "Open-mindedness",
                "Persuasion",
                "Negotiation",
                "Emotional",
                "Intelligence"
            ]
        },
        areaSpecific: {
            1: "Aria Specific Skills",
            2: "These are the specialized abilities, knowledge, or expertise required to execute specific, job-related tasks. Aria Specific Skills are a prerequisite to performing the role in the organization.",
            examples: [
                "Sampling",
                "Research Methodology",
                "Descriptive Statistics",
                "Questionnaire elaboration",
                "Data visualization (graphs and schemes)",
                "Interviewing"
            ]
        },
        technical: {
            1: "Technical Skills",
            2: "Technical skills are practical because they allow an individual to complete a designated task in the real world using specific tools and the technologies required to use those tools.",
            examples: [
                "Google Suite (Docs, Sheets, Slides, Forms)",
                "MS Office suite",
                "Google Analytics",
                "Spreadsheets",
                "Enterprise Systems"
            ]
        },
        management: {
            1: "Management Skills",
            2: "We employ Management skills to create an environment where things get done most productively. These are skills through which we assure resource allocation, prioritize, and facilitate the effective work of others.",
            examples: [
                "Planning",
                "Strategic thinking",
                "Mentoring",
                "Public Speaking/Presentation",
                "Delegation",
                "Prioritization",
                "Dealing with complexity",
                "Stakeholder management",
                "Problem solving",
                "Feedback Space building",
                "Feedback",
                "Goal setting",
                "Time management",
                "Project Management",
                "Networking"
            ]
        }
    }
};

export const contentAppEng = {
    TaskFeedbackView: {
        givenBy: GIVEN_BY_LABEL,
        assignee: ASSIGNEE_LABEL,
        project: PROJECT_LABEL,
        completedAt: COMPLETED_AT_LABEL,
        givenAt: GIVEN_AT_LABEL,
        tags: TAGS_LABEL,
        stop: STOP_LABEL,
        continue: CONTINUE_LABEL,
        improve: IMPROVE_LABEL,
        fweet: FWEET_LABEL
    },

    LoadParameterList: ["Please update list"],
    UnifiedSyncParameters: [
        "Setup sync parameters for feeback space",
        "Please authorize one of data sources"
    ],
    DeepSyncButton: ["Sync"],
    AtlassianSiteAuthorization: ["Deauthorize", "Authorize"],
    AddAtlassianSite: ["Add other Atlassian site", "Add"],
    NoFeedbackSpaceInvitations: [
        "There are no invitations for Feedback Spaces.",
        "If you need any help, please see the",
        "guide",
        "or get in touch with us at",
        "support@enamply.com"
    ],
    AsanaRedirectContent: [
        "Obtaining authentication tokens from Asana.",
        "This might take a few moments."
    ],
    DataSourceDescription: {
        title: ["Task Management Platform Authorization"],
        text: [
            "By Authorizing Enamply to access your Task Management Platform, you allow the transfer of information related to projects, tasks, and assignees between the platforms.",
            "The data is securely transferred using the OAuth 2.0 Authorization Framework.",
            "(For more information, please consult our ",
            "Terms of Services",
            " and ",
            "Privacy Policy",
            ")."
        ]
    },
    skillNameHeader: [SKILL_NAME],
    skillTypeHeader: [SKILL_TYPE],
    skillDescriptionHeader: [SKILL_DESCRIPTION],
    skillActionsHeader: ["Actions"],
    SkillForm: [SKILL_NAME, SKILL_TYPE, SKILL_DESCRIPTION, SAVE_BUTTON],
    DeuthorizeAsana: ["Deauthorise Asana"],
    AuthorizeAsana: ["Authorize Asana"],
    RangeAvgFeedback: {
        chartName: ["How feedback varies among assignees"],
        yAxisLabel: ["Variability"]
    },
    FeedbackVarianceChartInfo: [
        'The graph indicates the level of variation in the feedback. Variation results from the spread (Min and Max) applied on the scale when giving feedback per task. High variation results from using spreads that go over one level of evaluation (Example: "Concerned" to "Satisfied"). In contrast, you register low variation if the spread is around the same level. ',
        'To achieve the best productivity, the assignee must be in the "Satisified" to "Excited" area and register low variation.'
    ],
    FeedbackEvolution: {
        chartName: ["How feedback evolved in time"],
        xAxisLabel: ["(week/year)"]
    },
    SkillRaitingBarChart: {
        chartName: ["How often Skills were applied"]
    },
    RangeAvgSkill: {
        chartName: ["How feedback varies among skills"],
        yAxisLabel: ["Skill variability"]
    },
    EvaluationRangeInfo: {
        intro: [
            "Evaluation is never an exact thing. Applying a range on the Enamply Scale, you can capture the good and the bad in the feedback."
        ],
        concerned: [
            "Concerned",
            " - indicates that something wrong in the task execution. The evaluator must write specifics in the Stop or Improve sections of the Feedback form."
        ],
        neutral: [
            "Neutral",
            " - indicates that the task is according to lower expectations of good enough execution. The evaluator most likely should write specifics in the Improve section of the Feedback form."
        ],
        satisfied: [
            "Satisfied",
            " - indicates that the task execution reached high levels of expectations. The evaluator most likely should write specifics in the Continue Section."
        ],
        exited: [
            "Excited",
            " - indicates that the task exceeded expectations with innovative ways of doing things that have the potential to become best practices. The evaluator must write specifics in the Continue Section."
        ]
    },
    FeedbackSpaceForm: {
        label: ["Feedback space name"],
        button: [SAVE_BUTTON]
    },
    NoFeedbackSpaceMessage: [
        "Unfortunately, you are not part of any Feedback Spaces yet.",
        "Please check for ",
        "invitations ",
        "or ",
        "create ",
        "a Feedback Space ",
        "to start using Enamply.",
        "If you need any help, please see one of the ",
        "guides below or ",
        "get in touch with us at ",
        "support@enamply.com"
    ],
    ManageSkills: {
        title: ["Manage skills in feedbackSpace"],
        mngSkills: ["Management skills"],
        interpersSkills: ["Interpersonal skills"],
        techSkills: ["Technical skills"],
        areaSpecSkills: ["Area specific skills"],
        noItems: [
            "You have added all available skills of this type. To add more, go to Skills Management."
        ],
        button: [SAVE_BUTTON]
    },
    FeedbackFormContainer: {
        stopLabel: [STOP_LABEL],
        stopPlaceholder: [
            "Redirecting feedback - describe behaviours that are not constructive and should be excluded from future work. \n # - are availabel."
        ],
        continueLabel: [CONTINUE_LABEL],
        continuePlaceholder: [
            "Positive reinforcement — recognize good work that should be repeated in the future. \n # - are available."
        ],
        improveLabel: [IMPROVE_LABEL],
        improvePlaceholder: [
            "Constructive feedback — describe what is generally good but can be improved. \n # - are available."
        ],
        fweetLabel: [FWEET_LABEL],
        fweetPlaceholder: [
            "Provide any-free form feedback that you feel is relevant. \n # - are available."
        ],
        button: [SAVE_BUTTON]
    },
    FeedbackSpaceSelect: ["There are no feedback spaces"],
    AssigneeFilterIcon: {
        title: ["Assignees"],
        emptyMessage: [
            "There are no assignees available. Please go to setup to add assignees."
        ]
    },
    TaskTypeFilterIcon: {
        title: ["Task types"],
        emptyMessage: [
            "There are no task types available. Please go to setup to add task types."
        ]
    },
    DataSourceFilterIcon: {
        title: ["Data sources"],
        emptyMessage: [
            "There are no data sourcess available. Please go to setup to authorize with data sources."
        ]
    },
    ProjectsFilterIcon: {
        title: ["Projects"],
        emptyMessage: [
            "There are no project available. Please go to setup to add projects."
        ]
    },
    SkillNameFilterIcon: {
        title: ["Skills"],
        emptyMessage: [
            "There are no skills available. Please go to setup to add skills."
        ]
    },
    SkillTypeFilterIcon: {
        title: ["Skill types"],
        emptyMessage: ["No data"]
    },
    InventorySkillTypeFilterIcon: {
        title: ["Skill types"],
        emptyMessage: ["No data"]
    },
    EditFeedbackSpaceNameDialog: {
        title: ["Edit feedback space name"]
    },
    EditFeedbackSpaceNameForm: {
        title: ["Edit feedback space name"],
        inputLabel: ["Feedback space name"],
        messageSuccess: ["Changes submitted"],
        button: [SAVE_BUTTON]
    }
};

export const contentLandingEng = {
    LoginForm: {
        email: [EMAIL],
        password: [PASSWORD],
        resetPassword: [RESETPASSWORD],
        logIn: ["Log in"]
    },
    AzureLogin: {
        altText: ["Microsoft icon"],
        signIn: ["Sign in with Microsoft"]
    },
    ContactForDemoLink: {
        noAccount: ["Don't have an account? "],
        contact: ["Contact us for a demo"]
    },
    DiscoverOurProduct: {
        text: ["discover our ", "PRODUCT"]
    },
    HomeContent: {
        metaTitle: [
            "ENAMPLY | Description of our approach to Continuous feedback and Performance review"
        ],
        metaDescription: [
            "Overview of the Performance reviews systemic problems and presentation of the Enamply solution. The platform helps companies reduce bias in performance reviews by making feedback a habit and offers insights to improve through data, not impressions."
        ],
        metaKeywords: [
            "performance reviews, continuous feedback, micro-feedback, skills management, productivity, team management, employee engagement, feedback, bias, career planning, development plan"
        ]
    },
    PublicFeaturesContent: {
        metaTitle: [
            "ENAMPLY | Presentation of the main features of the Enamply platform"
        ],
        metaDescription: [
            "Overview of the features and tools that help our users efficiently and quickly collect specific feedback per task easily and quickly, assign and map based on the real live tasks and evaluate their use, aggregate the feedback, and observe the evolution of the employees through insightful reports."
        ],
        metaKeywords: [
            "performance reviews, continuous feedback, micro-feedback, skills management, productivity, team management, employee engagement, feedback, bias, career planning, development plan, task execution, task-based feedback, planning"
        ]
    },
    AssesmentTruncated: [
        "Assessment",
        "ignores incremental improvements"
    ],
    CognitiveBias: [
        "Evaluation is ",
        "affected",
        " by",
        " cognitive bias"
    ],
    DisconectedFromTasks: [
        "Evaluation is ",
        "disconnected",
        " from",
        "specific tasks"
    ],
    InconsistantEvaluation: [
        "Feedback is ",
        "infrequient",
        " and",
        "untraceable"
    ],
    NoDataBasedAssesment: ["Assessment is ", "non-data driven"],
    StressfullAndTimeConsuming: [
        "The process is ",
        "stressful",
        " and",
        "time-consuming"
    ],
    FeedbackProblems: {
        verticalHeader: ["PROBLEMS"],
        horizontalHeader: ["WE SOLVE"],
        problemDescription: [
            "Performance reviews do not address the root causes of underproductivity."
        ],
        stressfullAlt: [
            "Analog clock with hands in form of lightnings"
        ],
        truncatedAlt: [
            "document named review with parts flying out of it"
        ],
        biasAlt: ["brain with round maze in it"],
        disconectedAlt: [
            "sicle that has tasks and revie document and they are connected whrogh question mark and x sign"
        ],
        inconsistantAlt: [
            "calendar icon with question marc in day slots"
        ],
        noDataBasedAlt: [
            "stack of documets that magnifying glass with qustion marc in it"
        ],
        readMore: [READ_MORE]
    },
    OurApproach: {
        monitorsTheStatus: [
            "ENAMPLY monitors the status of tasks from your Task Management platform and empowers your team to collect ",
            "timely feedback",
            "Increase feedback discipline and enable performance evaluations based on knowledge from the tasks that matter, not impressions."
        ],
        provideJustInTime: [
            "Provide just-in-time and specific ",
            "feedback",
            ", which diminishes the ",
            "effects of cognitive biases",
            " and naturally empowers gradual skills development.",
            "Feedback in ENAMPLY is cost-effective because it enables often and quick reality checks about the relevant needs to be performant."
        ],
        collectFrequent: [
            "Collect frequent and structured ",
            "task-based feedback",
            " and empower teams to improve incrementally and be agile in their development.",
            "Take advantage of the compounding knowledge and observe the impact with every feedback. The productivity level is acknowledged more often and better managed."
        ],
        quicklyIdentify: [
            "Quickly identify the skill gaps of teams and individuals and improve learning programs and recruitment results.",
            "Combining qualitative and quantitative analyses, ENAMPLY helps teams to map their skill sets better and determine risks and needs in a data-driven way."
        ],
        applyComprehensiveInsights: [
            "Apply comprehensive ENAMPLY insights and ",
            "make better decisions",
            " during stand-up meetings, Sprint retrospectives, and periodical Performance reviews.",
            "Individuals and teams extract and share valuable knowledge about task execution and share best practices, so learning becomes a natural part of teamwork."
        ],
        verticalHeader: ["SOLUTION"],
        horizontalHeader: ["WE PROVIDE"],
        relevantTasksAlt: [
            "Gant Chart. Relevant tasks flying out of it."
        ],
        timeTrinityAlt: [
            "Clock with 7 minutes count. Line chart with step-by-step growth."
        ],
        incrementalImprovementAlt: [
            "Line chart comparing incremental growth vs periodical growth."
        ],
        skillPersonAlt: [
            "Persons silhouette puzzles of different color inside."
        ],
        leanImprovementAlt: [
            "Agile lifecycle with reports clip art inside it."
        ],
        eliminateFrictionHeader: [
            "Eliminate bias and friction from performance evaluations"
        ],
        enableIncrementalHeader: [
            "Enable incremental and data-driven productivity growth"
        ]
    },
    ExtendedFeatures: {
        firstFeatureHeader: [
            "Collect specific feedback per task easily and quickly"
        ],
        firstFeatureText: [
            "Using our feedback form, you can evaluate the tasks that matter in 3 to 7 minutes. Our proprietary scale and feedback framework decrease bias in quantitative and qualitative evaluation."
        ],
        secondFeatureHeader: [
            "Assign skills to real live tasks and evaluate their use"
        ],
        secondFeatureText: [
            "By connecting skills to specific tasks, you will make your evaluations to be formative and specific. You will refine the skills matrix for employees, teams, and the organization with each feedback."
        ],
        thirdFeatureHeader: [
            "Analyze the levels and the variation in task execution"
        ],
        thirdFeatureText: [
            "Any quantitative evaluation of human actions is prone to the inherent bias of the scale. Our approach is to determine the range, not an exact number. We treat variations in results as a natural thing that allows for a better evaluation and a more effective indication for future improvements."
        ],
        fourthFeatureHeader: [
            "Aggregate the feedback and observe the evolution of the employees through insightful reports"
        ],
        fourthFeatureText: [
            "We believe that performance comes in time, with knowledgeable effort, that is why our reporting enables each user and team to monitor and learn from variations in task execution."
        ],
        skillsAlt: [
            "screenshot of multiple dropdowns used in selection of skills"
        ],
        stopContrImprAlt: [
            "three pictures stacked on each other saying Stop Continue Improve"
        ],
        satisfactionRangeAlt: [
            "screenshot of range feedabck tool that permits area selection"
        ],
        reportsAlt: ["two pictures with charts stacked"]
    },
    WhoIsItFor: {
        verticalHeader: ["PEOPLE"],
        horizontalHeader: ["WE HELP"]
    },
    HRContent: {
        header: ["HR specialists"],
        text: [
            "Help teams to manage their skill development needs and learn from daily activities.",
            "Decrease biases from performance evaluations by enabling teams to act timely and targeted on specific issues.",
            "Better define and act on skill gaps across their organization."
        ]
    },
    ManagementContent: {
        header: ["Managers and Team Members"],
        text: [
            "Improve their skills and processes incrementally and based on specific needs.",
            "Monitor their development and help each other learn in the process of achieving business results."
        ]
    },
    LeadershipContent: {
        header: ["Leadership"],
        text: [
            "Build a culture of continuous improvement and data driven approach towards decision making.",
            "Empower teams to achieve the company strategy through specific actions and daily lessons learned."
        ]
    },
    MediumContent: {
        verticalHeader: ["KNOWLEDGE"],
        horizontalHeader: ["WE SHARE"]
    },
    MediumIntegration: {
        toMedium: ["Read it on Medium"]
    },
    Features: {
        verticalHeader: ["TECH"],
        horizontalHeader: ["WE ARE"],
        quickConnectHeader: ["Quick to connect"],
        quickConnectContent: [
            "Enamply will be connected to the task management platform and ready to use in a few clicks. We are continually increasing our reach for other platforms, so we are happy to prioritize your needs."
        ],
        scalableHeader: ["Scalable"],
        scalableContent: [
            "The platform is easily accessible for as many teams as needed. You can add teams and projects later and be sure to have a holistic view if needed."
        ],
        readyHeader: ["Ready to use"],
        readyContent: [
            "Once connected, you will be able to use feedback collection tools, dashboards, and specific reports. Also, you can access and use our skill inventory to kick-start your task evaluations even if you have never had a skill management system before."
        ],
        techHeader: ["OATH 2.0"],
        techContent: [
            "We integrate with other platforms using the OATH 2.0 authorization protocol, thus acting on behalf of our users without access to their usernames and passwords."
        ]
    },
    USP: {
        header: ["Reduce bias in performance improvement"],
        description: [
            "Make feedback a habit and drive productivity through insightful data."
        ]
    },
    BlogCard: ["Read more..."],
    BlogArticle: ["< Back to Blog"],
    HomeMenuItems: {
        solution: ["Solution"],
        features: ["Features"],
        blog: ["Blog"]
    },
    BlogFollow: {
        altText: {
            linkedIn: ["circle with I and N letters in it"],
            medium: [
                "circle and two elipsis each is narrower that the other standing side by side, all this three figures are in inside bigger circle"
            ]
        },
        follow: ["Follow us on:"]
    },
    BlogSideIndex: ["Read more:"],
    PositiveEffects: {
        verticalHeader: ["WE OBTAIN"],
        horizontalHeader: ["+EFFECTS"],
        lessHeader: ["LESS"],
        moreHeader: ["MORE"],
        lessContent: [
            "Ambiguity",
            "Damage control",
            "Meetings for trivial things",
            "Time for performance reviews",
            "Expenses for improvements"
        ],
        moreContent: [
            "Applied learning",
            "Actionable Know-how",
            "Skills gaps uncovered",
            "Trust in appraisals",
            "Implemented improvements"
        ]
    }
};
