import React from "react";

const PublicMainLayout = ({ children }) => {
    return (
        <div
            className={
                "absolute bg-white top-0 left-0 flex flex-col w-screen"
            }>
            {children}
        </div>
    );
};

export default PublicMainLayout;
