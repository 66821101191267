import { useEffect } from "react";
import {
    pageVar,
    pagesVar,
    tasksDueVar
} from "GraphQL/ReactiveVariables";
import {
    setOperationsDisplay,
    useFeedbackQuery
} from "../TaskFeedbackContent/Utils";
import {
    Pagination,
    TaskCard,
    ConditionalOutput
} from "ComponentIndex";
import { useUnifiedFilterItems } from "HookIndex";

const TaskFeedbackContent = () => {
    const {
        feedbackStatus,
        page,
        pageSize,
        search,
        taskStartDate,
        taskEndDate,
        projectIds,
        assigneeParametersIds,
        currentFeedbackSpace,
        taskTypeNames,
        dataSourceIds
    } = useUnifiedFilterItems();

    let {
        data: tasksData,
        loading,
        error
    } = useFeedbackQuery({
        feedbackStatus: feedbackStatus,
        page: page,
        pageSize: pageSize,
        search: search,
        tskStartDate: taskStartDate,
        tskEndDate: taskEndDate,
        projectIds: projectIds,
        assigneeParametersIds: assigneeParametersIds,
        feedbackSpaceId: currentFeedbackSpace.id,
        taskTypeNames: taskTypeNames,
        dataSourceIds: dataSourceIds
    });

    useEffect(() => {
        if (tasksData) {
            pageVar(tasksData.tasks.page);
            pagesVar(tasksData.tasks.pages);
            if (feedbackStatus === "PE") {
                tasksDueVar(tasksData.tasks.length);
            }
        }
    }, [tasksData, feedbackStatus]);

    let opDisplay = setOperationsDisplay(feedbackStatus);

    return (
        <div
            id='test_place_3'
            className={
                "w-full md:w-10/12 xl:w-8/12 2xl:w-6/12 flex flex-col gap-y-4 lg:gap-y-8 items-center"
            }>
            <ConditionalOutput error={error} loading={loading}>
                {tasksData ? (
                    <>
                        {tasksData.tasks.objects.map((task) => {
                            let taskKey = task.id;
                            return (
                                <TaskCard
                                    key={`Paper${taskKey}`}
                                    task={task}
                                    opDisplay={opDisplay}
                                />
                            );
                        })}
                        <Pagination />
                    </>
                ) : null}
            </ConditionalOutput>
        </div>
    );
};

export default TaskFeedbackContent;
