import React, { useRef, useEffect, useState } from "react";
import {
    linearScale,
    maxValueFromData,
    bandScale
} from "UtilityFunctionIndex";

import { useChartWrapperDimensions } from "HookIndex";
import HorizontalBarChartCSV from "./HorizontalBarChartCSV";
import { adjustOuterPadding } from "./Utils";

const MARGINS = {
    top: 30,
    right: 10,
    bottom: 30,
    left: 100
};

const SCALE_RANGE_START = 0;
const X_WIDTH_OFFSET = 9;
const PADDING_INNER = 0.2;
const BAR_RADIUS = 9;
const LABEL_Y_OFFSET = 0.5;
const BOX_LABEL_Y_OFFSET = 0.5;

const HorizontalBarChart = ({
    data,
    xAccessor,
    yAccessor,
    toolTipAccessor,
    chartName,
    toolTipKey,
    toolTipValue
}) => {
    const wrapperRef = useRef();
    const [dataSet, setDataSet] = useState(data);
    const [graphs, setGraphs] = useState({ plotData: null });

    const [dimensions, processed] = useChartWrapperDimensions(
        wrapperRef,
        MARGINS
    );

    useEffect(() => {
        if (processed) {
            const xScale = linearScale(
                SCALE_RANGE_START,
                maxValueFromData(dataSet, xAccessor),
                SCALE_RANGE_START,
                dimensions.boundedWidth - X_WIDTH_OFFSET
            );

            const maxRange = dimensions.boundedHeight;

            const yScale = bandScale(
                dataSet.map(yAccessor),
                SCALE_RANGE_START,
                maxRange,
                PADDING_INNER,
                adjustOuterPadding(data)
            );
            const plotData = dataSet.map((item) => {
                return {
                    plot: {
                        x: xScale(0),
                        y: yScale(yAccessor(item)),
                        width: xScale(xAccessor(item)),
                        height: yScale.bandwidth(),
                        rx: BAR_RADIUS,
                        ry: BAR_RADIUS
                    },
                    label: {
                        x: xScale(xAccessor(item)),
                        y:
                            yScale(yAccessor(item)) +
                            yScale.bandwidth() * BOX_LABEL_Y_OFFSET,
                        text: xAccessor(item)
                    },
                    toolTip: item[toolTipAccessor]
                };
            });

            const yAxis = dataSet.map((item) => {
                return {
                    y:
                        yScale(yAccessor(item)) +
                        yScale.bandwidth() * LABEL_Y_OFFSET,
                    label: yAccessor(item)
                };
            });

            const xTicks = xScale.ticks();
            const xAxis = xTicks.map((item) => {
                return {
                    x: xScale(item),
                    label: item
                };
            });

            setGraphs({
                ...graphs,
                plotData: plotData,
                chartName: chartName,
                xAxis: xAxis,
                yAxis: yAxis,
                dimensions: dimensions
            });
        }
    }, [data, dimensions]);

    return (
        <HorizontalBarChartCSV
            graphs={graphs}
            ref={wrapperRef}
            toolTipAccessor={toolTipAccessor}
            toolTipKey={toolTipKey}
            toolTipValue={toolTipValue}
        />
    );
};

export default HorizontalBarChart;
