import React from "react";

const BlogQuote = ({ children, author }) => {
    return (
        <div
            className={
                " flex flex-col gap-x-2 border-l-2 border-shocking-700 pl-4 py-1 my-12 max-w-xl"
            }>
            <p
                className={
                    "blog_body_large font-medium text-secondary-800 italic pr-10"
                }>
                {children}
            </p>
            <p
                className={
                    "blog_body_large self-end text-secondary-800"
                }>
                {author}
            </p>
        </div>
    );
};

export default BlogQuote;
