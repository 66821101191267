import { useState, useEffect } from "react";
import {
    assigneeParametersIdsVar,
    assigneesParametersForFilterVar
} from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { masterAcc } from "UtilityFunctionIndex";

export const stringifyAssigneeItems = (item) => {
    return masterAcc(item, "assigneesParameters", "name");
};

export const useAssigneeFilterItems = () => {
    const [options, setOptions] = useState([]);
    let data = useReactiveVar(assigneesParametersForFilterVar);

    useEffect(() => {
        assigneeParametersIdsVar(options.map((item) => item.id));
    }, [options]);

    return { data, options, setOptions };
};
