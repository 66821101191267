import React from "react";
import cn from "classnames";
import cls from "./Label.module.css";
import { trimLongLabel } from "UtilityFunctionIndex";

const Label = ({
    x,
    y,
    rotation = 0,
    children,
    type,
    color,
    shadow = false,
    anchor = "middle",
    labelLength = null,
    ...rest
}) => {
    const chartLabelClass = cn({
        ["label_large"]: type === "chart_name",
        ["label_medium"]: type === "axis_name",
        ["label_small"]: type === "data_label",
        ["fill-secondary-800"]: color === "dark",
        ["fill-shocking-50"]: color === "light",
        ["fill-shocking-700"]: color === "primary",
        [cls.anchorMiddle]: anchor === "middle",
        [cls.anchorEnd]: anchor === "end",
        [cls.anchorStart]: anchor === "start",
        ["shadow-lg"]: shadow === true
    });

    return (
        <text
            className={chartLabelClass}
            x={`${x}`}
            y={`${y}`}
            transform={`rotate(${rotation},${x},${y})`}
            {...rest}>
            <title>{children}</title>
            {`${trimLongLabel(children, labelLength)}`}
        </text>
    );
};

export default Label;
