import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSendLog } from "HookIndex";
import {
    AppHeader,
    AppDrawerMenu,
    TaskFeedbackRoot,
    ReportRoot,
    AccountSettingsRoot,
    SkillsRoot,
    DashboardRoot,
    ConditionalOutput,
    MessagesRoot,
    ProductTour
} from "ComponentIndex";
import { useGetUserPreference } from "./Utils";

const Application = () => {
    const { data, error, loading } = useGetUserPreference();
    useSendLog();

    return (
        <div
            className={
                "relative top-0 h-screen overflow-hidden flex flex-col"
            }>
            <ConditionalOutput error={error} loading={loading}>
                {data ? (
                    <>
                        <ProductTour />
                        <AppHeader />
                        <div
                            className={
                                "flex flex-row h-[calc(100%_-_3rem)] w-full"
                            }>
                            <AppDrawerMenu />

                            <Routes>
                                <Route
                                    path={`/*`}
                                    element={<DashboardRoot />}
                                />
                                <Route
                                    path={"taskfeedback/*"}
                                    element={<TaskFeedbackRoot />}
                                />
                                <Route
                                    path={"insights/*"}
                                    element={<ReportRoot />}
                                />
                                <Route
                                    path={"account-settings/*"}
                                    element={<AccountSettingsRoot />}
                                />
                                <Route
                                    path={"skills/*"}
                                    element={<SkillsRoot />}
                                />
                                <Route
                                    path={"message-boards/*"}
                                    element={<MessagesRoot />}
                                />
                            </Routes>
                        </div>
                    </>
                ) : null}
            </ConditionalOutput>
        </div>
    );
};

export default Application;
