import { productGuidesVar } from "GraphQL/ReactiveVariables";
import {
    FEATURE_TOUR,
    SETUP_TOUR,
    INVITATIONS_TOUR,
    MANAGE_SKILLS_TOUR,
    FEEBACK_TOUR,
    INSIGHTS_TOUR
} from "./Steps";

export const tourHandlerFactory = (parameter) => {
    switch (parameter) {
        case "featureTour":
            return handleFeatureTourClick;
        case "setupTour":
            return handleSetupTourClick;
        case "invitationTour":
            return handleInvitationTourClick;
        case "manageSkillsTour":
            return handleManageSkillsTourClick;
        case "feedbackTour":
            return handleFeedbackTourClick;
        case "insightsTour":
            return handleInsightsTourClick;
        default:
            return null;
    }
};

const handleFeatureTourClick = (e) => {
    e.preventDefault();

    productGuidesVar({
        ...productGuidesVar(),
        run: true,
        steps: FEATURE_TOUR,
        stepIndex: 0,
        callBackType: "featureTour"
    });
};

const handleSetupTourClick = (e) => {
    e.preventDefault();

    productGuidesVar({
        ...productGuidesVar(),
        run: true,
        steps: SETUP_TOUR,
        callBackType: "setupTour",
        stepIndex: 0,
        tourActive: true
    });
};

const handleInvitationTourClick = (e) => {
    e.preventDefault();

    productGuidesVar({
        ...productGuidesVar(),
        run: true,
        steps: INVITATIONS_TOUR,
        callBackType: "invitationTour",
        stepIndex: 0,
        tourActive: true
    });
};

const handleManageSkillsTourClick = (e) => {
    e.preventDefault();

    productGuidesVar({
        ...productGuidesVar(),
        run: true,
        steps: MANAGE_SKILLS_TOUR,
        callBackType: "manageSkillsTour",
        stepIndex: 0,
        tourActive: true
    });
};

const handleFeedbackTourClick = (e) => {
    e.preventDefault();

    productGuidesVar({
        ...productGuidesVar(),
        run: true,
        steps: FEEBACK_TOUR,
        callBackType: "feedbackTour",
        stepIndex: 0,
        tourActive: true
    });
};

const handleInsightsTourClick = (e) => {
    e.preventDefault();

    productGuidesVar({
        ...productGuidesVar(),
        run: true,
        steps: INSIGHTS_TOUR,
        callBackType: "insightsTour",
        stepIndex: 0,
        tourActive: true
    });
};
