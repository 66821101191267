import React from "react";
import { BackDrop } from "ComponentIndex";

const TextDialog = ({
    label,
    children,
    open,
    setOpen,
    id,
    textStyle = "body_large",
    textColor = "text-shocking-700"
}) => {
    return (
        <>
            <span
                className={`${textStyle} ${textColor} cursor-pointer`}
                id={id}
                onClick={(e) => setOpen(true)}>
                {label}
            </span>

            <BackDrop open={open} setOpen={setOpen}>
                {children}
            </BackDrop>
        </>
    );
};

export default TextDialog;
