import React from "react";
import cn from "classnames";
import { Icon } from "ComponentIndex";

const DrawerMenuItem = ({ selected, itemName, iconName, id }) => {
    const menuItemClass = cn(
        [
            "hover_text cursor-pointer",
            "text-base font-normal rounded-md px-4 py-2",
            "text-white w-full hover:bg-shocking-400",
            "flex flex-row items-center gap-x-4"
        ],
        {
            "bg-shocking-700 rounded-md hover:bg-shocking-400": selected
        }
    );

    return (
        <button id={id} className={menuItemClass}>
            <Icon name={iconName} size={"small"} color={"light"} />

            <p className={"label_large text-white text_shadow"}>
                {itemName}
            </p>
        </button>
    );
};

export default DrawerMenuItem;
