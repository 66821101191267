import React from "react";
import { Card } from "ComponentIndex";
import { useGetAtlassianTokens } from "./Utils";

const AtlassianRedirectContent = () => {
    useGetAtlassianTokens();

    return (
        <div
            className={
                "flex flex-col w-full lg:w-8/12 xl:w-6/12 2xl:w-4/12"
            }>
            <Card type={"light"}>
                <p className={"label_large text-primary-600"}>
                    Obtaining authentication tokens from Atlassian. This
                    might take a few moments.
                </p>
            </Card>
        </div>
    );
};

export default AtlassianRedirectContent;
