import {
    blogContentVar,
    currentArticleVar
} from "GraphQL/ReactiveVariables";
import {
    BlogKicker,
    BlogHeader,
    BlogSubHeader,
    BlogParagraphHeader,
    BlogParagraph,
    BlogQuote,
    BlogUli,
    BlogOli,
    BlogTakeAwayHeader,
    BlogCompartmentHeader,
    BlogLink,
    BlogReferanceHeader,
    BlogReferance,
    BlogCaption,
    BlogCategory,
    HelmetInclude
} from "ComponentIndex";
import HexagonImg from "Images/Blog/SystemicProbemsArticle/different-level-hexagons.png";
import InconsistantImg from "Images/Blog/SystemicProbemsArticle/inconsistant-evaluation.svg";
import CognitiveBiasImg from "Images/Blog/SystemicProbemsArticle/cognitive-bias.svg";
import IgnoreImprovementImg from "Images/Blog/SystemicProbemsArticle/ignores-incremental-improvement.svg";
import AssesmentTruncatedImg from "Images/Blog/SystemicProbemsArticle/assesment-truncated.svg";
import StressfullTimeImg from "Images/Blog/SystemicProbemsArticle/stressfull-time-consuming.svg";
import DisconectedFromTasksImg from "Images/Blog/SystemicProbemsArticle/disconnected-from-real-tasks.svg";
import StatisticsImg from "Images/Blog/SystemicProbemsArticle/statistics-for-problems.png";
import {
    ImpactfullFeedbackArticleLink,
    TaskBasedFeedbackArticleLink,
    CognitiveBiasesInPerformanceArticleLink,
    ReviewEmbraceEmployeesEffortsArticleLink
} from "BlogLinkIndex";
import { useMoveToPageTop } from "HookIndex";

const FIRST_REFERANCE =
    "https://www.cambridge.org/core/journals/industrial-and-organizational-psychology/article/abs/performance-management-can-be-fixed-an-onthejob-experiential-learning-approach-for-complex-behavior-change/E4F8A435C94B09BA0B2667D05965DA41";
const SECOND_REFERANCE =
    "https://www.pwc.com/mt/en/publications/people-and-organisation/pwc-hrs-employee-engagement-landscape-2015.pdf";
const THIRD_REFERANCE =
    "https://www.shrm.org/resourcesandtools/hr-topics/employee-relations/pages/performance-reviews-are-dead.aspx";

const SystemicProbemsArticle = ({ articleName }) => {
    const content = blogContentVar().SystemicProbemsArticle;
    currentArticleVar(articleName);
    useMoveToPageTop();
    return (
        <>
            <HelmetInclude content={content} />
            <div>
                <img
                    className={"w-full"}
                    src={HexagonImg}
                    alt={content.altText.hexagonImg[0]}
                />
                <div className={"py-8"}>
                    <BlogKicker content={content} />
                    <BlogHeader content={content} />
                    <BlogSubHeader content={content} />
                </div>
                <div>
                    <BlogTakeAwayHeader content={content} />
                    <ol className={"list-inside"}>
                        <BlogOli>
                            {content.mainTakeAway.problem[0]}
                        </BlogOli>
                        <BlogOli>
                            {content.mainTakeAway.issueList[0]}
                        </BlogOli>
                    </ol>
                    <ul className={"list-inside"}>
                        <BlogUli>
                            {content.mainTakeAway.issues[0]}
                        </BlogUli>
                        <BlogUli>
                            {content.mainTakeAway.issues[1]}
                        </BlogUli>
                        <BlogUli>
                            {content.mainTakeAway.issues[2]}
                        </BlogUli>
                        <BlogUli>
                            {content.mainTakeAway.issues[3]}
                        </BlogUli>
                        <BlogUli>
                            {content.mainTakeAway.issues[4]}
                        </BlogUli>
                        <BlogUli>
                            {content.mainTakeAway.issues[5]}
                            <BlogLink
                                to={
                                    CognitiveBiasesInPerformanceArticleLink
                                }>
                                {content.mainTakeAway.issues[6]}
                            </BlogLink>
                        </BlogUli>
                    </ul>
                </div>
                <BlogQuote author={content.quote.author[0]}>
                    {content.quote.text[0]}
                </BlogQuote>
                <BlogParagraph>{content.afterQuote[0]}</BlogParagraph>
                <BlogParagraphHeader>
                    {content.mainIssue.title[0]}
                </BlogParagraphHeader>
                <BlogParagraph>
                    {content.mainIssue.firstParagraph[0]}
                </BlogParagraph>
                <BlogCaption caption={content.captions.statistics[0]}>
                    <img
                        src={StatisticsImg}
                        alt={content.altText.statisticsImg[0]}
                    />
                </BlogCaption>

                <ul className={"list-inside"}>
                    {content.mainIssue.statisticsProblems.map(
                        (item, index) => {
                            return (
                                <BlogUli key={`issue_stats${index}`}>
                                    {item}
                                </BlogUli>
                            );
                        }
                    )}
                </ul>
                <BlogParagraph>
                    {content.mainIssue.closingParagrah[0]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-32 w-32"}
                        src={AssesmentTruncatedImg}
                        alt={content.altText.assesmentTruncatedImg[0]}
                    />
                    <BlogParagraphHeader>
                        {content.nonData.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.nonData.content[0]}
                    <BlogLink
                        to={ReviewEmbraceEmployeesEffortsArticleLink}>
                        {content.nonData.content[1]}
                    </BlogLink>
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-32 w-32"}
                        src={DisconectedFromTasksImg}
                        alt={
                            content.altText.disconnectedFromTasksImg[0]
                        }
                    />
                    <BlogParagraphHeader>
                        {content.evalDisconnected.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.evalDisconnected.content[0]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-32 w-32"}
                        src={InconsistantImg}
                        alt={content.altText.inconsistantImg[0]}
                    />
                    <BlogParagraphHeader>
                        {content.feedbackInfrequent.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.feedbackInfrequent.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.feedbackInfrequent.content[1]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-32 w-32"}
                        src={StressfullTimeImg}
                        alt={content.altText.stressfullTimeImg[0]}
                    />
                    <BlogParagraphHeader>
                        {content.processStressfull.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.processStressfull.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.processStressfull.content[1]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-32 w-32"}
                        src={IgnoreImprovementImg}
                        alt={content.altText.ignoreImprovementImg[0]}
                    />
                    <BlogParagraphHeader>
                        {content.assesmentIgnores.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.assesmentIgnores.content[0]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.assesmentIgnores.content[1]}
                </BlogParagraph>
                <BlogCompartmentHeader>
                    <img
                        className={"h-32 w-32"}
                        src={CognitiveBiasImg}
                        alt={content.altText.cognitiveBiasImg[0]}
                    />
                    <BlogParagraphHeader>
                        {content.cognitiveBias.title[0]}
                    </BlogParagraphHeader>
                </BlogCompartmentHeader>
                <BlogParagraph>
                    {content.cognitiveBias.content[0]}
                    <BlogLink
                        to={CognitiveBiasesInPerformanceArticleLink}>
                        {content.cognitiveBias.content[1]}
                    </BlogLink>
                    {content.cognitiveBias.content[2]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.cognitiveBias.content[3]}
                </BlogParagraph>
                <BlogParagraph>
                    {content.closing[0]}
                    <BlogLink to={TaskBasedFeedbackArticleLink}>
                        {content.closing[1]}
                    </BlogLink>
                    {content.closing[2]}
                    <BlogLink to={ImpactfullFeedbackArticleLink}>
                        {content.closing[3]}
                    </BlogLink>
                    {content.closing[4]}
                </BlogParagraph>
                <div className={"mt-10"}>
                    <BlogReferanceHeader content={content} />
                    <BlogReferance to={FIRST_REFERANCE}>
                        {content.referancess.first[0]}
                    </BlogReferance>
                    <BlogReferance to={SECOND_REFERANCE}>
                        {content.referancess.second[0]}
                    </BlogReferance>
                    <BlogReferance to={THIRD_REFERANCE}>
                        {content.referancess.third[0]}
                    </BlogReferance>
                </div>

                <BlogCategory content={content} />
            </div>
        </>
    );
};

export default SystemicProbemsArticle;
