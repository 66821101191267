import React from "react";
import classnames from "classnames";

import cls from "./Loader.module.css";

const Loader = ({ size, color, loadingTitle = "" }) => {
    const loaderClass = classnames(cls.loader, {
        [cls.small]: size === "small",
        [cls.medium]: size === "medium",
        [cls.large]: size === "large",
        [cls.primary]: color === "primary",
        [cls.light]: color === "light",
        [cls.dark]: color === "dark"
    });
    return <div title={loadingTitle} className={loaderClass}></div>;
};

export default Loader;
