import { bindActionCreators } from "redux";
import store from "State/store";
import {
    setSkillTypeContentAction,
    loadAreaSpecificSkillsAction,
    loadInterpersonalSkillsAction,
    loadMangementSkillsAction,
    loadTechnicalSkillsAction,
    updateAreaSpecificSkillsAction,
    updateTechnicalSkillsAction,
    updateInterpersonalSkillsAction,
    updateMangementSkillsAction
} from "./ActionCreators/skillActionCreators";

import {
    addNotificationAction,
    removeNotificationAction
} from "./ActionCreators/notificationActionCreator";

const reduxActions = bindActionCreators(
    {
        setSkillTypeContent: setSkillTypeContentAction,
        loadAreaSpecificSkills: loadAreaSpecificSkillsAction,
        loadInterpersonalSkills: loadInterpersonalSkillsAction,
        loadManagementSkills: loadMangementSkillsAction,
        loadTechnicalSkills: loadTechnicalSkillsAction,

        addNotification: addNotificationAction,
        removeNotification: removeNotificationAction,
        updateAreaSpecificSkills: updateAreaSpecificSkillsAction,
        updateTechnicalSkills: updateTechnicalSkillsAction,
        updateInterpersonalSkills: updateInterpersonalSkillsAction,
        updateMangementSkills: updateMangementSkillsAction
    },
    store.dispatch
);

export default reduxActions;
