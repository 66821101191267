import React, { useState, useRef } from "react";
import cn from "classnames";
import { useClickOutside } from "HookIndex";
import { IconButton } from "ComponentIndex";

const dropDownClass = cn([
    "scale-up-center gap-y-4",
    "absolute top-8 right-0 border",
    "border-secondary-300/10 bg-secondary-800/50",
    "backdrop-blur rounded-md shadow-md p-4 w-52",
    "flex justify-end items-end flex-col text-end"
]);

const HeaderMenu = ({ children }) => {
    const [open, setOpen] = useState(false);
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);

    return (
        <div
            className={"ml-4 relative flex grow justify-end md:hidden"}>
            {open ? (
                <IconButton
                    variant={"standard"}
                    size={"large"}
                    icon={"menu_open"}
                    onClick={() => setOpen(false)}
                />
            ) : (
                <IconButton
                    variant={"standard"}
                    size={"large"}
                    icon={"menu"}
                    onClick={() => setOpen(true)}
                />
            )}
            {open ? (
                <div ref={containerRef} className={dropDownClass}>
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export default HeaderMenu;
