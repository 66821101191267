import React from "react";
import { useReactiveVar } from "@apollo/client";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import {
    Card,
    ManageMembersButton,
    ManageSyncParameters,
    ManageNotificationSettings,
    SelectFeedbackSpaceButton,
    DetermineRole,
    ManageMemberInvitation,
    Title,
    ManageFeedbackSpaceSkills,
    EditFeedbackSpaceDialog
} from "ComponentIndex";
import { isOwner, isOwnerOrAdmin } from "UtilityFunctionIndex";

const FeedbackSpaceCard = ({ feedbackSpace }) => {
    let currentFeedbackSpace = useReactiveVar(currentFeedbackSpaceVar);

    return (
        <Card
            type={"light"}
            basel={
                feedbackSpace.id === currentFeedbackSpace.id
                    ? "primary"
                    : null
            }>
            <Title>
                <span
                    className={
                        "truncate w-full flex flex-row items-center justify-between"
                    }>
                    {feedbackSpace.feedbackSpaceName}
                    {isOwner(feedbackSpace) ? (
                        <EditFeedbackSpaceDialog
                            feedbackSpace={feedbackSpace}
                        />
                    ) : null}
                </span>
            </Title>

            <div className={"flex flex-col gap-y-4 mt-4"}>
                <div className={"flex flex-row justify-between"}>
                    <div>
                        <DetermineRole feedbackSpace={feedbackSpace} />
                    </div>
                    <div className={""}>
                        <SelectFeedbackSpaceButton
                            feedbackSpace={feedbackSpace}
                        />
                    </div>
                </div>
                <div className={"flex flex-row gap-4 justify-end"}>
                    <div
                        className={"flex flex-col gap-4 justify-start"}>
                        {isOwnerOrAdmin(feedbackSpace) ? (
                            <ManageMemberInvitation
                                feedbackSpaceId={feedbackSpace.id}
                            />
                        ) : null}
                    </div>
                    <div
                        className={"flex flex-col gap-4 justify-start"}>
                        {isOwner(feedbackSpace) ? (
                            <ManageSyncParameters
                                feedbackSpace={feedbackSpace}
                            />
                        ) : null}
                        {isOwner(feedbackSpace) ? (
                            <ManageFeedbackSpaceSkills
                                feedbackSpace={feedbackSpace}
                            />
                        ) : null}
                    </div>
                    <div
                        className={"flex flex-col gap-4 justify-start"}>
                        <ManageMembersButton
                            feedbackSpace={feedbackSpace}
                        />
                        <ManageNotificationSettings
                            feedbackSpace={feedbackSpace}
                        />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default FeedbackSpaceCard;
