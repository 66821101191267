import { useState, useEffect } from "react";
import { masterAcc } from "UtilityFunctionIndex";
import { clearCacheItem } from "GraphQL/Cache";

import { useMutation } from "@apollo/client";
import {
    currentFeedbackSpaceVar,
    tasksDueVar,
    activeTabVar
} from "GraphQL/ReactiveVariables";
import { CREATE_BASIC_FEEDBACK } from "GraphQL/Mutations";
import reduxActions from "State/ActionIndex";

const dataFieldWrapper = (item, accessor, parameter, valueIfNull) => {
    const value = masterAcc(item, accessor, parameter);
    if (value) {
        return value;
    } else {
        return valueIfNull;
    }
};

const skillStateFilter = (task, skillType) => {
    let selectedSkillsSet = masterAcc(
        task,
        "task",
        "taskAssociatedSkills"
    );

    return filterSkillsByType(selectedSkillsSet, skillType);
};

export const filterSkillsByType = (skills, skillType) => {
    if (skills.length !== 0) {
        return skills.filter((skill) => {
            return skill["skillType"] === skillType;
        });
    } else {
        return [];
    }
};

export const extractSkillId = (skillArray) => {
    return skillArray.map((item) => masterAcc(item, "skills", "id"));
};

export const useFormPopulate = (task, create, setOpen) => {
    const currentFeedbackSpace = currentFeedbackSpaceVar();

    const [stopFeedback, setStopFeedback] = useState(
        dataFieldWrapper(task, "task", "stopFeedbackDescription", "")
    );
    const [continueFeedback, setContinuetFeedback] = useState(
        dataFieldWrapper(
            task,
            "task",
            "continueFeedbackDescription",
            ""
        )
    );
    const [improveFeedback, setImproveFeedback] = useState(
        dataFieldWrapper(task, "task", "improveFeedbackDescription", "")
    );
    const [textFeedback, setTextFeedback] = useState(
        dataFieldWrapper(task, "task", "textFeedbackDescription", "")
    );
    const [rangeInput, setRangeInput] = useState([
        dataFieldWrapper(task, "task", "lowerSatisfactionRange", 50),
        dataFieldWrapper(task, "task", "upperSatisfactionRange", 50)
    ]);

    const [areaSpecificSkills, setAreaSpecificSkills] = useState(
        skillStateFilter(task, "ASP")
    );
    const [technicalSkills, setTechnicalSkills] = useState(
        skillStateFilter(task, "TEC")
    );
    const [interpersonalSkills, setInterpersonalSkills] = useState(
        skillStateFilter(task, "INT")
    );
    const [managementSkills, setManagementSkills] = useState(
        skillStateFilter(task, "MNG")
    );

    const [save, setSave] = useState(true);

    const [submitFeedback, { loading, error }] = useMutation(
        CREATE_BASIC_FEEDBACK
    );

    useEffect(() => {
        if (
            (textFeedback ||
                stopFeedback ||
                continueFeedback ||
                improveFeedback) &&
            (rangeInput[0] !== 50 || rangeInput[1] !== 50)
        ) {
            setSave(false);
        }
    }, [
        textFeedback,
        stopFeedback,
        continueFeedback,
        improveFeedback,
        rangeInput
    ]);

    let handleFromSubmit = (e) => {
        e.preventDefault();
        submitFeedback({
            variables: {
                continueFeedback: continueFeedback,
                improveFeedback: improveFeedback,
                stopFeedback: stopFeedback,
                taskId: task.id,
                textFeedback: textFeedback,
                lowerRange: rangeInput[0],
                upperRange: rangeInput[1],
                feedbackSpaceId: currentFeedbackSpace.id,
                skillIds: [
                    ...extractSkillId(areaSpecificSkills),
                    ...extractSkillId(technicalSkills),
                    ...extractSkillId(interpersonalSkills),
                    ...extractSkillId(managementSkills)
                ]
            }
        })
            .then((data) => {
                if (create) {
                    clearCacheItem(task);
                    setOpen(false);
                    if (activeTabVar() === "PE") {
                        tasksDueVar(tasksDueVar() - 1);
                    }
                    reduxActions.addNotification(
                        "Feedback created",
                        "success"
                    );
                } else {
                    setOpen(false);
                    return reduxActions.addNotification(
                        "Feedback edited",
                        "success"
                    );
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    let states = {
        save: save,
        rangeInput: rangeInput,
        textFeedback: textFeedback,
        improveFeedback: improveFeedback,
        continueFeedback: continueFeedback,
        stopFeedback: stopFeedback,
        areaSpecificSkills: areaSpecificSkills,
        technicalSkills: technicalSkills,
        interpersonalSkills: interpersonalSkills,
        managementSkills: managementSkills
    };

    let stateActions = {
        setSave: setSave,
        setRangeInput: setRangeInput,
        setTextFeedback: setTextFeedback,
        setImproveFeedback: setImproveFeedback,
        setContinuetFeedback: setContinuetFeedback,
        setStopFeedback: setStopFeedback,
        setAreaSpecificSkills: setAreaSpecificSkills,
        setTechnicalSkills: setTechnicalSkills,
        setInterpersonalSkills: setInterpersonalSkills,
        setManagementSkills: setManagementSkills
    };

    return { error, loading, states, stateActions, handleFromSubmit };
};
