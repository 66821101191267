import React from "react";
import { TourTextLine } from "ComponentIndex";

const GuideList = () => {
    return (
        <div className={"flex flex-col gap-y-4 "}>
            <TourTextLine
                handlerType={"featureTour"}
                text={"I want a quick tour of the features"}
            />
            <TourTextLine
                handlerType={"setupTour"}
                text={"I want to Setup Enamply for me and my team"}
            />
            <TourTextLine
                handlerType={"invitationTour"}
                text={"I want to manage invitations to feedback spaces"}
            />
            <TourTextLine
                handlerType={"manageSkillsTour"}
                text={"I want to manage the Skills Inventory"}
            />
            <TourTextLine
                handlerType={"feedbackTour"}
                text={"I want to know how to give feedback"}
            />
            <TourTextLine
                handlerType={"insightsTour"}
                text={"I want to learn about Insights"}
            />
        </div>
    );
};

export default GuideList;
