import {
    AtlassianSiteAuthorization,
    ConditionalOutput
} from "ComponentIndex";
import { useQuery } from "@apollo/client";
import { GET_ATLASSIAN_SITES_QUERY } from "GraphQL/Queries";

const AtlassianSiteList = () => {
    const { data, loading, error } = useQuery(
        GET_ATLASSIAN_SITES_QUERY
    );
    return (
        <div className={"flex flex-col w-full"}>
            <ConditionalOutput error={error} loading={loading}>
                <ul className={"flex flex-col gap-y-2"}>
                    {data && data.atlassianSites
                        ? data.atlassianSites.map((item, index) => {
                              return (
                                  <li
                                      key={`atlassianSite${index}`}
                                      className={
                                          "flex flex-row w-full justify-between items-center"
                                      }>
                                      <div
                                          className={
                                              "flex flex-row items-center"
                                          }>
                                          <img
                                              className={"h-6 mr-6"}
                                              src={item["avatarUrl"]}
                                          />
                                          <span
                                              className={"body_large"}>
                                              {item["siteName"]}
                                          </span>
                                      </div>
                                      <AtlassianSiteAuthorization
                                          siteUrl={item["siteUrl"]}
                                          syncStatus={
                                              item["authorizedToSync"]
                                          }
                                      />
                                  </li>
                              );
                          })
                        : null}
                </ul>
            </ConditionalOutput>
        </div>
    );
};

export default AtlassianSiteList;
