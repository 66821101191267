import { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import {
    skillTypesVar,
    inventorySkillTypesForFilterVar
} from "GraphQL/ReactiveVariables";

export const useInventorySkillTypesFilterItems = () => {
    const [options, setOptions] = useState([]);
    let data = useReactiveVar(inventorySkillTypesForFilterVar);

    useEffect(() => {
        skillTypesVar(options.map((items) => items.abbr));
    }, [options]);

    return { data, options, setOptions };
};
