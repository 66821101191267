import React from "react";
import {
    PublicCard,
    PublicSection,
    ContentWrapperBand
} from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const Features = () => {
    const content = landingContentVar().Features;
    return (
        <ContentWrapperBand color={"bg-shocking-50"}>
            <PublicSection
                verticalHeader={content.verticalHeader[0]}
                horizontalHeader={content.horizontalHeader[0]}
                id={"features"}>
                <div
                    className={
                        "flex flex-col items-center  lg:flex-row lg:gap-x-16  lg:gap-y-16 lg:flex-wrap lg:items-stretch lg:justify-around xl:gap-x-4 "
                    }>
                    <div className={"mb-16 lg:mb-0 sm:w-72 xl:w-64"}>
                        <PublicCard
                            icon={"cloud_sync"}
                            title={content.quickConnectHeader[0]}
                            text={content.quickConnectContent[0]}
                        />
                    </div>
                    <div className={"mb-16 lg:mb-0 sm:w-72 xl:w-64"}>
                        <PublicCard
                            icon={"tenancy"}
                            title={content.scalableHeader[0]}
                            text={content.scalableContent[0]}
                        />
                    </div>
                    <div className={"mb-16 lg:mb-0 sm:w-72 xl:w-64"}>
                        <PublicCard
                            icon={"check_box"}
                            title={content.readyHeader[0]}
                            text={content.readyContent[0]}
                        />
                    </div>
                    <div className={"mb-16 lg:mb-0 sm:w-72 xl:w-64"}>
                        <PublicCard
                            icon={"settings_suggest"}
                            title={content.techHeader[0]}
                            text={content.techContent[0]}
                        />
                    </div>
                </div>
            </PublicSection>
        </ContentWrapperBand>
    );
};

export default Features;
