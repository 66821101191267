import React from "react";

const TextPictureRight = ({ children, text, header = false }) => {
    return (
        <div
            className={
                "flex flex-col items-center gap-y-4 gap-x-4 md:flex-row md:justify-between lg:w-10/12"
            }>
            <div
                className={"flex flex-col md: pr-16  gap-y-4 md:w-2/3"}>
                {header ? (
                    <h3 className={"title_large text-shocking-700 "}>
                        {header}
                    </h3>
                ) : null}

                {text}
            </div>
            {children}
        </div>
    );
};

export default TextPictureRight;
