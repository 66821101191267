import React from "react";
import { verifyValueLength } from "UtilityFunctionIndex";
import { verifyFields } from "./UtilityFunctions";
import { appContentVar } from "GraphQL/ReactiveVariables";
import {
    TextArea,
    Input,
    InputLabelCount,
    SelectSkillType,
    Button
} from "ComponentIndex";

const SkillForm = ({ state, setState, handleSubmit }) => {
    const content = appContentVar().SkillForm;
    const handleSkillNameChange = (e) => {
        let value = verifyValueLength(e.target.value, 200);
        setState({
            ...state,
            skillName: value
        });
    };
    const handleSkilldescriptionChange = (e) => {
        let value = verifyValueLength(e.target.value, 1000);
        setState({
            ...state,
            skillDescription: value
        });
    };

    return (
        <form
            className={"flex flex-col gap-y-4 mt-4"}
            onSubmit={handleSubmit}>
            <InputLabelCount
                scale={"strech"}
                label={content[0]}
                input={
                    <Input
                        id={`inputTask`}
                        type={"text"}
                        required={true}
                        value={state.skillName}
                        onChange={handleSkillNameChange}
                        autoFocus
                        maxLength={80}
                    />
                }
                count={
                    <p
                        className={
                            "label_small text-secondary-500"
                        }>{`${state.skillName.length}/80`}</p>
                }
            />
            <InputLabelCount
                id={"select-skill-type"}
                scale={"strech"}
                label={content[1]}
                input={
                    <SelectSkillType
                        state={state}
                        setState={setState}
                    />
                }
            />
            <InputLabelCount
                scale={"strech"}
                label={content[2]}
                input={
                    <TextArea
                        rows={8}
                        value={state.skillDescription}
                        onChange={handleSkilldescriptionChange}
                    />
                }
                count={
                    <p
                        className={
                            "label_small text-secondary-500"
                        }>{`${state.skillDescription.length}/1000`}</p>
                }
            />
            <div className={"self-end"}>
                <Button
                    disabled={verifyFields(state)}
                    type={"submit"}
                    variant={"filled"}>
                    {content[3]}
                </Button>
            </div>
        </form>
    );
};

export default SkillForm;
