import {
    ContentWrapperBand,
    TermsContent,
    HomeFooter,
    HelmetExclude
} from "ComponentIndex";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";

const TermsAndConditionsContent = () => {
    workspaceNameVar("Terms and conditions");
    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand>
                <TermsContent />
                <HomeFooter color={"white"} />
            </ContentWrapperBand>
        </>
    );
};

export default TermsAndConditionsContent;
