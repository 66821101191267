import React from "react";

const BlogUli = ({ children }) => {
    return (
        <li
            className={
                "list-disc indent-4 blog_body_medium mb-2 text-secondary-900"
            }>
            {children}
        </li>
    );
};

export default BlogUli;
