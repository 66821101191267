import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { REGISTER_WITH_ASANA_MUTATION } from "GraphQL/Mutations";
import { ConditionalOutput, Button } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

const AuthorizeAsana = () => {
    let [registerWithAsana, { data, loading, error }] = useMutation(
        REGISTER_WITH_ASANA_MUTATION
    );

    useEffect(() => {
        if (data) {
            window.location.href = data.registerWithAsana.authLink;
        }
    }, [data]);

    const content = appContentVar().AuthorizeAsana;

    let hendleRegisterWithAsana = () => {
        registerWithAsana();
    };

    return (
        <ConditionalOutput loading={loading} error={error}>
            <Button
                variant={"filled"}
                onClick={hendleRegisterWithAsana}>
                {content}
            </Button>
        </ConditionalOutput>
    );
};

export default AuthorizeAsana;
