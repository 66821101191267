import React from "react";
import {
    ActionBar,
    SearchBar,
    SkillTypeFilterIcon,
    InventorySkillTypeFilterIcon
} from "ComponentIndex";
import {
    useFeedbackSpaceSkillTypeFilterData,
    useInventorySkillTypesFilterData
} from "CustomHooks/CustomQueries";

const SkillsInventoryActions = () => {
    useFeedbackSpaceSkillTypeFilterData();
    useInventorySkillTypesFilterData();

    return (
        <ActionBar>
            <SearchBar />
            <InventorySkillTypeFilterIcon />
        </ActionBar>
    );
};

export default SkillsInventoryActions;
