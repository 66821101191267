import React from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { userTourPreferanceVar } from "GraphQL/ReactiveVariables";
import { SET_TOUR_PREFERANCE } from "GraphQL/Mutations";

const TogleTour = () => {
    const [makeSetting] = useMutation(SET_TOUR_PREFERANCE);
    const productGuide = useReactiveVar(userTourPreferanceVar);
    const handleUserTourClick = (e) => {
        makeSetting({
            variables: {
                userProductGuideParameter: !productGuide
            }
        })
            .then((data) => {
                userTourPreferanceVar(!productGuide);
            })
            .catch((e) => {
                console.log("ERROR", e);
            });
    };
    return (
        <button
            onClick={handleUserTourClick}
            className={"label_medium text-secondary-700"}>
            {productGuide
                ? "Hide product guides"
                : "Reset product guides"}
        </button>
    );
};

export default TogleTour;
