import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    ManageFeedbackSpacesComponent,
    DataSourceSettingsComponent,
    FeedbackSpaceInvitationsComponent,
    AsanaRedirectComponent,
    AtlassianRedirectContent
} from "ComponentIndex";

const AccountSettingsRoot = () => {
    return (
        <Routes>
            <Route
                path={"data-source-settings"}
                element={<DataSourceSettingsComponent />}
            />
            <Route
                path={"manage-feedback-spaces"}
                element={<ManageFeedbackSpacesComponent />}
            />
            <Route
                path={"invitations"}
                element={<FeedbackSpaceInvitationsComponent />}
            />
            <Route
                path={"asana-redirect"}
                element={<AsanaRedirectComponent />}
            />
            <Route
                path={"atlassian-redirect"}
                element={<AtlassianRedirectContent />}
            />
        </Routes>
    );
};

export default AccountSettingsRoot;
