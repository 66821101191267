import { useEffect } from "react";
import { productGuidesVar } from "GraphQL/ReactiveVariables";

export const useLoadForTour = (
    stepToRestartTour,
    tourName = null,
    triggerStep
) => {
    const productGuide = productGuidesVar();
    useEffect(() => {
        if (
            productGuide.tourActive &&
            productGuide.callBackType === tourName &&
            productGuide.stepIndex === triggerStep
        ) {
            productGuidesVar({
                ...productGuidesVar(),
                run: true,
                stepIndex: stepToRestartTour
            });
        }
    }, [productGuide]);
};
