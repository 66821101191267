import React from "react";
import {
    Card,
    DataSourceDescription,
    AsanaAuthorization,
    AtlassianAuthorization
} from "ComponentIndex";
import { useLoadForTour } from "HookIndex";

const DataSourceSettingsContent = () => {
    useLoadForTour(1, "setupTour", 0);
    useLoadForTour(1, "setupTour", 2);
    return (
        <div
            id={"data-sources"}
            className={
                " flex flex-col gap-y-4 w-full md:w-11/12 xl:w-8/12 2xl:w-6/12"
            }>
            <DataSourceDescription />
            <AsanaAuthorization />
            <AtlassianAuthorization />
        </div>
    );
};

export default DataSourceSettingsContent;
