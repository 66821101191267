import React from "react";
import { VerifyCeleryTask } from "ComponentIndex";
import { useDataSync } from "./Utils";
import { Loader, Button } from "ComponentIndex";
import { useReactiveVar } from "@apollo/client";
import { deepSyncVar, appContentVar } from "GraphQL/ReactiveVariables";

let DeepSyncButton = ({ feedbackSpaceId }) => {
    const content = appContentVar().DeepSyncButton;
    let { handleSyncClick, syncStatus, setSyncStatus, data } =
        useDataSync(feedbackSpaceId, true);
    const activeButton = useReactiveVar(deepSyncVar);

    return (
        <>
            {syncStatus && data ? (
                <VerifyCeleryTask
                    celeryTaskId={data.syncTasks.celeryTaskId}
                    taskSyncStatus={data.syncTasks.syncInitiation}
                    setSyncStatus={setSyncStatus}>
                    <Loader
                        size={"medium"}
                        color={"primary"}
                        loadingTitle={"Synchronizing tasks"}
                    />
                </VerifyCeleryTask>
            ) : (
                <Button
                    disabled={!activeButton}
                    variant={"filled"}
                    icon={"published_with_changes"}
                    title={"Deep sync tasks"}
                    onClick={handleSyncClick}>
                    {content[0]}
                </Button>
            )}
        </>
    );
};

export default DeepSyncButton;
