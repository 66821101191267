import React from "react";

const ChartTooltip = ({ state, children }) => {
    return (
        <>
            {state.open && (
                <div
                    className={
                        "fixed shadow-2xl rounded-lg bg-shocking-300 overflow-auto break-words p-4"
                    }
                    style={{
                        top: `${state.y}px`,
                        left: `${state.x}px`
                    }}>
                    {children}
                </div>
            )}
        </>
    );
};

export default ChartTooltip;
