// Utility for maign sinusoid with data abowe zero
export let generateRange = (xMin, xMax, zeroOffset) => {
    let coords = [];
    for (let i = xMin; i <= xMax; i++) {
        coords.push({
            x: i,
            y: Math.pow(i - zeroOffset, 3) + Math.pow(zeroOffset, 3)
        });
    }

    return coords;
};

export const dinamicBoxWidth = (numberOfItems, util_width) => {
    /* function specifies width of a box plot to have spacing */
    let calcWidth = Math.floor(util_width / numberOfItems);
    if (calcWidth / util_width > 0.25) {
        return Math.floor(util_width * 0.25);
    } else {
        return calcWidth;
    }
};
