import React from "react";
import classname from "classnames";
import cls from "./HelpModule.module.css";
import { Icon, ToolTip } from "ComponentIndex";

const HelpModule = ({
    message,
    yOffset = 25,
    xOffset = -20,
    tailX = 19
}) => {
    const helpModuleClass = classname(cls.helpModule);
    return (
        <div className={helpModuleClass}>
            <ToolTip
                top={true}
                yOffset={yOffset}
                xOffset={xOffset}
                tailX={tailX}
                message={message}>
                <Icon
                    color={"primary"}
                    size={"small"}
                    name={"help_outline"}
                />
            </ToolTip>
        </div>
    );
};

export default HelpModule;
