import React from "react";
import {
    TaskFeedbackContent,
    MainLayout,
    TaskFeedbackNavigation,
    TaskFeedbackActions
} from "ComponentIndex";

const TaskFeedbackComponent = () => {
    return (
        <MainLayout
            navigation={<TaskFeedbackNavigation />}
            action={<TaskFeedbackActions />}
            content={<TaskFeedbackContent />}
            workspace={"Feedback"}
            title={"Provide Feedback"}
            activeTab={"PE"}
            feedbackSpaceCheck={true}
            logCode={"PTFPL"}
        />
    );
};

export default TaskFeedbackComponent;
