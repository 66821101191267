import React from "react";
import { ActionIconButton, BackDrop } from "ComponentIndex";

const ActionIconDialog = ({ name, title, children, open, setOpen }) => {
    return (
        <>
            <ActionIconButton
                name={name}
                title={title}
                onClick={(e) => setOpen(true)}
            />
            <BackDrop open={open} setOpen={setOpen}>
                {children}
            </BackDrop>
        </>
    );
};

export default ActionIconDialog;
