import React from "react";
import { Card } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { tourHandlerFactory } from "Components/ProducTour/TourParameters/TriggerHandlers";

const NoFeedbackSpaceInvitations = () => {
    const content = appContentVar().NoFeedbackSpaceInvitations;
    const handler = tourHandlerFactory("invitationTour");

    return (
        <Card type={"light"} basel={"blue-chill"}>
            <div className={"flex flex-col items-center"}>
                <p
                    className={
                        "headline_large text-secondary-800 mb-8 text-center "
                    }>
                    {content[0]}
                </p>
                <p className='body_large mb-1 text-secondary-700 '>
                    {content[1]}{" "}
                    <b
                        onClick={handler}
                        className={"cursor-pointer text-shocking-700"}>
                        {content[2]}
                    </b>
                </p>
                <p className='body_large mb-1 text-secondary-700'>
                    {content[3]}
                </p>
                <p className='body_large mb-1 text-secondary-700'>
                    <a
                        className={
                            "font-bold underline text-shocking-700"
                        }
                        href='mailto:support@enamply.com?subject=Notifications'>
                        {content[4]}
                    </a>
                </p>
            </div>
        </Card>
    );
};

export default NoFeedbackSpaceInvitations;
