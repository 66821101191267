import React, { useState } from "react";
import { ActionIconDialog, AddFeedbackSpace } from "ComponentIndex";

const CreateFeedbackSpace = () => {
    const [open, setOpen] = useState(false);
    return (
        <ActionIconDialog
            title={"Create feedback space"}
            name={"add"}
            open={open}
            setOpen={setOpen}>
            <AddFeedbackSpace setOpen={setOpen} />
        </ActionIconDialog>
    );
};

export default CreateFeedbackSpace;
