import { Button, Input } from "ComponentIndex";

const InviteMemberForm = ({
    handleFormSubmit,
    handleFieldChange,
    formState
}) => {
    return (
        <form
            className={"flex flex-row min-w-fit gap-x-4"}
            onSubmit={handleFormSubmit}>
            <Input
                id={"inviteEmail"}
                type={"email"}
                onChange={handleFieldChange}
                value={formState.inviteEmail}
                placeholder={"Email"}
                required={true}
            />
            <Button type={"submit"} variant={"filled"}>
                Invite
            </Button>
        </form>
    );
};

export default InviteMemberForm;
