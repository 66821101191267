import React from "react";
import {
    MainLayout,
    AccountSettingsNavigation,
    AsanaRedirectActions,
    AsanaRedirectContent
} from "ComponentIndex";

const AsanaRedirectComponent = () => {
    return (
        <MainLayout
            navigation={<AccountSettingsNavigation />}
            action={<AsanaRedirectActions />}
            content={<AsanaRedirectContent />}
            workspace={"Account Settings"}
            title={"Asana Redirect Page"}
            logCode={"PARPL"}
        />
    );
};

export default AsanaRedirectComponent;
