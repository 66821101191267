import React from "react";
import { VerifyCeleryTask } from "ComponentIndex";
import { useShalowSync } from "../Utils";
import { Loader, IconButton } from "ComponentIndex";

let ShallowSyncIcon = () => {
    let { handleSyncClick, syncStatus, setSyncStatus, data } =
        useShalowSync();

    return (
        <>
            {syncStatus && data ? (
                <VerifyCeleryTask
                    celeryTaskId={data.syncTasks.celeryTaskId}
                    taskSyncStatus={data.syncTasks.syncInitiation}
                    setSyncStatus={setSyncStatus}>
                    <Loader
                        size={"medium"}
                        color={"primary"}
                        loadingTitle={"Synchronizing tasks"}
                    />
                </VerifyCeleryTask>
            ) : (
                <IconButton
                    id={"sync_button"}
                    color={"light"}
                    icon={"published_with_changes"}
                    title={"Sync tasks"}
                    onClick={handleSyncClick}
                />
            )}
        </>
    );
};

export default ShallowSyncIcon;
