import { useRef } from "react";
import { useClickOutside, useUserLog } from "HookIndex";
import { useFormPopulate } from "./Utils";
import {
    FeedbackFormContainer,
    ConditionalOutput,
    FeedbackFormHeader,
    Card,
    Hr
} from "ComponentIndex";

const FeedbackForm = ({
    feedbackData,
    taskData,
    setOpen,
    create = false
}) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);

    const { error, loading, states, stateActions, handleFromSubmit } =
        useFormPopulate(feedbackData, create, setOpen);

    useUserLog("feedbackForm", "start");
    return (
        <div
            ref={containerRef}
            className={
                "w-11/12 h-[90vh] lg:w-8/12  xl:w-6/12 2xl:h-fit"
            }>
            <Card type={"light"} strech={false}>
                <div
                    className={
                        "h-full scroll-smooth overflow-auto touch-auto flex flex-col gap-y-2"
                    }>
                    <FeedbackFormHeader task={taskData} />
                    <Hr weight={"small"} color={"light"} />
                    <ConditionalOutput error={error} loading={loading}>
                        <FeedbackFormContainer
                            states={states}
                            stateActions={stateActions}
                            handleFromSubmit={handleFromSubmit}
                        />
                    </ConditionalOutput>
                </div>
            </Card>
        </div>
    );
};

export default FeedbackForm;
