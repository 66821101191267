import React from "react";
import { verifyValueLength } from "UtilityFunctionIndex";
import { Button, Input, InputLabelCount } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

const FeedbackSpaceForm = ({ state, setState, handleSubmit }) => {
    const content = appContentVar().FeedbackSpaceForm;
    const handleFeebackSpaceNameChange = (e) => {
        const value = verifyValueLength(e.target.value, 60);
        setState(value);
    };

    return (
        <form
            className={"flex flex-row min-w-fit gap-x-4 items-center"}
            onSubmit={handleSubmit}>
            <div className={"w-full"}>
                <InputLabelCount
                    label={
                        <h1
                            className={
                                "label_medium text-shocking-700"
                            }>
                            {content.label[0]}
                        </h1>
                    }
                    count={
                        <p
                            className={
                                "label_small text-secondary-900"
                            }>{`${state.length}/60`}</p>
                    }
                    scale={"strech"}
                    input={
                        <Input
                            id={`inputTask`}
                            type={"text"}
                            required={true}
                            value={state}
                            onChange={handleFeebackSpaceNameChange}
                            autoFocus
                        />
                    }
                />
            </div>
            <Button variant={"filled"}>{content.button[0]}</Button>
        </form>
    );
};

export default FeedbackSpaceForm;
