import { useState, useEffect } from "react";
import {
    dataSourceIdsVar,
    dataSourceForFilterVar
} from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { masterAcc } from "UtilityFunctionIndex";

export const stringifyDataSourceItems = (item) => {
    return masterAcc(item, "dataSource", "dataSource");
};

export const useDataSourceFilterItems = () => {
    const [options, setOptions] = useState([]);
    let data = useReactiveVar(dataSourceForFilterVar);

    useEffect(() => {
        dataSourceIdsVar(options.map((item) => item.id));
    }, [options]);

    return { data, options, setOptions };
};
