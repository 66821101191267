import React from "react";
import { useLoginUser } from "./Utils";
import { Link } from "react-router-dom";
import { ConditionalOutput, Input, Button } from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const LoginForm = () => {
    const content = landingContentVar().LoginForm;
    const {
        error,
        loading,
        formState,
        handleFieldChange,
        handleSubmit
    } = useLoginUser();

    return (
        <ConditionalOutput error={error} loading={loading}>
            <form
                className={"flex flex-col  w-80 gap-y-4"}
                onSubmit={handleSubmit}>
                <div className={"w-full"}>
                    <Input
                        id={"email"}
                        type={"email"}
                        onChange={handleFieldChange}
                        value={formState.email}
                        placeholder={content.email}
                        required={true}
                    />
                </div>
                <div>
                    <Input
                        id='password'
                        type='password'
                        onChange={handleFieldChange}
                        placeholder={content.password}
                        required={true}
                    />
                    <Link to='/recover-password'>
                        <p
                            className={
                                "label_small text-shocking-700 mt-2"
                            }>
                            {content.resetPassword}
                        </p>
                    </Link>
                </div>

                <div className={"self-end"}>
                    <Button
                        variant={"filled"}
                        type={"submit"}
                        icon={"login"}>
                        {content.logIn}
                    </Button>
                </div>
            </form>
        </ConditionalOutput>
    );
};

export default LoginForm;
