import React from "react";

const ProblemTextBody = ({ children }) => {
    return (
        <div className={"text-center mt-8 px-4 body_large text-white"}>
            {children}
        </div>
    );
};

export default ProblemTextBody;
