import React, { useState } from "react";
import {
    MenuDropDown,
    GuideList,
    Title,
    TogleTour
} from "ComponentIndex";

const HelpMenu = () => {
    const [open, setOpen] = useState(false);
    let handleDropDownDisplay = (e) => {
        setOpen(!open);
    };
    return (
        <div
            onClick={handleDropDownDisplay}
            className={"relative flex grow justify-end"}>
            <button
                className={"label_large text-secondary-50 capitalize"}
                variant={"tonal"}>
                help
            </button>
            {open ? (
                <MenuDropDown open={open} setOpen={setOpen}>
                    <Title>Guides</Title>
                    <div className={"w-80 flex flex-col gap-y-6"}>
                        <GuideList />
                        <TogleTour />
                    </div>
                </MenuDropDown>
            ) : null}
        </div>
    );
};

export default HelpMenu;
