import React from "react";

const BlogParagraph = ({ children }) => {
    return (
        <p className={"py-2 blog_body_large text-secondary-00"}>
            {children}
        </p>
    );
};

export default BlogParagraph;
