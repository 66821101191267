import React from "react";
import { useMutation } from "@apollo/client";
import { DELETE_TOKEN_COOKIES_MUTATION } from "GraphQL/Mutations";
import reduxActions from "State/ActionIndex";

const LogOut = () => {
    const [deleteCookie, { error, data }] = useMutation(
        DELETE_TOKEN_COOKIES_MUTATION
    );

    let handleLogOut = (e) => {
        deleteCookie();
    };

    if (error) {
        return reduxActions.addNotification(error.message, "error");
    }
    if (data) {
        window.location.reload();
    }

    return <span onClick={handleLogOut}>LogOut</span>;
};

export default LogOut;
