import React, { useRef } from "react";
import { useNotificationPreferences } from "CustomHooks/CustomQueries";
import { isOwnerOrAdmin } from "UtilityFunctionIndex";
import { useClickOutside } from "HookIndex";

import {
    AccountNotifications,
    FeedbackSpaceNotifications,
    ConditionalOutput,
    Title,
    Card
} from "ComponentIndex";

const NotificationSettings = ({ feedbackSpace, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const { data, loading, error } =
        useNotificationPreferences(feedbackSpace);

    return (
        <div ref={containerRef}>
            <Card>
                <ConditionalOutput error={error} loading={loading}>
                    {data ? (
                        <div>
                            <Title>
                                {`Notification settings for: ${feedbackSpace.feedbackSpaceName}`}
                            </Title>
                            <AccountNotifications
                                feedbackSpace={feedbackSpace}
                                data={data.userNotificationPreference}
                            />
                            {isOwnerOrAdmin(feedbackSpace) ? (
                                <FeedbackSpaceNotifications
                                    feedbackSpace={feedbackSpace}
                                    data={
                                        data.userNotificationPreference
                                    }
                                />
                            ) : null}
                        </div>
                    ) : null}
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default NotificationSettings;
