import React from "react";
import { Routes, Route } from "react-router-dom";
import {
    NoFeedbackSpaceComponent,
    FeedbackSpaceNotAvailableComponent
} from "ComponentIndex";

const MessagesRoot = () => {
    return (
        <Routes>
            <Route
                path={"no-feedback-space"}
                element={<NoFeedbackSpaceComponent />}
            />
            <Route
                path={"feedback-space-not-availble"}
                element={<FeedbackSpaceNotAvailableComponent />}
            />
        </Routes>
    );
};

export default MessagesRoot;
