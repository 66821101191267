import AssesmentTruncatedImg from "Images/ignores_incremental_improvement.svg";
import CognitiveBiasImg from "Images/cognitive_bias.svg";
import DisconectedFromTasksImg from "Images/disconected_from_real_tasks.svg";
import InconsistantEvaluationImg from "Images/inconsistant_evaluation.svg";
import NoDataBasedAssesmentImg from "Images/assesment_truncated.svg";
import StressfullAndTimeConsumingImg from "Images/sressfull_time_consuming.svg";
import { landingContentVar } from "GraphQL/ReactiveVariables";
import {
    AssesmentTruncated,
    CognitiveBias,
    DisconectedFromTasks,
    InconsistantEvaluation,
    NoDataBasedAssesment,
    StressfullAndTimeConsuming
} from "./ProblemDescriptions/ProblemDescriptions";
import {
    PublicSection,
    FeedbackProblem,
    ContentWrapperBand
} from "ComponentIndex";
import { SystemicProbemsArticleLink } from "BlogLinkIndex";

const FeedbackProblems = () => {
    const content = landingContentVar().FeedbackProblems;
    return (
        <ContentWrapperBand color={"bg-secondary-700"}>
            <PublicSection
                verticalHeader={content.verticalHeader[0]}
                horizontalHeader={content.horizontalHeader[0]}
                textColor={"text-shocking-400"}
                id={"solution"}>
                <div className={"flex flex-col w-full"}>
                    <div
                        className={
                            "min-h-screen-1/2 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-16 justify-items-center"
                        }>
                        <div className={"text-left md:col-span-2 "}>
                            <p
                                className={
                                    "pl-12 display_small md:display_medium text-shocking-50"
                                }>
                                {content.problemDescription[0]}
                            </p>
                        </div>
                        <FeedbackProblem
                            image={CognitiveBiasImg}
                            alt={content.biasAlt[0]}>
                            <CognitiveBias />
                        </FeedbackProblem>
                        <FeedbackProblem
                            image={AssesmentTruncatedImg}
                            alt={content.truncatedAlt[0]}>
                            <AssesmentTruncated />
                        </FeedbackProblem>
                        <FeedbackProblem
                            image={StressfullAndTimeConsumingImg}
                            alt={content.stressfullAlt[0]}>
                            <StressfullAndTimeConsuming />
                        </FeedbackProblem>
                        <FeedbackProblem
                            image={NoDataBasedAssesmentImg}
                            alt={content.noDataBasedAlt[0]}>
                            <NoDataBasedAssesment />
                        </FeedbackProblem>
                        <FeedbackProblem
                            image={InconsistantEvaluationImg}
                            alt={content.inconsistantAlt[0]}>
                            <InconsistantEvaluation />
                        </FeedbackProblem>
                        <FeedbackProblem
                            image={DisconectedFromTasksImg}
                            alt={content.disconectedAlt[0]}>
                            <DisconectedFromTasks />
                        </FeedbackProblem>
                    </div>
                    <p
                        className={
                            "self-end text-shocking-300 label_large mt-12"
                        }>
                        <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={SystemicProbemsArticleLink}>
                            {content.readMore}
                        </a>
                    </p>
                </div>
            </PublicSection>
        </ContentWrapperBand>
    );
};

export default FeedbackProblems;
