import React, { forwardRef } from "react";
import {
    DivWrapper,
    SVGWrapper,
    Label,
    ChartBounds,
    PlotAreaBackground,
    Grid,
    AxisLeft,
    AxisBottom,
    VerticalGradient,
    ChartLoading,
    Line,
    Area
} from "ChartIndex";

const MedianConfidenceChartSVG = forwardRef(({ graphs }, ref) => {
    return (
        <DivWrapper>
            <SVGWrapper ref={ref}>
                {graphs.medianLine ? (
                    <>
                        <Label
                            x={graphs.dimensions.width / 2}
                            y={graphs.dimensions.margin.top * 0.75}
                            type={"chart_name"}
                            color={"dark"}>
                            {graphs.chartName}
                        </Label>
                        <ChartBounds
                            xOffset={graphs.dimensions.margin.left}
                            yOffset={graphs.dimensions.margin.top}>
                            <g className='chartBackground'>
                                <PlotAreaBackground
                                    height={
                                        graphs.dimensions.boundedHeight
                                    }
                                    width={
                                        graphs.dimensions.boundedWidth
                                    }
                                    fill={"url(#verticalGradient)"}
                                />
                                <Grid
                                    horizontal={false}
                                    dataArray={graphs.xGrid}
                                    length={
                                        graphs.dimensions.boundedHeight
                                    }
                                />
                                <Grid
                                    horizontal={true}
                                    dataArray={graphs.yGrid}
                                    length={
                                        graphs.dimensions.boundedWidth
                                    }
                                />
                            </g>
                            <g>
                                <Area d={graphs.confidenceInterval} />
                                <Line d={graphs.medianLine} />
                            </g>

                            <g className='peripherials'>
                                <AxisLeft
                                    dataSequence={graphs.yAxis}
                                    length={5}
                                    xOffset={-5}
                                    yOffset={0}
                                    textOffsetX={-5}
                                    textOffsetY={3}
                                    textAnchor={"end"}
                                />

                                <AxisBottom
                                    dataSequence={graphs.xAxis}
                                    length={5}
                                    xOffset={0}
                                    yOffset={
                                        graphs.dimensions.boundedHeight
                                    }
                                    textRotation={45}
                                    textAnchor={"start"}
                                    textOffsetX={0}
                                    textOffsetY={15}
                                />
                            </g>
                        </ChartBounds>
                        <Label
                            x={
                                graphs.dimensions.margin.left +
                                graphs.dimensions.boundedWidth * 0.5
                            }
                            y={
                                graphs.dimensions.height -
                                graphs.dimensions.margin.bottom * 0.2
                            }
                            type={"axis_name"}
                            color={"dark"}>
                            {graphs.xAxisLabel}
                        </Label>
                        <defs>
                            <VerticalGradient />
                        </defs>
                    </>
                ) : (
                    <ChartLoading />
                )}
            </SVGWrapper>
        </DivWrapper>
    );
});

export default MedianConfidenceChartSVG;
