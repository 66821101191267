export const SETUP_TOUR_LINKS = [
    "account-settings/data-source-settings",
    "account-settings/manage-feedback-spaces",
    "account-settings/invitations"
];

export const INVITATIONS_TOUR_LINKS = ["account-settings/invitations"];

export const MANAGE_SKILLS_LINKS = [
    "skills/add-skills",
    "skills/skills-inventory"
];

export const FEEDBACK_TOUR_LINKS = ["taskfeedback/"];

export const INSIGHTS_TOUR_LINKS = [
    "insights/feedback-insights",
    "insights/skills-insights"
];
