import React from "react";
import classnames from "classnames";
import cls from "./Grid.module.css";
import { generateCoords } from "./Utils";

const Grid = ({ horizontal, dataArray, length }) => {
    const gridLineClass = classnames(cls.gridLine);
    return (
        <>
            {dataArray && (
                <g className={`grid${horizontal}`}>
                    {dataArray.map((d, index) => {
                        const [x1, x2, y1, y2] = generateCoords(
                            horizontal,
                            d,
                            length
                        );
                        return (
                            <line
                                className={gridLineClass}
                                key={`${horizontal}Grid${index}`}
                                x1={x1}
                                x2={x2}
                                y1={y1}
                                y2={y2}
                            />
                        );
                    })}
                </g>
            )}
        </>
    );
};

export default Grid;
