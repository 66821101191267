import { STATUS, ACTIONS } from "react-joyride";
import {
    SETUP_TOUR_LINKS,
    INVITATIONS_TOUR_LINKS,
    MANAGE_SKILLS_LINKS,
    FEEDBACK_TOUR_LINKS,
    INSIGHTS_TOUR_LINKS
} from "./NavigationLinks";
import { productGuidesVar } from "GraphQL/ReactiveVariables";

export const callbackFactory = (callbackType, navigate) => {
    switch (callbackType) {
        case "featureTour":
            return featureTourCallback;
        case "setupTour":
            return (data) => setupTourCallback(data, navigate);
        case "invitationTour":
            return (data) => invitationTourCallback(data, navigate);
        case "manageSkillsTour":
            return (data) => manageSkillsTourCallback(data, navigate);
        case "feedbackTour":
            return (data) => feedbackTourCallback(data, navigate);
        case "insightsTour":
            return (data) => insightsTourCallback(data, navigate);
        default:
            return null;
    }
};

const featureTourCallback = (data) => {
    const { status, type, action, index } = data;

    finished(status, action);
    tourDirectionSwitch(
        type,
        action,
        () => {
            increaseStepIndex(index);
        },
        () => {
            decreaseStepIndex(index);
        }
    );
};

const setupTourCallback = (data, navigate) => {
    const { status, type, index, action } = data;

    finished(status, action);
    tourDirectionSwitch(
        type,
        action,
        () => {
            setupTourForwardSwitch(index, navigate, SETUP_TOUR_LINKS);
        },
        () => {
            setupTourBackwardSwitch(index, navigate, SETUP_TOUR_LINKS);
        }
    );
};

const invitationTourCallback = (data, navigate) => {
    const { status, type, index, action } = data;

    // console.log("Controlled: ", controlled);
    // console.log("Status: ", status);
    // console.log("Type: ", type);
    // console.log("Index: ", index);
    // console.log("Action: ", action);
    // console.log("Life cycle: ", lifecycle);
    // console.log("*********************");

    finished(status, action);

    tourDirectionSwitch(
        type,
        action,
        () => {
            invitationTourForwardSwitch(
                index,
                navigate,
                INVITATIONS_TOUR_LINKS
            );
        },
        () => {
            invitationTourBackwardSwitch(index);
        }
    );
};

const manageSkillsTourCallback = (data, navigate) => {
    const { status, type, index, action } = data;

    finished(status, action);

    tourDirectionSwitch(
        type,
        action,
        () =>
            manageSkillsForwardSwitch(
                index,
                navigate,
                MANAGE_SKILLS_LINKS
            ),
        () => {
            manageSkillsBackwardSwitch(
                index,
                navigate,
                MANAGE_SKILLS_LINKS
            );
        }
    );
};

const feedbackTourCallback = (data, navigate) => {
    const { status, type, index, action } = data;

    finished(status, action);

    tourDirectionSwitch(
        type,
        action,
        () =>
            feedbackTourForwardSwitch(
                index,
                navigate,
                FEEDBACK_TOUR_LINKS
            ),
        () =>
            feedbackTourBackwardSwitch(
                index,
                navigate,
                FEEDBACK_TOUR_LINKS
            )
    );
};

const insightsTourCallback = (data, navigate) => {
    const { status, type, index, action } = data;

    finished(status, action);

    tourDirectionSwitch(
        type,
        action,
        () =>
            insightsTourForwardSwitch(
                index,
                navigate,
                INSIGHTS_TOUR_LINKS
            ),
        () =>
            insightsTourBackwardSwitch(
                index,
                navigate,
                INSIGHTS_TOUR_LINKS
            )
    );
};

// setup tour utils

const setupTourForwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 0:
            redirectToTourRoute(links[0], navigate);
            break;
        case 1:
            redirectToTourRoute(links[1], navigate);
            break;
        case 8:
            redirectToTourRoute(links[2], navigate);
            break;
        default:
            increaseStepIndex(index);
    }
};

const setupTourBackwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 2:
            redirectToTourRoute(links[0], navigate);
            break;
        case 9:
            redirectToTourRoute(links[1], navigate);
            break;
        default:
            decreaseStepIndex(index);
    }
};

// invitation tour utils

const invitationTourForwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 1:
            redirectToTourRoute(links[0], navigate);
            break;
        default:
            increaseStepIndex(index);
    }
};

const invitationTourBackwardSwitch = (index) => {
    decreaseStepIndex(index);
};

// skill tour utils

const manageSkillsForwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 0:
            redirectToTourRoute(links[0], navigate);
            break;

        case 3:
            redirectToTourRoute(links[1], navigate);
            break;

        default:
            increaseStepIndex(index);
            break;
    }
};

const manageSkillsBackwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 4:
            redirectToTourRoute(links[0], navigate);
            break;

        default:
            decreaseStepIndex(index);
            break;
    }
};

// feedback tour utils

const feedbackTourForwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 0:
            redirectToTourRoute(links[0], navigate);
            break;
        default:
            increaseStepIndex(index);
            break;
    }
};

const feedbackTourBackwardSwitch = (index, navigate, links) => {
    decreaseStepIndex(index);
};

// insights tour utils

const insightsTourForwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 0:
            redirectToTourRoute(links[0], navigate);
            break;
        case 3:
            redirectToTourRoute(links[1], navigate);
            break;
        default:
            increaseStepIndex(index);
            break;
    }
};

const insightsTourBackwardSwitch = (index, navigate, links) => {
    switch (index) {
        case 4:
            redirectToTourRoute(links[0], navigate);
            break;
        default:
            decreaseStepIndex(index);
            break;
    }
};

// common functions
const finished = (status, action) => {
    const finishedStatuses = [
        STATUS.FINISHED,
        STATUS.SKIPPED,
        ACTIONS.CLOSE
    ];
    if (
        finishedStatuses.includes(status) ||
        finishedStatuses.includes(action)
    ) {
        productGuidesVar({
            run: false,
            tourActive: false,
            stepIndex: 0
        });
    }
};

const redirectToTourRoute = (navLink, navigate) => {
    stopStepProgression();
    navigate(navLink);
};

const increaseStepIndex = (index) => {
    productGuidesVar({
        ...productGuidesVar(),
        stepIndex: index + 1
    });
};

const decreaseStepIndex = (index) => {
    productGuidesVar({
        ...productGuidesVar(),
        stepIndex: index - 1
    });
};

const tourDirectionSwitch = (
    type,
    action,
    forwardSwitch,
    backwardSwitch
) => {
    if (type === "step:after" && action === "next") {
        console.log("in forward");
        return forwardSwitch();
    } else if (type === "step:after" && action === "prev") {
        return backwardSwitch();
    }
};

const stopStepProgression = () => {
    productGuidesVar({
        ...productGuidesVar(),
        run: false
    });
};
