import React from "react";
import { useReactiveVar } from "@apollo/client";

import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    DrawerCompartment,
    RouterLink,
    DrawerMenuItem
} from "ComponentIndex";

const DrawerNavigation = () => {
    const wsName = useReactiveVar(workspaceNameVar);

    return ( 
        <div className={"flex flex-col gap-y-1"}>
            <DrawerCompartment label={"Navigation"} />
            <RouterLink to={`/app/`}>
                <DrawerMenuItem
                    id={"dashboard"}
                    selected={wsName === "Dashboard"}
                    iconName={"dashboard"}
                    itemName={"Dashboard"}
                />
            </RouterLink>
            <RouterLink to={`/app/taskfeedback/`}>
                <DrawerMenuItem
                    id={"feedback"}
                    selected={wsName === "Feedback"}
                    iconName={"rate_review"}
                    itemName={"Feedback"}
                />
            </RouterLink>
            <RouterLink to={`/app/insights/skills-insights`}>
                <DrawerMenuItem
                    id={"insights"}
                    selected={wsName === "Insights"}
                    iconName={"summarize"}
                    itemName={"Insights"}
                />
            </RouterLink>
        </div>
    );
};

export default DrawerNavigation;
