import { Icon, ComboBoxIcon } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";

import { stringifySkillItems, useSkillsFilterItems } from "./Utils";

const SkillNameFilterIcon = () => {
    const content = appContentVar().SkillNameFilterIcon;
    const { data, options, setOptions } = useSkillsFilterItems();

    return (
        <ComboBoxIcon
            size={"md_24"}
            options={options}
            setOptions={setOptions}
            items={data}
            itemToString={stringifySkillItems}
            itemPrefix={"skillName"}
            emptyMessage={content.emptyMessage[0]}>
            <Icon
                title={content.title[0]}
                shadow={true}
                name={"psychology"}
                size={"small"}
                color={"light"}
            />
        </ComboBoxIcon>
    );
};

export default SkillNameFilterIcon;
