import React from "react";
import {
    SearchBar,
    DateRangePicker,
    AssigneeFilterIcon,
    ProjectsFilterIcon,
    TaskTypeFilterIcon,
    DataSourceFilterIcon,
    ActionBar
} from "ComponentIndex";

import {
    useAssigneeFilterData,
    useProjectFilterData,
    useTaskTypeFilterData,
    useDataSourceFilterData
} from "CustomHooks/CustomQueries";

const TaskFeedbackActions = () => {
    useAssigneeFilterData();
    useProjectFilterData();
    useTaskTypeFilterData();
    useDataSourceFilterData();

    return (
        <ActionBar>
            <SearchBar />
            <DateRangePicker />
            <AssigneeFilterIcon />
            <ProjectsFilterIcon />
            <TaskTypeFilterIcon />
            <DataSourceFilterIcon />
        </ActionBar>
    );
};

export default TaskFeedbackActions;
