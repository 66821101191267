import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    AddSkillsComponent,
    SkillsInventoryComponent
} from "ComponentIndex";

const SkillsRoot = () => {
    return (
        <Routes>
            <Route
                path={"add-skills"}
                element={<AddSkillsComponent />}
            />
            <Route
                path={"skills-inventory"}
                element={<SkillsInventoryComponent />}
            />
        </Routes>
    );
};

export default SkillsRoot;
