import React from "react";

import { Card } from "ComponentIndex";

const InfoWidget = ({
    type = "light",
    elevation,
    strech = true,
    rounded = "3xl",
    children
}) => {
    return (
        <div className={"w-48 h-48"}>
            <Card
                rounded={rounded}
                type={type}
                elevation={elevation}
                strech={strech}>
                <div
                    className={
                        "p-2 h-full flex flex-col gap-y-2 justify-around"
                    }>
                    {children}
                </div>
            </Card>
        </div>
    );
};

export default InfoWidget;
