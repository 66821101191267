import React from "react";
import classnames from "classnames";
import cls from "./FeedbackSpaceDailyDigest.module.css";
import { Switch, HelpModule } from "ComponentIndex";
import { useNotificationPrefChange } from "CustomHooks/CustomMutations";

const FeedbackSpaceDailyDigest = ({ data, feedbackSpace }) => {
    const mutationVariable = {
        parameterLabel: "feedback_space_daily_digest",
        feedbackSpaceId: feedbackSpace.id
    };
    const [switchState, handleSwitchChange] = useNotificationPrefChange(
        data,
        mutationVariable,
        "fb"
    );

    const feedbackSpaceDailyDigestClass = classnames(
        cls.feedbackSpaceDailyDigest
    );
    const descriptionClass = classnames(cls.description);

    return (
        <div className={feedbackSpaceDailyDigestClass}>
            <Switch
                checked={switchState}
                onChange={handleSwitchChange}
            />
            <div className={descriptionClass}>
                <p className={"label_large text-secondary-900"}>
                    {"Send feedback space daily digest"}
                </p>
            </div>
            <HelpModule
                yOffset={-110}
                xOffset={-120}
                message={
                    "You will receive a short resume of events heppened whithin this feedback space during previous day on Enamply platform."
                }
            />
        </div>
    );
};

export default FeedbackSpaceDailyDigest;
