import React from "react";

import {
    MainLayout,
    SkillsNavigation,
    AddSkillsContent,
    AddSkillsActions
} from "ComponentIndex";

const AddSkillsComponent = () => {
    return (
        <MainLayout
            navigation={<SkillsNavigation />}
            action={<AddSkillsActions />}
            content={<AddSkillsContent />}
            workspace={"Skills Management"}
            title={"Add Skills"}
            activeTab={"addSkills"}
            logCode={"PASPL"}
        />
    );
};

export default AddSkillsComponent;
