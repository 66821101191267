import React from "react";
import { chartTheme } from "ComponentIndex";

const VerticalGradient = () => {
    return (
        <linearGradient
            id='verticalGradient'
            x1='0%'
            x2='0%'
            y1='100%'
            y2='0%'>
            <stop
                offset='0%'
                stopColor={chartTheme.color.gradient.gradient1}
            />
            <stop
                offset='20%'
                stopColor={chartTheme.color.gradient.gradient2}
            />
            <stop
                offset='40%'
                stopColor={chartTheme.color.gradient.gradient3}
            />
            <stop
                offset='80%'
                stopColor={chartTheme.color.gradient.gradient4}
            />
            <stop
                offset='100%'
                stopColor={chartTheme.color.gradient.gradient5}
            />
        </linearGradient>
    );
};

export default VerticalGradient;
