import { format } from "date-fns";

export const makeArray = (data, dataAccessorFunction) => {
    // data should be provided to the level on which .map should be applied
    if (data && typeof data !== "undefined") {
        return data.map(dataAccessorFunction);
    } else {
        return [];
    }
};

export const makeFlatArray = (data, dataAccessorFunction) => {
    if (data && typeof data !== "undefined") {
        return data.map(dataAccessorFunction).flat(Infinity);
    } else {
        return [];
    }
};

export const verifyValueLength = (value, limit) => {
    if (value <= limit) {
        return value;
    } else {
        return value.slice(0, limit);
    }
};

export const makeSet = (data, setKey) => {
    return new Set(
        data.map((value) => {
            return value[setKey];
        })
    );
};

export const getUniqueArray = (data) => {
    const set = new Set(data);
    return [...set];
};

export const extractFromArray = (minuend, subtrahend) => {
    return minuend.filter((el) => !subtrahend.includes(el));
};

export const makeDateSet = (data, setKey) => {
    return new Set(
        data.map((value) => {
            return format(new Date(value[setKey]), "yyyy-MM-dd");
        })
    );
};

export const treatUndefinedValue = (value) => {
    if (value) {
        return value;
    } else {
        return "NA";
    }
};

export const removeNullFromArray = (array) => {
    return array.filter((item) => {
        return item != null;
    });
};

export const getGQLData = (data, prefix) => {
    if (data) {
        return data[prefix];
    } else {
        return null;
    }
};

// date time

export const convertDate = (dateVar) => {
    if (dateVar) {
        return format(new Date(dateVar), "yyyy-MM-dd");
    } else {
        return dateVar;
    }
};
