import React from "react";
import { Link } from "react-router-dom";
import { IconButton, ConditionalOutput } from "ComponentIndex";
import { useGetFeedbackSpaceInvitations } from "CustomHooks/CustomQueries";

const Notifications = () => {
    const { data, loading, error } = useGetFeedbackSpaceInvitations();

    return (
        <div id={"notifications"}>
            <ConditionalOutput error={error} loading={loading}>
                {data && data.feedbackSpaceInvitations.length !== 0 ? (
                    <Link
                        className={"flex flex-row align-middle"}
                        to={"/app/account-settings/invitations"}>
                        <IconButton
                            icon={"group_add"}
                            color={"light"}
                        />
                    </Link>
                ) : null}
            </ConditionalOutput>
        </div>
    );
};

export default Notifications;
