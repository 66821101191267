import React from "react";
import classnames from "classnames";
import cls from "./AccountDailyDigest.module.css";
import { Switch, HelpModule } from "ComponentIndex";
import { useNotificationPrefChange } from "CustomHooks/CustomMutations";

const AccountDailyDigest = ({ data, feedbackSpace }) => {
    const mutationVariable = {
        parameterLabel: "account_daily_digest",
        feedbackSpaceId: feedbackSpace.id
    };
    const [switchState, handleSwitchChange] = useNotificationPrefChange(
        data,
        mutationVariable,
        "acc"
    );

    const accountDailyDigestClass = classnames(cls.accountDailyDigest);
    const descriptionClass = classnames(cls.description);

    return (
        <div className={accountDailyDigestClass}>
            <Switch
                checked={switchState}
                onChange={handleSwitchChange}
            />
            <div className={descriptionClass}>
                <p className={"label_large text-secondary-900"}>
                    {"Send daily digest"}
                </p>
            </div>
            <HelpModule
                message={
                    "You will receive a short resume of events concerning you that heppened during previous day on Enamply platform."
                }
            />
        </div>
    );
};

export default AccountDailyDigest;
