import React from "react";

const TabBar = ({ children }) => {
    return (
        <div className={"flex flex-row gap-x-2 h-full bg-transparent "}>
            {children}
        </div>
    );
};

export default TabBar;
