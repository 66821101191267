import {
    ResumeTableConstructor,
    ConditionalOutput
} from "ComponentIndex";
import { useGetTableData } from "./Utils";

const ResumeTable = () => {
    const { data, loading, error } = useGetTableData();

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <ResumeTableConstructor data={data.tasks.objects} />
            ) : null}
        </ConditionalOutput>
    );
};

export default ResumeTable;
