import { useState, useEffect } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
    currentFeedbackSpaceVar,
    deepSyncVar
} from "GraphQL/ReactiveVariables";
import {
    SYNC_TASKS_MUTATION,
    SYNC_ATLASSIAN_TASK_MUTATIONS
} from "GraphQL/Mutations";
import reduxActions from "State/ActionIndex";

export const useDataSync = (feedbackSpaceId, deepSync = false) => {
    let [syncStatus, setSyncStatus] = useState(false);

    let [tskSync, { data }] = useMutation(SYNC_TASKS_MUTATION, {
        variables: {
            deepSync: deepSync,
            feedbackSpaceId: feedbackSpaceId
        },
        onCompleted: () => deepSyncVar(false)
    });
    let [atlassianSync, { data: atlassianResult }] = useMutation(
        SYNC_ATLASSIAN_TASK_MUTATIONS,
        {
            variables: {
                feedbackSpaceId: feedbackSpaceId
            },
            onCompleted: () => deepSyncVar(false)
        }
    );
    let handleSyncClick = () => {
        atlassianSync()
            .then((result) => {
                // TODO do something with result that is boolean
            })
            .catch((e) => {
                reduxActions.addNotification(e.message, "error");
            });
        tskSync()
            .then((result) => {
                setSyncStatus(result.data.syncTasks.syncInitiation);
            })
            .catch((e) => {
                reduxActions.addNotification(e.message, "error");
            });
    };

    return {
        handleSyncClick,
        tskSync,
        syncStatus,
        setSyncStatus,
        data,
        atlassianSync
    };
};

export const useShalowSync = () => {
    const currentFeedbackSpace = useReactiveVar(
        currentFeedbackSpaceVar
    ).id;

    let {
        handleSyncClick,
        tskSync,
        syncStatus,
        setSyncStatus,
        data,
        atlassianSync
    } = useDataSync(currentFeedbackSpace);

    useEffect(() => {
        if (currentFeedbackSpace) {
            atlassianSync()
                .then((result) => {
                    // TODO do something with result that is boolean
                })
                .catch((e) => {
                    reduxActions.addNotification(e.message, "error");
                });
            tskSync()
                .then((result) => {
                    setSyncStatus(result.data.syncTasks.syncInitiation);
                })
                .catch((e) => {
                    reduxActions.addNotification(e.message, "error");
                });
        }
    }, [currentFeedbackSpace]);

    return {
        handleSyncClick,
        syncStatus,
        setSyncStatus,
        data
    };
};
