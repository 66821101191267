import React from "react";
import {
    SkillsSelector,
    InfoModule,
    SkillSelectorInfo
} from "ComponentIndex";

const SkillSelectors = ({
    areaSpecificSkills,
    technicalSkills,
    interpersonalSkills,
    managementSkills,
    setAreaSpecificSkills,
    setTechnicalSkills,
    setInterpersonalSkills,
    setManagementSkills
}) => {
    return (
        <div className={"flex flex-col items-end w-full"}>
            <InfoModule
                message={<SkillSelectorInfo />}
                xOffset={-300}
            />
            <SkillsSelector
                skillIdsState={areaSpecificSkills}
                setSkillIdsState={setAreaSpecificSkills}
                placeHolder={"Area specific skills"}
                skillType={"ASP"}
                leadingIconName={"batch_prediction"}
            />
            <SkillsSelector
                skillIdsState={technicalSkills}
                setSkillIdsState={setTechnicalSkills}
                placeHolder={"Technical skills"}
                skillType={"TEC"}
                leadingIconName={"settings_applications"}
            />
            <SkillsSelector
                skillIdsState={interpersonalSkills}
                setSkillIdsState={setInterpersonalSkills}
                placeHolder={"Interpersonal skills"}
                skillType={"INT"}
                leadingIconName={"groups"}
            />
            <SkillsSelector
                skillIdsState={managementSkills}
                setSkillIdsState={setManagementSkills}
                placeHolder={"Management skills"}
                skillType={"MNG"}
                leadingIconName={"schema"}
            />
        </div>
    );
};

export default SkillSelectors;
