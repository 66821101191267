import React from "react";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const LeadershipContent = () => {
    const content = landingContentVar().LeadershipContent;
    return (
        <div
            className={
                "lg:h-full rounded-md shadow-md bg-white p-8 border-solid border-2 border-shocking-400"
            }>
            <h3 className={"title_large text-shocking-400"}>
                {content.header[0]}
            </h3>
            <ul
                className={
                    "pt-6 flex flex-col gap-y-4 justify-between lg:flex-row lg:gap-x-6 2xl:gap-x-12"
                }>
                <li
                    className={
                        "mb-4 body_large text-secondary-900 w-full lg:5/12"
                    }>
                    {content.text[0]}
                </li>
                <li
                    className={
                        "mb-4 body_large text-secondary-900 w-full lg:5/12"
                    }>
                    {content.text[1]}
                </li>
            </ul>
        </div>
    );
};

export default LeadershipContent;
