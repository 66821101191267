export const generateCoords = (horizontal, data, length) => {
    let x1 = 0;
    let x2 = 0;
    let y1 = 0;
    let y2 = 0;
    if (horizontal) {
        x1 = 0;
        x2 = length;
        y1 = `${data}`;
        y2 = `${data}`;
    } else {
        x1 = `${data}`;
        x2 = `${data}`;
        y1 = 0;
        y2 = length;
    }

    return [x1, x2, y1, y2];
};
