import { landingContentVar } from "GraphQL/ReactiveVariables";
import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    ExtendedFeatures,
    ClickToAction,
    ContentWrapperBand,
    HomeFooter,
    HelmetInclude
} from "ComponentIndex";
import { useMoveToPageTop } from "HookIndex";

const PublicFeaturesContent = () => {
    useMoveToPageTop();
    const content = landingContentVar().PublicFeaturesContent;
    workspaceNameVar("Features");

    return (
        <>
            <HelmetInclude content={content} />
            <ContentWrapperBand color={"bg-white"}>
                <ExtendedFeatures />
                <ClickToAction />
            </ContentWrapperBand>
            <HomeFooter />
        </>
    );
};

export default PublicFeaturesContent;
