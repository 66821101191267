import React from "react";
import cn from "classnames";
import { IconButton, Icon } from "ComponentIndex";

const ExtendedAutocomplete = ({
    placeHolder,
    leadingIconName = null,
    itemPrefix,
    autoCompleteProps: {
        items,
        getSelectedItemProps,
        getDropdownProps,
        reset,
        removeSelectedItem,
        selectedItems,
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        getFilteredItems,
        noItems
    }
}) => {
    const autoCompleteClass = "relative w-full";

    const selectionAreaClass = cn(
        "appearance-none outline-none w-full min-h-9 max-h-64 border-t border-l border-r border-secondary-400 bg-transparent rounded-t flex flex-col justify-between"
    );

    const optionListClass = cn(
        "absolute top-0 left-0 z-10 rounded",
        "bg-white w-full shadow-md placeholder:text-secondary-300",
        "placeholder:label_large",
        {
            "border border-secondary-100 py-2 px-2 flex flex-col gap-y-2":
                isOpen === true
        }
    );

    const optionItemClass = cn([
        "cursor-pointer text-secondary-900",
        "label_medium rounded px-2 py-1",
        "hover:bg-shocking-700 hover:text-white hover:text-shadow"
    ]);

    return (
        <div className={autoCompleteClass}>
            <div className={selectionAreaClass}>
                <div
                    className={
                        "h-full flex flex-row p-1 w-full justify-between overflow-y-auto"
                    }>
                    <div className={"flex flex-row flex-wrap gap-2 "}>
                        {selectedItems.map((selectedItem, index) => (
                            <div
                                className={
                                    "flex flex-row max-w-40 gap-x-2 items-center h-6 border rounded border-secondary-200"
                                }
                                key={`selected-item${index}}`}
                                {...getSelectedItemProps({
                                    selectedItem,
                                    index
                                })}
                                title={selectedItem}>
                                <p
                                    className={
                                        "label_small text-secondary-800 truncate pl-2"
                                    }>
                                    {selectedItem[itemPrefix]}
                                </p>
                                <IconButton
                                    size={"small"}
                                    variant={"standard"}
                                    icon={"close"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeSelectedItem(
                                            selectedItem
                                        );
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    {!!selectedItems.length && (
                        <IconButton
                            size={"small"}
                            variant={"standard"}
                            icon={"close"}
                            onClick={reset}
                            aria-label={"toggle menu"}
                        />
                    )}
                </div>
            </div>
            <div
                className={
                    "flex flex-row rounded-b border-none bg-shocking-300 w-full h-6 border-t-none items-center"
                }>
                {leadingIconName && (
                    <div className={"pl-2"}>
                        <Icon
                            name={leadingIconName}
                            color={"dark"}
                            size={"small"}
                        />
                    </div>
                )}
                <input
                    className={
                        "bg-transparent rounded-b w-full left-0 outline-none pl-6 label_small h-6 text-secondary-800 placeholder:text-secondary-800"
                    }
                    placeholder={`Search ${placeHolder}`}
                    {...getInputProps(
                        getDropdownProps({
                            preventKeyAction: isOpen,
                            onFocus: () => {
                                if (!isOpen) {
                                    openMenu();
                                }
                            },
                            onClick: () => {
                                if (!isOpen) {
                                    openMenu();
                                }
                            }
                        })
                    )}
                />
            </div>
            <div className={"relative"}>
                <ul className={optionListClass} {...getMenuProps()}>
                    {isOpen ? (
                        getFilteredItems(items).length ? (
                            getFilteredItems(items).map(
                                (item, index) => (
                                    <li
                                        className={optionItemClass}
                                        {...getItemProps({
                                            key: index,
                                            index,
                                            item,
                                            style: {
                                                backgroundColor:
                                                    highlightedIndex ===
                                                    index
                                                        ? "##9A2472"
                                                        : ""
                                            }
                                        })}>
                                        {item[itemPrefix]}
                                    </li>
                                )
                            )
                        ) : (
                            <li
                                className={
                                    "label_medium text-secondary-600 text-center"
                                }>
                                {noItems}
                            </li>
                        )
                    ) : null}
                </ul>
            </div>
        </div>
    );
};

export default ExtendedAutocomplete;
