import React from "react";

const TextArea = ({ ...rest }) => {
    return (
        <textarea
            className={
                "w-full placeholder:text-secondary-300 placeholder:text-base border border-secondary-500 rounded-md py-2 px-3 focus_ring outline-none text-secondary-900"
            }
            {...rest}
        />
    );
};

export default TextArea;
