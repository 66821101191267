import React from "react";
import { Button, BackDrop } from "ComponentIndex";

const ButtonDialog = ({
    label,
    children,
    open,
    setOpen,
    variant = "filled",
    icon,
    disabled = false,
    id
}) => {
    return (
        <>
            <Button
                id={id}
                disabled={disabled}
                variant={variant}
                icon={icon}
                onClick={(e) => setOpen(true)}>
                {label}
            </Button>

            <BackDrop open={open} setOpen={setOpen}>
                {children}
            </BackDrop>
        </>
    );
};

export default ButtonDialog;
