import React from "react";
import { useSelector } from "react-redux";
import { Title } from "ComponentIndex";
import { content_eng } from "ContentManagement/english";

const SkillCathegoryText = () => {
    const selectedSkillType = useSelector(
        (state) => state.skillTypeContent
    );
    const content = content_eng["skillCategory"][selectedSkillType];

    return (
        <>
            <Title type={"title_medium"}>
                {"Description per type of skills"}
            </Title>
            <h2 className={"title_small text-secondary-900 my-4"}>
                {content[1]}
            </h2>

            <p className={"body_medium text-secondary-900"}>
                {content[2]}
            </p>
            <p className={"body_medium text-secondary-900"}>
                {content[3]}
            </p>
        </>
    );
};

export default SkillCathegoryText;
