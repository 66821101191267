import React from "react";

import { SkillsAutocomplete, ConditionalOutput } from "ComponentIndex";

import { useDataPopulateSelectorData, prepareData } from "./Utils";

const SkillsSelector = ({
    skillIdsState,
    setSkillIdsState,
    skillType,
    placeHolder,
    leadingIconName = null
}) => {
    let { data, error, loading } =
        useDataPopulateSelectorData(skillType);
    return (
        <div className={"flex flex-row items-center w-full"}>
            <ConditionalOutput error={error} loading={loading}>
                {data ? (
                    <SkillsAutocomplete
                        skillIdsState={skillIdsState}
                        setSkillIdsState={setSkillIdsState}
                        items={prepareData(data.skillsInFeedbackSpace)}
                        placeHolder={placeHolder}
                        leadingIconName={leadingIconName}
                        skillType={skillType}
                        itemPrefix={"skillName"}
                    />
                ) : null}
            </ConditionalOutput>
        </div>
    );
};

export default SkillsSelector;
