import { useMutation } from "@apollo/client";
import { LEAVE_FEEDBACK_SPACE_MUTATION } from "GraphQL/Mutations";
import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import { clearCacheItem } from "GraphQL/Cache";

export const useLeaveFeedbackSpace = (setOpenLeave, feedbackSpace) => {
    const [leaveFeedbackSpace, { error, loading }] = useMutation(
        LEAVE_FEEDBACK_SPACE_MUTATION,
        {
            variables: {
                feedbackSpaceId: feedbackSpace.id
            },
            onCompleted: () => {
                clearCacheItem(feedbackSpace);
                currentFeedbackSpaceVar({
                    id: null,
                    feedbackSpaceName: "",
                    role: ""
                });
            }
        }
    );

    const handleFeedbackSpaceLeave = (e) => {
        leaveFeedbackSpace()
            .then((data) => {
                setOpenLeave(false);
            })
            .catch((e) => {
                setOpenLeave(false);
            });
    };
    return { handleFeedbackSpaceLeave, error, loading };
};
