import React from "react";
import { SkillsMatrixTable, ConditionalOutput } from "ComponentIndex";
import { useGetSkillMatrixData } from "./Utils";

const SkillsMatrix = () => {
    const { data, loading, error } = useGetSkillMatrixData();
    return (
        <ConditionalOutput error={error} loading={loading}>
            {data ? (
                <SkillsMatrixTable skillMatrix={data.skillMatrix} />
            ) : null}
        </ConditionalOutput>
    );
};

export default SkillsMatrix;
