import React from "react";
import {
    MainLayout,
    NoFeedbackSpaceActions,
    NoFeedbackSpaceContent,
    MessagesNavigation
} from "ComponentIndex";

const NoFeedbackSpaceComponent = () => {
    return (
        <MainLayout
            navigation={<MessagesNavigation />}
            action={<NoFeedbackSpaceActions />}
            content={<NoFeedbackSpaceContent />}
            workspace={"No Feedback Space selected"}
            title={"No feedback space selected"}
            activeTab={""}
            logCode={"PNFPL"}
        />
    );
};

export default NoFeedbackSpaceComponent;
