import React from "react";
import cn from "classnames";

const Card = ({
    type = "light",
    elevation = null,
    strech = true,
    rounded = "md",
    children,
    padding = "sm",
    basel = false
}) => {
    const cardClass = cn([
        "rounded-md",
        {
            "bg-white border border-secondary-300/40": type === "light",
            "bg-secondary-100 border border-secondary-300/40":
                type === "dark",
            "bg-shocking-50 border border-shocking-300/60":
                type === "primary",
            "rounded-md": rounded === "md",
            "rounded-xl": rounded === "xl",
            "rounded-3xl": rounded === "3xl",
            "shadow-md": elevation === "md",
            "shadow-lg": elevation === "lg",
            "shadow-sm": elevation === "sm",
            "w-full h-full": strech === true,
            "ring-2 ring-shocking-700": basel === "primary",
            "ring-2 ring-matisse-700": basel === "matisse",
            "ring-2 ring-blue-chill-700": basel === "blue-chill",
            "ring-2 ring-thunderbird-700": basel === "thunderbird",
            "ring-2 ring-la-palma-700": basel === "la-palma",
            "p-6": padding === "sm"
        }
    ]);
    return <div className={cardClass}>{children}</div>;
};

export default Card;
