import React from "react";
import cn from "classnames";

const tab = [
    "label_large text-white w-32 border-b-solid",
    "border-b-4 hover:border-b-shocking-400",
    "hover:bg-shocking-400 hover:text-secondary-800"
];

const Tab = ({ children, handleFunction, fieldValue, active, id }) => {
    const tabClass = cn(tab, {
        "border-b-shocking-400": active === true,
        "border-b-transparent": active !== true
    });
    return (
        <button
            id={id}
            onClick={handleFunction}
            value={fieldValue}
            className={tabClass}>
            {children}
        </button>
    );
};

export default Tab;
