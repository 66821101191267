import React from "react";
import { IconButton } from "ComponentIndex";

const BackToTop = () => {
    return (
        <div className={"sticky top-[90%] inline-block left-full"}>
            <a href={"#top"}>
                <IconButton
                    icon={"arrow_upward"}
                    variant={"standard"}
                    color={"primary"}
                    size={"large"}
                />
            </a>
        </div>
    );
};

export default BackToTop;
