import React from "react";

const SolutionHeader = ({ children }) => {
    return (
        <p
            className={
                "title_large font-medium text-secondary-700 font-bold"
            }>
            {children}
        </p>
    );
};

export default SolutionHeader;
