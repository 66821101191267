import React from "react";

const FeedbackProblem = ({ children, image, alt }) => {
    return (
        <div className={"flex flex-col items-center w-40"}>
            <img className={"h-24 w-auto "} src={image} alt={alt} />
            {children}
        </div>
    );
};

export default FeedbackProblem;
