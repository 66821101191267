import React from "react";

const Table = ({ children, tableProps }) => {
    return (
        <table className={"w-full"} {...tableProps}>
            {children}
        </table>
    );
};

export default Table;
