import { Icon, ComboBoxIcon } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { stringifyProjectItems, useProjectsFilterItems } from "./Utils";

const ProjectsFilterIcon = () => {
    const content = appContentVar().ProjectsFilterIcon;
    const { data, options, setOptions } = useProjectsFilterItems();

    return (
        <ComboBoxIcon
            size={"md_24"}
            options={options}
            setOptions={setOptions}
            items={data}
            itemToString={stringifyProjectItems}
            emptyMessage={content.emptyMessage[0]}
            itemPrefix={"projectName"}>
            <Icon
                title={content.title[0]}
                shadow={true}
                name={"account_tree"}
                size={"small"}
                color={"light"}
            />
        </ComboBoxIcon>
    );
};

export default ProjectsFilterIcon;
