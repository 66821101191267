import React from "react";

const ColumnFilter = ({ column }) => {
    return (
        <>
            {column.isSorted
                ? column.isSortedDesc
                    ? "↓"
                    : "↑"
                : column.disableSortBy
                ? ""
                : "  ↓↑"}
        </>
    );
};

export default ColumnFilter;
