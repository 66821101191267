import React, { forwardRef } from "react";

const SVGWrapper = forwardRef(({ children }, ref) => {
    return (
        <svg ref={ref} width={"100%"} height={"100%"}>
            {children}
        </svg>
    );
});

export default SVGWrapper;
