import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "ComponentIndex";
import { DEAUTHORIZE_ASANA_MUTATION } from "GraphQL/Mutations";
import { appContentVar } from "GraphQL/ReactiveVariables";

const DeuthorizeAsana = ({ setAuthorized }) => {
    const content = appContentVar().DeuthorizeAsana;
    let [deAuthoriseAsana] = useMutation(DEAUTHORIZE_ASANA_MUTATION, {
        onCompleted: () => {
            setAuthorized(false);
        }
    });
    return (
        <>
            <Button
                variant={"outline"}
                onClick={() => deAuthoriseAsana()}>
                {content}
            </Button>
        </>
    );
};

export default DeuthorizeAsana;
