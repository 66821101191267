import React from "react";
import { Hr, AzureLogin } from "ComponentIndex";

const OpenIDCompartment = () => {
    return (
        <div>
            <Hr weight={"small"} color={"light"} />
            <AzureLogin />
        </div>
    );
};

export default OpenIDCompartment;
