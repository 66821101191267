import { currentFeedbackSpaceVar } from "GraphQL/ReactiveVariables";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { useMutation } from "@apollo/client";
import { SET_USER_CURRENT_FEEDBACK_SPACE_MUTATION } from "GraphQL/Mutations";
import reduxActions from "State/ActionIndex";

export const getInitialSelectedItems = (data, currentFeedbackSpace) => {
    if (data && data.feedbackSpaces) {
        let result = data.feedbackSpaces.filter((item) => {
            return item.id === currentFeedbackSpace.id;
        })[0];
        return result;
    } else {
        return null;
    }
};

export const makeChanges = (changes, setPreferance) => {
    setPreferance({
        variables: {
            feedbackSpaceId: masterAcc(
                changes.selectedItem,
                "feedbackSpace",
                "id"
            )
        }
    })
        .then((data) => {})
        .catch((e) => {
            reduxActions.addNotification(e.error, "error");
        });

    currentFeedbackSpaceVar({
        id: masterAcc(changes.selectedItem, "feedbackSpace", "id"),
        feedbackSpaceName: masterAcc(
            changes.selectedItem,
            "feedback",
            "name"
        ),
        role: masterAcc(changes.selectedItem, "feedbackSpace", "role")
    });
};

export const useSetPreferance = () => {
    const [setPreferance] = useMutation(
        SET_USER_CURRENT_FEEDBACK_SPACE_MUTATION
    );
    return setPreferance;
};

export const stringifyItems = (item) => {
    return masterAcc(item, "feedbackSpace", "name");
};
