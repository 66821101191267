import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Button, ConditionalOutput } from "ComponentIndex";
import { GET_ATLASSIAN_CODE_MUTATION } from "GraphQL/Mutations";
import { appContentVar } from "GraphQL/ReactiveVariables";

const AddAtlassianSite = () => {
    const content = appContentVar().AddAtlassianSite;
    let [registerAtlassian, { data, error, loading }] = useMutation(
        GET_ATLASSIAN_CODE_MUTATION
    );

    useEffect(() => {
        if (data) {
            window.location.href = data.registerWithAtlassian.authLink;
        }
    }, [data]);

    return (
        <div
            className={
                "flex flex-row w-full gap-x-8 items-center justify-between"
            }>
            <span className={"label_large"}>{content[0]}</span>
            <ConditionalOutput loading={loading} error={error}>
                <Button onClick={registerAtlassian} variant={"filled"}>
                    {content[1]}
                </Button>
            </ConditionalOutput>
        </div>
    );
};

export default AddAtlassianSite;
