import React, { useState, useEffect } from "react";
import { ExtendedAutocomplete } from "ComponentIndex";
import {
    useSkiilsFeedbackAutoComplete,
    prepareSkillState
} from "./Utils";
import { usePrevious } from "CustomHooks/CustomHooks";

const SkillsInFeedbackSpaceAutocomplete = ({
    data,
    items,
    placeHolder,
    leadingIconName,
    setSkillIds,
    skillIds,
    noItems = null
}) => {
    const [dataState, setDataState] = useState(data);
    const autoCompleteProps = useSkiilsFeedbackAutoComplete(
        dataState,
        setDataState,
        items,
        "skillName",
        noItems
    );
    const previousSkillArray = usePrevious(dataState);
    useEffect(() => {
        setSkillIds(
            prepareSkillState(skillIds, dataState, previousSkillArray)
        );
    }, [dataState]);
    return (
        <ExtendedAutocomplete
            autoCompleteProps={autoCompleteProps}
            placeHolder={placeHolder}
            leadingIconName={leadingIconName}
            itemPrefix={"skillName"}
        />
    );
};

export default SkillsInFeedbackSpaceAutocomplete;
