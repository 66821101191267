import { useUnifiedFilterItems } from "HookIndex";
import { useQuery } from "@apollo/client";
import { GET_SKILL_LIST_QUERY } from "GraphQL/Queries";

export const useGetSkillList = () => {
    const { skillTypes, search } = useUnifiedFilterItems();

    const { data, error, loading } = useQuery(GET_SKILL_LIST_QUERY, {
        variables: {
            searchCriteria: search,
            skillTypes: skillTypes
        },
        fetchPolicy: "cache-and-network"
    });

    return { data, error, loading };
};
