const BlogLink = ({ children, to }) => {
    return (
        <a
            className={"underline text-shocking-700"}
            href={to}
            target='_blank'
            rel='noopener noreferrer'>
            {children}
        </a>
    );
};

export default BlogLink;
