import React from "react";

import {
    Card,
    SkillTagTable,
    SkillRaitingBarChart,
    RangeAvgSkill,
    InfoModule,
    SkillFrequencyInfo,
    SkillVarianceChart,
    InfoTableSkillInfo,
    Title,
    SkillsMatrix
} from "ComponentIndex";

const DisplaySkillReport = () => {
    return (
        <div
        className={"flex flex-col w-full h-full gap-4 2xl:w-11/12"}>
            <div>
                <Card type={"light"} rounded={"xl"}>
                    <div
                        className={
                            "h-full flex flex-col gap-y-4 overflow-x-auto"
                        }>
                        {/* <div className={"self-end"}>
                            <InfoModule
                                message={<InfoTableSkillInfo />}
                                xOffset={-280}
                            />
                        </div> */}
                        <Title type={"title_medium"}>
                            {"Skills Matrix"}
                        </Title>
                        <SkillsMatrix />
                    </div>
                </Card>
            </div>
            <div className={"flex flex-col gap-4 xl:flex-row"}>
                <div
                    className={
                        "w-full scroll-m-0 h-[80vh] md:h-[70vh] lg:h-[70vh] xl:h-[60vh] 2xl:h-[50vh] "
                    }>
                    <Card type={"light"} rounded={"xl"}>
                        <div className={"h-full flex flex-col overflow-y-auto"}>
                            <div className={"self-end"}>
                                <InfoModule
                                    message={<SkillFrequencyInfo />}
                                    xOffset={-280}
                                />
                            </div>
                            <SkillRaitingBarChart />
                        </div>
                    </Card>
                </div>
                <div
                    className={
                        "w-full h-[80vh] md:h-[70vh] lg:h-[70vh] xl:h-[60vh] 2xl:h-[50vh] "
                    }>
                    <Card type={"light"} rounded={"xl"}>
                        <div className={"h-full flex flex-col"}>
                            <div className={"self-end"}>
                                <InfoModule
                                    message={<SkillVarianceChart />}
                                    xOffset={-280}
                                />
                            </div>
                            <RangeAvgSkill />
                        </div>
                    </Card>
                </div>
            </div>
            {/* <div>
                <Card type={"light"} rounded={"xl"}>
                    <div className={"h-full flex flex-col gap-y-4"}>
                        <div className={"self-end"}>
                            <InfoModule
                                message={<InfoTableSkillInfo />}
                                xOffset={-280}
                            />
                        </div>
                        <Title type={"title_medium"}>
                            {"Tags per Skill Combination"}
                        </Title>
                        <SkillTagTable />
                    </div>
                </Card>
            </div> */}
        </div>
    );
};

export default DisplaySkillReport;
