import React from "react";

const Th = ({
    children,
    headerProps,
    columWidth = null,
    title = null
}) => {
    return (
        <th
            title={title ? title : null}
            className={
                "text-center border-b border-solid border-shocking-900"
            }
            {...headerProps}
            style={{ width: columWidth ? `${columWidth}px` : null }}>
            <h1 className={"title_small text-secondary-900"}>
                {children}
            </h1>
        </th>
    );
};

export default Th;
