import React from "react";
import { nameTheRole } from "./Utils";

const MemberNamePart = ({ recordItem }) => {
    return (
        <div className={"flex flex-row justify-start gap-x-8"}>
            <p className={"body_medium text-secondary-900 w-40"}>
                {recordItem.memberAccount.email}
            </p>
            <p className={"body_medium text-shocking-700"}>
                {nameTheRole(recordItem.role)}
            </p>
        </div>
    );
};

export default MemberNamePart;
