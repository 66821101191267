import React from "react";
import {
    PublicSection,
    ContentWrapperBand,
    ManagementContent,
    HRContent,
    LeadershipContent
} from "ComponentIndex";
import { landingContentVar } from "GraphQL/ReactiveVariables";

const WhoIsItFor = () => {
    const content = landingContentVar().WhoIsItFor;
    return (
        <ContentWrapperBand color={"bg-shocking-50"}>
            <PublicSection
                verticalHeader={content.verticalHeader}
                horizontalHeader={content.horizontalHeader}>
                <div
                    className={
                        "grid gap-y-8 lg:px-12 lg:gap-x-8 xl:gap-x-24 lg:grid-cols-3 "
                    }
                    id={"forWhom"}>
                    <div className={"lg:row-span-2"}>
                        <HRContent />
                    </div>
                    <div className={"lg:col-span-2"}>
                        <ManagementContent />
                    </div>
                    <div className={"lg:col-span-2"}>
                        <LeadershipContent />
                    </div>
                </div>
            </PublicSection>
        </ContentWrapperBand>
    );
};

export default WhoIsItFor;
