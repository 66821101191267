import React, { useState, useRef } from "react";
import { useFeedbackSpaceUsersQuery, useClickOutside } from "HookIndex";
import { useMutation } from "@apollo/client";
import { ConditionalOutput } from "ComponentIndex";
import { Select, Title, Button, Card } from "ComponentIndex";
import { TRANSFER_FEEDBACK_SPACE_OWNERSHIP } from "GraphQL/Mutations";
import reduxActions from "State/ActionIndex";

const createItems = (data) => {
    return data.feedbackSpaceUsers.map((item) => {
        return item.memberAccount.email;
    });
};

const getAccountId = (data, email) => {
    let result = null;
    if (email) {
        result = data.feedbackSpaceUsers.filter((item) => {
            return item.memberAccount.email === email;
        });
    }
    if (result) {
        return result[0].memberAccount.id;
    } else {
        return result;
    }
};

const TransferRights = ({ recordItem, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const [selected, setSelected] = useState(null);
    const { data, error, loading } = useFeedbackSpaceUsersQuery(
        recordItem.feedbackSpace.id
    );

    const [
        submitForm,
        { data: resultData, error: resultError, loading: resultLoading }
    ] = useMutation(TRANSFER_FEEDBACK_SPACE_OWNERSHIP);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        submitForm({
            variables: {
                feedbackSpaceId: recordItem.feedbackSpace.id,
                userId: getAccountId(data, selected)
            }
        })
            .then((data) => {
                reduxActions.addNotification(
                    "Ownership has been transfered",
                    "success"
                );
                setOpen(false);
            })
            .catch((e) => {
                reduxActions.addNotification(e.message, "error");
            });
    };

    return (
        <div ref={containerRef} className={"w-auto"}>
            <Card>
                <Title>Transfer feedback space ownership</Title>
                <ConditionalOutput error={error} loading={loading}>
                    {data && (
                        <form
                            className={
                                "flex flex-row justify-between items-end gap-x-8"
                            }
                            onSubmit={handleFormSubmit}>
                            <Select
                                items={createItems(data)}
                                selected={selected}
                                setSelected={setSelected}
                                labelName={"Select account:"}
                            />

                            <Button type={"submit"} variant={"filled"}>
                                Transfer
                            </Button>
                        </form>
                    )}
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default TransferRights;
