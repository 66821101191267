import React from "react";
import { Icon, ToolTip } from "ComponentIndex";

const InfoModule = ({ message, xOffset = -20, tailX = 19 }) => {
    return (
        <ToolTip
            top={true}
            xOffset={xOffset}
            tailX={tailX}
            message={message}>
            <Icon
                color={"matisse"}
                size={"large"}
                name={"info"}
                font={"outline"}
            />
        </ToolTip>
    );
};

export default InfoModule;
