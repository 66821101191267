import React from "react";
import { Routes, Route } from "react-router-dom";
import {
    FeedbackReportComponent,
    SkillReportComponent
} from "ComponentIndex";

let ReportRoot = () => {
    return (
        <Routes>
            <Route
                path={"feedback-insights"}
                element={<FeedbackReportComponent />}
            />
            <Route
                path={"skills-insights"}
                element={<SkillReportComponent />}
            />
        </Routes>
    );
};

export default ReportRoot;
