import { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import {
    skillTypesVar,
    skillTypesForFilterVar
} from "GraphQL/ReactiveVariables";

export const useSkillTypesFilterItems = () => {
    const [options, setOptions] = useState([]);
    let data = useReactiveVar(skillTypesForFilterVar);

    useEffect(() => {
        skillTypesVar(options.map((items) => items.abbr));
    }, [options]);

    return { data, options, setOptions };
};
