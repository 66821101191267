import React from "react";
import {
    ActionBar,
    SearchBar,
    DateRangePicker,
    AssigneeFilterIcon,
    ProjectsFilterIcon,
    DataSourceFilterIcon,
    TaskTypeFilterIcon
} from "ComponentIndex";
import {
    useAssigneeFilterData,
    useProjectFilterData,
    useFeedbackSpaceSkillNameFilterData,
    useFeedbackSpaceSkillTypeFilterData,
    useTaskTypeFilterData,
    useDataSourceFilterData
} from "CustomHooks/CustomQueries";

const FeedbackReportActions = () => {
    useAssigneeFilterData();
    useProjectFilterData();
    useFeedbackSpaceSkillNameFilterData();
    useFeedbackSpaceSkillTypeFilterData();
    useTaskTypeFilterData();
    useDataSourceFilterData();
    return (
        <ActionBar id={"feedback_insights_actions"}>
            <SearchBar />
            <DateRangePicker />
            <AssigneeFilterIcon />
            <ProjectsFilterIcon />
            <TaskTypeFilterIcon />
            <DataSourceFilterIcon />
        </ActionBar>
    );
};

export default FeedbackReportActions;
