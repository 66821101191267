import { useMutation } from "@apollo/client";
import { Button } from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { CHANGE_ATTLASSIAN_SITE_AUTHORIZATION } from "GraphQL/Mutations";

const AtlassianSiteAuthorization = ({ siteUrl, syncStatus }) => {
    const [makeAuth, { loading, error }] = useMutation(
        CHANGE_ATTLASSIAN_SITE_AUTHORIZATION,
        {
            variables: {
                siteUrl: siteUrl
            }
        }
    );
    const content = appContentVar().AtlassianSiteAuthorization;
    return (
        <>
            {syncStatus ? (
                <Button onClick={makeAuth} variant={"outline"}>
                    {content[0]}
                </Button>
            ) : (
                <Button onClick={makeAuth} variant={"filled"}>
                    {content[1]}
                </Button>
            )}
        </>
    );
};

export default AtlassianSiteAuthorization;
