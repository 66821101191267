import {
    blogContentVar,
    currentArticleVar
} from "GraphQL/ReactiveVariables";
import { useMoveToPageTop } from "HookIndex";
import {
    BlogBold,
    BlogCompartmentHeader,
    BlogHeader,
    BlogKicker,
    BlogLink,
    BlogOli,
    BlogParagraph,
    BlogParagraphHeader,
    BlogSubHeader,
    BlogTakeAwayHeader,
    BlogUli,
    HelmetInclude,
    BlogReferanceHeader,
    BlogReferance,
    BlogCategory
} from "ComponentIndex";
import {
    CognitiveBiasesInPerformanceArticleLink,
    ImpactfullFeedbackArticleLink
} from "BlogLinkIndex";

import PrinciplesImg from "Images/Blog/NoFeedbackNoProductivityArticle/principles-of-feedback.png";
import FeedbackHabitImg from "Images/Blog/NoFeedbackNoProductivityArticle/feedback-habit.png";
import FeedbackHowImg from "Images/Blog/NoFeedbackNoProductivityArticle/feedback-how.png";
import FeedbackLessonsImg from "Images/Blog/NoFeedbackNoProductivityArticle/feedback-lessons.png";
import ActionableFeedbackImg from "Images/Blog/NoFeedbackNoProductivityArticle/actionable-feedback.png";

const FIRST_REFERANCE =
    "https://www.cambridge.org/core/journals/industrial-and-organizational-psychology/article/abs/performance-management-can-be-fixed-an-onthejob-experiential-learning-approach-for-complex-behavior-change/E4F8A435C94B09BA0B2667D05965DA41";
const SECOND_REFERANCE =
    "https://www.pwc.com/mt/en/publications/people-and-organisation/pwc-hrs-employee-engagement-landscape-2015.pdf";

const NoFeedbackNoProductivityArticle = ({ articleName }) => {
    const content = blogContentVar().NoFeedbackNoProductivityArticle;
    currentArticleVar(articleName);
    useMoveToPageTop();
    return (
        <>
            <HelmetInclude content={content} />
            <div>
                <img
                    src={PrinciplesImg}
                    alt={content.altText.principlesOfFeedback}
                />
                <div className={"py-8"}>
                    <BlogKicker content={content} />
                    <BlogHeader content={content} />
                    <BlogSubHeader content={content} />
                </div>
                <div>
                    <BlogTakeAwayHeader content={content} />
                    <ol className={"list-inside"}>
                        <BlogOli>
                            {content.mainTakeAway.content[0]}
                        </BlogOli>
                        <BlogOli>
                            {content.mainTakeAway.content[1]}
                        </BlogOli>
                        <BlogOli>
                            {content.mainTakeAway.content[2]}
                        </BlogOli>
                        <ul className={"list-inside"}>
                            <BlogUli>
                                {content.mainTakeAway.content[3]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[4]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[5]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[6]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[7]}
                            </BlogUli>
                        </ul>

                        <BlogOli>
                            {content.mainTakeAway.content[8]}
                        </BlogOli>
                        <ul className={"list-inside"}>
                            <BlogUli>
                                {content.mainTakeAway.content[9]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[10]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[11]}
                            </BlogUli>
                            <BlogUli>
                                {content.mainTakeAway.content[12]}
                            </BlogUli>
                        </ul>
                    </ol>
                    <BlogCompartmentHeader>
                        <BlogParagraphHeader>
                            {content.performanceEmpower.title[0]}
                        </BlogParagraphHeader>
                    </BlogCompartmentHeader>
                    <BlogParagraph>
                        {content.performanceEmpower.content[0]}
                    </BlogParagraph>
                    <BlogParagraph>
                        {content.performanceEmpower.content[1]}
                    </BlogParagraph>
                    <BlogCompartmentHeader>
                        <BlogParagraphHeader>
                            {content.highProductivity.title[0]}
                        </BlogParagraphHeader>
                    </BlogCompartmentHeader>
                    <BlogParagraph>
                        {content.highProductivity.content[0]}
                    </BlogParagraph>
                    <BlogParagraph>
                        {content.highProductivity.content[1]}
                    </BlogParagraph>
                    <BlogCompartmentHeader>
                        <BlogParagraphHeader>
                            {content.factsToConsider.title[0]}
                        </BlogParagraphHeader>
                    </BlogCompartmentHeader>
                    <BlogParagraph>
                        <BlogBold>
                            {
                                content.factsToConsider.content
                                    .humansBiased[0]
                            }
                        </BlogBold>
                        {
                            content.factsToConsider.content
                                .humansBiased[1]
                        }
                        <BlogLink
                            to={
                                CognitiveBiasesInPerformanceArticleLink
                            }>
                            {
                                content.factsToConsider.content
                                    .humansBiased[2]
                            }
                        </BlogLink>
                        {
                            content.factsToConsider.content
                                .humansBiased[3]
                        }
                    </BlogParagraph>
                    <BlogParagraph>
                        <BlogBold>
                            {
                                content.factsToConsider.content
                                    .timelyFeedback[0]
                            }
                        </BlogBold>
                        {
                            content.factsToConsider.content
                                .timelyFeedback[1]
                        }
                    </BlogParagraph>
                    <BlogParagraph>
                        <BlogBold>
                            {
                                content.factsToConsider.content
                                    .peopleLearn[0]
                            }
                        </BlogBold>
                        {content.factsToConsider.content.peopleLearn[1]}
                    </BlogParagraph>
                    <BlogParagraph>
                        <BlogBold>
                            {
                                content.factsToConsider.content
                                    .variation[0]
                            }
                        </BlogBold>
                        {content.factsToConsider.content.variation[1]}
                    </BlogParagraph>
                    <BlogParagraph>
                        <BlogBold>
                            {
                                content.factsToConsider.content
                                    .moreData[0]
                            }
                        </BlogBold>
                        {content.factsToConsider.content.moreData[1]}
                    </BlogParagraph>
                    <BlogParagraph>
                        {content.factsToConsider.content.article[0]}
                        <BlogLink
                            to={
                                CognitiveBiasesInPerformanceArticleLink
                            }>
                            {content.factsToConsider.content.article[1]}
                        </BlogLink>
                        {content.factsToConsider.content.article[2]}
                    </BlogParagraph>

                    <BlogCompartmentHeader>
                        <BlogParagraphHeader>
                            {content.principlesOfFeedback.title[0]}
                        </BlogParagraphHeader>
                    </BlogCompartmentHeader>
                    <BlogParagraph>
                        <BlogBold>
                            {
                                content.principlesOfFeedback.content
                                    .developHabit[0]
                            }
                        </BlogBold>
                    </BlogParagraph>
                    <img
                        className={"my-16"}
                        src={FeedbackHabitImg}
                        alt={content.altText.feedbackHabit[0]}
                    />
                    <BlogParagraph>
                        {
                            content.principlesOfFeedback.content
                                .developHabit[1]
                        }
                        <BlogLink to={ImpactfullFeedbackArticleLink}>
                            {
                                content.principlesOfFeedback.content
                                    .developHabit[2]
                            }
                        </BlogLink>
                        {
                            content.principlesOfFeedback.content
                                .developHabit[3]
                        }
                    </BlogParagraph>
                    <div className={"my-8"}>
                        <BlogParagraph>
                            <BlogBold>
                                {
                                    content.principlesOfFeedback.content
                                        .feedbackHow[0]
                                }
                            </BlogBold>
                        </BlogParagraph>
                        <img
                            className={"my-16"}
                            src={FeedbackHowImg}
                            alt={content.altText.feedbackHow[0]}
                        />
                        <BlogParagraph>
                            {
                                content.principlesOfFeedback.content
                                    .feedbackHow[1]
                            }
                            <BlogLink
                                to={ImpactfullFeedbackArticleLink}>
                                {
                                    content.principlesOfFeedback.content
                                        .feedbackHow[2]
                                }
                            </BlogLink>
                            {
                                content.principlesOfFeedback.content
                                    .feedbackHow[3]
                            }
                        </BlogParagraph>
                    </div>
                    <div className={"my-8"}>
                        <BlogParagraph>
                            <BlogBold>
                                {
                                    content.principlesOfFeedback.content
                                        .captureLessons[0]
                                }
                            </BlogBold>
                        </BlogParagraph>
                        <img
                            className={"my-16"}
                            src={FeedbackLessonsImg}
                            alt={content.altText.feedbackLessons[0]}
                        />
                        <BlogParagraph>
                            {
                                content.principlesOfFeedback.content
                                    .captureLessons[1]
                            }
                        </BlogParagraph>
                    </div>
                    <div className={"my-8"}>
                        <BlogParagraph>
                            <BlogBold>
                                {
                                    content.principlesOfFeedback.content
                                        .actionableFeedback[0]
                                }
                            </BlogBold>
                        </BlogParagraph>
                        <img
                            className={"my-16"}
                            src={ActionableFeedbackImg}
                            alt={content.altText.actionableFeedback[0]}
                        />
                        <BlogParagraph>
                            {
                                content.principlesOfFeedback.content
                                    .actionableFeedback[1]
                            }
                        </BlogParagraph>
                    </div>
                    <div className={"mt-10"}>
                        <BlogReferanceHeader content={content} />
                        <BlogReferance to={FIRST_REFERANCE}>
                            {content.referancess.first[0]}
                        </BlogReferance>
                        <BlogReferance to={SECOND_REFERANCE}>
                            {content.referancess.second[0]}
                        </BlogReferance>
                    </div>
                    <BlogCategory content={content} />
                </div>
            </div>
        </>
    );
};

export default NoFeedbackNoProductivityArticle;
