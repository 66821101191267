import React from "react";
import {
    EditSkillDialog,
    ReactTableCell,
    ReactTableHeader,
    CellDataWrapper
} from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { skillTypeSelect } from "./Utils";

// headers

export const skillNameHeader = (column) => {
    const content = appContentVar().skillNameHeader;
    return (
        <ReactTableHeader column={column} columWidth={150}>
            {content[0]}
        </ReactTableHeader>
    );
};

export const skillTypeHeader = (column) => {
    const content = appContentVar().skillTypeHeader;
    return (
        <ReactTableHeader column={column} columWidth={150}>
            {content[0]}
        </ReactTableHeader>
    );
};

export const skillDescriptionHeader = (column) => {
    const content = appContentVar().skillDescriptionHeader;
    return (
        <ReactTableHeader column={column}>
            {content[0]}
        </ReactTableHeader>
    );
};

export const skillActionsHeader = (column) => {
    const content = appContentVar().skillActionsHeader;
    return (
        <ReactTableHeader column={column} columWidth={50}>
            {content[0]}
        </ReactTableHeader>
    );
};

// Cells

export const skillNameCell = (cell) => {
    return (
        <ReactTableCell cell={cell} overflow={true} title={cell.value}>
            {cell.value}
        </ReactTableCell>
    );
};

export const skillTypeCell = (cell) => {
    return (
        <ReactTableCell cell={cell}>
            {skillTypeSelect(cell.value)}
        </ReactTableCell>
    );
};

export const skillDescriptionCell = (cell) => {
    return (
        <ReactTableCell cell={cell} title={cell.value} overflow={true}>
            {cell.value}
        </ReactTableCell>
    );
};

export const skillActionsCell = (cell) => {
    return (
        <ReactTableCell cell={cell}>
            <CellDataWrapper justify={"center"}>
                <EditSkillDialog skill={cell.row.values} />
            </CellDataWrapper>
        </ReactTableCell>
    );
};
