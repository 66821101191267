import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { screenSizeVar, pagesVar } from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { useUnifiedFilterItems } from "HookIndex";
import { RESUME_TABLE_QUERY } from "GraphQL/Queries";

export const useColumnHide = (setHiddenColumns) => {
    const windowSize = useReactiveVar(screenSizeVar);
    useEffect(() => {
        if (windowSize.width <= 1240) {
            setHiddenColumns([
                "project",
                "assignee",
                "tweetTags",
                "range"
            ]);
        } else {
            setHiddenColumns(["range"]);
        }
    }, [windowSize.width]);
};

export const useGetTableData = () => {
    const {
        currentFeedbackSpace,
        taskEndDate,
        taskStartDate,
        search,
        projectIds,
        assigneeParametersIds,
        pageSize,
        page,
        taskTypeNames,
        dataSourceIds
    } = useUnifiedFilterItems();

    let { data, loading, error } = useQuery(RESUME_TABLE_QUERY, {
        variables: {
            feedbackStatus: "GV",
            pageSize: pageSize,
            page: page,
            feedbackSpaceId: currentFeedbackSpace.id,
            tskStartDate: taskEndDate,
            tskEndDate: taskStartDate,
            search: search,
            projectIds: projectIds,
            assigneeParametersIds: assigneeParametersIds,
            taskTypeNames: taskTypeNames,
            dataSourceIds: dataSourceIds,
            order_by: 2
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first"
    });
    useEffect(() => {
        if (data) {
            pagesVar(data.tasks.pages);
        }
    }, [data]);

    return { data, loading, error };
};
