import cn from "classnames";

const DEFAULT_POSITION = "absolute top-0 left-0 w-full";

const DownshiftItemList = ({
    children,
    menuProps,
    isOpen,
    position = DEFAULT_POSITION,
    color = "white"
}) => {
    const optionListClass = cn(
        position,
        "z-10 rounded max-h-96 overflow-auto",
        "shadow-md placeholder:text-secondary-300",
        "placeholder:label_large",
        {
            "border border-secondary-200 py-2 px-2": isOpen === true,
            "bg-white ": color === "white",
            " bg-secondary-700": color === "dark"
        }
    );
    return (
        <ul className={optionListClass} {...menuProps()}>
            {children}
        </ul>
    );
};

export default DownshiftItemList;
