import React from "react";
import { appContentVar } from "GraphQL/ReactiveVariables";

const FeedbackVarianceChartInfo = () => {
    const content = appContentVar().FeedbackVarianceChartInfo;
    return (
        <>
            <p className={"body_small text-secondary-900"}>
                {content[0]}
            </p>
            <p className={"body_small text-secondary-900"}>
                {content[1]}
            </p>
        </>
    );
};

export default FeedbackVarianceChartInfo;
