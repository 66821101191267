import { workspaceNameVar } from "GraphQL/ReactiveVariables";
import {
    ContentWrapperBand,
    PageToScreenWrapper,
    Card,
    Title,
    ContactForDemoForm,
    HelmetExclude
} from "ComponentIndex";

const ContactForDemoContent = () => {
    workspaceNameVar("Contact for demo");
    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Card
                        type={"light"}
                        strech={false}
                        elevation={"lg"}>
                        <Title>Contact us </Title>
                        <h2
                            className={
                                "title_medium text-secondary-700 mb-4"
                            }>
                            It seems like you don't have an account yet.
                            <br /> We would be excited to show you a
                            demo.
                            <br />
                            Let us connect.
                        </h2>
                        <ContactForDemoForm />
                    </Card>
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default ContactForDemoContent;
