import { useState, useEffect } from "react";
import {
    taskTypeNamesVar,
    taskTypesForFilterVar
} from "GraphQL/ReactiveVariables";
import { useReactiveVar } from "@apollo/client";
import { masterAcc } from "UtilityFunctionIndex";

export const stringifyTaskTypeItems = (item) => {
    return masterAcc(item, "taskType", "taskType");
};

export const useTaskTypeFilterItems = () => {
    const [options, setOptions] = useState([]);
    let data = useReactiveVar(taskTypesForFilterVar);

    useEffect(() => {
        taskTypeNamesVar(options.map((item) => item.taskType));
    }, [options]);

    return { data, options, setOptions };
};
