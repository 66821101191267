import { Helmet } from "react-helmet-async";

const HelmetExclude = () => {
    return (
        <Helmet>
            <meta name='robots' content='noindex, nofollow' />
        </Helmet>
    );
};

export default HelmetExclude;
