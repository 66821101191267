import { MedianConfidenceChart } from "ChartIndex";
import { useGetFeedbackEvolutionData } from "./Utils";
import {
    medianAcc,
    upperQuantileAcc,
    lowerQuantileAcc,
    periodAcc
} from "UtilityFunctions/DataAccessors";
import { appContentVar } from "GraphQL/ReactiveVariables";
import { ConditionalOutput } from "ComponentIndex";

const FeedbackEvolution = () => {
    const content = appContentVar().FeedbackEvolution;
    const { data, error, loading } = useGetFeedbackEvolutionData();
    return (
        <ConditionalOutput error={error} loading={loading}>
            {data && data.feedbackEvolution ? (
                <MedianConfidenceChart
                    chartName={content.chartName[0]}
                    data={data.feedbackEvolution}
                    xAxisLabel={content.xAxisLabel[0]}
                    xAccessor={periodAcc}
                    upperQAccessor={upperQuantileAcc}
                    lowerQAccessor={lowerQuantileAcc}
                    medianAccessor={medianAcc}
                />
            ) : null}
        </ConditionalOutput>
    );
};

export default FeedbackEvolution;
