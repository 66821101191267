import { useQuery } from "@apollo/client";
import { meVar } from "GraphQL/ReactiveVariables";
import { FEEDBACK_SPACE_USERS_QUERY } from "GraphQL/Queries";
import { masterAcc } from "UtilityFunctions/DataAccessors";

export const useFeedbackSpaceUsersQuery = (feedbackSpaceId) => {
    const { data, error, loading } = useQuery(
        FEEDBACK_SPACE_USERS_QUERY,
        {
            variables: { feedbackSpaceId: feedbackSpaceId },
            fetchPolicy: "network-only"
        }
    );

    return { data, error, loading };
};

export const myRoleInFeedbackSpace = (data) => {
    let me = meVar();
    let myRole = data.filter((item) => {
        return (
            masterAcc(item, "roleInFeedbackSpace", "memberId") === me.id
        );
    });
    return myRole[0].role;
};
