import React from "react";
import {
    MainLayout,
    DashboardContent,
    DashboardActions,
    DashboardNavigation
} from "ComponentIndex";

let DashboardComponent = () => {
    return (
        <MainLayout
            navigation={<DashboardNavigation />}
            action={<DashboardActions />}
            content={<DashboardContent />}
            workspace={"Dashboard"}
            title={"Dashboard"}
            activeTab={""}
            feedbackSpaceCheck={true}
            logCode={"PDBPL"}
        />
    );
};

export default DashboardComponent;
