import React from "react";

import {
    Hr,
    SelectAllForSync,
    SyncParameters,
    LoadParameterList
} from "ComponentIndex";

const ManageAssignees = ({ feedbackSpace }) => {
    return (
        <div className={"flex flex-col basis-5/12 h-full"}>
            <div className={" flex flex-row justify-between"}>
                <h1 className={"title_small text-secondary-900"}>
                    Assignees sync setup
                </h1>
                <SyncParameters
                    title={"Update list from data source"}
                    feedbackSpaceId={feedbackSpace.id}
                    parameter={"assigneesParameters"}
                />
            </div>
            <div className={"mb-6"}>
                <Hr weight={"small"} color={"dark"} />
            </div>
            <SelectAllForSync
                parameter={"assigneesParameters"}
                feedbackSpace={feedbackSpace}
            />
            <LoadParameterList
                feedbackSpaceId={feedbackSpace.id}
                parameter={"assigneesParameters"}
            />
        </div>
    );
};

export default ManageAssignees;
