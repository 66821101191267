import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import reduxActions from "State/ActionIndex";
import {
    Card,
    SkillForm,
    Title,
    ConditionalOutput
} from "ComponentIndex";
import { CREATE_SKILL_MUTATION } from "GraphQL/Mutations";

const INITIAL_SKILL_PARAMETERS = {
    skillName: "",
    skillType: "NA",
    skillDescription: ""
};

const AddSkill = () => {
    const [skillParams, setSkillParams] = useState(
        INITIAL_SKILL_PARAMETERS
    );

    const [createSkill, { error, loading }] = useMutation(
        CREATE_SKILL_MUTATION,
        {}
    );
    const handleFormSubmit = (e) => {
        e.preventDefault();
        createSkill({
            variables: {
                ...skillParams
            }
        })
            .then((data) => {
                reduxActions.addNotification(
                    "Skill created",
                    "success"
                );
            })
            .catch((e) => {
                reduxActions.addNotification(e.message, "error");
            });
        setSkillParams(INITIAL_SKILL_PARAMETERS);
    };

    return (
        <Card type={"light"}>
            <Title
                type={"title_large"}
                textColor={"text-secondary-900"}>
                Add new skill
            </Title>
            <ConditionalOutput error={error} loading={loading}>
                <SkillForm
                    state={skillParams}
                    setState={setSkillParams}
                    handleSubmit={handleFormSubmit}
                />
            </ConditionalOutput>
        </Card>
    );
};

export default AddSkill;
