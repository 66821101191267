import React from "react";
import { content_eng } from "ContentManagement/english";

const SkillFrequencyInfo = () => {
    const info = content_eng["info"]["skillFrequency"];
    return (
        <p className={"body_small text-secondary-900"}>{info["1"]}</p>
    );
};

export default SkillFrequencyInfo;
