import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import reduxActions from "State/ActionIndex";
import { CELERY_TASK_STATUS } from "GraphQL/Queries";

let VerifyCeleryTask = ({ celeryTaskId, setSyncStatus, children }) => {
    let {
        data: taskSyncData,
        error,
        startPolling,
        stopPolling
    } = useQuery(CELERY_TASK_STATUS, {
        variables: {
            celeryTaskId: celeryTaskId
        }
    });
    useEffect(() => {
        startPolling(5000);
        if (
            taskSyncData &&
            taskSyncData.celeryTaskStatus.celeryTaskStatus
        ) {
            stopPolling();
            setSyncStatus(false);
        }
    }, [taskSyncData]);
    if (error) {
        reduxActions.addNotification(error.message, "error");
    }

    return <>{children}</>;
};

export default VerifyCeleryTask;
