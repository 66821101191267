export const SystemicProbemsArticleLink =
    "/blog/article/systemic-problems-with-performance-reviews";

export const ImpactfullFeedbackArticleLink =
    "/blog/article/how-to-make-micro-feedback-truly-impactful";

export const TaskBasedFeedbackArticleLink =
    "/blog/article/task-based-feedback-the-continuous-improvement-technique";

export const CognitiveBiasesInPerformanceArticleLink =
    "/blog/article/cognitive-biases-in-preformance-review";

export const ReviewEmbraceEmployeesEffortsArticleLink =
    "/blog/article/embrace-variation-of-human-activity-during-performance-reviews";

export const NoFeedbackNoProductivityArticleLink =
    "/blog/article/no-feedback-no-productivity-improvements";
