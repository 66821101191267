import React from "react";

import { MemberNamePart, MemberActionPart } from "ComponentIndex";

const MemberRecord = ({ recordItem, myRole }) => {
    return (
        <div
            className={
                "flex flex-row justify-between items-center gap-x-8"
            }>
            <MemberNamePart recordItem={recordItem} />

            <MemberActionPart recordItem={recordItem} myRole={myRole} />
        </div>
    );
};

export default MemberRecord;
