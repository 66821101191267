export const notificationReducer = (state = [], action) => {
    switch (action.type) {
        case "add_notification":
            return [...state, action.payload];
        case "remove_notification":
            return state.filter((el) => el.id !== action.payload.id);
        default:
            return state;
    }
};
