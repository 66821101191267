import React from "react";
import { Th, ColumnFilter } from "ComponentIndex";

const RactTableHeader = ({
    column,
    children,
    columWidth = null,
    title = null
}) => {
    return (
        <Th
            title={title}
            columWidth={columWidth}
            headerProps={column.getHeaderProps(
                column.getSortByToggleProps()
            )}>
            {children}
            {<ColumnFilter column={column} />}
        </Th>
    );
};

export default RactTableHeader;
