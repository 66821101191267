import { useRef, useState } from "react";
import {
    Title,
    Card,
    InputLabelCount,
    Input,
    Button,
    ConditionalOutput
} from "ComponentIndex";
import { appContentVar } from "GraphQL/ReactiveVariables";
import reduxActions from "State/ActionIndex";

import { useClickOutside } from "HookIndex";
import { verifyValueLength } from "UtilityFunctionIndex";
import { useMutation } from "@apollo/client";
import { EDIT_FEEDBACK_SPACE_NAME_MUTATION } from "GraphQL/Mutations";

const EditFeedbackSpaceNameForm = ({ feedbackSpace, setOpen }) => {
    const [name, setName] = useState(feedbackSpace.feedbackSpaceName);
    const content = appContentVar().EditFeedbackSpaceNameForm;
    const [submitName, { error, loading }] = useMutation(
        EDIT_FEEDBACK_SPACE_NAME_MUTATION,
        {
            onCompleted: () => {
                setOpen(false);
                reduxActions.addNotification(content.messageSuccess[0]);
            }
        }
    );
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const handleFieldChange = (e) => {
        const value = verifyValueLength(e.target.value, 60);
        setName(value);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        submitName({
            variables: {
                feedbackSpaceId: feedbackSpace.id,
                feedbackSpaceName: name
            }
        })
            .then((data) => {})
            .catch((e) => {})
            .then(() => {
                setOpen(false);
            });
    };

    return (
        <div ref={containerRef}>
            <Card>
                <Title>{content.title[0]}</Title>
                <ConditionalOutput error={error} loading={loading}>
                    <form
                        onSubmit={handleSubmit}
                        className={
                            "flex flex-row min-w-fit gap-x-4 items-center"
                        }>
                        <div className={"w-full"}>
                            <InputLabelCount
                                label={content.inputLabel[0]}
                                count={
                                    <p
                                        className={
                                            "label_small text-secondary-800"
                                        }>{`${name.length}/60`}</p>
                                }
                                input={
                                    <Input
                                        onChange={handleFieldChange}
                                        required={true}
                                        type={"text"}
                                        value={name}
                                        autoFocus
                                    />
                                }></InputLabelCount>
                        </div>
                        <Button type={"submit"} variant={"filled"}>
                            {content.button[0]}
                        </Button>
                    </form>
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default EditFeedbackSpaceNameForm;
