import React from "react";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { ME_QUERY } from "GraphQL/Queries";
import { Loading, LoginContent } from "ComponentIndex";

const redirectSelection = (error, data) => {
    console.log("Ppublic route",error,data)
    if (error && error.message === "Not logged in") {
        return <LoginContent />;
    } else if (error && error.message === "Email is not verified") {
        return <Navigate to={"/resend-verification"} />;
    } else if (data && data.me.email && data.me.isVerified) {
        return <Navigate to={"/app/*"} />;
    } else if (data && data.me.email && !data.me.isVerified) {
        return <Navigate to={"/resend-verification"} />;
    }else{
        return <LoginContent />
    }
};

const PublicRoute = () => {
    const { data, loading, error } = useQuery(ME_QUERY);
    console.log('Public route')
    if (loading) return <Loading />;

    return redirectSelection(error, data);
};

export default PublicRoute;
