import { cardStyle } from "./Styles";
import { Content, Title } from "./Utils";
import {
    FEATURE_TOUR_TEXT,
    FEATURE_TOUR_TITLES,
    SETUP_TOUR_TEXT,
    SETUP_TOUR_TITLES,
    INVITATIONS_TOUR_TEXT,
    INVITATIONS_TOUR_TITLES,
    MANAGE_SKILLS_TOUR_TEXT,
    MANAGE_SKILLS_TOUR_TITLES,
    FEEDBACK_TOUR_TITLES,
    FEEDABCK_TOUR_TEXT,
    INSIGHTS_TOUR_TITLES,
    INSIGHTS_TOUR_TEXT
} from "./Content";

export const FEATURE_TOUR = [
    {
        content: Content(FEATURE_TOUR_TEXT[1]),
        title: Title(FEATURE_TOUR_TITLES[1]),
        target: "#dashboard",
        disableBeacon: true,
        placement: "left",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[2]),
        title: Title(FEATURE_TOUR_TITLES[2]),
        target: "#feedback",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[3]),
        title: Title(FEATURE_TOUR_TITLES[3]),
        target: "#insights",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[4]),
        title: Title(FEATURE_TOUR_TITLES[4]),
        target: "#feedback_space_select",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[5]),
        title: Title(FEATURE_TOUR_TITLES[5]),
        target: "#skill_management",
        placement: "top",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[6]),
        title: Title(FEATURE_TOUR_TITLES[6]),
        target: "#profile",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[7]),
        title: Title(FEATURE_TOUR_TITLES[7]),
        target: "#sync_button",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(FEATURE_TOUR_TEXT[8]),
        title: Title(FEATURE_TOUR_TITLES[8]),
        target: "#help",
        placement: "right",
        styles: cardStyle
    }
];

export const SETUP_TOUR = [
    {
        content: Content(SETUP_TOUR_TEXT[1]),
        title: Title(SETUP_TOUR_TITLES[1]),
        target: "#profile",
        disableBeacon: true,
        placement: "left",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[2]),
        title: Title(SETUP_TOUR_TITLES[2]),
        target: "#data-source",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[3]),
        title: Title(SETUP_TOUR_TITLES[3]),
        target: "#feedback-spaces-tab",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[4]),
        title: Title(SETUP_TOUR_TITLES[4]),
        target: "#feedback-space-list",
        placement: "left",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[5]),
        title: Title(SETUP_TOUR_TITLES[5]),
        target: "#manage-sync-parameters",
        placement: "top",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[6]),
        title: Title(SETUP_TOUR_TITLES[6]),
        target: "#invite-members",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[7]),
        title: Title(SETUP_TOUR_TITLES[7]),
        target: "#skills-allocation",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[8]),
        title: Title(SETUP_TOUR_TITLES[8]),
        target: "#notifications-tettings",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[9]),
        title: Title(SETUP_TOUR_TITLES[9]),
        target: "#manage-roles",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[10]),
        title: Title(SETUP_TOUR_TITLES[10]),
        target: "#invitations-tab",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(SETUP_TOUR_TEXT[11]),
        title: Title(SETUP_TOUR_TITLES[11]),
        target: "body",
        placement: "center",
        styles: cardStyle
    }
];

export const INVITATIONS_TOUR = [
    {
        content: Content(INVITATIONS_TOUR_TEXT[1]),
        title: Title(INVITATIONS_TOUR_TITLES[1]),
        disableBeacon: true,
        target: "#notifications",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INVITATIONS_TOUR_TEXT[2]),
        title: Title(INVITATIONS_TOUR_TITLES[2]),
        target: "#profile",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INVITATIONS_TOUR_TEXT[3]),
        title: Title(INVITATIONS_TOUR_TITLES[3]),
        target: "#invitations-tab",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INVITATIONS_TOUR_TEXT[4]),
        title: Title(INVITATIONS_TOUR_TITLES[4]),
        target: "body",
        placement: "center",
        styles: cardStyle
    }
];

export const MANAGE_SKILLS_TOUR = [
    {
        content: Content(MANAGE_SKILLS_TOUR_TEXT[1]),
        title: Title(MANAGE_SKILLS_TOUR_TITLES[1]),
        disableBeacon: true,
        target: "#skills-setup",
        placement: "top",
        styles: cardStyle
    },
    {
        content: Content(MANAGE_SKILLS_TOUR_TEXT[2]),
        title: Title(MANAGE_SKILLS_TOUR_TITLES[2]),
        target: "#add-skills",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(MANAGE_SKILLS_TOUR_TEXT[3]),
        title: Title(MANAGE_SKILLS_TOUR_TITLES[3]),
        target: "#select-skill-type",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(MANAGE_SKILLS_TOUR_TEXT[4]),
        title: Title(MANAGE_SKILLS_TOUR_TITLES[4]),
        target: "#skill-examples",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(MANAGE_SKILLS_TOUR_TEXT[5]),
        title: Title(MANAGE_SKILLS_TOUR_TITLES[5]),
        target: "#skills-inventory",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(MANAGE_SKILLS_TOUR_TEXT[6]),
        title: Title(MANAGE_SKILLS_TOUR_TITLES[6]),
        target: "body",
        placement: "center",
        styles: cardStyle
    }
];

export const FEEBACK_TOUR = [
    {
        content: Content(FEEDABCK_TOUR_TEXT[1]),
        title: Title(FEEDBACK_TOUR_TITLES[1]),
        disableBeacon: true,
        target: "#feedback",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(FEEDABCK_TOUR_TEXT[2]),
        title: Title(FEEDBACK_TOUR_TITLES[2]),
        disableBeacon: true,
        target: "#pending_tasks",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(FEEDABCK_TOUR_TEXT[3]),
        title: Title(FEEDBACK_TOUR_TITLES[3]),
        disableBeacon: true,
        target: "#feedback_button",
        placement: "left",
        styles: cardStyle
    },
    {
        content: Content(FEEDABCK_TOUR_TEXT[4]),
        title: Title(FEEDBACK_TOUR_TITLES[4]),
        disableBeacon: true,
        target: "#skip_button",
        placement: "left",
        styles: cardStyle
    },
    {
        content: Content(FEEDABCK_TOUR_TEXT[5]),
        title: Title(FEEDBACK_TOUR_TITLES[5]),
        disableBeacon: true,
        target: "#given_tasks",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(FEEDABCK_TOUR_TEXT[6]),
        title: Title(FEEDBACK_TOUR_TITLES[6]),
        disableBeacon: true,
        target: "#skiped_tasks",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(FEEDABCK_TOUR_TEXT[7]),
        title: Title(FEEDBACK_TOUR_TITLES[7]),
        disableBeacon: true,
        target: "body",
        placement: "center",
        styles: cardStyle
    }
];

export const INSIGHTS_TOUR = [
    {
        content: Content(INSIGHTS_TOUR_TEXT[1]),
        title: Title(INSIGHTS_TOUR_TITLES[1]),
        disableBeacon: true,
        target: "#insights",
        placement: "right",
        styles: cardStyle
    },
    {
        content: Content(INSIGHTS_TOUR_TEXT[2]),
        title: Title(INSIGHTS_TOUR_TITLES[2]),
        disableBeacon: true,
        target: "#feedback_insights_tab",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INSIGHTS_TOUR_TEXT[3]),
        title: Title(INSIGHTS_TOUR_TITLES[3]),
        disableBeacon: true,
        target: "#feedback_insights_actions",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INSIGHTS_TOUR_TEXT[4]),
        title: Title(INSIGHTS_TOUR_TITLES[4]),
        disableBeacon: true,
        target: "#feedback_insights_info",
        placement: "left",
        styles: cardStyle
    },
    {
        content: Content(INSIGHTS_TOUR_TEXT[5]),
        title: Title(INSIGHTS_TOUR_TITLES[5]),
        disableBeacon: true,
        target: "#skills_insights_tab",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INSIGHTS_TOUR_TEXT[6]),
        title: Title(INSIGHTS_TOUR_TITLES[6]),
        disableBeacon: true,
        target: "#skills_insights_actions",
        placement: "bottom",
        styles: cardStyle
    },
    {
        content: Content(INSIGHTS_TOUR_TEXT[7]),
        title: Title(INSIGHTS_TOUR_TITLES[7]),
        disableBeacon: true,
        target: "body",
        placement: "center",
        styles: cardStyle
    }
];
