import { useQuery } from "@apollo/client";
import { useUnifiedFilterItems } from "HookIndex";
import { SKILLS_VARIANCE_QUERY } from "GraphQL/Queries";

export const useGetRangeAvgSkillData = () => {
    const {
        currentFeedbackSpace,
        taskEndDate,
        taskStartDate,
        assigneeParametersIds,
        skillIds,
        projectIds,
        skillTypes,
        taskTypeNames,
        dataSourceIds
    } = useUnifiedFilterItems();
    const { data, loading, error } = useQuery(SKILLS_VARIANCE_QUERY, {
        variables: {
            feedbackSpaceId: currentFeedbackSpace.id,
            tskStartDate: taskStartDate,
            tskEndDate: taskEndDate,
            assigneeParametersIds: assigneeParametersIds,
            skillIds: skillIds,
            projectIds: projectIds,
            skillTypes: skillTypes,
            taskTypeNames: taskTypeNames,
            dataSourceIds: dataSourceIds
        },
        fetchPolicy: "network-only"
    });

    return { data, loading, error };
};
