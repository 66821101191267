import React from "react";
import cn from "classnames";
import { useSelect } from "downshift";
import { appContentVar } from "GraphQL/ReactiveVariables";
import {
    Icon,
    DownshiftItemList,
    DownshiftListItem,
    DownshiftEmptyItem
} from "ComponentIndex";
import { masterAcc } from "UtilityFunctions/DataAccessors";
import { makeChanges, useSetPreferance, stringifyItems } from "./Utils";

const buttonClass = cn([
    "w-full text-white truncate",
    " border border-secondary-500",
    "rounded py-2 px-2 focus:ring-2 focus:ring-inset",
    "focus:border-shocking-700  focus:ring-shocking-700",
    "outline-none flex flex-row justify-between"
]);

const FeedbackSpaceSelect = ({ data, currentFeedbackSpace, id }) => {
    const content = appContentVar().FeedbackSpaceSelect;
    const setPreferance = useSetPreferance();
    const {
        isOpen,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps
    } = useSelect({
        items: data,
        itemToString: (item) => stringifyItems(item),
        selectedItem: currentFeedbackSpace,
        onSelectedItemChange: (changes) => {
            makeChanges(changes, setPreferance);
        }
    });

    return (
        <div id={id} className={"relative w-full"}>
            <button
                className={buttonClass}
                variant={"positive"}
                type='button'
                {...getToggleButtonProps()}>
                <span className={"w-52 truncate"}>
                    {selectedItem
                        ? masterAcc(
                              selectedItem,
                              "feedbackSpace",
                              "name"
                          )
                        : ""}
                </span>
                <Icon
                    size={"small"}
                    name={"arrow_drop_down"}
                    color={"light"}
                />
            </button>
            <DownshiftItemList
                color={"dark"}
                menuProps={getMenuProps}
                position={"absolute top-12 w-full"}
                isOpen={isOpen}>
                {isOpen ? (
                    data.length ? (
                        data.map((item, index) => (
                            <DownshiftListItem
                                textColor={"light"}
                                key={`${masterAcc(
                                    item,
                                    "feedbackSpace",
                                    "id"
                                )}${index}`}
                                title={masterAcc(
                                    item,
                                    "feedbackSpace",
                                    "name"
                                )}
                                item={masterAcc(
                                    item,
                                    "feedbackSpace",
                                    "name"
                                )}
                                index={index}
                                itemProps={getItemProps}
                                highlightedIndex={highlightedIndex}>
                                {masterAcc(
                                    item,
                                    "feedbackSpace",
                                    "name"
                                )}
                            </DownshiftListItem>
                        ))
                    ) : (
                        <DownshiftEmptyItem textColor={"light"}>
                            {content[0]}
                        </DownshiftEmptyItem>
                    )
                ) : null}
            </DownshiftItemList>
        </div>
    );
};

export default FeedbackSpaceSelect;
