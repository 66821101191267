import React, { useState, useEffect } from "react";
import classnames from "classnames";
import cls from "./RangeSlider.module.css";

const RangeSlider = ({
    min,
    max,
    rangeState = [0, 0],
    setRangeState
}) => {
    const [firstVal, setFirstVal] = useState(rangeState[0]);
    const [secondVal, setSecondVal] = useState(rangeState[1]);

    useEffect(() => {
        setRangeState([
            Math.min(firstVal, secondVal),
            Math.max(firstVal, secondVal)
        ]);
    }, [firstVal, secondVal]);

    const leftSlider = classnames(cls["thumb"], cls["thumb--left"]);
    const rightSlider = classnames(cls["thumb"], cls["thumb--right"]);
    const sliderTrack = classnames(cls["slider__track"]);

    const container = classnames(cls["container"]);
    const handleFirstValChange = (e) => {
        setFirstVal(parseInt(e.target.value));
    };

    const handleSecondValChange = (e) => {
        setSecondVal(parseInt(e.target.value));
    };

    return (
        <div className={container}>
            <div className={sliderTrack} />
            <input
                type={"range"}
                min={min}
                max={max}
                value={firstVal}
                className={leftSlider}
                onChange={handleFirstValChange}
                id={"slider-1"}
            />
            <input
                type={"range"}
                min={min}
                max={max}
                value={secondVal}
                className={rightSlider}
                onChange={handleSecondValChange}
                id={"slider-2"}
            />
        </div>
    );
};

export default RangeSlider;
