import React from "react";
import cn from "classnames";
import { useSelect } from "downshift";
import cls from "./ComboBoxIcon.module.css";

import {
    Capsule,
    DownshiftEmptyItem,
    DownshiftItemList,
    DownshiftListItem
} from "ComponentIndex";

function stateReducer(state, actionAndChanges) {
    const { changes, type } = actionAndChanges;
    switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
            return {
                ...changes,
                isOpen: true, // keep menu open after selection.
                highlightedIndex: state.highlightedIndex
            };
        default:
            return changes;
    }
}

const ComboBoxIcon = ({
    items,
    options,
    setOptions,
    children,
    size,
    color,
    emptyMessage,
    itemToString,
    itemPrefix
}) => {
    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        highlightedIndex,
        getItemProps
    } = useSelect({
        items,
        stateReducer,
        itemToString: (item) => itemToString(item),
        selectedItem: null,
        onSelectedItemChange: ({ selectedItem }) => {
            if (!selectedItem) {
                return;
            }
            const index = options.indexOf(selectedItem);
            if (index > 0) {
                setOptions([
                    ...options.slice(0, index),
                    ...options.slice(index + 1)
                ]);
            } else if (index === 0) {
                setOptions([...options.slice(1)]);
            } else {
                setOptions([...options, selectedItem]);
            }
        }
    });
    const iconButtonClass = cn(cls.iconButton, {
        [cls.md_12]: size === "md_12",
        [cls.md_18]: size === "md_18",
        [cls.md_24]: size === "md_24",
        [cls.md_36]: size === "md_36",
        [cls.md_48]: size === "md_48",
        [cls.activePrimary]: color === "activePrimary",
        [cls.activeSecondary]: color === "activeSecondary",
        [cls.primaryColor]: color === "primary",
        [cls.secondaryColor]: color === "secondary"
    });

    const selectionText = options.length ? `(${options.length})` : null;
    // NOTE: Did no use IconButton element as downshit has issue applying ref to component
    // with native button component  it works well but styles should be reapplyed
    return (
        <div className={"relative"}>
            <Capsule>
                <button
                    className={iconButtonClass}
                    {...getToggleButtonProps()}>
                    {children}
                </button>
                {!!options.length && (
                    <>
                        <p className={"label_medium text-shocking-50"}>
                            {selectionText}
                        </p>
                        <div
                            className={
                                "flex items-center h-full my-0 mx-1"
                            }>
                            <span
                                className={
                                    "material-symbols-rounded text-shocking-50 cursor-pointer text-base"
                                }
                                onClick={(e) => {
                                    setOptions([]);
                                }}>
                                {"close"}
                            </span>
                        </div>
                    </>
                )}
            </Capsule>

            <DownshiftItemList
                menuProps={getMenuProps}
                isOpen={isOpen}
                position={"absolute top-8 right-0 max-w-xs"}>
                {isOpen ? (
                    items.length ? (
                        items.map((item, index) => (
                            <DownshiftListItem
                                title={item[itemPrefix]}
                                index={index}
                                key={index}
                                itemProps={getItemProps}
                                highlightedIndex={highlightedIndex}>
                                <input
                                    type='checkbox'
                                    className={"accent-shocking-700"}
                                    checked={options.includes(item)}
                                    value={item[itemPrefix]}
                                    onChange={() => null}
                                />
                                <span />
                                <span
                                    className={
                                        "text-ellipsis overflow-hidden whitespace-nowrap "
                                    }>
                                    {item[itemPrefix]}
                                </span>
                            </DownshiftListItem>
                        ))
                    ) : (
                        <DownshiftEmptyItem>
                            {emptyMessage}
                        </DownshiftEmptyItem>
                    )
                ) : null}
            </DownshiftItemList>
        </div>
    );
};

export default ComboBoxIcon;
