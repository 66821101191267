import React from "react";
import { useTable } from "react-table";
import { CellWrapper, ReactTableCell } from "ComponentIndex";
import { v4 } from "uuid";

function SkillsMatrixTable({ skillMatrix }) {
    const data = React.useMemo(() => {
        const body = skillMatrix.body;

        // build table data
        const tableData = body.map((row) => {
            const rowData = {
                assignee: row.assignee
            };

            row.skills.forEach((skill) => {
                rowData[skill.skillName] = {
                    occurance: skill.occurancess,
                    feedbackValue: skill.feedbackHex
                };
            });

            return rowData;
        });

        return tableData;
    }, [skillMatrix]);

    const columns = React.useMemo(() => {
        const header = skillMatrix.header;

        // build table columns
        const tableColumns = [
            {
                Header: () => (
                    <th className={"max-h-6 w-20 truncate"}>
                        {"Assignee"}
                    </th>
                ),
                accessor: "assignee",
                id: "assigneeCol",
                Cell: ({ cell }) => {
                    return (
                        <td className={"text-bold px-1 w-20 max-h-20"}>
                            {cell.value}
                        </td>
                    );
                }
            }
        ];

        header.forEach((skillName) => {
            tableColumns.push({
                Header: () => (
                    <th>
                        <div className={" w-20 line-clamp-4 "}>
                            {skillName}
                        </div>
                    </th>
                ),
                accessor: skillName,
                Cell: ({ cell }) => {
                    let valueObj = cell.value
                        ? cell.value
                        : { occurance: 0, feedbackValue: "#FFFFFF" };
                    return (
                        <td
                            className={"w-20 h-20 text-center px-2"}
                            style={{
                                backgroundColor: valueObj.feedbackValue
                            }}>
                            {valueObj.occurance}
                        </td>
                    );
                }
            });
        });

        return tableColumns;
    }, [skillMatrix]);

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <table
            className={"mx-auto w-full sm:w-auto"}
            {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <CellWrapper key={v4()}>
                                {/* <th {...column.getHeaderProps()}> */}
                                {column.render("Header")}
                                {/* </th> */}
                            </CellWrapper>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <CellWrapper key={v4()}>
                                        {/* <td {...cell.getCellProps()}> */}
                                        {cell.render("Cell")}
                                        {/* </td> */}
                                    </CellWrapper>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default SkillsMatrixTable;
