import React, { useState } from "react";
import { ButtonDialog, UnifiedSyncParameters } from "ComponentIndex";

const ManageSyncParameters = ({ feedbackSpace }) => {
    const [open, setOpen] = useState(false);
    return (
        <ButtonDialog
            id={"manage-sync-parameters"}
            label={"Sync setup"}
            icon={"sync"}
            variant={"tonal"}
            open={open}
            setOpen={setOpen}>
            <UnifiedSyncParameters
                setOpen={setOpen}
                feedbackSpace={feedbackSpace}
            />
        </ButtonDialog>
    );
};

export default ManageSyncParameters;
