export const skillContentTypeReducer = (
    state = "noneSelected",
    action
) => {
    switch (action.type) {
        case "INT":
            return "interpersonal";
        case "ASP":
            return "areaSpecific";
        case "TEC":
            return "technical";
        case "MNG":
            return "management";
        default:
            return state;
    }
};

export const areaSpecificSkillsReducer = (state = [], action) => {
    switch (action.type) {
        case "loadAreaSpecificSkills":
            return action.payload;
        case "updateAreaSpecificSkills":
            return action.payload;
        default:
            return state;
    }
};

export const technicalSkillsReducer = (state = [], action) => {
    switch (action.type) {
        case "loadTechnicalSkills":
            return action.payload;
        case "updateTehnicalSkills":
            return action.payload;
        default:
            return state;
    }
};

export const interpersonalSkillsReducer = (state = [], action) => {
    switch (action.type) {
        case "loadInterpersonalSkills":
            return action.payload;
        case "updateInterpersonalSkills":
            return action.payload;
        default:
            return state;
    }
};

export const mangementSkillsReducer = (state = [], action) => {
    switch (action.type) {
        case "loadManagementSkills":
            return action.payload;
        case "updateManagementSkills":
            return action.payload;
        default:
            return state;
    }
};
