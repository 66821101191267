import React from "react";
import { useDashboardData } from "../DashroardWidgets/Utils";
import { ConditionalOutput, InfoWidget, Icon } from "ComponentIndex";

const IconDefinition = (skippedRatio)=>{
    switch (skippedRatio) {
        case skippedRatio > 66:
            return <Icon name={"cancel"} color={"thunderbird"} size={"2xlarge"}/>
        
        case skippedRatio > 33:
                return <Icon name={'warning'} color={"old-gold"} size={"2xlarge"}/>
    
        default:
            return <Icon name={"check_circle"} color={"la-palma"} size={"2xlarge"}/>
    }
}




const DashboardWidgets = () => {
    const { data, error, loading, pendingPercent, givenPercent, skippedPercent, skippedRatio} = useDashboardData();

    console.log("Widget pending percent", pendingPercent)
    console.log("Widget  given  percent", givenPercent)
    console.log("Widget  skipped percent", skippedPercent)

    

    return (
        <ConditionalOutput error={error} loading={loading}>
            {data && data.dashboardData.avgFeedbackTime !== null ? (
                <div
                    className={
                        "flex flex-row flex-wrap gap-16 content-around justify-between"
                    }>
                    <InfoWidget type={"primary"}>
                        <h1
                            className={
                                "title_medium text-shocking-700 text-center"
                            }>
                            {"AVG waiting time to feedback"}
                        </h1>

                        <h2
                            className={
                                "display_small text-secondary-900 text-center"
                            }>
                            {`${data.dashboardData.avgFeedbackTime} Days`}
                        </h2>
                    </InfoWidget>
                    <div className={"flex flex-row flex-wrap gap-16 content-around justify-between"}>
                        <div className={"flex flex-col gap-16"}>
                    <InfoWidget>
                        <h1
                            className={
                                "title_medium text-secondary-900 text-center"
                            }>
                            {"Pending tasks"}
                        </h1>

                        <h2
                            className={
                                "display_large text-secondary-900 text-center"
                            }>
                            {data.dashboardData.pendingTasks}
                        </h2>
                        
                    </InfoWidget>
                    <InfoWidget>
                        <h1
                            className={
                                "title_medium text-secondary-900 text-center"
                            }>
                            {"% Pending tasks"}
                        </h1>

                        <h2
                            className={
                                "display_small text-secondary-900 text-center"
                            }>
                            {`${pendingPercent}%`}
                        </h2>
                    </InfoWidget>
                    </div>
                    <div className={"flex flex-col gap-16"}>
                    <InfoWidget>
                        <h1
                            className={
                                "title_medium text-secondary-900 text-center"
                            }
                            weigth={"medium"}
                            color={"dark"}>
                            {"Feedback given"}
                        </h1>

                        <h2
                            className={
                                "display_large text-secondary-900 text-center"
                            }>
                            {data.dashboardData.givenTasks}
                        </h2>
                        
                    </InfoWidget>
                    <InfoWidget>
                        <h1
                            className={
                                "title_medium text-secondary-900 text-center"
                            }
                            weigth={"medium"}
                            color={"dark"}>
                            {"% Feedback given"}
                        </h1>

                        <h2
                            className={
                                "display_small text-secondary-900 text-center"
                            }>
                            {`${givenPercent}%`}
                        </h2>
                    </InfoWidget>
                    </div>
                    <div className={"flex flex-col gap-16"}>
                    <InfoWidget>
                        <h1
                            className={
                                "tour-policy title_medium text-secondary-900 text-center align-middle"
                            }>
                            {"Skipped tasks  "}<br/>{IconDefinition(skippedRatio)}
                        </h1>

                        <h2
                            className={
                                "display_large text-secondary-900 text-center"
                            }>
                            {data.dashboardData.skippedTasks}
                        </h2>
                    </InfoWidget>
                    <InfoWidget>
                        <h1
                            className={
                                "tour-policy title_medium text-secondary-900 text-center align-middle"
                            }>
                            {"% Skipped tasks  "}<br/>{IconDefinition(skippedRatio)}
                        </h1>
                        <h2
                            className={
                                "display_small text-secondary-900 text-center"
                            }>
                            {`${skippedPercent}%`}
                        </h2>
                    </InfoWidget>
                    </div>
                    </div>
                </div>
            ) : null}
        </ConditionalOutput>
    );
};

export default DashboardWidgets;
