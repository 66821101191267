import React from "react";
import { NavigationBar, FeedbackTabBar } from "ComponentIndex";

const TaskFeedbackNavigation = () => {
    return (
        <NavigationBar>
            <FeedbackTabBar />
        </NavigationBar>
    );
};

export default TaskFeedbackNavigation;
