import React, { useRef } from "react";
import classnames from "classnames";
import cls from "./LeaveFeedbackSpaceDialog.module.css";
import { useClickOutside } from "HookIndex";
import { Button, Title, ConditionalOutput, Card } from "ComponentIndex";

import { useLeaveFeedbackSpace } from "./Utils";

const LeaveFeedbackSpaceDialog = ({ feedbackSpace, setOpen }) => {
    const containerRef = useRef(null);
    useClickOutside(containerRef, setOpen);
    const { handleFeedbackSpaceLeave, error, loading } =
        useLeaveFeedbackSpace(setOpen, feedbackSpace);

    const buttonSpaceClass = classnames(cls.buttonSpace);
    return (
        <div ref={containerRef}>
            <Card>
                <ConditionalOutput error={error} loading={loading}>
                    <Title>
                        {`You are about to leave ${feedbackSpace.feedbackSpaceName}`}
                    </Title>
                    <p
                        className={
                            "label_large text-shocking-700"
                        }>{`Are you sure?`}</p>
                    <div className={buttonSpaceClass}>
                        <Button
                            variant={"filled"}
                            onClick={() => setOpen(false)}>
                            No
                        </Button>
                        <Button
                            onClick={handleFeedbackSpaceLeave}
                            variant={"outline"}>
                            Yes
                        </Button>
                    </div>
                </ConditionalOutput>
            </Card>
        </div>
    );
};

export default LeaveFeedbackSpaceDialog;
