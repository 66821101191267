import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    HelmetExclude,
    ContentWrapperBand,
    PageToScreenWrapper,
    Loading
} from "ComponentIndex";

const SignInContent = () => {
    // useEffect(() => {
    //     const hash = window.location;
    //     const params = new URLSearchParams(hash);
    //     const accessToken = params.get("id_token");

    //     console.log("Params", params);
    //     console.log("HASH", hash);
    //     console.log("TOKEN", accessToken);
    // }, []);
    console.log("LOADING TEST")

    

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const formData = new FormData();
    
        // Retrieve the form data from the URL search parameters
        for (const [key, value] of urlSearchParams.entries()) {
          formData.append(key, value);
        }
    
        // Access the form data values
        const formValue = formData.get('id_token');
        
    }, []);

    return (
        <>
            <HelmetExclude />
            <ContentWrapperBand color={"bg-white"}>
                <PageToScreenWrapper>
                    <Loading />
                </PageToScreenWrapper>
            </ContentWrapperBand>
        </>
    );
};

export default SignInContent;
