import { GET_TASKS_QUERY } from "GraphQL/Queries";
import { useQuery } from "@apollo/client";

export let setOperationsDisplay = (febdStatus) => {
    let opDisplay = {};
    switch (febdStatus) {
        case "PE":
            opDisplay["dispalyFeedback"] = true;
            opDisplay["displaySkip"] = true;
            opDisplay["displayEdit"] = false;
            opDisplay["displayView"] = false;
            break;
        case "GV":
            opDisplay["dispalyFeedback"] = false;
            opDisplay["displayEdit"] = true;
            opDisplay["displaySkip"] = false;
            opDisplay["displayView"] = true;

            break;
        case "NA":
            opDisplay["dispalyFeedback"] = true;
            opDisplay["displayEdit"] = false;
            opDisplay["displaySkip"] = false;
            opDisplay["displayView"] = false;
            break;
        default:
            opDisplay["dispalyFeedback"] = true;
            opDisplay["displaySkip"] = true;
            opDisplay["displayEdit"] = false;
            opDisplay["displayView"] = false;
            break;
    }
    return opDisplay;
};

export let useFeedbackQuery = (variables) => {
    return useQuery(GET_TASKS_QUERY, {
        variables: variables,
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    });
};
