import React from "react";
import { useMutation, useLazyQuery } from "@apollo/client";

import reduxActions from "State/ActionIndex";
import { Card, Button, ConditionalOutput, Title } from "ComponentIndex";
import { RESPOND_TO_FEEDBACK_SPACE_INVITATION_MUTATION } from "GraphQL/Mutations";
import { GET_MY_FEEDBACK_SPACES_QUERY } from "GraphQL/Queries";
import { clearCacheItem } from "GraphQL/Cache";

const FeedbackSpaceInvitationCard = ({
    invitation,
    feedbackSpaceId
}) => {
    const [respondInvitation, { data, error, loading }] = useMutation(
        RESPOND_TO_FEEDBACK_SPACE_INVITATION_MUTATION,
        {
            onCompleted: () => {
                clearCacheItem(invitation);
            },
            refetchQueries: [
                {
                    query: GET_MY_FEEDBACK_SPACES_QUERY,
                    variables: { feedbackSpaceId: feedbackSpaceId }
                }
            ]
        }
    );

    const handleInvitationAcceptClick = (e) => {
        respondInvitation({
            variables: {
                invitationId: invitation.id,
                response: true,
                feedbackSpaceId: feedbackSpaceId
            }
        });
        reduxActions.addNotification(
            "You have accepted feedback space invitation.",
            "message"
        );
    };
    const handleInvitationDeclineClick = (e) => {
        respondInvitation({
            variables: {
                invitationId: invitation.id,
                response: false,
                feedbackSpaceId: feedbackSpaceId
            }
        });
        reduxActions.addNotification(
            "Invitation has been declined.",
            "message"
        );
    };

    return (
        <Card type={"light"}>
            <div className={"flex flex-col w-full content-center"}>
                <Title>
                    <>
                        <span
                            className={"label_small text-shocking-700"}>
                            {`Feedback space: `}
                        </span>
                        <span
                            className={
                                "title_medium text-secondary-900"
                            }>
                            {`${invitation.feedbackSpace.feedbackSpaceName}`}
                        </span>
                    </>
                </Title>

                <ConditionalOutput error={error} loading={loading}>
                    <div
                        className={
                            "self-end flex flex-row mt-4 gap-x-4"
                        }>
                        <Button
                            variant={"filled"}
                            onClick={handleInvitationAcceptClick}>
                            Accept
                        </Button>
                        <Button
                            variant={"outline"}
                            onClick={handleInvitationDeclineClick}>
                            Decline
                        </Button>
                    </div>
                </ConditionalOutput>
            </div>
        </Card>
    );
};

export default FeedbackSpaceInvitationCard;
