import React from "react";

const TextPictureLeft = ({ children, text, header = false }) => {
    return (
        <div
            className={
                "flex flex-col-reverse items-center gap-y-4 gap-x-4 md:flex-row md:justify-between lg:w-10/12"
            }>
            {children}
            <div className={"flex flex-col md:pl-16 gap-y-4 md:w-2/3"}>
                {header ? (
                    <h3 className={"title_large text-shocking-700 "}>
                        {header}
                    </h3>
                ) : null}
                {text}
            </div>
        </div>
    );
};

export default TextPictureLeft;
